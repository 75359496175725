import * as angular from 'angular';
import { Component, OnInit } from 'angular-ts-decorators';
import * as Highcharts from 'highcharts';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Classifier, Tag } from '../../core/classes/classes';
import { ConstService } from '../../../app/shared/const.service';

@Component({
  selector: 'classifierComponent',
  templateUrl: './classifier.pug'
})
export class ClassifierComponent implements OnInit {

  static $inject: string[] = [
    '$scope',
    '$q',
    'classifierService',
    'domainsResource',
    'classifierResource',
    '$http',
    'Notification'
  ];
  public dataset_list: any[] = [];
  public selectedEntity: any = null;
  public selectedDataset: any = {id: null, name: 'Default', dataset_name: true, updated_at: true};
  public gridConfigTagUrls: any = {
    data: [],
    enableFiltering: false,
    enableRowSelection: true,
    enableSelectAll: true,
    enableColumnMenus: false,
    showGridFooter: true,
    showTreeExpandNoChildren: false,
    enableGridMenu: true,
    gridMenuShowHideColumns: false,
    exporterMenuPdf: false,
    enableGroupHeaderSelection: true,

    exporterCsvFilename: 'segments_' + moment().format('DD_MM_YYYY') + '.csv',
    exporterCsvLinkElement: angular.element(document.querySelectorAll('.custom-csv-link-location')),
    exporterCsvColumnSeparator: ';',

    columnDefs: [
      {
        field: 'url', displayName: 'URL',
        width: '*',
        headerCellFilter: 'translate'
      },
      {
        field: 'title', displayName: 'Title',
        width: '*',
        headerCellFilter: 'translate'
      }
    ]
  };
  public gridConfigPredictReport: any = {
    data: [],
    enableFiltering: false,
    enableRowSelection: true,
    enableSelectAll: true,
    enableColumnMenus: false,
    showGridFooter: false,
    showTreeExpandNoChildren: false,
    enableGridMenu: true,
    gridMenuShowHideColumns: false,
    exporterMenuPdf: false,
    enableGroupHeaderSelection: true,
    paginationPageSizes: [250, 1000, 10000, 100000],
    paginationPageSize: 250,
    useExternalPagination: true,

    onRegisterApi: (gridApi: any) => {
      gridApi.pagination.on.paginationChanged(this.$scope, (newPage: any) => {
        this.selectPredictReportPage(newPage);
      });
    },

    exporterCsvFilename: 'dataset_' + moment().format('DD_MM_YYYY') + '.csv',
    exporterCsvLinkElement: angular.element(document.querySelectorAll('.custom-csv-link-location')),
    exporterCsvColumnSeparator: ';',

    columnDefs: [
      {
        field: 'name', displayName: 'URL',
        width: '*',
        headerCellFilter: 'translate'
      },
      {
        field: 'proba', displayName: 'Proba',
        width: '200',
        headerCellFilter: 'translate'
      }
    ]
  };
  // todo: (prokopenko) duplication of domains search
  public gridConfigDomains: any = {
    data: [],
    enableFiltering: false,
    enableRowSelection: false,
    enableSelectAll: true,
    enableColumnMenus: false,
    showGridFooter: false,
    showTreeExpandNoChildren: false,
    enableGridMenu: true,
    gridMenuShowHideColumns: false,
    exporterMenuPdf: false,
    enableGroupHeaderSelection: false,
    exporterOlderExcelCompatibility: true,

    paginationPageSizes: [250, 1000, 10000, 100000],
    paginationPageSize: 250,
    useExternalPagination: true,

    onRegisterApi: (gridApi: any) => {
      gridApi.pagination.on.paginationChanged(this.$scope, (newPage: any) => {
        this.searchDomains(newPage);
      });
    },

    columnDefs: [
      {
        field: 'domain',
        displayName: 'Domain',
        headerCellFilter: 'translate',
        cellTemplate: `<div class='ui-grid-cell-contents'><a href='http://{{row.entity.domain}}' target='_blank'>{{row.entity.domain}}</a></div>`,
        width: '200'
      },
      {
        field: 'title',
        displayName: 'Title',
        headerCellFilter: 'translate',
        width: '*'
      },
      {
        field: 'uniqs',
        displayName: 'Uniqs',
        headerCellFilter: 'translate',
        cellFilter: 'number:0',
        type: 'number',
        width: '100'
      }
    ]
  };
  public query: any = '';
  public preloader = true;
  public datasetPreloader = true;
  public searchPreloader = false;
  public statsPreloader = false;
  public datasetCalcPreloader = false;
  public isValidationViewStats = false;
  public trasholdChartConfig: any;
  public gridApi: any;
  public currentReport: any;
  public defaultReport: any;
  public historyModel: any;
  public gridConfig: any = {
    data: [],
    enableFiltering: false,
    enableRowSelection: true,
    enableSelectAll: false,
    enableColumnMenus: false,
    showGridFooter: true,
    multiSelect: false,
    showTreeExpandNoChildren: false,
    enableGridMenu: true,
    gridMenuShowHideColumns: false,
    exporterMenuPdf: false,
    enableGroupHeaderSelection: false,

    // exporterCsvFilename: 'segments_' + moment().format("DD_MM_YYYY") + '.csv',
    // exporterCsvLinkElement: angular.element(document.querySelectorAll(".custom-csv-link-location")),
    // exporterCsvColumnSeparator: ';',

    onRegisterApi: (gridApi: any) => {
      this.gridApi = gridApi;
      // this.gridApi.grid.registerRowsProcessor(this.gridFilter, 200);
      this.gridApi.selection.on.rowSelectionChanged(this.$scope, (row: any) => {
        this.trasholdChartConfig.series.forEach((item: any) => {
          item.data.length = 0;
        });
        this.gridConfigPredictReport.data.length = 0;
        this.gridConfigTagUrls.data.length = 0;
        if(row.isSelected) {
          this.selectedEntity = row.entity;
          if(row.entity instanceof Classifier) {
            this.statsPreloader = true;

            let promiseDefaultReport = row.entity.getLastHistoryModel().then((historyModel: any) => {
              this.historyModel = historyModel;
              _.forOwn(this.historyModel.thresholds, (item: any, key: any) => {
                this.trasholdChartConfig.series[0].data.push({
                  x: parseFloat(key) * 100,
                  y: item.recall_score,
                  selected: (row.entity.percent / 100 === key)
                });
                this.trasholdChartConfig.series[1].data.push({
                  x: parseFloat(key) * 100,
                  y: item.f1_score,
                  selected: (row.entity.percent / 100 === key)
                });
                this.trasholdChartConfig.series[2].data.push({
                  x: parseFloat(key) * 100,
                  y: item.precision_score,
                  selected: (row.entity.percent / 100 === key)
                });
                this.trasholdChartConfig.series[3].data.push({
                  x: parseFloat(key) * 100,
                  y: item.FPR,
                  selected: (row.entity.percent / 100 === key)
                });
              });
              this.trasholdChartConfig.series.forEach((item: any) => {
                item.data.sort((a: any, b: any) => {
                  return a.x - b.x;
                });
              });

              // return historyModel.getPredictReport();
              return historyModel.getDefaultReport();
            }).then((defaultReport: any) => {
              this.defaultReport = defaultReport;
              this.currentReport = defaultReport;
              this.selectedDataset = {id: null, name: 'Default', dataset_name: true, updated_at: true};
              return this.classifierResource.get({
                object: 'predictreport',
                'report': defaultReport.id
              }).$promise;
            });
            let promiseUrls = row.entity.getUrls();

            this.statsPreloader = true;
            this.$q.all([promiseDefaultReport, promiseUrls]).then((responses: any) => {
              this.gridConfigPredictReport.data = responses[0].data.results;
              this.gridConfigPredictReport.totalItems = responses[0].data.count;
              this.gridConfigTagUrls.data = responses[1].data;
            }).catch(() => {
            }).finally(() => {
              this.statsPreloader = false;
            });
          } else if(row.entity instanceof Tag) {
            // this.selectedUrlGroup = this.urlGroups[1];
            this.isValidationViewStats = false;
            this.statsPreloader = true;
            row.entity.getUrls().then((response: any) => {
              this.gridConfigTagUrls.data = response.data;
            }).finally(() => {
              this.statsPreloader = false;
            });
          }
        }
      });
    },

    columnDefs: [
      {
        field: 'name', displayName: 'Name',
        width: '*',
        headerCellFilter: 'translate',
        cellTemplate: `<div class='ui-grid-cell-contents'>{{row.entity.name}} <a ui-sref=\"layout.classifier_edit({classifierId: row.entity.id})\" class='glyphicon glyphicon-cog classifier__edit_icon' ng-show='row.entity.name == \"classifier\"'></a></div>`,
        cellClass: function(grid: any, row: any) {
          let addClass = 'tree_level--' + row.treeLevel;
          if(row.entity instanceof Tag) {
            addClass += ' segments__group_row';
          }
          return addClass;
        }
      },
      {
        field: 'parent',
        visible: false,
        width: '100'
      },
      {
        field: 'train_set',
        displayName: 'Train set',
        headerCellFilter: 'translate',
        type: 'number',
        width: '100'
      },
      {
        field: 'urls',
        displayName: 'URLs',
        headerCellFilter: 'translate',
        type: 'number',
        width: '100'
      },
      {
        field: 'precision',
        displayName: 'Precision',
        headerCellFilter: 'translate',
        width: '150',
        type: 'number',
        cellFilter: 'number:0'
      },
      {
        field: 'recall',
        displayName: 'Recall',
        headerCellFilter: 'translate',
        width: '100'
      },
      {
        field: 'status',
        displayName: 'Status',
        width: '150',
        cellFilter: 'IsAvailable',
        headerCellFilter: 'translate',
        cellClass: 'to-center'
      }
    ]
  };

  constructor(private $scope: any,
              private $q: any,
              private classifierService: any,
              private domainsResource: any,
              private classifierResource: any,
              private $http: any,
              private Notification: any) {
  }

  ngOnInit(): void {
    this.searchDomains(undefined);

    this.trasholdChartConfig = this.getChartConfig({
      title: 'Trashold',
      type: 'spline',
      legend: true
    });

    this.classifierService.getClassifiers().then((classifier_grid_data: any) => {
      this.gridConfig.data = classifier_grid_data;
    }).finally(() => {
      this.preloader = false;
    });

    this.classifierService.getDatasetList().then((dataset_list: any) => {
      this.dataset_list = dataset_list;
      this.dataset_list.unshift({id: null, name: 'Default', dataset_name: true, updated_at: true});
    }).finally(() => {
      this.datasetPreloader = false;
    });
  }

  public searchDomains(page: any) {
    if(this.searchPreloader) {
      return;
    }
    this.searchPreloader = true;
    let data: any = {page_size: this.gridConfigDomains.paginationPageSize};
    this.query && (data.q = this.query);
    page && (data.page = page);
    this.domainsResource.query(data).$promise.then((response: any) => {
      let data = response.data;
      this.gridConfigDomains.data = data.results;
      this.gridConfigDomains.totalItems = data.count;
    }).catch(() => {
    }).finally(() => {
      this.searchPreloader = false;
    });
  }

  public getCreateClassifierLink() {
    if(!this.gridApi || this.gridApi.selection.getSelectedCount() !== 1 || !(this.gridApi.selection.getSelectedRows()[0] instanceof Tag)) {
      return '';
    } else {
      return '/classifier/' + this.gridApi.selection.getSelectedRows()[0].id + '/create';
    }
  }

  public selectPredictReportPage(page: any) {
    if(this.currentReport) {
      let data = {
        page_size: this.gridConfigPredictReport.paginationPageSize,
        object: 'predictreport',
        report: this.currentReport.id,
        page: page
      };
      this.classifierResource.get(data)
        .$promise
        .then((response: any) => {
          this.gridConfigPredictReport.data = response.data.results;
          this.gridConfigPredictReport.totalItems = response.data.count;
        });
    }
  }

  public selectDataset(dataset: any) {
    this.statsPreloader = true;
    this.selectedDataset = dataset;
    this.gridConfigPredictReport.data.length = 0;
    this.gridConfigPredictReport.totalItems = 0;

    if(!this.selectedDataset.id) {
      this.currentReport = this.defaultReport;
      this.classifierResource.get({object: 'predictreport', 'report': this.currentReport.id}).$promise
        .then((response: any) => {
          this.gridConfigPredictReport.data = response.data.results;
          this.gridConfigPredictReport.totalItems = response.data.count;
        })
        .finally(() => {
          this.statsPreloader = false;
        });
    } else {
      this.classifierResource.get({object: 'report', dataset: dataset.id, model: this.historyModel.id}).$promise
        .then((response: any) => {
          this.currentReport = response.data.reduce((previousValue: any, currentItem: any) => {
            if(!previousValue) {
              return currentItem;
            }
            return moment(previousValue.created_at) < moment(currentItem.created_at) ?
              currentItem :
              previousValue;
          }, null);
          if(this.currentReport) {
            this.classifierResource.get({object: 'predictreport', 'report': this.currentReport.id})
              .$promise
              .then((response: any) => {
                this.gridConfigPredictReport.data = response.data.results;
                this.gridConfigPredictReport.totalItems = response.data.count;
              }).finally(() => {
              this.statsPreloader = false;
            });
          } else {
            this.statsPreloader = false;
          }
        })
        .catch((error: any) => {
        });
    }
  }

  public isSelectedClassifier() {
    return this.selectedEntity instanceof Classifier;
  }

  public testDataset() {
    if(!this.selectedDataset.id || !this.selectedDataset.dataset_name || !this.historyModel) {
      return;
    }
    // todo: (prokopenko) use resources
    this.datasetCalcPreloader = true;
    this.$http.post('/api/v1/classifier/service/apply-async/classifier_model_test/', {
      args: [this.historyModel.id, this.selectedDataset.id]
    }).then((response: any) => {
      let state = response.data.state;
      let task_id = response.data['task-id'];
      this.checkDatasetCalculationStatus(task_id, this.historyModel.id, this.selectedDataset.id);
    }).catch((error: any) => {
      this.datasetCalcPreloader = false;
    }).finally(() => {
    });
  }

  public checkDatasetCalculationStatus(task_id: any, history_model_id: any, dataset_id: any) {
    let CHECK_TIMEOUT = 3000;
    this.datasetCalcPreloader = true;
    // todo: (prokopenko) use resources
    this.$http.get('/api/v1/classifier/service/tasks/result/' + task_id + '/').then((response: any) => {
      switch(response.data.state) {
        case 'SUCCESS':
          this.datasetCalcPreloader = false;
          if(this.historyModel.id === history_model_id && this.selectedDataset.id === dataset_id) {
            this.selectDataset(this.selectedDataset);
          }
          this.Notification.success('Test finished successful');
          break;
        case 'PENDING':
          setTimeout(() => {
            this.checkDatasetCalculationStatus(task_id, history_model_id, dataset_id);
          }, CHECK_TIMEOUT);
          break;
        case 'FAILURE':
          this.datasetCalcPreloader = false;
          this.Notification.error('Test finished with error');
          break;
        default:
          this.datasetCalcPreloader = false;
          this.Notification.error('Test finished with status: ' + response.data.state);
      }
    }).catch((error: any) => {
      this.datasetCalcPreloader = false;
    });
  }

  // todo: (prokopenko) move to service
  private getChartConfig(opts: any) {
    const config: any = {};
    let title = opts.title || '';
    let type = opts.type || 'bar';
    let opposite = !!opts.opposite;
    let legend = (typeof opts.legend === 'boolean') ? opts.legend : true;
    let xAxisVisible = (typeof opts.xAxisVisible === 'boolean') ? opts.xAxisVisible : true;
    let categories = opts.categories || [];
    let color = opts.color || '';

    config.options = {
      title: {
        text: title
      },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: type
      },
      tooltip: {
        enabled: false,
        pointFormat: '<b>{point.name}: {point.percentage:.1f}%</b>',
        style: {
          padding: 10
        }
      },
      exporting: {
        enabled: true,
        buttons: {
          contextButton: {
            menuItems: [{
              text: 'Export to PNG',
              onclick: function() {
                this.exportChart();
              }
            }]
          }
        }
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            inside: false,
            color: '#000000',
            formatter: function() {
              let dataSum = this.series.data.reduce((x: any, item: any) => {
                return x + item.total;
              }, 0);
              let pcnt = (this.y / dataSum) * 100;
              return Highcharts.numberFormat(pcnt, 0, ',') + '%';
            }
          }
        }
      },
      legend: {
        enabled: legend
      }
    };
    config.xAxis = {
      categories: categories,
      opposite: opposite,
      title: {
        text: null
      },
      visible: xAxisVisible
    };
    config.yAxis = {
      visible: false,
      reversed: opposite
    };
    config.series = [{
      name: title,
      color: color,
      data: []
    }];

    for(let i = 0; i < categories.length; i++) {
      config.series[0].data.push({
        name: '',
        size: 0,
        default_percent: 0,
        y: 0
      });
    }
    switch(title) {
      case 'Trashold':
        config.series = [{
          name: 'Recall',
          color: ConstService.COLORS.GREEN,
          data: []
        },
          {
            name: 'F1',
            color: ConstService.COLORS.RED,
            data: []
          },
          {
            name: 'Precision',
            color: ConstService.COLORS.BLUE,
            data: []
          },
          {
            name: 'FPR',
            color: ConstService.COLORS.ORANGE,
            data: []
          }];
        config.options.tooltip.enabled = true;
        config.options.tooltip.split = true;
        config.options.tooltip.pointFormat = '<b>{series.name}: {point.y:,.6f}</b>';
        config.yAxis = [{
          max: 1,
          min: 0,
          labels: {
            style: {
              color: Highcharts.getOptions().colors[1]
            },
            format: '{value:,.2f}'
          },
          title: {
            style: {
              color: Highcharts.getOptions().colors[1]
            }
          },
          opposite: false,
          visible: true
        }];
        config.xAxis.title.text = 'trashold';
        config.xAxis.tickInterval = 5;
        break;
      default:
        config.options.tooltip.pointFormat = '<b>{point.name}: {point.percentage:.1f}%</b>';
        break;
    }
    return config;
  }
}
