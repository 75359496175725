import { Component, Input, OnInit } from 'angular-ts-decorators';
import * as _ from 'lodash';
import { AuthService } from '../../../app/core/services/auth.service';

@Component({
  selector: 'modalEditFolderAudienceComponent',
  templateUrl: './audience-group.modal.pug'
})
export class ModalEditFolderAudienceComponent implements OnInit {

  static $inject: string[] = [
    'audiencesResource',
    'audiencesService',
    'authService'
  ];
  @Input() modalInstance: any;
  @Input() resolve: any;
  public segments: any;
  public groupAudiences: any[] = [];
  public searchData: any[] = [];
  public audience: any;
  public mode: any;
  public submitDisable = false;
  public message = '';
  public modalTitle = 'Create group';
  private segmentsIds: any[];
  public textLoc = {};

  constructor(private audiencesResource: any,
              private audiencesService: any,
              private authService: AuthService) {

  }

  ngOnInit(): void {
    this.segments = this.resolve.segments || [];
    this.segmentsIds = this.segments.map((segment: any) => {
      return segment.segment_id;
    });
    this.mode = this.modalInstance.mode;
    this.audience = {
      segment_name: '',
      is_empty: true,
      pixel_pid: null,
      account: this.authService.getSelectedAccount().account_id
    };

    this.audiencesService.getAudienceList().then((audiences: any) => {
      audiences.forEach((audience: any) => {
        if(audience.is_active
          && (this.segmentsIds.indexOf(audience.segment_id) === -1)
          && this.segments.every((parent: any) => {
            return !this.audiencesService.isParentOf(audience, parent);
          })
        ) {
          this.groupAudiences.push(audience);
        }
      });
      this.groupAudiences.unshift({
        segment_name: '',
        new: true,
        is_empty: true,
        pixel_pid: null,
        account: this.authService.getSelectedAccount().account_id
      });
      this.getGroups('');
    });

    this.authService.text$.subscribe((text) => {
      this.textLoc = text;
      if(this.segments.length) {
        this.modalTitle = text.moveToGroup;
      } else {
        this.modalTitle = text.createGroup;
      }
    });
  }

  public createFolder() {
    if(this.submitDisable) {
      return;
    }
    this.submitDisable = true;
    if(this.audience.id) {
      this.moveSegmentsToGroup(this.audience);
      return;
    }
    this.audiencesService.addAudienceGroup(this.audience).subscribe((response: any) => {
      this.audiencesService.addAudience(response);
      this.moveSegmentsToGroup(response);
      this.submitDisable = false;
    }, (error: any) => {
      this.message = error.error.data.error_message[0];
      this.submitDisable = false;
    });
  }

  public haveEditPermission() {
    return _.get(this.authService.getSelectedAccount(), 'permission') === 'edit';
  }

  public cancel() {
    return this.modalInstance.dismiss('cancel');
  }

  public getGroups(search: any): void {
    const SEARCH_RESULTS_LIMIT: number = 50;
    this.searchData = [];
    if(!this.groupAudiences.length) {
      return;
    }
    this.groupAudiences[0].segment_name = search;
    const matcher: RegExp = new RegExp(search, 'i');
    let segment: any;
    for(let i: number = 0; i < this.groupAudiences.length && this.searchData.length < SEARCH_RESULTS_LIMIT; i++) {
      segment = this.groupAudiences[i];
      if(segment.segment_name.match(matcher)) {
        this.searchData.push(segment);
      }
    }
  }

  public copySelected(model: any) {
    this.audience = model;
  }

  private moveSegmentsToGroup(groupSegment: any) {
    this.segments.forEach((segment: any) => {
      segment.parent = groupSegment.segment_id;
      segment.parents = [groupSegment.segment_id];
      this.audiencesService.patchAudience({
        id: segment.id,
        parents: segment.parents
      }).subscribe();
    });
    this.audiencesService.updateTree();
    this.modalInstance.close();
  }
}
