import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// Services
import { SharedTabsEvent, SharedWorkerService } from './shared-worker.service';
import { AuthService } from './auth.service';
import { NotifyService } from '../../shared/notify.service';
import { Audience } from '../../../app-js/core/classes/classes';
import { ConstService } from '../../shared/const.service';
// Types
import { GeoDataResponse } from '../../../app-js/audiences/shared/response.type';
import { DjangoResponse } from '../../shared/types/django-response.type';

@Injectable({
  providedIn: 'root'
})
export class ConstructorService {

  private geodataUrl: string = '/api/v1/collection/geodata/';
  private audiences: any[] = [];

  private addAudienceEvent: SharedTabsEvent = new SharedTabsEvent('constructor.add', (e: any) => {
    this.addAudienceLocal(new Audience(e.data.msg.audience));
  });

  private removeAudienceEvent: SharedTabsEvent = new SharedTabsEvent('constructor.remove', (e: any) => {
    this.removeAudienceLocal(new Audience(e.data.msg.audience));
  });

  constructor(private $sharedTabsWorker: SharedWorkerService,
              private authService: AuthService,
              private notifyService: NotifyService,
              private http: HttpClient
  ) {

    this.loadStorage();

    $sharedTabsWorker.addEvent(this.addAudienceEvent);

    $sharedTabsWorker.addEvent(this.removeAudienceEvent);

    this.notifyService.notification$.subscribe((message: string) => {
      switch(message) {
        case ConstService.AUTH_EVENTS.impersonated:
          this.load();
          break;
        case ConstService.AUTH_EVENTS.login:
          this.load();
          break;
        case ConstService.AUTH_EVENTS.logout:
          this.clear();
          break;
        case ConstService.AUTH_EVENTS.changeAccount:
          this.load();
          break;
      }
    });
  }

  public getAudienceList(): any {
    return this.audiences;
  }

  public addAudience(audience: any): void {
    this.addAudienceLocal(audience);
    this.$sharedTabsWorker.send({audience: audience}, 'constructor.add');
  }

  public removeAudience(audience: any): void {
    this.removeAudienceLocal(audience);
    this.$sharedTabsWorker.send({audience: audience}, 'constructor.remove');
  }

  public isAudienceInList(id: any): any {
    return this.audiences.find((audience: any) => audience.id === id);
  }

  public clear(): void {
    this.audiences.length = 0;
  }

  public load(): void {
    this.loadStorage();
  }

  public getGeodata(params: any): Observable<GeoDataResponse> {
    return this.http.get(this.geodataUrl, {params: params}).pipe(
      map((response: DjangoResponse<GeoDataResponse>) =>  response.data));
  }

  private loadStorage(): void {
    try {
      const storageAudiences: any = JSON.parse(localStorage.getItem('constructor.audiences'));
      if(storageAudiences && storageAudiences[this.authService.getUserId()]) {
        this.audiences.length = 0;
        storageAudiences[this.authService.getUserId()].forEach((audience: any) => {
          this.audiences.push(new Audience(audience));
        });
      }
    } catch(e) {
      return;
    }
  }

  private saveStorage(): void {
    const storageAudiences: any = JSON.parse(localStorage.getItem('constructor.audiences')) || {};
    storageAudiences[this.authService.getUserId()] = this.audiences;
    localStorage.setItem('constructor.audiences', JSON.stringify(storageAudiences));
  }

  private addAudienceLocal(audience: any): void {
    if(!(audience instanceof Audience)) {
      throw new TypeError('audience must be Audience instance');
    }
    if(!audience.id || !audience.segment_id) {
      console.error('audience must have ids');
      return;
    }
    if(this.audiences.find((item: any) => item.id === audience.id)) {
      return;
    }
    this.audiences.push(audience);
    this.saveStorage();
  }

  private removeAudienceLocal(audience: any): void {
    if(!(audience instanceof Audience)) {
      throw new TypeError('audience must be Audience instance');
    }
    if(!audience.id || !audience.segment_id) {
      console.error('audience must have ids');
      return;
    }
    this.audiences = this.audiences.filter((item: any) => item.id !== audience.id);
    this.saveStorage();
  }
}
