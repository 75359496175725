import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
// RxJS
import { Observable } from 'rxjs';
import { Observer } from 'rxjs/internal/types';
// Services
import { ConstService } from '../../shared/const.service';
import { SharedTabsEvent, SharedWorkerService } from './shared-worker.service';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class SegmentsService {
  private segmentUrl: string = '/api/v1/partner/segments/';
  private _updated: boolean = false;
  private segmentsTree: any[] = [];

  constructor(private $sharedTabsWorker: SharedWorkerService,
              @Inject('$rootScope') private _rootScope: any,
              private utilsService: UtilsService,
              private http: HttpClient) {
    $sharedTabsWorker.addEvent(new SharedTabsEvent('segment.add', (e: any) => {
      this.addSegmentLocal(e.data.msg.segment);
    }));

    $sharedTabsWorker.addEvent(new SharedTabsEvent('segment.edit', (e: any) => {
      this.editSegmentLocal(e.data.msg.segment);
      this._rootScope.$broadcast(ConstService.WORKER_EVENTS.updateTreeSegments);
    }));
  }

  public getSegmentsTreeData(): any {
    if(!this._updated) {
      console.warn('Segments data not updated');
    }
    return this.segmentsTree;
  }

  public getParent(id: any): any {
    if(!this._updated) {
      console.warn('Segments data not updated');
    }
    const segment: any = this.getSegment(id);
    if(segment.parents.length === 0) {
      return {
        segment_name: '',
        is_empty: false
      };
    }
    for(let i: number = 0; i < this.segmentsTree.length; i++) {
      if(this.segmentsTree[i].segment_id === segment.parents[0]) {
        return this.segmentsTree[i];
      }
    }
    return {
      segment_name: '',
      is_empty: false
    };
  }

  public getSegment(id: any): any {
    if(!this._updated) {
      console.warn('Marketplace data not updated');
    }
    for(let i: number = 0; i < this.segmentsTree.length; i++) {
      if(this.segmentsTree[i].id === id) {
        return this.segmentsTree[i];
      }
    }
    return {
      segment_name: '',
      is_empty: false
    };
  }


  public getSegmentBySID(sid: any): any {
    if(!this._updated) {
      console.warn('Marketplace data not updated');
    }
    for(let i: number = 0; i < this.segmentsTree.length; i++) {
      if(this.segmentsTree[i].segment_id === sid) {
        return this.segmentsTree[i];
      }
    }
    return {
      segment_name: '',
      is_empty: false
    };
  }

  public addSegment(segment: any): void {
    this.addSegmentLocal(segment);
    this.$sharedTabsWorker.send({segment: segment}, 'segment.add');
  }

  public editSegment(segment: any): void {
    this.editSegmentLocal(segment);
    this.$sharedTabsWorker.send({segment: segment}, 'segment.edit');
  }

  // todo: (prokopenko) use modern promises logic (move to getSegmentTree)
  public updateSegmentsTree(): Promise<any> {
    return new Observable((observer: Observer<any>) => {
      if(this._updated) {
        observer.next(this.segmentsTree);
        observer.complete();
      } else {
        this.getSegmnetsRequest().subscribe((response: any) => {
          this.segmentsTree.length = 0;
          response.data.forEach((segment: any) => {
            segment.parent = segment.parents[0] ? segment.parents[0] : null;
          });
          this.segmentsTree = this.utilsService.constructTree(response.data);
          this._updated = true;
          observer.next(this.segmentsTree);
          observer.complete();
        }, (error: any) => {
          observer.error(error);
          observer.complete();
        });
      }
    }).toPromise();
  }

  public updated(): boolean {
    return this._updated;
  }

  public getSegmnetsRequest(): Observable<any> {
    return this.http.get(this.segmentUrl);
  }

  public getSegmnetsAnalytics(): Observable<any> {
    return this.http.get(this.segmentUrl + 'analytics/');
  }

  public getSegmentsCSV(): Observable<any> {
    return this.http.get(this.segmentUrl + 'get_csv/',{responseType: 'blob' as 'json'});
  }

  private addSegmentLocal(segment: any): void {
    if(!this._updated) {
      return;
    }
    segment.parent = segment.parents[0] ? segment.parents[0] : null;
    if(segment.parent) {
      this.segmentsTree.forEach((item: any, index: any) => {
        if(item.segment_id === segment.parent) {
          segment.$$treeLevel = item.$$treeLevel + 1;
          this.segmentsTree.splice(index + 1, 0, segment);
        }
      });
    } else {
      segment.$$treeLevel = 0;
      this.segmentsTree.push(segment);
    }
  }

  private editSegmentLocal(segment: any): void {
    if(!this._updated) {
      return;
    }
    segment.parent = segment.parents[0] ? segment.parents[0] : null;
    for(let i: number = 0; i < this.segmentsTree.length; i++) {
      if(this.segmentsTree[i].segment_id === segment.segment_id) {
        if(this.segmentsTree[i].parent !== segment.parent) {
          // todo: (prokopenko) optimize
          this.segmentsTree[i].parent = segment.parent;
          this.segmentsTree[i].segment_name = segment.segment_name;
          this.segmentsTree = this.utilsService.constructTree(this.segmentsTree);
        } else {
          this.segmentsTree[i].segment_name = segment.segment_name;
        }
      }
    }
  }
}
