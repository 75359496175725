import { StateProvider } from '@uirouter/angularjs';
import { ConstService } from '../../app/shared/const.service';

export const sharesRouting = ($stateProvider: StateProvider) => {
  $stateProvider
    .state('layout.sharing', {
      url: '/sharing',
      component: 'sharingComponent',
      data: {
        permissions: {
          except: [ConstService.USER_ROLES.unauthorized],
          redirectTo: 'login'
        }
      }
    })
    .state('layout.sharing_analytics', {
      url: '/sharing/{sharingId:int}/analytics',
      // component: 'sharingAnalyticsComponent',
      // data: {
        // permissions: {
          // except: [ConstService.USER_ROLES.unauthorized],
          // redirectTo: ConstService.REDIRECTS.base
        // }
      // }
    });
};
