import { Component, OnInit } from 'angular-ts-decorators';
import * as Highcharts from 'highcharts';
import * as moment from 'moment';
import { ConstService } from '../../../app/shared/const.service';

@Component({
  selector: 'segmentStatsComponent',
  templateUrl: './segment-stats.pug'
})
export class SegmentStatsComponent implements OnInit {

  static $inject: string[] = [
    '$scope',
    '$stateParams',
    'segmentsResource',
    'segmentsService',
    'Notification',
    'uiGridConstants'
  ];
  public domainsPreloader: boolean;
  public segmentId: any = this.$stateParams.segmentId;
  public preloader = true;
  public gridApi: any;
  public stopDomains: any[] = [];
  public chartConfig: any = {
    options: {
      title: {
        text: 'Данные по объёму сегмента'
      },
      chart: {
        zoomType: 'x',
        type: 'line'
      },
      tooltip: {
        style: {
          padding: 10,
          fontWeight: 'bold'
        }
      }
    },
    xAxis: {
      type: 'datetime'
    },
    yAxis: [{
      labels: {
        style: {
          color: Highcharts.getOptions().colors[1]
        },
        format: '{value:,.0f}'
      },
      title: {
        text: 'Объём',
        style: {
          color: Highcharts.getOptions().colors[1]
        }
      },
      opposite: false,
      visible: true
    }],
    series: [{name: 'Объём', data: []}],
    tooltip: {
      shared: true
    },
    legend: {
      layout: 'vertical',
      align: 'left',
      x: 120,
      verticalAlign: 'top',
      y: 100,
      floating: true,
      backgroundColor: ((Highcharts as any).theme && (Highcharts as any).theme.legendBackgroundColor) || '#FFFFFF'
    }
  };
  public gridConfig: any;
  public disableStopDomains: any;
  public segment: any;
  public segmentPreloader: any;
  public statsPreloader: any;
  public notFound: any;
  public noStats = false;
  public datePicker: any = {
    date: {startDate: null, endDate: null},
    opts: {
      ranges: {
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        'This year': [moment().startOf('year'), moment()],
        'Year to date': [moment().subtract(365, 'days'), moment()]
      },
      eventHandlers: {
        'apply.daterangepicker': () => {
          this.chartConfig.xAxis.min = Number(this.datePicker.date.startDate.format('x'));
          this.chartConfig.xAxis.max = Number(this.datePicker.date.endDate.format('x'));
        }
      }
    },
    min: '',
    max: ''
  };

  constructor(private $scope: any,
              private $stateParams: any,
              private segmentsResource: any,
              private segmentsService: any,
              private Notification: any,
              private uiGridConstants: any) {
  }

  ngOnInit(): void {
    const domainsSelectionUpdate = () => {
      let selectedDomains: any[] = [];
      if(this.gridApi) {
        selectedDomains = this.gridApi.selection.getSelectedRows(); // .map(function (item) { return item.name});
      }
      if(selectedDomains.length) {
        if(!this.chartConfig.series[1]) {
          this.chartConfig.series[1] = {
            name: 'Selected domains',
            data: [],
            color: ConstService.COLORS.RED
          };
        } else {
          this.chartConfig.series[1].data.length = 0;
        }
        let data: any = {};
        selectedDomains.forEach((domain: any) => {
          domain.history.forEach(function(day: any) {
            data.hasOwnProperty(day.dt) ?
              (data[day.dt] += parseInt(day.cnt, 10)) :
              (data[day.dt] = parseInt(day.cnt, 10));
          });
        });
        let result = [];
        for(let key in data) {
          if(data.hasOwnProperty(key) && key !== '0') {
            result.push([parseInt(key, 10), data[key]]);
          }
        }
        result.sort((a: any, b: any) => {
          return a[0] - b[0];
        });
        this.chartConfig.series[1].data = result;
      } else {
        this.chartConfig.series.length = 1;
      }
    };

    this.gridConfig = {
      data: [],
      enableFiltering: false,
      enableRowSelection: true,
      enableSelectAll: true,
      enableColumnMenus: false,
      showGridFooter: false,
      showTreeExpandNoChildren: false,

      onRegisterApi: (gridApi: any) => {
        this.gridApi = gridApi;
        gridApi.selection.on.rowSelectionChanged(this.$scope, domainsSelectionUpdate);
        gridApi.selection.on.rowSelectionChangedBatch(this.$scope, domainsSelectionUpdate);
      },

      columnDefs: [
        {
          field: 'domain',
          displayName: 'Domains',
          headerCellFilter: 'translate',
          cellTemplate: `<div class="ui-grid-cell-contents" ng-class="{'segments_stats__grid_stop_domain' : row.entity.stop }"><a target="_blank" href="http://{{grid.getCellValue(row, col)}}">{{grid.getCellValue(row, col)}}</a></div>`,
        },
        {
          field: 'cnt',
          displayName: 'Size',
          type: 'number',
          cellFilter: 'number:0',
          headerCellFilter: 'translate',
          sort: {
            direction: 'desc'
          }
        },
        {
          field: 'history',
          displayName: 'Dynamic',
          headerCellFilter: 'translate',
          type: 'object',
          width: 200,
          cellTemplate: '<div class="ui-grid-cell-contents"><div><svg-spark-chart history="row.entity.history"></svg-spark-chart></div></div>'
        },
        {
          field: 'stop',
          type: 'boolean',
          visible: false
        }
      ]
    };
    this.getSegment(this.segmentId);
  }

  public addStopDomains() {
    this.disableStopDomains = true;
    let selectedRows = this.gridApi.selection.getSelectedRows();
    let stopDomains: any[] = [];
    selectedRows.forEach((item: any) => {
      stopDomains.push(item.domain);
    });
    this.segmentsResource.patch({id: this.segmentId, stop_domains: stopDomains}).$promise.then(() => {
      this.Notification.success('Домены добавлены в стоп домены');
      selectedRows.forEach((item: any) => {
        item.stop = true;
      });
      this.stopDomains.push.apply(this.stopDomains, stopDomains);
      this.gridApi.core.notifyDataChange(this.uiGridConstants.dataChange.ALL);
    }).finally(() => {
      this.disableStopDomains = false;
    });
  }

  private isStopDomain(domain: any) {
    return this.stopDomains.indexOf(domain) !== -1;
  }

  private getSegment(id: any) {
    if(this.segmentsService.updated()) {
      this.segment = this.segmentsService.getSegment(id);
      this.getStats();
      this.getDomains();
    } else {
      this.segmentPreloader = true;
      this.segmentsResource.get({id: id, fields: 'segment_name,stop_domains,segment_id'})
        .$promise
        .then((response: any) => {
          this.segment = response.data;
          this.stopDomains = response.data.stop_domains;
          this.gridApi.core.notifyDataChange(this.uiGridConstants.dataChange.ALL);
          this.getStats();
          this.getDomains();
        })
        .catch(() => {
          this.notFound = true;
        })
        .finally(() => {
          this.segmentPreloader = false;
        });
    }
  }

  private getStats() {
    this.statsPreloader = true;
    this.segmentsResource.get({id: this.segmentId, option: 'stats'}).$promise.then((response: any) => {
      let stats = response.data;
      if(stats.length === 0) {
        this.noStats = false;
      } else {
        this.chartConfig.series[0].data.length = 0;
        this.chartConfig.series[0].name = this.segment.segment_name;
        stats.forEach((stat: any) => {
          this.chartConfig.series[0].data.push([new Date(stat.dt).getTime(), stat.size]);
        });
        this.datePicker.date.startDate = moment(this.chartConfig.series[0].data[0][0]);
        this.datePicker.date.endDate = moment(this.chartConfig.series[0].data.slice(-1)[0][0]);
        this.datePicker.min = this.datePicker.date.startDate;
        this.datePicker.max = this.datePicker.date.endDate;
      }
    }).finally(() => {
      this.statsPreloader = false;
    });
  }

  private getDomains() {
    this.domainsPreloader = true;
    this.segmentsResource.get({id: this.segmentId, option: 'domains'}).$promise.then((response: any) => {
      let domains: any = {};
      response.data.forEach((item: any) => {
        if(item.dt) {
          if(!domains.hasOwnProperty(item.domain)) {
            domains[item.domain] = {
              domain: item.domain,
              stop: this.isStopDomain(item.domain),
              history: [{cnt: item.cnt, dt: new Date(item.dt).getTime()}]
            };
          } else {
            domains[item.domain].history.push({cnt: item.cnt, dt: new Date(item.dt).getTime()});
          }
        }
      });
      this.gridConfig.data = [];
      this.gridConfig.data = Object.keys(domains).map((key: any) => {
        domains[key].cnt = domains[key].history.find(function(element: any) {
          return moment(Date.now()).isSame(element.dt, 'day');
        });
        domains[key].history.sort((a: any, b: any) => {
          return a.dt - b.dt;
        });
        domains[key].cnt = domains[key].cnt ? domains[key].cnt.cnt : domains[key].history.slice(-1)[0].cnt;
        return domains[key];
      });

    }).finally(() => {
      this.domainsPreloader = false;
    });
  }
}


