import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  // TODO Переделать сервис на статические методы
  public constructTree(data: any, parent_key_field: any = 'segment_id') {
    const search_childs = function(id: any, treeLevel: any, data: any, result: any, parent_key_field: any) {
      data.forEach(function(segment: any) {
        if(segment.parent === id) {
          segment.$$treeLevel = treeLevel;
          result.push(segment);
          if(Boolean(segment[parent_key_field])) {
            search_childs(segment[parent_key_field], treeLevel + 1, data, result, parent_key_field);
          }
        }
      });
    };

    const result: any[] = [];
    data.forEach(function(segment: any) {
      for(let i = 0; i < data.length; i++) {
        if(!!data[i][parent_key_field] && segment.parent === data[i][parent_key_field]) {
          return;
        }
      }
      segment.parent = null;
    });
    data.forEach(function(segment: any) {
      if(segment.parent === null) {
        segment.$$treeLevel = 0;
        result.push(segment);
        if(!!segment[parent_key_field]) {
          search_childs(segment[parent_key_field], 1, data, result, parent_key_field);
        }
      }
    });
    return result;
  }

  public exportCSVTable(table: any, table_name: any, header_string: any = '') {
    let dataString = '';
    let csvContent = 'data:text/csv;charset=utf-8,';
    csvContent += header_string;
    table.forEach(function(infoArray: any, index: any) {
      if(infoArray.length) {
        dataString = '"' + infoArray.join('","') + '"';
      } else {
        dataString = '';
      }
      csvContent += index < table.length ? dataString + '\n' : dataString;
    });
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', table_name + '_' + moment().format('DD_MM_YYYY__HH_mm_ss') + '.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  public safeApply(scope: any, fn: any) {
    const phase = scope.$root.$$phase;
    if(phase === '$apply' || phase === '$digest') {
      if(fn && (typeof(fn) === 'function')) {
        fn();
      }
    } else {
      scope.$apply(fn);
    }
  }
}
