import { NgModule } from 'angular-ts-decorators';
import { AudienceModule } from './audiences/audience.module';
import { AuthModule } from './auth/auth.module';
import { BillingModule } from './billing/billing.module';
import { CampaignModule } from './campaigns/campaign.module';
import { CoreModule } from './core/core.module';
import { LayoutModule } from './layout/layout.module';
import { MarketplaceModule } from './marketplace/marketplace.module';
import { PixelModule } from './pixels/pixel.module';
import { SegmentModule } from './segments/segment.module';
import { SharesModule } from './sharings/shares.module';

@NgModule({
  id: 'application',
  imports: [
    CoreModule,
    AuthModule,
    LayoutModule,
    PixelModule,
    MarketplaceModule,
    BillingModule,
    SharesModule,
    AudienceModule,
    CampaignModule,
    SegmentModule
  ]
})
export class AppModule {
}
