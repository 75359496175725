import { StateProvider } from '@uirouter/angularjs';
import { ConstService } from '../../app/shared/const.service';

export const segmentRouting = ($stateProvider: StateProvider) => {
  $stateProvider
    .state('layout.segments', {
      url: '/segments',
      component: 'segmentListComponent',
      data: {
        permissions: {
          except: ConstService.USER_ROLES.unauthorized,
          only: ConstService.PERMISSIONS.segments,
          redirectTo: ConstService.REDIRECTS.segments
        }
      }
    })
    .state('layout.domains_search', {
      url: '/domains_search',
      component: 'domainsSearchComponent',
      data: {
        permissions: {
          except: ConstService.USER_ROLES.unauthorized,
          only: ConstService.PERMISSIONS.segments,
          redirectTo: ConstService.REDIRECTS.segments
        }
      }
    })
    .state('layout.classifier', {
      url: '/classifier',
      component: 'classifierComponent',
      data: {
        permissions: {
          except: ConstService.USER_ROLES.unauthorized,
          only: ConstService.PERMISSIONS.segments,
          redirectTo: ConstService.REDIRECTS.segments
        }
      }
    })
    .state('layout.classifier_create', {
      url: '/classifier/{tagId:int}/create',
      component: 'classifierEditComponent',
      data: {
        permissions: {
          except: ConstService.USER_ROLES.unauthorized,
          only: ConstService.PERMISSIONS.segments,
          redirectTo: ConstService.REDIRECTS.segments
        },
        mode: 'create'
      }
    })
    .state('layout.classifier_edit', {
      url: '/classifier/{classifierId:int}/edit',
      component: 'classifierEditComponent',
      data: {
        permissions: {
          except: ConstService.USER_ROLES.unauthorized,
          only: ConstService.PERMISSIONS.segments,
          redirectTo: ConstService.REDIRECTS.segments
        },
        mode: 'edit'
      }
    })
    .state('layout.dataset_create', {
      url: '/classifier/dataset/create',
      component: 'datasetComponent',
      data: {
        permissions: {
          except: ConstService.USER_ROLES.unauthorized,
          only: ConstService.PERMISSIONS.segments,
          redirectTo: ConstService.REDIRECTS.segments
        },
        mode: 'create'
      }
    })
    .state('layout.dataset_edit', {
      url: '/classifier/dataset/{datasetId:int}/edit',
      component: 'datasetComponent',
      data: {
        permissions: {
          except: ConstService.USER_ROLES.unauthorized,
          only: ConstService.PERMISSIONS.segments,
          redirectTo: ConstService.REDIRECTS.segments
        },
        mode: 'edit'
      }
    })
    .state('layout.stats', {
      url: '/segments/{segmentId:int}/stats',
      component: 'segmentStatsComponent',
      data: {
        permissions: {
          except: ConstService.USER_ROLES.unauthorized,
          only: ConstService.PERMISSIONS.segments,
          redirectTo: ConstService.REDIRECTS.segments
        }
      }
    })
    .state('layout.segment_edit', {
      url: '/segments/{segmentId:int}/edit',
      component: 'segmentEditComponent',
      data: {
        permissions: {
          except: ConstService.USER_ROLES.unauthorized,
          only: ConstService.PERMISSIONS.segments,
          redirectTo: ConstService.REDIRECTS.segments
        },
        mode: 'edit'
      }
    })
    .state('layout.segment_create', {
      url: '/segments/create',
      component: 'segmentEditComponent',
      data: {
        permissions: {
          except: ConstService.USER_ROLES.unauthorized,
          only: ConstService.PERMISSIONS.segments,
          redirectTo: ConstService.REDIRECTS.segments
        },
        mode: 'create'
      }
    });
};
