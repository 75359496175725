import 'src/app-js/core/libs/jquery-ui';
import 'jquery';
import 'angular';
import '@uirouter/angularjs';
import 'slick-carousel';

import './core/libs/chart-draggable-rows/draggable-rows.js';
import './core/libs/angularjs-dropdown-multiselect/angularjs-dropdown-multiselect.js';

import 'angular-ui-notification';
import 'angular-resource';
import 'angular-local-storage';
import 'angular-cookies';
import 'angular-translate';
import 'angular-translate-storage-local';
import 'angular-translate-storage-cookie';
import 'angular-translate-loader-static-files';
import 'angular-permission';
import 'angular-sanitize';
import 'angular-ui';
import 'angular-ui-bootstrap';
import 'angular-ui-slider';
import 'angular-ui-ace';
import 'angular-slick';
import 'ng-file-upload';
import 'ui-select';
import 'angular-daterangepicker';
import 'highcharts-ng';
import 'angular-ui-tree';

import 'angular-ui-grid';
import 'angular-resource';
import 'angular-messages';
import 'angular-animate';
import 'angular-aria';
import 'angular-material';

import 'ngclipboard';
