import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
// RxJS
import { Observable } from 'rxjs';
import { Observer } from 'rxjs/internal/types';
import { map } from 'rxjs/operators';
// Types
import { CategoryAnalytics } from '../../../app-js/audiences/shared/analytics.type';
import { SegmentCreateResponse } from '../../../app-js/audiences/shared/response.type';
import { DjangoResponse } from '../../shared/types/django-response.type';
// Services
import { AuthService } from './auth.service';
import { NotifyService } from '../../shared/notify.service';
import { SharedTabsEvent, SharedWorkerService } from './shared-worker.service';
import { ConstService } from '../../shared/const.service';

@Injectable({
  providedIn: 'root'
})
export class PixelService {

  private pixelUrl: string = '/api/v1/partner/pixels/';
  private pixelsAll: any[] = [];
  private pixels: any[] = [];
  private isUpdated: boolean = false;
  private UPDATE_DELAY: number = 1000 * 60 * 5;
  private deletePixelEvent: SharedTabsEvent = new SharedTabsEvent('pixel.delete', (e: any) => {
    this.deletePixelLocal(e.data.msg.pixel_id);
  });
  private editPixelEvent: SharedTabsEvent = new SharedTabsEvent('pixel.edit', (e: any) => {
    this.editPixelLocal(e.data.msg.pid, e.data.msg.description);
    this._rootScope.$broadcast(ConstService.WORKER_EVENTS.updateTreeAudiences);
  });

  constructor(@Inject('$rootScope') private _rootScope: any,
              private $sharedTabsWorker: SharedWorkerService,
              private notifyService: NotifyService,
              private authService: AuthService,
              private http: HttpClient
  ) {
    this.$sharedTabsWorker.addEvent(new SharedTabsEvent('pixel.add', (e: any) => {
      this.addPixelLocal(e.data.msg.pixel);
    }));

    this.$sharedTabsWorker.addEvent(this.editPixelEvent);
    this.$sharedTabsWorker.addEvent(this.deletePixelEvent);
    this.silentUpdate();

    this.notifyService.notification$.subscribe((message: string) => {
      switch(message) {
        case ConstService.AUTH_EVENTS.impersonated:
          this.update();
          break;
        case ConstService.AUTH_EVENTS.login:
          this.update();
          break;
        case ConstService.AUTH_EVENTS.logout:
          this.notUpdated();
          break;
        case ConstService.AUTH_EVENTS.changeAccount:
          this.getPixelRequest().subscribe((response: any) => {
            this.isUpdated = true;
            this.pixels.length = 0;
            this.pixelsAll.length = 0;
            response.data.forEach((pixel: any) => {
              this.pixelsAll.push(pixel);
              this.pixels.push(pixel);
            });
          });
          break;
      }
    });
  }

  // todo: use class for pixel
  public getPixels = () => {
    return new Observable((observer: Observer<any>) => {
      if(this.isUpdated) {
        observer.next(this.pixels);
        observer.complete();
      } else {
        this.getPixelRequest().subscribe((response: any) => {
          this.isUpdated = true;
          this.pixels.length = 0;
          this.pixelsAll.length = 0;
          response.data.forEach((pixel: any) => {
            this.pixelsAll.push(pixel);
            this.pixels.push(pixel);
          });
          observer.next(this.pixels);
          observer.complete();
        }, () => {
          observer.next(this.pixels);
          observer.complete();
        });
      }
    }).toPromise();
  }

  public getPixel(id: any, searchField: any = 'pid'): Promise<any> {
    return new Observable((observer: Observer<any>) => {
      this.getPixels().then(() => {
        for(let i: number = 0; i < this.pixelsAll.length; i++) {
          if(this.pixelsAll[i][searchField] === id) {
            observer.next(this.pixelsAll[i]);
            observer.complete();
            return;
          }
        }
        observer.next(null);
        observer.complete();
      });
    }).toPromise();
  }

  public addPixel(pixel: any): void {
    this.addPixelLocal(pixel);
    this.$sharedTabsWorker.send({pixel: pixel}, 'pixel.add');
  }

  public editPixel(pid: any, description: any): void {
    this.editPixelLocal(pid, description);
    this.$sharedTabsWorker.send({pid: pid, description: description}, 'pixel.edit');
  }

  public deletePixel(pixelId: any): void {
    this.deletePixelLocal(pixelId);
    this.$sharedTabsWorker.send({pixel_id: pixelId}, 'pixel.delete');
  }

  public update(): Promise<any> {
    this.isUpdated = false;
    return this.getPixels();
  }

  public savePixel(params): Observable<any> {
    return this.http.post(this.pixelUrl, params)
      .pipe(map((response: DjangoResponse<any>) =>  response.data));
  }

  public edPixel(params): Observable<any> {
    return this.http.put(`${this.pixelUrl}${params.pixelId}/`, params)
      .pipe(map((response: DjangoResponse<any>) =>  response.data));
  }

  public getAnalytics(pixelId: any, date_from: any, date_to: any, domain: any, group: string[] = []): Observable<any> {
    date_from = date_from || moment().subtract(1, 'months');
    date_to = date_to || moment();
    domain = domain || 'All';
    return this.getPixelAnalyticsRequest( pixelId, {
      date_from: date_from.format('YYYY-MM-DD'),
      date_to: date_to.format('YYYY-MM-DD'),
      domain: domain,
      groups: group.join(','),
      top_http_referrers: 5
    });
  }

  public notUpdated(): void {
    this.isUpdated = false;
  }

  public getPixelById(pixelId: any): Observable<any> {
    return this.http.get(`${this.pixelUrl}${pixelId}/`)
      .pipe(map((response: DjangoResponse<any>) =>  response.data));
  }

  public getPixelDomains(pixelId: any): Observable<any> {
    return this.http.get(`${this.pixelUrl}${pixelId}/domains/`)
      .pipe(map((response: DjangoResponse<any>) =>  response.data));
  }

  public getPixelAnalyticsCommon(params: any): Observable<CategoryAnalytics[]> {
    return this.http.get(`${this.pixelUrl}${params.pixelId}/analytics_common/`, {params: params})
      .pipe(map((response: DjangoResponse<CategoryAnalytics[]>) =>  response.data));
  }

  public getRelated(pixelId: any): Observable<any> {
    return this.http.get(`${this.pixelUrl}${pixelId}/related/`)
      .pipe(map((response: DjangoResponse<any>) =>  response.data));
  }

  public delete(id: number): Observable<any> {
    return this.http.delete(`${this.pixelUrl}${id}/`);
  }

  public addSegmentGeo(params: any): Observable<SegmentCreateResponse> {
    return this.http.post(`${this.pixelUrl}${params.pixelId}/segment_geo/`, params)
      .pipe(map((response: DjangoResponse<SegmentCreateResponse>) =>  response.data));
  }

  public addSegmentConstructorAnalytics(params: any): Observable<SegmentCreateResponse> {
    return this.http.post(`${this.pixelUrl}${params.pixelId}/constructor_analytics/`, params)
      .pipe(map((response: DjangoResponse<SegmentCreateResponse>) =>  response.data));
  }

  public addSegmentConstructorAnalyticsEvent(params: any): Observable<SegmentCreateResponse> {
    return this.http.post(`${this.pixelUrl}${params.pixelId}/constructor_analytics_event/`, params)
      .pipe(map((response: DjangoResponse<SegmentCreateResponse>) =>  response.data));
  }

  private addPixelLocal(pixel: any): void {
    this.pixelsAll.unshift(pixel);
    this.pixels.unshift(pixel);
  }

  private editPixelLocal(pid: any, description: any): void {
    this.getPixel(pid).then((pixel: any) => {
      if(pixel) {
        pixel.description = description;
      }
    });
  }

  private deletePixelLocal(pixelId: any): void {
    const index: number = this.pixels.findIndex((item: any) => {
      return item.id === pixelId;
    });
    this.pixels.splice(index, 1);
  }

  private silentUpdate(): void {
    setInterval(() => {
      this.update();
    }, this.UPDATE_DELAY);
  }

  private getPixelRequest(): Observable<any> {
    return this.http.get(this.pixelUrl);
  }

  private getPixelAnalyticsRequest(id: number, params: any): Observable<any> {
    return this.http.get(`${this.pixelUrl}${id}/analytics/`, {params: params});
  }
}
