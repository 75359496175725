import { downgradeInjectable } from '@angular/upgrade/static';
import { StateProvider, UrlRulesApi } from '@uirouter/angularjs';
import * as angular from 'angular';
import { IHttpProvider, IRootScopeService, local, resource, translate, uiNotification } from 'angular';
import { NgModule } from 'angular-ts-decorators';
import { AuthService } from '../../app/core/services/auth.service';
import { UtilsService } from '../../app/core/services/utils.service';
import { AudiencesService } from '../../app/core/services/audiences.service';
import { UserPermission } from '../../app/auth/shared/user.type';
import { NotifyService } from '../../app/shared/notify.service';
import { PixelService } from '../../app/core/services/pixel.service';
import { MarketplaceService } from '../../app/core/services/marketplace.service';
import { SegmentsService } from '../../app/core/services/segments.service';
import { BillingService } from '../../app/core/services/billing.service';
import { ConstService } from '../../app/shared/const.service';
import { ClassifierService } from '../../app/core/services/classifier.service';
import { ConstructorService } from '../../app/core/services/constructor.service';
import { SharingService } from '../../app/core/services/sharing.service';
import { UserMarketplaceService } from '../../app/core/services/user-marketplace.service';
import { SharedWorkerService } from '../../app/core/services/shared-worker.service';
import { Router } from '@angular/router';
/**
 * Import Module Components
 */
import { RootComponent } from './components/root/root.component';
//
import { AuthInterceptor, ConnectionInterceptor } from './core.interceptor';
import { IsActive, IsAvailable, IsNaN, NumberIsAvailable, UiSelectAddSearch } from './core.pipe';
import {
  AffinityResource,
  AudiencesResource,
  BillingResource,
  CampaignsResource,
  ClassifierResource,
  CollectionsResource,
  DomainsResource,
  ExternalsResource,
  ImpersonateResource,
  MarketplaceResource,
  PixelResource,
  SegmentsResource,
  UserResource
} from './core.resources';
import { coreRoutes } from './core.routes';
import { CssSelectorValidatorDirective } from './directives/form-validators/css-selector-validator.directive';
import { PagePreloader } from './directives/preloader/page-preloader.component';
import { SvgSparkChartDirective } from './directives/svg-spark-chart/svg-spark-chart.directive';

@NgModule({
  id: 'application.core',
  imports: [
    'ui.router',
    'highcharts-ng',
    'angularjs-dropdown-multiselect',
    'ui.bootstrap',
    'ui.grid',
    'ui.grid.draggable-rows',
    'ui.grid.selection',
    'ui.grid.resizeColumns',
    'ui.grid.exporter',
    'ui.grid.treeView',
    'ui.grid.pagination',
    'ui.slider',
    'ui.ace',
    'ui-notification',
    'LocalStorageModule',
    'ngResource',
    'ngMessages',
    'ngAria',
    'ngMaterial',
    'ngMessages',
    'slick',
    'permission',
    'permission.ui',
    'ngSanitize',
    'daterangepicker',
    'ui.select',
    'ngCookies',
    'pascalprecht.translate',
    'ngFileUpload',
    'ui.tree',
    'ngclipboard'
  ],
  declarations: [
    RootComponent,
    // pipes
    IsActive,
    IsNaN,
    IsAvailable,
    NumberIsAvailable,
    UiSelectAddSearch,
    PagePreloader,
    CssSelectorValidatorDirective,
    SvgSparkChartDirective
  ],
  providers: [
    // resources
    UserResource,
    ImpersonateResource,
    AffinityResource,
    PixelResource,
    ExternalsResource,
    CollectionsResource,
    BillingResource,
    MarketplaceResource,
    ClassifierResource,
    AudiencesResource,
    SegmentsResource,
    CampaignsResource,
    DomainsResource,
    // services
    {provide: 'authService', useFactory: downgradeInjectable(AuthService)},
    {provide: 'notifyService', useFactory: downgradeInjectable(NotifyService)},
    {provide: 'utilsService', useFactory: downgradeInjectable(UtilsService)},
    {provide: 'audiencesService', useFactory: downgradeInjectable(AudiencesService)},
    {provide: 'pixelService', useFactory: downgradeInjectable(PixelService)},
    {provide: 'marketplaceService', useFactory: downgradeInjectable(MarketplaceService)},
    {provide: 'segmentsService', useFactory: downgradeInjectable(SegmentsService)},
    {provide: 'classifierService', useFactory: downgradeInjectable(ClassifierService)},
    {provide: 'constructorService', useFactory: downgradeInjectable(ConstructorService)},
    {provide: 'sharingService', useFactory: downgradeInjectable(SharingService)},
    {provide: 'billingService', useFactory: downgradeInjectable(BillingService)},
    {provide: 'userMarketplaceService', useFactory: downgradeInjectable(UserMarketplaceService)},
    {provide: '$sharedTabsWorker', useFactory: downgradeInjectable(SharedWorkerService)},
    {provide: 'router', useFactory: downgradeInjectable(Router)}
  ]
})
export class CoreModule {

  static config = config;
  static run = run;

  static configNotification(NotificationProvider: uiNotification.INotificationProvider): void {
    NotificationProvider.setOptions({
      delay: 15000,
      startTop: 20,
      startRight: 10,
      verticalSpacing: 20,
      horizontalSpacing: 20,
      positionX: 'right',
      positionY: 'bottom'
    });
  }

  static configTranslation($translateProvider: translate.ITranslateProvider): void {
    $translateProvider
      .useSanitizeValueStrategy('sceParameters') // use this param to fix bug </body> in pixels view
      .useStaticFilesLoader({
        'prefix': '/public/json/locale/',
        'suffix': '.json'
      })
      .uniformLanguageTag('bcp47')
      .registerAvailableLanguageKeys(['en', 'ru'], {
        'en-*': 'en',
        'en_*': 'en',
        'ru_RU': 'ru',
        '*': 'en'
      })
      .determinePreferredLanguage()
      .fallbackLanguage('en')
      .useLocalStorage();
  }

  static configLocalStorage(localStorageServiceProvider: local.storage.ILocalStorageServiceProvider): void {
    localStorageServiceProvider
      .setPrefix('aidata')
      .setStorageType('localStorage')
      .setNotify(true, true);
  }

  static configHttp($httpProvider: IHttpProvider): void {
    $httpProvider.interceptors.push(ConnectionInterceptor.factory);
    $httpProvider.interceptors.push(AuthInterceptor.factory);
    $httpProvider.defaults.xsrfCookieName = 'csrftoken';
    $httpProvider.defaults.xsrfHeaderName = 'X-CSRFToken';
  }

  static configResource($resourceProvider: resource.IResourceServiceProvider): void {
    $resourceProvider.defaults.stripTrailingSlashes = false;
  }
}

function config($urlRouterProvider: UrlRulesApi,
                $stateProvider: StateProvider,
                $translateProvider: translate.ITranslateProvider,
                localStorageServiceProvider: local.storage.ILocalStorageServiceProvider,
                $httpProvider: IHttpProvider,
                $resourceProvider: resource.IResourceServiceProvider,
                NotificationProvider: uiNotification.INotificationProvider,
                $locationProvider: angular.ILocationProvider): void {
  CoreModule.configNotification(NotificationProvider);
  CoreModule.configTranslation($translateProvider);
  CoreModule.configLocalStorage(localStorageServiceProvider);
  CoreModule.configHttp($httpProvider);
  CoreModule.configResource($resourceProvider);

  coreRoutes($stateProvider, $urlRouterProvider);

  $locationProvider.hashPrefix('');
  $locationProvider.html5Mode({
    enabled: true,
    requireBase: false
  });
}

config.$inject = ['$urlRouterProvider', '$stateProvider', '$translateProvider', 'localStorageServiceProvider', '$httpProvider', '$resourceProvider', 'NotificationProvider', '$locationProvider'];

function run(localStorageService: any,
             authService: AuthService,
             PermPermissionStore: any,
             PermRoleStore: any,
             Notification: any,
             $rootScope: IRootScopeService,
             $window: any,
             $location: any): void {

  if($window.ga) {
    $rootScope.$on('$viewContentLoaded', function() {
      $window.ga('send', 'pageview', {page: $location.url()});
    });
  }

  PermRoleStore.defineRole(ConstService.USER_ROLES.unauthorized, (): boolean => {
    return !authService.isLoggedIn;
  });

  PermPermissionStore.defineManyPermissions(
    Object.values(ConstService.PERMISSIONS),
    (permissionName: UserPermission) => {
      return authService.hasPermission(permissionName);
    });
}

run.$inject = [
  'localStorageService',
  'authService',
  'PermPermissionStore',
  'PermRoleStore',
  'Notification',
  '$rootScope',
  '$window',
  '$location'];
