import { Directive } from 'angular-ts-decorators';

@Directive({
  selector: 'cssSelectorValidator',
  require: 'ngModel',
  link: function(scope: any, elm: any, attrs: any, ctrl: any) {
    ctrl.$validators.cssSelectorValidator = function(modelValue: any, viewValue: any) {
      if(ctrl.$isEmpty(modelValue)) {
        return true;
      }
      try {
        document.querySelector(viewValue);
      } catch(e) {
        return false;
      }
      return true;
    };
  }
})
export class CssSelectorValidatorDirective {
}
