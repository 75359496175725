import { StateProvider, UrlRulesApi } from '@uirouter/angularjs';
import { NgModule } from 'angular-ts-decorators';
import { authRouting } from './auth.routes';

@NgModule({
  id: 'AuthModule',
  imports: [],
  declarations: []
})
export class AuthModule {
  static config = config;
}

function config($stateProvider: StateProvider) {
  authRouting($stateProvider);
}

config.$inject = ['$stateProvider'];
