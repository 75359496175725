import { StateProvider, UrlRulesApi } from '@uirouter/angularjs';

export const coreRoutes = ($stateProvider: StateProvider, $urlRouterProvider: UrlRulesApi) => {
  $stateProvider
    .state('angular', {
      url: '/angular',
      template: ''
    });

  $urlRouterProvider.otherwise('/login');
};
