import { IHttpService } from 'angular';
import { Component, OnInit } from 'angular-ts-decorators';

@Component({
  selector: 'campaignListComponent',
  templateUrl: './campaign-list.pug'
})
export class CampaignListComponent implements OnInit {

  static $inject: string[] = ['$http'];
  public preloader = true;
  public gridApi: any;
  public gridConfig: any = {
    data: [],
    enableFiltering: false,
    enableRowSelection: true,
    enableSelectAll: false,
    enableColumnMenus: false,
    multiSelect: false,


    onRegisterApi: (gridApi: any) => {
      this.gridApi = gridApi;
    },

    columnDefs: [
      {
        field: 'campaign_id',
        displayName: 'Campaign ID',
        width: 150,
        headerCellFilter: 'translate'
      },
      {
        field: 'description',
        displayName: 'Description',
        width: '*',
        headerCellFilter: 'translate'
      },
      {
        field: 'is_active',
        displayName: 'Status',
        width: 100,
        cellFilter: 'IsActive|translate',
        headerCellFilter: 'translate'
      },
      {
        field: 'impressions',
        displayName: 'Impressions',
        width: 100,
        type: 'number',
        cellFilter: 'number:0',
        cellClass: 'to-right',
        headerCellFilter: 'translate'
      },
      {
        field: 'reach',
        displayName: 'Reach',
        type: 'number',
        cellFilter: 'NumberIsAvailable:0',
        width: 100,
        cellClass: 'center',
        headerCellFilter: 'translate'
      },
      {
        field: 'frequency',
        type: 'number',
        displayName: 'Frequency',
        cellClass: 'to-left',
        cellFilter: 'NumberIsAvailable:2',
        width: 100,
        headerCellFilter: 'translate'
      },
      {
        field: 'start_date',
        displayName: 'Start date',
        cellFilter: 'IsAvailable',
        cellClass: 'center',
        width: 150,
        headerCellFilter: 'translate'
      },
      {
        field: 'end_date',
        displayName: 'End date',
        cellFilter: 'IsAvailable',
        cellClass: 'center',
        width: 150,
        headerCellFilter: 'translate'
      }
    ]
  };

  constructor(
    private $http: IHttpService
  ) {
  }

  ngOnInit(): void {
    this.$http.get('/public/json/demo/campaigns.json').then((response: any) => {
      this.gridConfig.data = response.data.campaigns;
    }).finally(() => this.preloader = false);
  }


  public getLink(source: any) {
    switch(source) {
      // case 'edit':
      // 	return getEditAudienceLink();
      // 	break;
      case 'analytics':
        return this.getAnalyticLink();
      default:
        return '';
    }
  }

  public isDisabled(button: any) {
    let active: any = true;
    if(['edit', 'analytics'].indexOf(button) !== -1) {
      active *= <any>(this.gridApi.selection.getSelectedCount() === 1);
    }
    if(['analytics'].indexOf(button) !== -1 && active) {
      active *= this.gridApi.selection.getSelectedRows()[0].is_active;
    }
    return !active;
  }

  private getAnalyticLink() {
    if(!this.gridApi || this.isDisabled('analytics')) {
      return '';
    } else {
      return '/campaigns/' + this.gridApi.selection.getSelectedRows()[0].id + '/analytics';
    }
  }

}
