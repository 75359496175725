import { Component, OnInit } from 'angular-ts-decorators';
import { Audience } from '../../core/classes/classes';
import { ConstService } from '../../../app/shared/const.service';
import {Router} from '@angular/router';
import {AuthService} from '../../../app/core/services/auth.service';

@Component({
  selector: 'marketplaceComponent',
  templateUrl: './marketplace.pug'
})
export class MarketplaceComponent implements OnInit {

  static $inject: string[] = [
    '$scope',
    '$uibModal',
    '$log',
    '$q',
    '$filter',
    'marketplaceService',
    'constructorService',
    'router',
    'uiGridConstants',
    'i18nService',
    'authService'
  ];
  public gridConfig: any;
  public preloader = true;
  public searchData: any[] = [];
  public searchFilter = '';
  private gridApi: any;
  public tariff: any = {};
  public textLoc: any = {};
  private langId: any = localStorage.getItem('langId') || 'ru';

  constructor(private $scope: any,
              private $uibModal: any,
              private $log: any,
              private $q: any,
              private $filter: any,
              private marketplaceService: any,
              private constructorService: any,
              private router: Router,
              private uiGridConstants: any,
              private i18nService: any,
              private authService: AuthService
  ) {

  }

  ngOnInit(): void {
    const $filter = this.$filter;
    this.i18nService.setCurrentLang(this.langId);

    // todo: (prokopenko) move all configs creation to factory
    this.gridConfig = {
      data: [],
      enableFiltering: false,
      enableRowSelection: true,
      exporterMenuVisibleData : false,
      enableSelectAll: false,
      enableColumnMenus: false,
      multiSelect: false,
      enableGridMenu: true,
      showTreeExpandNoChildren: false,
      gridMenuShowHideColumns: true,
      exporterMenuPdf: false,
      exporterMenuExcel: false,

      exporterCsvFilename: 'market_segments.csv',
      exporterFieldCallback: function(grid: any, row: any, col: any, value: any) {
        if(col.name === 'is_active') {
          value = $filter('IsActive')(value);
        }
        if(col.name === 'updated_at') {
          value = $filter('IsAvailable')(value);
        }
        return value;
      },

      exporterPdfDefaultStyle: {fontSize: 9},
      exporterPdfTableStyle: {margin: [0, 0, 0, 0]},
      exporterPdfTableHeaderStyle: {
        fontSize: 10,
        bold: true,
        italics: true,
        color: 'red'
      },
      exporterPdfHeader: {text: 'Segments', style: 'headerStyle'},
      exporterPdfFooter: function(currentPage: any, pageCount: any) {
        return {text: currentPage.toString() + ' of ' + pageCount.toString(), style: 'footerStyle'};
      },
      exporterPdfCustomFormatter: function(docDefinition: any) {
        docDefinition.styles.headerStyle = {fontSize: 22, bold: true, margin: [40, 10, 0, 0]};
        docDefinition.styles.footerStyle = {fontSize: 10, bold: true, margin: [40, 10, 0, 0]};
        docDefinition.content[0].table.widths = ['*', '*', 'auto', 'auto', 'auto', 'auto', 'auto'];

        return docDefinition;
      },
      exporterPdfOrientation: 'landscape',
      exporterPdfPageSize: 'LETTER',
      exporterPdfMaxGridWidth: 650,
      treeIndent: 1,
      treeRowHeaderBaseWidth: 30,

      rowTemplate: '<div grid="grid" ng-class="{ \'audiences__grid_folder\': row.entity.is_empty }"><div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader, \'custom\': true }" ui-grid-cell></div></div>',

      onRegisterApi: (gridApi: any) => {
        this.gridApi = gridApi;

        this.getAnalyticLink = () => {
          if(this.gridApi.selection.getSelectedCount() !== 1 || this.gridApi.selection.getSelectedRows()[0].is_empty) {
            return '';
          } else {
            return '/marketplace/' + this.gridApi.selection.getSelectedRows()[0].id + '/analytics';
          }
        };
      },
      columnDefs: [
        {
          field: 'segment_name',
          displayName: 'Name',
          headerCellFilter: 'translate',
          sort: {direction: 'asc', priority: 1},
          width: '*',
          cellTemplate: `<div class='ui-grid-cell-contents'>{{row.entity.segment_name}} <span ng-show='!row.entity.is_empty && !!row.entity.segment_id'>({{row.entity.segment_id}})</span></div>`,
          cellClass: function(grid: any, row: any) {
            let addClass = 'tree_level--' + row.entity.$$treeLevel;
            if(row.entity.is_empty) {
              addClass += ' segments__group_row';
            }
            addClass += ' audiences__cell_name';
            return addClass;
          }
        },
        {
          field: 'size',
          type: 'number',
          displayName: 'Size',
          cellClass: 'to-right',
          cellFilter: 'number:0',
          headerCellFilter: 'translate',
          width: 100
        },
        {
          field: 'is_empty',
          type: 'boolean',
          displayName: 'group',
          visible: false,
          width: '0',
          sort: {direction: 'desc', priority: 0}
        },
        {
          name: 'add',
          displayName: 'Add to Constructor',
          headerCellFilter: 'translate',
          visible: false,
          cellTemplate: `<div class='ui-grid-cell-contents center' ng-click='grid.appScope.$ctrl.toggleConstructorAudience(row.entity)'><span ng-class='grid.appScope.getIcon(row.entity)'></span></div>`,
          width: 150
        }
      ]
    };


    this.marketplaceService.getAudienceList().then((marketplace: any) => {
      this.gridConfig.data = marketplace;
      this.preloader = false;
    });

    this.$scope.$on(ConstService.AUTH_EVENTS.impersonated, () => {
      this.marketplaceService.update();
    });

    this.authService.text$.subscribe((text) => {
      this.textLoc = text;
      this.gridConfig.columnDefs[0].displayName = text.name;
      this.gridConfig.columnDefs[1].displayName = text.sizeL;
      this.gridConfig.columnDefs[2].displayName = text.group;
      this.gridConfig.columnDefs[3].displayName = text.addToConstructor;
      if (this.gridApi) {
        this.gridApi.core.notifyDataChange(this.uiGridConstants.dataChange.COLUMN);
      }
    });

    this.authService.userTariff$.subscribe((tariff: any) => {
      if (!tariff) {
        return;
      }
      this.tariff = tariff;
    });
  }

  public getAnalyticLink() {
    return '';
  }

  public getTooltipText(button: any) {
    if(!this.tariff.type) {
      return this.textLoc.tariffPermissionError;
    }
    if (button === 'analytic' && (!this.gridApi.selection.getSelectedGridRows().length || this.gridApi.selection.getSelectedRows()[0].is_empty)) {
      return this.textLoc.selectAtLeastOneSegmentFromTheTable;
    }
    if (button === 'constructor') {
      return this.textLoc.useTheConstructor;
    }
    return '';
  }

  public toggleConstructorAudience(audience: any) {
    if(!audience.is_active) {
      return;
    }
    if(!this.constructorService.isAudienceInList(audience.id)) {
      this.constructorService.addAudience(new Audience(audience));
    } else {
      this.constructorService.removeAudience(new Audience(audience));
    }
  }

  public getIcon(audience: any) {
    if(!audience.is_active) {
      return 'glyphicon glyphicon-ban-circle';
    }
    if(!this.constructorService.isAudienceInList(audience.id)) {
      return 'glyphicon glyphicon-plus';
    } else {
      return 'glyphicon glyphicon-minus';
    }
  }

  public navigate(button: string): void {
    if (!this.tariff.type) {
      return;
    }
    if(button === 'analytic') {
      this.router.navigate(['/marketplace/' + this.gridApi.selection.getSelectedRows()[0].id + '/analytics']);
    }
    if(button === 'constructor') {
      this.router.navigate(['/audiences/constructor/']);
    }
  }

  public getSearchData(search: any) {
    const SEARCH_RESULTS_LIMIT = 50;
    let matcher = new RegExp(search, 'i');
    this.searchData = [];
    let segment;
    let colMatch;
    for(let i = 0; i < this.gridConfig.data.length && this.searchData.length < SEARCH_RESULTS_LIMIT; i++) {
      colMatch = 1;
      segment = this.gridConfig.data[i];
      if(segment.segment_name.match(matcher) || (String(segment.segment_id).match(matcher) && !(colMatch = 0))) {
        this.searchData.push({
          segment_name: segment.segment_name,
          segment_id: segment.segment_id,
          index: i,
          colMatch: colMatch
        });
      }
    }
  }

  public uiSelect(item: any, select: any) {
    if(!item) { return; }
    let treeLevel = this.gridApi.grid.rows[item.index].treeLevel - 1;
    let parentRow = this.gridApi.grid.rows[item.index];
    while(treeLevel-- >= 0) {
      parentRow = parentRow.treeNode.parentRow;
      this.gridApi.treeBase.expandRow(parentRow);
    }
    this.gridApi.selection.clearSelectedRows();
    this.gridApi.selection.selectRow(this.gridConfig.data[item.index]);

    const scrollToFocus = () => {
      this.gridApi.core.scrollTo(this.gridConfig.data[item.index], this.gridConfig.columnDefs[item.colMatch]);
    };

    setTimeout(scrollToFocus, 0);

    const event = document.createEvent('Event');
    select.clear(event);
  }
}


