import { Component, OnInit } from 'angular-ts-decorators';
import * as Highcharts from 'highcharts';
import * as moment from 'moment';
// Services
import { AudiencesService } from '../../../app/core/services/audiences.service';
import { ConstService } from '../../../app/shared/const.service';
import { PixelService } from '../../../app/core/services/pixel.service';
import { AuthService } from '../../../app/core/services/auth.service';
import { UtilsService } from '../../../app/core/services/utils.service';
import { uiNotification, ui, IScope, IQService } from 'angular';
// Classes
import { AnalyticsBasic, CategoryAnalytics } from '../../audiences/shared/analytics.type';
import {
  PixelStatsSelector,
  PixelAnalyticsTimeSpent,
  PixelAnalyticsTimeSpentSelected,
  PixelAnalyticsCategoryGroup,
  AnalyticsSelector,
  DatePicker
} from '../shared/pixel-analytics.type';
import { PixelStats } from '../shared/pixel.type';
import { PixelResponse } from '../shared/response.type';
import { DjangoResponse } from '../../../app/shared/types/django-response.type';
import { ErrorResponse } from '../../../app/shared/types/error-response.type';
import { SegmentCreateResponse } from '../../audiences/shared/response.type';
import {forkJoin} from 'rxjs';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'pixelAnalyticsComponent',
  templateUrl: './pixel-analytics.pug'
})
export class PixelAnalyticsComponent implements OnInit {

  static $inject: string[] = [
    '$stateParams',
    '$scope',
    'pixelService',
    'pixelResource',
    'Notification',
    'audiencesService',
    'utilsService',
    'authService',
    '$q'
  ];
  private allCSVdata: (string | number)[][] = [];
  private seriesMap: AnalyticsBasic = {
    views: 0,
    hosts: 1,
    uniqs: 2
  };
  private selectorNamesMap: PixelStatsSelector = {};
  private selectorIdMap: any = {};
  private formsIdMap: any = {};
  private timeSpentData: PixelAnalyticsTimeSpent[] = [{
    name: '1s-5s',
    min: 1,
    max: 4,
    count: 0
  }, {
    name: '5s-10s',
    min: 5,
    max: 9,
    count: 0
  }, {
    name: '10s-30s',
    min: 10,
    max: 29,
    count: 0
  }, {
    name: '30s-60s',
    min: 30,
    max: 59,
    count: 0
  }, {
    name: '1m-2m',
    min: 60,
    max: 119,
    count: 0
  }, {
    name: '2m-5m',
    min: 120,
    max: 299,
    count: 0
  }, {
    name: '5m-10m',
    min: 300,
    max: 599,
    count: 0
  }, {
    name: '10m-30m',
    min: 600,
    max: 1799,
    count: 0
  }, {
    name: '>30m',
    min: 1800,
    max: 1801,
    count: 0
  }];
  private breakdownAnalytics: any = [];
  private selectorsAnalytics: any = [];
  private dinamicAnalytics: any = [];
  private formAnalytics: any = [];

  public preloader: boolean = true;
  public pixelId: number;
  public pixel: PixelResponse;
  public notFound: boolean = false;
  public analytics: CategoryAnalytics[] = [];
  public domains: string[] = [];
  public domainsPreloader: boolean = true;
  public selectedDomain: string = 'All';
  public commonInfo: AnalyticsBasic = {
    views: 0,
    hosts: 0,
    uniqs: 0
  };
  public selectedTimeSpent: PixelAnalyticsTimeSpentSelected = {
    name: '',
    category: '',
    y: null
  };
  public selectedPageView: any = {
    name: '',
    category: '',
    x: null
  };
  public timeSpentRange: number[] = [1, 60 * 30];
  public pageViewRange: number[] = [1, 2];
  public pageViewMax: number = 2;
  public dynamicsFilters: PixelAnalyticsCategoryGroup[] = [{
    name: 'All',
    group: ''
  }];
  public selectedCategory: PixelAnalyticsCategoryGroup;
  public selectorSelected: AnalyticsSelector;
  public selectorsList: AnalyticsSelector[] = [];
  public formSelected: any;
  public formsList: any = [];

  public createAnalyticsConstructorPreloader: boolean = false;
  public createTimeSpentAudiencePreloader: boolean = false;

  public dynamicsChartConfig: any;
  public devicesChartConfig: any;
  public osChartConfig: any;
  public browsersChartConfig: any;
  public referrersChartConfig: any;
  public timeSpentChartConfig: any;
  public pageViewChartConfig: any;
  public selectorsChartConfig: any;
  public formsChartConfig: any;

  public searchDomains: string[] = ['All'];
  public datePicker: any = {
    date: {
      startDate: moment().subtract(1, 'months'),
      endDate: moment()
    },
    opts: {
      separator: '.',
      opens: 'left',
      eventHandlers: {
        'apply.daterangepicker': () => {
          this.dynamicsChartConfig.xAxis.min = parseInt(this.datePicker.date.startDate.format('x'), 10);
          this.dynamicsChartConfig.xAxis.max = parseInt(this.datePicker.date.endDate.hour(0)
            .minute(0)
            .second(0)
            .millisecond(0)
            .format('x'), 10);
          this.updateAnalytics();
        }
      },
      locale: {
        cancelLabel: 'Cancel',
        applyLabel: 'Okay',
        clearLabel: 'Clear',
        customRangeLabel: 'Custom range',
        daysOfWeek: moment.weekdaysMin(),
        monthNames: moment.monthsShort(),
      }
    }
  };
  public textLoc: any = {};
  public langId: any = localStorage.getItem('langId') || 'ru';

  constructor(private $stateParams: ui.IStateParamsService,
              private $scope: IScope,
              private pixelService: PixelService,
              private pixelResource: any,
              private Notification: uiNotification.INotificationService,
              private audiencesService: AudiencesService,
              private utilsService: UtilsService,
              private authService: AuthService,
              private $q: IQService) {

  }

  ngOnInit(): void {
    this.pixelId = this.$stateParams.pixelId;
    this.selectedCategory = this.dynamicsFilters[0];
    this.pixelService.getPixelById(this.pixelId).subscribe((response: any) => {
      this.pixel = response;
      this.pixel.stats_settings.forEach((setting: PixelStats) => {
        if(['clicks_selector', 'views_selector'].indexOf(setting.type) !== -1) {
          this.selectorNamesMap[setting.value] = setting.description;
          this.selectorIdMap[setting.id] = setting.description;
        }
      });
      this.pixel.postforms.forEach((forms: any) => {
        this.formsIdMap[forms.id] = forms.description;
      });
      this.updateAnalytics();
      this.preloader = false;
    }, () => {
      this.notFound = true;
      this.preloader = false;
    });

    this.dynamicsChartConfig = this.getChartOptions({
      title: 'Dynamics',
      type: 'spline',
      legend: true
    });
    this.devicesChartConfig = this.getChartOptions({
      title: 'Devices',
      type: 'pie',
      legend: true
    });
    this.osChartConfig = this.getChartOptions({
      title: 'OS',
      type: 'pie',
      legend: true
    });
    this.browsersChartConfig = this.getChartOptions({
      title: 'Browsers',
      type: 'pie',
      legend: true
    });
    this.referrersChartConfig = this.getChartOptions({
      title: 'Referrers',
      type: 'areaspline',
      legend: true
    });
    this.timeSpentChartConfig = this.getChartOptions({
      title: 'TimeSpent',
      type: 'column',
      legend: true,
      categories: this.timeSpentData.map((item: PixelAnalyticsTimeSpent) => {
        return item.name;
      })
    });
    this.pageViewChartConfig = this.getChartOptions({
      title: 'ScrollDepth',
      type: 'areaspline',
      legend: true
    });
    this.selectorsChartConfig = this.getChartOptions({
      title: 'Selectors',
      type: 'bar',
      legend: true
    });
    this.formsChartConfig = this.getChartOptions({
      title: 'Forms',
      type: 'bar',
      legend: true
    });

    this.pixelService.getPixelDomains(this.pixelId).subscribe((response: any) => {
      this.domains = response.filter((domain: string) => {
        return domain !== 'All';
      });
      this.getSearchDomains(null);
      this.domainsPreloader = false;
    },() => {
      this.domainsPreloader = false;
    });

    this.authService.text$.pipe(filter((text) => text)).subscribe((text) => {
      this.textLoc = text;
      this.langId = localStorage.getItem('langId') || 'ru';

      this.datePicker.opts.locale.cancelLabel = text.cancel;
      this.datePicker.opts.locale.applyLabel = text.applyLabel;
      this.datePicker.opts.locale.clearLabel = text.clearLabel;
      this.datePicker.opts.locale.customRangeLabel = text.customRangeLabel;
      this.datePicker.opts.locale.daysOfWeek = moment.weekdaysMin();
      this.datePicker.opts.locale.monthNames = moment.monthsShort();

      setTimeout(() => {
        this.dynamicsChartConfig.options.title.text = text.dynamics;
        this.devicesChartConfig.options.title.text = text.devices;
        this.osChartConfig.options.title.text = text.os;
        this.browsersChartConfig.options.title.text = text.browsers;
        this.referrersChartConfig.options.title.text = text.referrers;
        this.timeSpentChartConfig.options.title.text = text.timeSpent;
        this.pageViewChartConfig.options.title.text = text.scrollDepth;
        this.selectorsChartConfig.options.title.text = text.selectors;
        this.formsChartConfig.options.title.text = text.forms;
      }, 500);
    });
  }

  public selectDomain(domainName: string): void {
    this.selectedDomain = domainName;
    this.updateAnalytics();
  }

  /**
   * @param {PixelAnalyticsCategoryGroup} category - category
   */
  public selectCategory(category: PixelAnalyticsCategoryGroup): void {
    setTimeout(() => {
      const group: any = category.name === 'All' ? 'total' : category.group;
      this.pixelService.getAnalytics(this.pixelId, this.datePicker.date.startDate, this.datePicker.date.endDate,
        this.selectedDomain, [group])
        .subscribe((response: any) => {
          if(category.name === 'All') {
            this.dinamicAnalytics = response.data;
          } else {
            this.dinamicAnalytics = response.data.filter((row: any) => row.category_name === category.name);
          }
          this.getChartsData();
        });
    }, 0);
  }

  /**
   * @param {AnalyticsSelector} selector
   */
  public selectSelector(selector: AnalyticsSelector): void {
    this.selectorSelected = selector;
  }

  /**
   * Create audience for pixel analytics category
   * @param {string} type - user action type
   */
  public createAnalyticsConstructor(type: string): void {
    const name: string = [this.pixel.description, this.selectorSelected.description,
      type.replace('event_user_action_', '') + 's'].join(' ');
    if(this.createAnalyticsConstructorPreloader || !this.isAnalyticsConstructorAvailable(type)
      || !confirm(`'${this.textLoc.audienceWillBeCreated}' '${name}' ?`)) {
      return;
    }
    this.createAnalyticsConstructorPreloader = true;
    this.pixelService.addSegmentConstructorAnalytics({
      account: this.authService.getSelectedAccount().account_id,
      pixelId: this.pixelId,
      analytics_category_id: this.selectorSelected.actions[type],
      segment_name: name
    }).subscribe((response: any) => {
      this.audiencesService.addAudience(response);
      this.Notification.success(this.textLoc.audienceCreated);
      this.createAnalyticsConstructorPreloader = false;
    },(error: any) => {
      this.Notification.error(error.data.error_message);
      this.createAnalyticsConstructorPreloader = false;
    });
  }

  public createFormAnalyticsConstructor(): void {
    this.createAnalyticsConstructorPreloader = true;
    this.audiencesService.addAudienceForm({
      account: this.authService.getSelectedAccount().account_id,
      postform_id: this.formSelected.id
    }).subscribe((response: DjangoResponse<SegmentCreateResponse>) => {
      this.audiencesService.addAudience(response.data);
      this.Notification.success(this.textLoc.audienceCreated);
      this.createAnalyticsConstructorPreloader = false;
    }, (error: DjangoResponse<ErrorResponse>) => {
      this.createAnalyticsConstructorPreloader = false;
      this.Notification.error(error.data.error_message);
    });
  }

  /**
   * Create audience for pixel time range
   */
  public createTimeSpentAudience(): void {
    if(!this.selectedTimeSpent.category || !confirm(`'${this.textLoc.audienceWillBeCreated}' '${this.selectedTimeSpent.name}' ?`)) {
      return;
    }
    this.createTimeSpentAudiencePreloader = true;
    // const selectedTimeCategory = timeSpentData.reduce(function(prev, cur) {
    // 	return cur.name === this.selectedTimeSpent.category ? cur : prev;
    // }, null);
    // if(!selectedTimeCategory) {
    // 	this.Notification.error('Time category not found');
    // 	return;
    // }
    this.pixelService.addSegmentConstructorAnalyticsEvent({
      account: this.authService.getSelectedAccount().account_id,
      pixelId: this.pixelId,
      start: this.timeSpentRange[0],
      end: this.timeSpentRange[1] === 1801 ? 60 * 60 * 10 : this.timeSpentRange[1],
      segment_name: this.selectedTimeSpent.name,
      event_name: 'event_time_spent'
    }).subscribe((response: any) => {
      this.audiencesService.addAudience(response);
      this.Notification.success(this.textLoc.audienceCreated);
      this.createTimeSpentAudiencePreloader = false;
    }, (error: any) => {
      this.Notification.error(error.data.error_message);
      this.createTimeSpentAudiencePreloader = false;
    });
  }

  /**
   * Create audience for pixel page view
   */
  public createPageViewAudience(): void {
    if(!this.selectedPageView.category || !confirm(`'${this.textLoc.audienceWillBeCreated}' '${this.selectedPageView.name}' ?`)) {
      return;
    }
    this.createTimeSpentAudiencePreloader = true;
    this.pixelService.addSegmentConstructorAnalyticsEvent({
      account: this.authService.getSelectedAccount().account_id,
      pixelId: this.pixelId,
      start: this.pageViewRange[0],
      end: this.pageViewRange[1],
      segment_name: this.selectedPageView.name,
      event_name: 'event_user_action_scroll'
    }).subscribe((response: any) => {
      this.audiencesService.addAudience(response);
      this.Notification.success(this.textLoc.audienceCreated);
      this.createTimeSpentAudiencePreloader = false;
    },(error: any) => {
      this.Notification.error(error.data.error_message);
      this.createTimeSpentAudiencePreloader = false;
    });
  }

  /**
   * Available constructor for pixel analytics category
   * @param {string} type - user action type
   * @return {boolean}
   */
  public isAnalyticsConstructorAvailable(type: string): boolean {
    if(!this.pixel || !this.pixel.id) {
      return false;
    }
    if(this.authService.selectedAccount.permission !== 'edit') {
      return false;
    } else if(!this.selectorSelected) {
      return false;
    } else {
      return !!this.selectorSelected.actions[type];
    }
  }

  public isFormsAnalyticsConstructorAvailable(): boolean {
    if(!this.pixel || !this.pixel.id) {
      return false;
    }
    if(this.authService.selectedAccount.permission !== 'edit') {
      return false;
    } else if(!this.formSelected) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * Get search domain list with limit 50
   * @param {string} search - search string
   */
  public getSearchDomains(search: string): void {
    const searchResultsLimit: number = 50;
    if(!search || search.toLowerCase() === 'all') {
      this.searchDomains = this.domains.slice(0, searchResultsLimit);
      this.searchDomains.unshift('All');
      return;
    }
    const matcher: RegExp = new RegExp(search, 'i');
    this.searchDomains = [];
    for(let i: number = 0; i < this.domains.length && this.searchDomains.length < searchResultsLimit; i++) {
      if(this.domains[i].match(matcher)) {
        this.searchDomains.push(this.domains[i]);
      }
    }
  }

  /**
   * Download all charts data in csv format
   */
  public exportAllCSV(): void {
    this.utilsService.exportCSVTable(this.allCSVdata, this.pixel.pid + '_pixel_analytics');
  }

  /**
   * Get pixel analytics data from two requests for optimization and
   * fills up lists for form controls
   */
  private updateAnalytics(): void {
    this.preloader = true;

    const query: any = {
      pixelId: this.pixelId,
      noaggr: 1,
      groups: 'total,devices,operating_system,browsers,event_user_action_click,event_user_action_view,event_user_action_form'
    };
    if(this.selectedDomain !== 'All') {
      query.domain = this.selectedDomain;
    }

    forkJoin([
      this.pixelService.getAnalytics(this.pixelId, this.datePicker.date.startDate, this.datePicker.date.endDate,
        this.selectedDomain, ['total', 'ad_tags', 'campaigns', 'event_time_spent', 'event_user_action_scroll']),
      this.pixelService.getAnalytics(this.pixelId, this.datePicker.date.startDate, this.datePicker.date.endDate,
        this.selectedDomain, ['http_referrers']),
      this.pixelService.getPixelAnalyticsCommon(query)
    ]).subscribe((responses: any) => { // TODO пределать интерфейсы
      this.analytics = responses[0].data.concat(responses[1].data, responses[2]);

      this.dinamicAnalytics = responses[0].data.filter(
        (item: any) => ['total'].includes(item.group_name)
      );

      const selectorsList: any = {};
      this.analytics.filter((item: CategoryAnalytics) => {
        return ['event_user_action_click', 'event_user_action_view'].indexOf(item.group_name) !== -1 && item.event_id;
      }).forEach((row: CategoryAnalytics) => {
        selectorsList[row.event_id] || (selectorsList[row.event_id] = {
          actions: {},
          name: row.category_name,
          description: row.event_desc
        });
        selectorsList[row.event_id].actions[row.group_name] = row.category_id;
      });

      this.selectorsList = Object.values(selectorsList);
      this.selectorSelected = this.selectorsList[0];

      const dates: { [key: string]: boolean } = {};
      const categories: any = {
        devices: {},
        operating_system: {},
        browsers: {},
        http_referrers: {},
        ad_tags: {},
        campaigns: {}
      };
      this.analytics.forEach((row: CategoryAnalytics) => {
        categories[row.group_name] && (categories[row.group_name][row.category_name] = true);
      });

      this.dinamicAnalytics.forEach((row: any) => {
        dates[row.date] = true;
      });

      for(const group in categories) {
        if(categories.hasOwnProperty(group)) {
          for(const category in categories[group]) {
            if(categories[group].hasOwnProperty(category)) {
              this.dynamicsFilters.push({name: category, group: group});
            }
          }
        }
      }

      const dateList: number[] = Object.keys(dates).map((date: string) => {
        return parseInt(moment(date, 'YYYY-MM-DD').format('x'), 10);
      });

      if(dateList.length) {
        dateList.sort();
        this.datePicker.date.startDate = moment(dateList[0]);
        this.datePicker.date.endDate = moment(dateList[dateList.length - 1]);
      }

      this.commonInfo = responses[2].find((item: any) => item.group_name === 'total');
      if(!this.commonInfo) {
        this.commonInfo = {
          views: 0,
          hosts: 0,
          uniqs: 0
        };
      }
      this.breakdownAnalytics = responses[2].filter(
        (item: any) => ['devices', 'operating_system', 'browsers'].includes(item.group_name)
      );
      this.selectorsAnalytics = responses[2].filter(
        (item: any) => ['event_user_action_click', 'event_user_action_view'].includes(item.group_name)
      );
      this.formAnalytics = responses[2].filter(
        (item: any) => ['event_user_action_form'].includes(item.group_name)
      );
      this.getBreakdownData();
      this.getSelectorsData();
      this.getFormsData();

      this.getSearchDomains(null);
      this.getChartsData();
      this.getReferrersData();
      this.getTimeSpentData();
      this.getPageViewData();
      this.getCSVdata();
      this.preloader = false;
    }, () => {
      this.preloader = false;
    });
  }

  /**
   * Get csv format data from pixel analytics
   */
  private getCSVdata(): void {
    this.allCSVdata.length = 0;
    this.allCSVdata.push(['pixel', this.pixel.description]);
    this.allCSVdata.push(['pid', this.pixel.pid]);

    const categories: any = {
      devices: {name: 'Device', data: []},
      operating_system: {name: 'OS', data: []},
      browsers: {name: 'Browser', data: []},
      http_referrers: {name: 'Referrers', data: []},
      ad_tags: {name: 'Tags', data: []},
      campaigns: {name: 'Campaigns', data: []},
      event_time_spent: {name: 'Visit Time', data: []},
      event_user_action_form: {name: 'Forms', data: []},
      event_user_action_click: {name: 'Clicks', data: []},
      event_user_action_view: {name: 'Views', data: []},
      event_user_action_scroll: {name: 'Scroll depth', data: []}
    };
    const category_title: string[] = ['category', 'date', 'domain', 'hosts', 'uniqs', 'views'];
    this.analytics.forEach((row: CategoryAnalytics) => {
      if (categories[row.group_name]) {
        categories[row.group_name].data.push([row.category_name, row.date, row.domain, row.hosts, row.uniqs, row.views]);
      }
    });

    for(const prop in categories) {
      if(categories.hasOwnProperty(prop)) {
        if(!categories[prop].data.length) {
          return;
        }
        this.allCSVdata.push([]);
        this.allCSVdata.push([categories[prop].name]);
        this.allCSVdata.push(category_title);
        categories[prop].data.forEach((row: any) => {
          this.allCSVdata.push(row);
        });
      }
    }
  }

  /**
   * Get dynamics data for chart
   */
  private getChartsData(): void {
    const dynamicsData: any = {views: {}, hosts: {}, uniqs: {}};
    this.dinamicAnalytics.forEach((row: CategoryAnalytics) => {
      dynamicsData.views[row.date] ?
        dynamicsData.views[row.date] += row.views :
        dynamicsData.views[row.date] = row.views;
      dynamicsData.hosts[row.date] ?
        dynamicsData.hosts[row.date] += row.hosts :
        dynamicsData.hosts[row.date] = row.hosts;
      dynamicsData.uniqs[row.date] ?
        dynamicsData.uniqs[row.date] += row.uniqs :
        dynamicsData.uniqs[row.date] = row.uniqs;
    });
    this.dynamicsChartConfig.series[0].data.length = 0;
    this.dynamicsChartConfig.series[1].data.length = 0;
    this.dynamicsChartConfig.series[2].data.length = 0;
    for(const key in dynamicsData) {
      if(dynamicsData.hasOwnProperty(key)) {
        for(const date in dynamicsData[key]) {
          if(dynamicsData[key].hasOwnProperty(date)) {
            this.dynamicsChartConfig.series[this.seriesMap[key]].data.push([parseInt(String(moment.utc(date)
              .valueOf()), 10), dynamicsData[key][date]]);
          }
        }
        this.dynamicsChartConfig.series[this.seriesMap[key]].data.sort((a: number[], b: number[]) => {
          return a[0] - b[0];
        });
      }
    }
  }

  /**
   * Get time spent data for chart
   */
  private getTimeSpentData(): void {
    this.timeSpentData.forEach((item: PixelAnalyticsTimeSpent) => {
      item.count = 0;
    });
    this.timeSpentChartConfig.series[0].data = [];
    this.analytics.forEach((row: CategoryAnalytics) => {
      if(row.group_name === 'event_time_spent') {
        const seconds: number = parseInt(row.category_name, 10);
        const maxCategory: PixelAnalyticsTimeSpent = this.timeSpentData[this.timeSpentData.length - 1];
        if(seconds >= maxCategory.min) {
          maxCategory.count += row.uniqs;
          return;
        }
        let category: PixelAnalyticsTimeSpent = null;
        for(let i: number = 0; i < this.timeSpentData.length; i++) {
          category = this.timeSpentData[i];
          if(seconds >= category.min && seconds <= category.max) {
            category.count += row.uniqs;
          }
        }
      }
    });
    if(this.timeSpentData.every((item: PixelAnalyticsTimeSpent) => {
      return item.count === 0;
    })) {
      return;
    }
    this.timeSpentChartConfig.series[0].data = this.timeSpentData.map((item: PixelAnalyticsTimeSpent) => {
      return item.count;
    });
  }

  /**
   * Get page view data for chart
   */
  private getPageViewData(): void {
    const pageViewData: { [key: string]: number } = {};
    this.analytics.forEach((row: CategoryAnalytics) => {
      if(row.group_name === 'event_user_action_scroll') {
        pageViewData[row.category_name] ?
          pageViewData[row.category_name] += row.uniqs :
          pageViewData[row.category_name] = row.uniqs;
      }
    });

    const pageViewArr: Highcharts.Position[] = [];
    for(const prop in pageViewData) {
      if(pageViewData.hasOwnProperty(prop)) {
        pageViewArr.push({
          x: parseInt(prop, 10),
          y: pageViewData[prop]
        });
      }
    }
    pageViewArr.sort((a: Highcharts.Position, b: Highcharts.Position) => {
      return b.x - a.x;
    });
    this.pageViewChartConfig.series[0].data.length = 0;
    pageViewArr.forEach((row: Highcharts.Position, index: number) => {
      pageViewArr[index + 1] && (pageViewArr[index + 1].y += pageViewArr[index].y);
    });
    this.pageViewChartConfig.series[0].data = pageViewArr.reverse();
    if (this.pageViewChartConfig.series[0].data.length > 1000) {
      this.pageViewChartConfig.series[0].data.length = 1000;
    }
    if (this.pageViewChartConfig.series[0].data.length) {
      this.pageViewMax = (this.pageViewChartConfig.series[0].data[this.pageViewChartConfig.series[0].data.length - 1] as any).x;
    }
  }

  private getFormsData(): void {
    this.formsChartConfig.xAxis.categories.length = 0;

    this.formAnalytics.forEach((row: any) => {
      const formId: any = row.category_name.replace('id!_', '');
      this.formsList.push({id: formId, description: this.formsIdMap[formId]});
      this.formsChartConfig.xAxis.categories.push(this.formsIdMap[formId]);
      this.formsChartConfig.series[0].data.push(row.uniqs);
    });

    this.formSelected = this.formsList[0];
  }

  /**
   * Get selectors data for chart
   */
  private getSelectorsData(): void {
    const selectorsData: any = {
      'event_user_action_click': {},
      'event_user_action_view': {}
    };
    const selectorsDataForEventId: any = {
      'event_user_action_click': {},
      'event_user_action_view': {}
    };
    this.selectorsChartConfig.xAxis.categories.length = 0;
    this.selectorsAnalytics.forEach((row: CategoryAnalytics) => {
      if(['event_user_action_click', 'event_user_action_view'].indexOf(row.group_name) !== -1 && row.event_id) {
        selectorsDataForEventId[row.group_name][row.event_id] ?
          selectorsDataForEventId[row.group_name][row.event_id] += row.uniqs :
          selectorsDataForEventId[row.group_name][row.event_id] = row.uniqs;
        this.selectorIdMap[row.event_id] || (this.selectorIdMap[row.event_id] = row.category_name);
        this.selectorsChartConfig.xAxis.categories.push(this.selectorIdMap[row.event_id]);
      }
    });
    this.selectorsChartConfig.xAxis.categories = this.selectorsChartConfig.xAxis.categories.filter((v, i, a) => a.indexOf(v) === i);
    this.selectorsChartConfig.series[0].data = new Array(this.selectorsChartConfig.xAxis.categories.length).fill(null);
    this.selectorsChartConfig.series[1].data = new Array(this.selectorsChartConfig.xAxis.categories.length).fill(null);
    for(const prop in selectorsData.event_user_action_click) {
      if(selectorsData.event_user_action_click.hasOwnProperty(prop)) {
        this.selectorsChartConfig.series[0].data[this.selectorsChartConfig.xAxis.categories.indexOf(this.selectorNamesMap[prop])] =
          selectorsData.event_user_action_click[prop];
      }
    }
    for(const prop in selectorsData.event_user_action_view) {
      if(selectorsData.event_user_action_view.hasOwnProperty(prop)) {
        this.selectorsChartConfig.series[1].data[this.selectorsChartConfig.xAxis.categories.indexOf(this.selectorNamesMap[prop])] =
          selectorsData.event_user_action_view[prop];
      }
    }
    for(const prop in selectorsDataForEventId.event_user_action_click) {
      if(selectorsDataForEventId.event_user_action_click.hasOwnProperty(prop)) {
        this.selectorsChartConfig.series[0].data[this.selectorsChartConfig.xAxis.categories.indexOf(this.selectorIdMap[prop])] =
          selectorsDataForEventId.event_user_action_click[prop];
      }
    }
    for(const prop in selectorsDataForEventId.event_user_action_view) {
      if(selectorsDataForEventId.event_user_action_view.hasOwnProperty(prop)) {
        this.selectorsChartConfig.series[1].data[this.selectorsChartConfig.xAxis.categories.indexOf(this.selectorIdMap[prop])] =
          selectorsDataForEventId.event_user_action_view[prop];
      }
    }

    this.selectorsChartConfig.yAxis[0].type = 'logarithmic';
    this.selectorsChartConfig.yAxis[0].labels.step = 2;
    const height: number = this.selectorsChartConfig.xAxis.categories.length * 50;
    if(height > 400) {
      this.selectorsChartConfig.options.chart.height = height;
      this.selectorsChartConfig.options.chart.width = 1062;
    }
  }

  /**
   * Get referrers data for chart
   */
  private getReferrersData(): void {
    const referrersData: any = {};
    const all_dates: { [key: string]: boolean } = {};
    this.analytics.forEach((row: CategoryAnalytics) => {
      if(row.group_name === 'http_referrers') {
        if(referrersData[row.category_name]) {
          referrersData[row.category_name][row.date] ?
            referrersData[row.category_name][row.date] += row.views :
            referrersData[row.category_name][row.date] = row.views;
        } else {
          referrersData[row.category_name] = {};
          referrersData[row.category_name][row.date] = row.views;
        }
        all_dates[row.date] = true;
      }
    });
    const allDateList: string[] = Object.keys(all_dates);

    this.referrersChartConfig.series.length = 0;
    for(const prop in referrersData) {
      if(referrersData.hasOwnProperty(prop)) {
        allDateList.forEach((date: string) => {
          referrersData[prop][date] || (referrersData[prop][date] = 0);
        });
        this.referrersChartConfig.series.push({
          name: prop,
          // color: ConstService.COLORS.RED,
          data: []
        });
        const currentSeries: any = this.referrersChartConfig.series.slice(-1).pop();
        for(const date in referrersData[prop]) {
          if(referrersData[prop].hasOwnProperty(date)) {
            currentSeries.data.push([parseInt(String(moment.utc(date).valueOf()), 10), referrersData[prop][date]]);
          }
        }
        currentSeries.data.sort((a: number[], b: number[]) => {
          return a[0] - b[0];
        });
      }
    }
    this.referrersChartConfig.series.sort((b: Highcharts.SeriesOptions, a: Highcharts.SeriesOptions) => {
      return a.data.slice(-1).pop()[1] - b.data.slice(-1).pop()[1];
    });
  }

  /**
   * Get breakdown data for charts
   */
  private getBreakdownData(): void {
    const breakdown: any = {
      devices: {},
      operating_system: {},
      browsers: {}
    };
    this.breakdownAnalytics.forEach((row: CategoryAnalytics) => {
      if(breakdown.hasOwnProperty(row.group_name)) {
        breakdown[row.group_name][row.category_name] ?
          breakdown[row.group_name][row.category_name] += row.uniqs :
          breakdown[row.group_name][row.category_name] = row.uniqs;
      }
    });
    let breakdownConfigSeriesData: any = null;
    this.devicesChartConfig.series[0].data.length = 0;
    this.osChartConfig.series[0].data.length = 0;
    this.browsersChartConfig.series[0].data.length = 0;
    for(const group in breakdown) {
      if(breakdown.hasOwnProperty(group)) {
        switch(group) {
          case 'devices':
            breakdownConfigSeriesData = this.devicesChartConfig.series[0].data;
            break;
          case 'operating_system':
            breakdownConfigSeriesData = this.osChartConfig.series[0].data;
            break;
          case 'browsers':
            breakdownConfigSeriesData = this.browsersChartConfig.series[0].data;
            break;
          default:
            console.warn('unknown analytics group: ' + group);
            breakdownConfigSeriesData = [];
            break;
        }
        const colors: any = Object.values(ConstService.COLORS);
        for(const category in breakdown[group]) {
          if(breakdown[group].hasOwnProperty(category)) {
            breakdownConfigSeriesData.push({
              name: category,
              y: breakdown[group][category]
            });
          }
        }
        breakdownConfigSeriesData.sort((a: any, b: any) => {
          return b.y - a.y;
        });
        breakdownConfigSeriesData.forEach((item: any, index: any) => {
          item.color = colors[index % colors.length];
        });
      }
    }
  }

  /**
   * Get template options for chart
   */
  private getChartOptions(localConfig: {
    title?: string,
    type?: string,
    opposite?: boolean,
    xAxisVisible?: boolean,
    legend?: boolean,
    color?: string,
    categories?: any[]
  }): any {
    const title: string = localConfig.title || '';
    const type: string = localConfig.type || 'bar';
    const opposite: boolean = !!localConfig.opposite;
    const legend: boolean = (typeof localConfig.legend === 'boolean') ? localConfig.legend : true;
    const xAxisVisible: boolean = (typeof localConfig.xAxisVisible === 'boolean') ? localConfig.xAxisVisible : true;
    const categories: any[] = localConfig.categories || [];
    const color: string = localConfig.color || '';

    const options: any = {
      title: {
        text: title
      },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: type
      },
      tooltip: {
        enabled: false,
        pointFormat: '<b>{point.name}: {point.percentage:.1f}%</b>',
        style: {
          padding: 10
        }
      },
      exporting: {
        enabled: true,
        buttons: {
          contextButton: {
            menuItems: [{
              text: 'Export to PNG',
              onclick: function(): void {
                this.exportChart();
              }
            }]
          }
        }
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
            inside: false,
            color: '#000000',
            formatter: function(): string {
              const dataSum: number = this.series.data.reduce((x: any, item: any) => {
                return x + item.total;
              }, 0);
              const pcnt: number = (this.y / dataSum) * 100;
              return Highcharts.numberFormat(pcnt, 0, ',') + '%';
            }
          }
        },
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },
          showInLegend: true
        },
        column: {
          dataLabels: {
            enabled: true,
            inside: false,
            color: '#000000',
            formatter: function(): string {
              const dataSum: number = this.series.data.reduce((x: any, item: any) => {
                return x + item.total;
              }, 0);
              const pcnt: number = (this.y / dataSum) * 100;
              return Highcharts.numberFormat(pcnt, 0, ',') + '%';
            }
          }
        }
        // series: {
        // 	stacking: 'normal'
        // }
      },
      legend: {
        enabled: legend
      },

    };
    let xAxis: any = {
      categories: categories,
      opposite: opposite,
      title: {
        text: null
      },
      visible: xAxisVisible
    };
    let yAxis: any = {
      visible: false,
      reversed: opposite
    };
    let series: any = [{
      name: title,
      color: color,
      // colorByPoint: true,
      data: []
    }];

    const self: PixelAnalyticsComponent = this;
    const selectedTimeSpent: PixelAnalyticsTimeSpentSelected = this.selectedTimeSpent;
    const selectedPageView: any = this.selectedPageView;
    const timeSpentData: any = this.timeSpentData;
    const Notification: uiNotification.INotificationService = this.Notification;
    const timeSpentRange: number[] = this.timeSpentRange;
    const pageViewRange: number[] = this.pageViewRange;
    const $scope: IScope = this.$scope;

    for(let i: number = 0; i < categories.length; i++) {
      series[0].data.push({
        name: '',
        size: 0,
        default_percent: 0,
        y: 0
      });
    }
    switch(title) {
      case 'Gender':
        options.exporting.enabled = false;
        options.tooltip.pointFormat = '<b>{point.percentage:.1f}%</b>';
        options.tooltip.enabled = true;
        break;
      case 'Population':
        options.tooltip.pointFormat = '<b>{point.percentage:.1f}%</b>';
        options.tooltip.enabled = true;
        break;
      case 'Females':
        options.exporting.enabled = false;
        options.plotOptions.series = {stacking: 'normal'};
        options.tooltip.pointFormat = '<b>{point.name}: {point.percent:.1f}%</b>';
        break;
      case 'Males':
      // todo: fix this
      // options.exporting.buttons.contextButton.menuItems[0].onclick = exportAgeGender;
      case 'Education':
      case 'Income':
        options.plotOptions.series = {stacking: 'normal'};
        options.tooltip.pointFormat = '<b>{point.name}: {point.percent:.1f}%</b>';
        break;
      case 'Uniqs':
        options.tooltip.pointFormat = '<b>uniqs: {point.y}</b>';
        options.plotOptions.bar.dataLabels.enabled = false;
        options.tooltip.enabled = true;
        series[0].pointWidth = 20;
        xAxis.width = 200;
        xAxis.labels = {
          style: {
            // 'width': '300px',
            // 'min-width': '300px',
            'font-size': '14px'
          }
          // useHTML : true
        };
        break;
      case 'Affinity':
        options.tooltip.pointFormat = 'Affinity index: <b>{point.real}</b>';
        options.tooltip.enabled = true;
        options.plotOptions.bar.dataLabels.enabled = false;
        series[0].pointWidth = 20;
        yAxis.min = -100;
        yAxis.max = 100;
        break;
      case 'Browsers':
      case 'OS':
      case 'Devices':
        // todo: (prokopenko) to match magic values
        options.tooltip.enabled = true;
        options.legend.floating = true;
        options.legend.layout = 'vertical';
        options.legend.y = 365;
        options.legend.verticalAlign = 'top';
        options.chart.marginBottom = 175;
        options.chart.height = 555;
        series = [{
          type: 'pie',
          innerSize: '30%',
          data: []
        }];
        break;
      case 'Distribution':
        options.plotOptions.series = {
          stacking: 'percent'
        };
        options.plotOptions.column.dataLabels = {
          enabled: true,
          color: 'black',
          format: '{percentage:.0f} %'
        };
        options.legend = {
          layout: 'vertical',
          align: 'right',
          verticalAlign: 'middle'
        };
        break;
      case 'Benchmarks':
        options.plotOptions.column.dataLabels = {
          enabled: true,
          color: 'black',
          format: '{y:.0f} %'
        };
        break;
      case 'Selectors':
        series = [{
          name: 'clicks',
          color: ConstService.COLORS.PINK,
          data: []
        },
          {
            name: 'views',
            color: ConstService.COLORS.PURPLE,
            data: []
          }];
        options.tooltip.enabled = true;
        options.tooltip.split = true;
        options.tooltip.pointFormat = '<b>{series.name}: {point.y}</b>';
        options.plotOptions.bar.dataLabels = {
          enabled: false
        };
        yAxis = [{
          labels: {
            style: {
              color: Highcharts.getOptions().colors[1]
            },
            format: '{value:,.0f}'
          },
          title: {
            text: 'uniqs',
            style: {
              color: Highcharts.getOptions().colors[1]
            }
          },
          opposite: false,
          visible: true
        }];
        // xAxis = {type: 'logarithmic'};
        break;
      case 'Forms':
        series = [{
          name: 'forms',
          color: ConstService.COLORS.PINK,
          data: []
        }];
        options.tooltip.enabled = true;
        options.tooltip.split = true;
        options.tooltip.pointFormat = '<b>{series.name}: {point.y}</b>';
        options.plotOptions.bar.dataLabels = {
          enabled: false
        };
        yAxis = [{
          labels: {
            style: {
              color: Highcharts.getOptions().colors[1]
            },
            format: '{value:,.0f}'
          },
          title: {
            text: 'uniqs',
            style: {
              color: Highcharts.getOptions().colors[1]
            }
          },
          opposite: false,
          visible: true
        }];
        break;
      case 'Referrers':
        xAxis = {type: 'datetime'};
        series = [];
        options.plotOptions.areaspline = {
          stacking: 'normal'
        };
        options.tooltip.enabled = true;
        options.tooltip.split = true;
        options.tooltip.pointFormat = '<b>{series.name}: {point.y}</b>';
        yAxis = [{
          labels: {
            style: {
              color: Highcharts.getOptions().colors[1]
            },
            format: '{value:,.0f}'
          },
          title: {
            text: 'views',
            style: {
              color: Highcharts.getOptions().colors[1]
            }
          },
          opposite: false,
          visible: true
        }];
        break;
      case 'TimeSpent':
        options.tooltip.enabled = true;
        options.tooltip.pointFormat = '<b>{series.name}: {point.y}</b>';
        xAxis.title.text = 'Seconds';
        yAxis = [{
          labels: {
            style: {
              color: Highcharts.getOptions().colors[1]
            },
            format: '{value:,.0f}'
          },
          title: {
            text: 'uniqs',
            style: {
              color: Highcharts.getOptions().colors[1]
            }
          },
          opposite: false,
          visible: true
        }];
        options.plotOptions.column.dataLabels = {
          enabled: true,
          color: 'black',
          formatter: function(): string {
            const dataSum: number = this.series.data.reduce((x: any, item: any) => {
              return x + item.y;
            }, 0);
            const pcnt: number = (this.y / dataSum) * 100;
            return Highcharts.numberFormat(pcnt, 0, ',') + '%';
          }
        };
        series = [
          {
            name: 'uniqs',
            color: ConstService.COLORS.GREEN,
            data: []
          }];
        options.plotOptions.series = {
          cursor: 'pointer',
          point: {
            events: {
              click: function(): void {
                this.select();
                if(this.selected) {
                  selectedTimeSpent.category = this.category;
                  selectedTimeSpent.y = this.y;
                  selectedTimeSpent.name = this.category + ' visit time at Pixel ' + self.pixel.description + ' audience';
                } else {
                  delete selectedTimeSpent.category;
                  delete selectedTimeSpent.y;
                  delete selectedTimeSpent.name;
                }
                if(this.selected) {
                  const timeSpent: any = timeSpentData.reduce((prev: any, cur: any) => {
                    return cur.name === selectedTimeSpent.category ? cur : prev;
                  }, null);
                  if(!timeSpent) {
                    Notification.error('Time category not found');
                    return;
                  }
                  timeSpentRange[0] = timeSpent.min;
                  timeSpentRange[1] = timeSpent.max;
                }
                $scope.$apply();
              }
            }
          }
        };
        break;
      case 'ScrollDepth':
        options.tooltip.enabled = true;
        options.tooltip.split = true;
        options.tooltip.pointFormat = '<b>{series.name}: {point.y}</b>';
        series = [
          {
            name: 'uniqs',
            color: ConstService.COLORS.GREEN,
            data: []
          }];
        xAxis.title.text = 'Pixels';
        yAxis = [{
          labels: {
            style: {
              color: Highcharts.getOptions().colors[1]
            },
            format: '{value:,.0f}'
          },
          title: {
            text: 'uniqs',
            style: {
              color: Highcharts.getOptions().colors[1]
            }
          },
          opposite: false,
          visible: true
        }];
        options.chart.zoomType = 'x';
        options.plotOptions.series = {
          cursor: 'pointer',
          point: {
            events: {
              click: function(): void {
                this.select();
                if(this.selected) {
                  pageViewRange[1] = Number(this.x);
                  selectedPageView.category = this.category;
                  selectedPageView.x = this.x;
                  selectedPageView.name = this.category + ' scroll depth at Pixel ' + self.pixel.description + ' audience';
                } else {
                  delete selectedPageView.category;
                  delete selectedPageView.x;
                  delete selectedPageView.name;
                }
                $scope.$apply();
              }
            }
          }
        };
        break;
      case 'Dynamics':
        xAxis = {type: 'datetime'};
        options.tooltip.enabled = true;
        options.tooltip.split = true;
        options.tooltip.pointFormat = '<b>{series.name}: {point.y}</b>';
        series = [{
          name: 'views',
          color: ConstService.COLORS.RED,
          data: []
        },
          {
            name: 'hosts',
            color: ConstService.COLORS.BLUE,
            data: []
          },
          {
            name: 'uniqs',
            color: ConstService.COLORS.GREEN,
            data: []
          }];
        yAxis = [{
          labels: {
            style: {
              color: Highcharts.getOptions().colors[1]
            },
            format: '{value:,.0f}'
          },
          title: {
            text: 'size',
            style: {
              color: Highcharts.getOptions().colors[1]
            }
          },
          opposite: false,
          visible: true
        }];
        break;
      case 'Performance':
        yAxis = [{
          labels: {
            style: {
              color: Highcharts.getOptions().colors[1]
            }
          },
          title: {
            style: {
              color: Highcharts.getOptions().colors[1]
            }
          },
          opposite: false,
          visible: false
        },
          {
            labels: {
              style: {
                color: Highcharts.getOptions().colors[0]
              }
            },
            title: {
              style: {
                color: Highcharts.getOptions().colors[0]
              }
            },
            opposite: true,
            visible: false
          }];
        xAxis.type = 'datetime';
        xAxis.categories = null;
        options.plotOptions.column.dataLabels.enabled = false;
        options.tooltip.pointFormat = '<b>{point.y:.f}</b>';
        options.tooltip.enabled = true;
        options.chart.zoomType = 'x';
        break;
      default:
        options.tooltip.pointFormat = '<b>{point.name}: {point.percentage:.1f}%</b>';
        break;
    }

    return {
      options,
      xAxis,
      yAxis,
      series
    };
  }
}
