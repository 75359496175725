import { StateProvider } from '@uirouter/angularjs';
import { NgModule } from 'angular-ts-decorators';
import { audisenceRouting } from '../audiences/audience.routes';
import { MarketplaceComponent } from './market-private/marketplace.component';
import { MarketplacePublicComponent } from './market-public/marketplace-public.component';
import { marketplaceRouting } from './marketplace.routes';

@NgModule({
  id: 'MarketplaceModule',
  declarations: [
    MarketplaceComponent,
    MarketplacePublicComponent
  ]
})
export class MarketplaceModule {
  static config = config;
}

function config($stateProvider: StateProvider) {
  marketplaceRouting($stateProvider);
}

config.$inject = ['$stateProvider'];
