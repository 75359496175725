import { StateProvider } from '@uirouter/angularjs';
import { ConstService } from '../../app/shared/const.service';

export const audisenceRouting = ($stateProvider: StateProvider) => {
  $stateProvider
    .state('layout.audiences', {
      url: '/audiences',
      component: 'audienceListComponent',
      data: {
        permissions: {
          except: [ConstService.USER_ROLES.unauthorized],
          redirectTo: ConstService.REDIRECTS.base
        }
      }
    })
    .state('layout.audiences_create', {
      url: '/audiences/create/{pixelId:int}',
      // component: 'audienceEditComponent',
      // data: {
        // permissions: {
          // except: [ConstService.USER_ROLES.unauthorized],
          // redirectTo: ConstService.REDIRECTS.base
        // }
      // }
    })
    .state('layout.audiences_edit', {
      url: '/audiences/{segmentId:int}/edit',
      // component: 'audienceEditComponent',
      // data: {
        // permissions: {
          // except: [ConstService.USER_ROLES.unauthorized],
          // redirectTo: ConstService.REDIRECTS.base
        // },
        // mode: 'edit'
      // }
    })
    .state('layout.lookalike', {
      url: '/audiences/{segmentId:int}/lookalike',
      component: 'audienceLookalikeComponent',
      data: {
        permissions: {
          except: [ConstService.USER_ROLES.unauthorized],
          redirectTo: ConstService.REDIRECTS.base
        }
      }
    })
    .state('layout.constructor', {
      url: '/audiences/constructor',
      // component: 'audienceConstructorComponent',
      // data: {
        // permissions: {
          // except: [ConstService.USER_ROLES.unauthorized],
          // redirectTo: ConstService.REDIRECTS.base
        // }
      // }
    })
    .state('layout.constructor_edit', {
      url: '/audiences/constructor/{segmentId:int}/edit',
      // component: 'audienceConstructorComponent',
      // data: {
        // permissions: {
          // except: [ConstService.USER_ROLES.unauthorized],
          // redirectTo: ConstService.REDIRECTS.base
        // }
      // }
    })
    .state('layout.journey', {
      url: '/audiences/journey'
    })
    .state('layout.predictive', {
      url: '/audiences/{segmentId:int}/predictive'
    })
    .state('layout.analytics', {
      url: '/audiences/{audienceId:int}/analytics'
    });
};
