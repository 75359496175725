import { StateProvider } from '@uirouter/angularjs';
import { ConstService } from '../../app/shared/const.service';

export const billingRouting = ($stateProvider: StateProvider) => {
  $stateProvider
    .state('layout.billing', {
      url: '/billing',
      component: 'billingComponent',
      data: {
        permissions: {
          except: [ConstService.USER_ROLES.unauthorized],
          only: [ConstService.PERMISSIONS.billing],
          redirectTo: ConstService.REDIRECTS.billing
        }
      }
    });
};
