import { Component, OnInit } from 'angular-ts-decorators';

@Component({
  selector: 'datasetComponent',
  templateUrl: './dataset.pug'
})
export class DatasetComponent implements OnInit {

  static $inject: string[] = [
    '$scope',
    '$state',
    '$stateParams',
    'classifierResource',
    'Upload',
    '$http',
    'Notification'
  ];
  public isEdit: any = (this.$state.current.data.mode === 'edit');
  public file: any = null;
  public datasetName = '';
  public uploadPreloader: any;
  public datasetId: any;
  public preloader: any;
  public notFound: any;

  constructor(private $scope: any,
              private $state: any,
              private $stateParams: any,
              private classifierResource: any,
              private Upload: any,
              private $http: any,
              private Notification: any) {
  }

  ngOnInit(): void {
    if(this.isEdit) {
      this.datasetId = this.$stateParams.datasetId;
      this.preloader = true;
      this.classifierResource.get({object: 'dataset', id: this.datasetId}).$promise.then((response: any) => {
        this.datasetName = response.data.name;
      }).catch((error: any) => {
        this.notFound = true;
      }).finally(() => {
        this.preloader = false;
      });
    }
  }


  public create() {
    if(!this.file || !this.datasetName) {
      return;
    }
    this.classifierResource.post({object: 'dataset', name: this.datasetName}).$promise.then((response: any) => {
      this.upload(response.data.id);
      this.$state.go('layout.classifier', {}, {reload: true});
      this.Notification.success('Dataset created');
    }).catch((error: any) => {
    }).finally(() => {
    });
  }

  // upload on file select or drop
  public upload(datasetId: any) {
    if(!this.file || this.uploadPreloader) {
      return;
    }

    this.Upload.upload({
      url: '/api/v1/classifier/dataset/' + datasetId + '/upload/',
      data: {file_obj: this.file},
      method: 'POST',
      // arrayKey: ''
      // sendFieldsAs: 'form'
    }).then((response: any) => {
      let task_id = response.data.data['task-id'];
      this.checkUploadStatus(task_id);
    }, (resp: any) => {
      this.Notification.error('Upload finished with error');
    }, (evt: any) => {
      let progressPercentage = parseInt(String(100.0 * evt.loaded / evt.total), 10);
      console.log('progress: ' + progressPercentage + '%');
    });
  }

  // todo: (prokopenko) move to service
  public checkUploadStatus(task_id: any) {
    let CHECK_TIMEOUT = 3000;
    this.uploadPreloader = true;
    // todo: (prokopenko) use resources
    this.$http.get('/api/v1/classifier/service/tasks/result/' + task_id + '/').then((response: any) => {
      switch(response.data.state) {
        case 'SUCCESS':
          this.uploadPreloader = false;
          this.Notification.success('Upload finished successful');
          break;
        case 'PENDING':
          setTimeout(() => {
            this.checkUploadStatus(task_id);
          }, CHECK_TIMEOUT);
          break;
        case 'FAILURE':
          this.uploadPreloader = false;
          this.Notification.error('Upload finished with error');
          break;
        default:
          this.uploadPreloader = false;
          this.Notification.error('Upload finished with status: ' + response.data.state);
      }
    }).catch((error: any) => {
      this.uploadPreloader = false;
      this.Notification.error('Upload finished with error');
    });
  }

}
