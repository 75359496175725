import { Component, Input } from 'angular-ts-decorators';
import {AuthService} from '../../../app/core/services/auth.service';

@Component({
  selector: 'modalDeactivateComponent',
  templateUrl: './deactivate.modal.pug'
})
export class ModalDeactivateComponent {

  @Input() modalInstance: any;
  static $inject: string[] = [
    'authService'
  ];
  public textLoc = {};

  constructor(private authService: AuthService) {
    this.authService.text$.subscribe((text) => {
      this.textLoc = text;
    });
  }

  public ok(): void {
    this.modalInstance.close();
  }

  public cancel(): void {
    this.modalInstance.dismiss('cancel');
  }
}
