import { Component, OnInit } from 'angular-ts-decorators';
import { IStateService } from 'angular-ui-router';
import * as _ from 'lodash';
import { AuthService } from '../../../app/core/services/auth.service';
import { PixelService } from '../../../app/core/services/pixel.service';

@Component({
  selector: 'pixelEditComponent',
  templateUrl: './pixel-edit.pug'
})
export class PixelEditComponent implements OnInit {

  static $inject: string[] = [
    '$state',
    '$scope',
    '$stateParams',
    'pixelService',
    'audiencesService',
    'Notification',
    'authService'
  ];
  public textLoc: any = {};
  public preloader = true;
  public notFound = false;
  public mode: any;
  public modeEdit: any;
  public submitDisable = false;
  public message = '';
  public title = '';
  public pixel: any = null;
  public aceOption = {mode: 'javascript'};
  public isCustomJsCollapsed = true;
  public urlPatternCollapsedIndex = 0;

  constructor(private $state: IStateService,
              private $scope: any,
              private $stateParams: any,
              private pixelService: PixelService,
              private audiencesService: any,
              private Notification: any,
              private authService: AuthService) {
    this.authService.text$.subscribe((text) => {
      this.textLoc = text;
      this.title = this.textLoc.createNewPixel;
    });
  }

  ngOnInit(): void {
    this.mode = this.$state.current.data.mode;
    this.modeEdit = (this.$state.current.data.mode === 'edit');

    this.clearForm();

    if(this.modeEdit) {
      this.pixel.id = this.$stateParams.pixelId;
      this.pixelService.getPixelById(this.pixel.id).subscribe((response: any) => {
        const pixel = response;
        // todo: (prokopenko) move pixel to class
        this.pixel.description = pixel.description;
        this.pixel.pid = pixel.pid;
        this.pixel.collect_analytics = pixel.collect_analytics;
        this.pixel.account = this.authService.getSelectedAccount().account_id;
        this.title = `${this.textLoc.edit} '${pixel.pid}' ${this.textLoc.pixelL}`;
        this.updateSettings(pixel.stats_settings);
        this.updateForms(pixel.postforms);
        this.preloader = false;
      }, () => {
        this.notFound = true;
        this.preloader = false;
      });
    } else {
      this.preloader = false;
    }
  }

  public getBackLink() {
    return '/pixel/list';
  }

  public haveEditPermission() {
    return (this.modeEdit ?
      this.authService.getAccountById(this.pixel.account).permission :
      _.get(this.authService.getSelectedAccount(), 'permission')) === 'edit';
  }

  public addUrlSettings() {
    const settings = {
      url_pattern: '',
      reg_exp: 0,
      clicks_selector: [{description: '', value: ''}],
      views_selector: [{description: '', value: ''}],
      forms: [{description: '', button: '', phone: false, email: false, selectors: [{description: '', value: '', type: 'text'}]}]
    };
    this.pixel.domain_settings.push(settings);
    return settings;
  }

  public removeUrlSettings(index: any) {
    this.pixel.domain_settings.splice(index, 1);
  }

  public createPixel() {
    this.submitDisable = true;
    this.pixelService.savePixel(this.getPixelData()).subscribe((response: any) => {
      this.pixelService.addPixel(response);
      this.audiencesService.notUpdated();
      this.Notification.success(this.textLoc.pixelCreated);
      this.message = '';
      this.$state.go('layout.pixels');
      this.submitDisable = false;
    }, (error: any) => {
      this.message = error.error.data.error_message[0];
      this.submitDisable = false;
    });
  }

  public editPixel() {
    this.submitDisable = true;
    this.pixelService.edPixel(this.getPixelData()).subscribe((response: any) => {
      this.pixelService.editPixel(this.pixel.pid, response.description);
      this.updateSettings(response.stats_settings);
      this.updateForms(response.postforms);
      this.Notification.success(this.textLoc.pixelUpdated);
      this.message = '';
      this.submitDisable = false;
    }, (error: any) => {
      this.message = error.error.data.error_message[0];
      this.submitDisable = false;
    });
  }

  public edit() {
    if(this.$scope.editPixelForm.$valid) {
      if(this.modeEdit) {
        this.editPixel();
      } else {
        this.createPixel();
      }
    }
  }

  public addClicksSelector(setting: any) {
    this.addOption('clicks_selector', setting);
  }

  public removeClicksSelector(index: any, setting: any) {
    this.removeOption('clicks_selector', index, setting);
  }

  public addViewsSelector(setting: any) {
    this.addOption('views_selector', setting);
  }

  public removeViewsSelector(index: any, setting: any) {
    this.removeOption('views_selector', index, setting);
  }

  public addFormSelector(setting: any) {
    this.addOption('selectors', setting);
  }

  public removeFormSelector(index: any, setting: any) {
    this.removeOption('selectors', index, setting);
    this.checkFormFieldsType(setting);
  }

  public addCustomJS(setting: any) {
    this.addOption('custom_js', setting);
  }

  public removeCustomJS(index: any, setting: any) {
    this.removeOption('custom_js', index, setting);
  }

  public selectFieldType(setting: any, field: any, type: any): void {
    field.type = type;
    this.checkFormFieldsType(setting);
  }

  public checkFormFieldsType(setting: any): void {
    setting.phone = setting.selectors.some((field) => field.type === 'phone');
    setting.email = setting.selectors.some((field) => field.type === 'email');
  }

  public collapseUrlPattern(index: any) {
    this.urlPatternCollapsedIndex = this.urlPatternCollapsedIndex === index ? null : index;
  }

  public removeForm(index: any, setting: any): void {
    setting.forms.splice(index, 1).pop();
    if(setting.forms.length === 0) {
      setting.forms.push({description: '', button: '', phone: false, email: false, selectors: [{description: '', value: '', type: 'text'}]});
    }
  }

  public addForm(setting: any): void {
    setting.forms.push({description: '', button: '', phone: false, email: false, selectors: [{description: '', value: '', type: 'text'}]});
  }

  private addOption(name: any, setting: any) {
    setting[name].push({description: '', value: '', type: 'text'});
  }

  private removeOption(name: any, index: any, setting: any) {
    setting[name].splice(index, 1).pop();
    if(setting[name].length === 0) {
      this.addOption(name, setting);
    }
  }

  private getPixelData() {
    const pixelData: any = {
      description: this.pixel.description,
      create_segment: this.pixel.create_segment,
      account: this.pixel.account,
      stats_settings: [],
      postforms: []
    };
    this.pixel.id && (pixelData.pixelId = this.pixel.id);

    ['visit_timer', 'scroll_meter'].forEach((key: any) => {
      pixelData.stats_settings.push({
        type: key,
        is_active: this.pixel[key].is_active,
        id: this.pixel[key].id
      });
    });

    this.pixel.forms.forEach((form: any) => {
      if(form.button && form.selectors.some((field) => field.value)) {
        const selectors: any = form.selectors.filter((field) => field.value);
        const phone: any = selectors.find((field) => field.type === 'phone');
        const email: any = selectors.find((field) => field.type === 'email');
        pixelData.postforms.push({
          description: form.description,
          url_pattern: '',
          button_selector: form.button,
          field_names: selectors.map((field) => field.description),
          field_selectors: selectors.map((field) => field.value),
          email_field: email ? email.value : '',
          phone_field: phone ? phone.value : '',
          id: form.id
        });
      }
    });

    ['clicks_selector', 'views_selector', 'custom_js'].forEach((key: any) => {
      this.pixel[key].forEach((setting: any) => {
        if(setting.value) {
          pixelData.stats_settings.push({
            type: key,
            value: setting.value,
            description: setting.description,
            id: setting.id
          });
        }
      });
      if(key === 'custom_js') {
        return;
      }
      this.pixel.domain_settings.forEach((domain_settings: any) => {
        domain_settings[key].forEach((setting: any) => {
          if(setting.value) {
            pixelData.stats_settings.push({
              type: key,
              url_pattern: (!domain_settings.reg_exp && domain_settings.url_pattern.charAt(0) !== '~')
                ? '~' + domain_settings.url_pattern : domain_settings.url_pattern,
              value: setting.value,
              description: setting.description,
              id: setting.id
            });
          }
        });
      });
    });
    this.pixel.domain_settings.forEach((domain_settings: any) => {
      domain_settings.forms.forEach((form: any) => {
        if(form.button && form.selectors.some((field) => field.value)) {
          const selectors: any = form.selectors.filter((field) => field.value);
          const phone: any = selectors.find((field) => field.type === 'phone');
          const email: any = selectors.find((field) => field.type === 'email');
          pixelData.postforms.push({
            description: form.description,
            url_pattern: (!domain_settings.reg_exp && domain_settings.url_pattern.charAt(0) !== '~')
              ? '~' + domain_settings.url_pattern : domain_settings.url_pattern,
            button_selector: form.button,
            field_names: selectors.map((field) => field.description),
            field_selectors: selectors.map((field) => field.value),
            email_field: email ? email.value : '',
            phone_field: phone ? phone.value : '',
            id: form.id
          });
        }
      });
    });
    return pixelData;
  }

  private clearForm() {
    this.pixel = {
      pid: '',
      description: '',
      visit_timer: {is_active: false},
      scroll_meter: {is_active: false},
      clicks_selector: [{description: '', value: ''}],
      views_selector: [{description: '', value: ''}],
      forms: [{description: '', button: '', phone: false, email: false, selectors: [{description: '', value: '', type: 'text'}]}],
      custom_js: [{description: '', value: ''}],
      domain_settings: [],
      create_segment: true,
      account: this.authService.getSelectedAccount().account_id
    };
  }

  private updateSettings(stats_settings: any) {
    ['clicks_selector', 'views_selector', 'custom_js'].forEach((key: any) => {
      this.pixel[key] = [];
    });
    this.pixel.domain_settings = [];
    stats_settings && stats_settings.forEach((setting: any) => {
      let pixel_setting = this.pixel;
      if(setting.url_pattern) {
        pixel_setting = this.pixel.domain_settings.filter((domain_setting: any) => {
          return domain_setting.url_pattern === setting.url_pattern;
        }).pop();
        if(!pixel_setting) {
          pixel_setting = this.addUrlSettings();
          pixel_setting.url_pattern = setting.url_pattern;
          pixel_setting.clicks_selector.length = 0;
          pixel_setting.views_selector.length = 0;
        }
      }
      switch(setting.type) {
        case 'visit_timer':
          pixel_setting.visit_timer = {is_active: setting.is_active, id: setting.id};
          break;
        case 'scroll_meter':
          pixel_setting.scroll_meter = {is_active: setting.is_active, id: setting.id};
          break;
        case 'clicks_selector':
          pixel_setting.clicks_selector.push(setting);
          break;
        case 'views_selector':
          pixel_setting.views_selector.push(setting);
          break;
        case 'custom_js':
          pixel_setting.custom_js.push(setting);
          break;
      }
    });
    ['clicks_selector', 'views_selector', 'custom_js'].forEach((key: any) => {
      if(!this.pixel[key].length) {
        this.pixel[key].push({description: '', value: ''});
      }
      this.pixel[key].sort((a: any, b: any) => {
        return a.id - b.id;
      });
      if(key === 'custom_js') {
        return;
      }
      this.pixel.domain_settings.forEach((setting: any) => {
        if(!setting[key].length) {
          setting[key].push({description: '', value: ''});
        }
        setting[key].sort((a: any, b: any) => {
          return a.id - b.id;
        });
      });
    });
  }

  private updateForms(forms: any): void {
    this.pixel.forms.length = 0;
    forms.forEach((form) => {
      let pixel_setting = this.pixel;
      if(form.url_pattern) {
        pixel_setting = this.pixel.domain_settings.filter((domain_setting: any) => {
          return domain_setting.url_pattern === form.url_pattern;
        }).pop();
        if (!pixel_setting) {
          pixel_setting = this.addUrlSettings();
          pixel_setting.url_pattern = form.url_pattern;
          pixel_setting.forms.length = 0;
        }
      }
      const hasPhone: boolean = !!form.phone_field;
      const hasEmail: boolean = !!form.email_field;
      const formObj = {
        description: form.description,
        button: form.button_selector,
        phone: hasPhone,
        email: hasEmail,
        selectors: form.field_selectors.map((field, index) => {
          const type: any = hasPhone && field === form.phone_field ? 'phone' : hasEmail && field === form.email_field ? 'email' : 'text';
          return {description: form.field_names[index], value: field, type};
        }),
        id: form.id
      };
      pixel_setting.forms.push(formObj);
    });
    if(!this.pixel.forms.length) {
      this.pixel.forms.push({description: '', button: '', phone: false, email: false, selectors: [{description: '', value: '', type: 'text'}]});
    }
  }
}
