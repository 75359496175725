import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
import { Observer } from 'rxjs/internal/types';
import { map } from 'rxjs/operators';
// Services
import { AuthService } from './auth.service';
import { NotifyService } from '../../shared/notify.service';
import { ConstService } from '../../shared/const.service';
import { SharedTabsEvent, SharedWorkerService } from './shared-worker.service';
import { UtilsService } from './utils.service';
// Types
import { ConstructorRule, ConstructorSendData } from '../../../app-js/audiences/shared/constructor.type';
import { SegmentCreateResponse } from '../../../app-js/audiences/shared/response.type';
import { DjangoResponse } from '../../shared/types/django-response.type';

@Injectable({
  providedIn: 'root'
})
export class AudiencesService {
  private audiences: any[] = [];
  private audiencesAll: any[] = [];
  private publicSegments: any[] = [];
  private isPublicUpdated: boolean = false;
  private isUpdated: boolean = false;

  private audienceUrl: string = '/api/v1/user/segments/';
  private marketPlaceUrl: string = '/api/v1/marketplace/';
  private partnerUrl: string = '/api/v1/partner/segments/';

  private addAudienceEvent: SharedTabsEvent = new SharedTabsEvent('audiences.add', (e: any) => {
    this.addAudienceLocal(e.data.msg.audience);
  });

  private editAudienceEvent: SharedTabsEvent = new SharedTabsEvent('audiences.edit', (e: any) => {
    this.editAudienceLocal(e.data.msg.segmentId, e.data.msg.segment_name, e.data.msg.lookalike_percent);
  });

  private deleteAudienceEvent: SharedTabsEvent = new SharedTabsEvent('audiences.delete', (e: any) => {
    this.deleteAudienceLocal(e.data.msg.segment_id);
  });

  private updateAudienceParentEvent: SharedTabsEvent = new SharedTabsEvent('audiences.update_parent', (e: any) => {
    this.updateParentLocal(e.data.msg.id, e.data.msg.parents);
  });

  constructor(private utilsService: UtilsService,
              private $sharedTabsWorker: SharedWorkerService,
              @Inject('$rootScope') private _rootScope: any,
              private notifyService: NotifyService,
              private authService: AuthService,
              private http: HttpClient
  ) {

    this.$sharedTabsWorker.addEvent(this.addAudienceEvent);

    this.$sharedTabsWorker.addEvent(this.editAudienceEvent);

    this.$sharedTabsWorker.addEvent(this.deleteAudienceEvent);

    this.$sharedTabsWorker.addEvent(this.updateAudienceParentEvent);

    this.notifyService.notification$.subscribe((message: string) => {
      switch(message) {
        case ConstService.AUTH_EVENTS.impersonated:
          this.update();
          break;
        case ConstService.AUTH_EVENTS.login:
          this.update();
          break;
        case ConstService.AUTH_EVENTS.logout:
          this.notUpdated();
          break;
        case ConstService.AUTH_EVENTS.changeAccount:
          this.getAudienceRequest().subscribe((response: any) => {
            this.audiences.length = 0;
            this.audiencesAll.length = 0;
            response.data.forEach((segment: any) => {
              segment.parent = segment.parents[0] ? segment.parents[0] : null;
              if(!segment.type) {
                segment.type = 'normal';
              }
              if(segment.type === 'look-alike') {
                segment.pixel_pid = segment.lookalike_percent + '% lookalike';
              }
            });
            response.data.forEach((segment: any) => {
              this.audiencesAll.push(segment);
            });
            this.updateTree();
          });
          break;
      }
    });

  }

  public getAudienceList(all: any = false): Promise<any> {
    const resultAudiences: any[] = all ? this.audiencesAll : this.audiences;
    return new Observable((observer: Observer<any>) => {
      if(this.isUpdated) {
        observer.next(resultAudiences);
        observer.complete();
      } else {
        this.getAudienceRequest().subscribe((response: any) => {
          this.isUpdated = true;
          this.audiences.length = 0;
          this.audiencesAll.length = 0;
          response.data.forEach((segment: any) => {
            segment.parent = segment.parents[0] ? segment.parents[0] : null;
            if(!segment.type) {
              segment.type = 'normal';
            }
            if(segment.type === 'look-alike') {
              segment.pixel_pid = segment.lookalike_percent + '% lookalike';
            }
          });
          response.data.forEach((segment: any) => {
            this.audiencesAll.push(segment);
          });
          this.updateTree();
          observer.next(resultAudiences);
          observer.complete();
        }, () => {
          observer.next(resultAudiences);
          observer.complete();
        });
      }
    }).toPromise();
  }

  public getPublicAudiences(): Promise<any> {
    return new Observable((observer: Observer<any>) => {
      if(this.isPublicUpdated) {
        observer.next(this.publicSegments);
        observer.complete();
      } else {
        this.getPublicMarketPlaceRequest().subscribe((response: any) => {
          this.isPublicUpdated = true;
          this.publicSegments.length = 0;
          response.data.forEach((segment: any) => {
            this.publicSegments.push(segment);
          });
          observer.next(this.publicSegments);
          observer.complete();
        }, () => {
          observer.next(this.publicSegments);
          observer.complete();
        });
      }
    }).toPromise();
  }

  public getAudience(id: any): any {
    if(!this.isUpdated) {
      console.warn('Marketplace data not updated');
    }
    for(let i: number = 0; i < this.audiencesAll.length; i++) {
      if(this.audiencesAll[i].id === id) {
        return this.audiencesAll[i];
      }
    }
    return {
      segment_name: ''
    };
  }

  public getAudienceName(id: any): string {
    for(let i: number = 0; i < this.audiencesAll.length; i++) {
      if(this.audiencesAll[i].id === id) {
        return this.audiencesAll[i].segment_name;
      }
    }
    return '';
  }

  public addAudience(audience: any): void {
    this.addAudienceLocal(audience);
    this.$sharedTabsWorker.send({audience: audience}, 'audiences.add');
  }


  public editAudience(segmentId: any, segment_name: any, lookalike_percent: any): void {
    this.editAudienceLocal(segmentId, segment_name, lookalike_percent);
    this.$sharedTabsWorker.send({
      segmentId: segmentId,
      segment_name: segment_name,
      lookalike_percent: lookalike_percent
    }, 'audiences.edit');
  }

  public deleteAudience(segmentId: any): void {
    this.deleteAudienceLocal(segmentId);
    this.$sharedTabsWorker.send({segment_id: segmentId}, 'audiences.delete');
  }

  public updateParent(id: any, parents: any): void {
    this.updateParentLocal(id, parents);
    this.patchAudienceRequest(id , {
      parents: parents
    }).subscribe();
    this.$sharedTabsWorker.send({
      id: id,
      parents: parents
    }, 'audiences.update_parent');
  }

  public updateTree(): void {
    if(!this.isUpdated) {
      return;
    }
    this.audiences.length = 0;
    this.utilsService.constructTree(this.audiencesAll).forEach((audience: any) => {
      this.audiences.push(audience);
    });
    this._rootScope.$broadcast(ConstService.WORKER_EVENTS.updateTreeAudiences);
  }

  public update(): void {
    this.isUpdated = false;
    this.getAudienceList(true);
  }

  public isParentOf(child: any, parent: any): boolean {
    let start: any = null;
    for(let i: number = 0; (i < this.audiences.length); i++) {
      if(start !== null) {
        if(this.audiences[i].$$treeLevel > this.audiences[start].$$treeLevel) {
          if(this.audiences[i].segment_id === child.segment_id) {
            return true;
          }
        } else {
          break;
        }
      } else if(parent.segment_id === this.audiences[i].segment_id) {
        start = i;
      }
    }
    return false;
  }

  public notUpdated(): void {
    this.isUpdated = false;
  }

  public getAudienceById(id: number): Observable<any> {
    return this.http.get(`${this.audienceUrl}${id}/`)
      .pipe(map((response: DjangoResponse<any>) =>  response.data));
  }

  public getTopCities(id: number): Observable<any> {
    return this.http.get(`${this.audienceUrl}${id}/top_cities/`)
      .pipe(map((response: DjangoResponse<any>) =>  response.data));
  }

  public getAnalytics(id: number): Observable<any> {
    return this.http.get(`${this.audienceUrl}${id}/analytics/`)
      .pipe(map((response: DjangoResponse<any>) =>  response.data));
  }

  public getBreakdownAnalytics(id: number): Observable<any> {
    return this.http.get(`${this.audienceUrl}${id}/analytics_ua/`)
      .pipe(map((response: DjangoResponse<any>) =>  response.data));
  }

  public getIntersections(id: number, limit: string): Observable<any> {
    return this.http.get(`${this.audienceUrl}${id}/intersections/`, {params: {limit: limit}})
      .pipe(map((response: DjangoResponse<any>) =>  response.data));
  }

  public getRelated(id: number): Observable<any> {
    return this.http.get(`${this.audienceUrl}${id}/related/`)
      .pipe(map((response: DjangoResponse<any>) =>  response.data));
  }

  public delete(id: number): Observable<any> {
    return this.http.delete(`${this.audienceUrl}${id}/`);
  }

  public addAudienceConstructor(params: ConstructorSendData): Observable<SegmentCreateResponse> {
    return this.http.post(`${this.audienceUrl}constructor/`, params)
      .pipe(map((response: DjangoResponse<SegmentCreateResponse>) =>  response.data));
  }

  public addAudienceLookAlike(params: any): Observable<SegmentCreateResponse> {
    return this.http.post(`${this.audienceUrl}${params.id}/lookalike/`, params)
      .pipe(map((response: DjangoResponse<SegmentCreateResponse>) =>  response.data));
  }

  public addAudienceGroup(params: any): Observable<SegmentCreateResponse> {
    return this.http.post(`${this.audienceUrl}folder/`, params)
      .pipe(map((response: DjangoResponse<SegmentCreateResponse>) =>  response.data));
  }

  public addAudienceForm(params: any): Observable<any> {
    return this.http.post(`${this.audienceUrl}postform/`, params)
      .pipe(map((response: DjangoResponse<any>) =>  response.data));
  }

  public addAudiencePredictive(params: any, id: number): Observable<any> {
    return this.http.post(`${this.audienceUrl}${id}/predictive/`, params)
      .pipe(map((response: DjangoResponse<any>) =>  response.data));
  }

  public updateAudience(params: ConstructorSendData): Observable<SegmentCreateResponse> {
    return this.http.put(`${this.audienceUrl}${params.id}/`, params)
      .pipe(map((response: DjangoResponse<SegmentCreateResponse>) =>  response.data));
  }

  public getConstructorRules(id: number): Observable<ConstructorRule> {
    return this.http.get(`${this.audienceUrl}${id}/constructor_rules/`)
      .pipe(map((response: DjangoResponse<ConstructorRule>) =>  response.data));
  }

  public getUserJourney(id: number, params: any): Observable<any> {
    return this.http.get(`${this.audienceUrl}${id}/user_journey/`, {params})
      .pipe(map((response: DjangoResponse<any>) =>  response.data));
  }

  public splitAudience(id: number, params: any): Observable<any> {
    return this.http.post(`${this.audienceUrl}${id}/split/`, params)
      .pipe(map((response: DjangoResponse<any>) =>  response.data));
  }

  public saveAudience(params: any): Observable<SegmentCreateResponse> {
    return this.http.post(this.audienceUrl, params)
      .pipe(map((response: DjangoResponse<SegmentCreateResponse>) =>  response.data));
  }

  public patchAudience(params: any): Observable<SegmentCreateResponse> {
    return this.http.patch(`${this.audienceUrl}${params.id}/`, params)
      .pipe(map((response: DjangoResponse<SegmentCreateResponse>) =>  response.data));
  }

  public getSegmentStats(id: number): Observable<any> {
    return this.http.get(`${this.audienceUrl}${id}/stats/`)
      .pipe(map((response: DjangoResponse<any>) =>  response.data));
  }

  private editAudienceLocal(segmentId: any, segment_name: any, lookalike_percent: any): void {
    const segment: any = this.getAudience(segmentId);
    segment.segment_name = segment_name;
    segment.lookalike_percent = lookalike_percent;
    if(segment.type === 'look-alike') {
      segment.pixel_pid = segment.lookalike_percent + '% lookalike';
    }
  }

  private deleteAudienceLocal(segmentId: any): void {
    const index: any = this.audiences.findIndex((item) => {
      return item.id === segmentId;
    });
    this.audiences.splice(index, 1);

    const indexAll: any = this.audiencesAll.findIndex((item) => {
      return item.id === segmentId;
    });
    this.audiencesAll.splice(indexAll, 1);

    this.updateTree();
  }

  private updateParentLocal(id: any, parents: any): void {
    const segment: any = this.getAudience(id);
    segment.parents = parents;
    segment.parent = (parents[0] || null);
    this.updateTree();
  }

  private addAudienceLocal(audience: any): void {
    audience.parent = (audience.parents && audience.parents[0]) ? audience.parents[0] : null;
    if(!audience.type) {
      audience.type = 'normal';
    }
    audience.$$treeLevel = 0;
    this.audiencesAll.push(audience);
    if(!audience.parent) {
      this.audiences.unshift(audience);
    } else {
      for(let i: number = 0; i < this.audiences.length; i++) {
        if(this.audiences[i].segment_id === audience.parent) {
          audience.$$treeLevel = this.audiences[i].$$treeLevel + 1;
          this.audiences.splice(i + 1, 0, audience);
          return;
        }
      }
      this.audiences.unshift(audience);
    }
  }

  private getAudienceRequest(): Observable<any> {
    return this.http.get(this.audienceUrl);
  }

  private patchAudienceRequest(id: number, params: any): Observable<any> {
    return this.http.patch(`${this.audienceUrl}${id}/`, params);
  }

  private getPublicMarketPlaceRequest(): Observable<any> {
    return this.http.get(`${this.marketPlaceUrl}public/`);
  }
}
