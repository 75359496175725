import { Router } from '@angular/router';
import { Component, OnInit } from 'angular-ts-decorators';
import * as _ from 'lodash';
import { AudiencesService } from '../../../app/core/services/audiences.service';
import { AuthService } from '../../../app/core/services/auth.service';
import { UtilsService } from '../../../app/core/services/utils.service';
import { NotifyService } from '../../../app/shared/notify.service';
import { Audience } from '../../core/classes/classes';
import { ConstService } from '../../../app/shared/const.service';

@Component({
  selector: 'audienceListComponent',
  templateUrl: './audience-list.pug'
})
export class AudienceListComponent implements OnInit {

  static $inject: string[] = [
    '$scope',
    '$uibModal',
    '$filter',
    'audiencesService',
    'constructorService',
    '$rootScope',
    'Notification',
    'sharingService',
    'authService',
    'notifyService',
    'utilsService',
    'router',
    'uiGridConstants',
    'i18nService'
  ];
  public gridApi: any;
  public dragItem: any = null;
  public leaveDropZone = false;
  public preloader = true;
  public searchData: any = [];
  public searchFilter = '';
  public gridConfig: any;
  public textLoc: any = {};
  public tariff: any = {};
  public platformList = [];
  private langId: any = localStorage.getItem('langId') || 'ru';

  constructor(private $scope: any,
              private $uibModal: any,
              private $filter: any,
              private audiencesService: AudiencesService,
              private constructorService: any,
              private $rootScope: any,
              private Notification: any,
              private sharingService: any, // TODO временное решение для списка платформ в модальном окне
              private authService: AuthService,
              private notifyService: NotifyService,
              private utilsService: UtilsService,
              private router: Router,
              private uiGridConstants: any,
              private i18nService: any) {

  }

  ngOnInit(): void {
    const popupText = this.langId === 'ru' ? 'Количество уников за 90 дней' : 'Uniques count for 90 days';
    this.i18nService.setCurrentLang(this.langId);

    // TODO: (prokopenko) logic is not clear for Angular 2
    this.$scope.$on(ConstService.WORKER_EVENTS.updateTreeAudiences, (event: any) => {
      this.gridApi.core.notifyDataChange('all');
    });

    this.$rootScope.$on(ConstService.AUTH_EVENTS.changeAccount, () => {
      this.checkDragAndDropAvailable();
    });

    this.$scope.formatSize = (row, type) => {
      const message: string = type === 'class' ? 'cell-red' : this.textLoc.lowerSimilarity;
      let result: string = '';
      if (row.entity.parent) {
        const parent: any = row.grid.options.data.find((segment) => segment.segment_id === row.entity.parent);
        result = parent && parent.size >= row.entity.size ? message : '';
      }
      return result;
    };

    // todo: (prokopenko) move all configs creation to factory
    this.gridConfig = {
      data: [],
      enableFiltering: false,
      enableRowSelection: true,
      enableSelectAll: false,
      enableColumnMenus: false,
      multiSelect: true,
      enableGridMenu: true,
      showTreeExpandNoChildren: false,
      exporterMenuVisibleData : false,
      gridMenuShowHideColumns: true,
      treeIndent: 1,
      treeRowHeaderBaseWidth: 30,

      exporterCsvFilename: 'my_audiences.csv',
      exporterFieldCallback: (grid: any, row: any, col: any, value: any) => {
        if(col.name === 'is_active') {
          value = this.$filter('IsActive')(value);
        }
        if(col.name === 'updated_at') {
          value = this.$filter('IsAvailable')(value);
        }
        if(col.name === 'segment_name') {
          value = (!row.entity.is_empty && !!row.entity.segment_id) ? `${value} (${row.entity.segment_id})` : value;
        }
        return value;
      },
      exporterMenuPdf: false,
      exporterMenuExcel: false,

      rowTemplate: `<div grid="grid" class="ui-grid-draggable-row" draggable="true" ng-class="{ \'audiences__grid_folder\': row.entity.is_empty }"><div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader, \'custom\': true }" ui-grid-cell></div></div>`,

      onRegisterApi: (gridApi: any) => {
        this.gridApi = gridApi;
        this.checkDragAndDropAvailable();
        gridApi.draggableRows.on.beforeRowMove(this.$scope, function(from: any, to: any, data: any, options: any) {
          options.cancelMove = true;
        });

        gridApi.draggableRows.on.rowDragged(this.$scope, (info: any) => {
          this.dragItem = info.draggedRowEntity;
        });

        gridApi.draggableRows.on.rowFinishDrag(this.$scope, () => {
          if(!this.leaveDropZone) {
            return;
          }

          const segment = gridApi.grid.rows.find((item: any) => {
            return item.entity.id === this.dragItem.id;
          });
          this.extractSelectedFromTreeLocal(segment);

          this.leaveDropZone = false;
        });

        gridApi.draggableRows.on.rowOverRow(this.$scope, () => {
          if(!this.dragItem.parent) { return; }
          this.utilsService.safeApply(this.$scope, () => {
            this.leaveDropZone = false;
          });
        });

        gridApi.draggableRows.on.rowLeavesRow(this.$scope, () => {
          if(!this.dragItem.parent) { return; }
          this.utilsService.safeApply(this.$scope, () => {
            this.leaveDropZone = true;
          });
        });

        gridApi.draggableRows.on.rowDropped(this.$scope, (info: any, dropTarget: any) => {
          if(info.draggedRowEntity.parent === info.targetRowEntity.segment_id
            || info.draggedRowEntity.segment_id === info.targetRowEntity.segment_id
            || !info.targetRowEntity.segment_id) {
            return;
          }

          let start = null;
          const rows: any[] = this.gridApi.grid.rows;
          for(let i = 0; (i < rows.length); i++) {
            if(start !== null) {
              if(rows[i].treeLevel > rows[start].treeLevel) {
                if(rows[i].entity.segment_id === info.targetRowEntity.segment_id) {
                  return;
                }
              } else {
                break;
              }
            } else if(info.draggedRowEntity.segment_id === rows[i].entity.segment_id) {
              start = i;
            }
          }

          info.draggedRowEntity.parent = info.targetRowEntity.segment_id;
          info.draggedRowEntity.parents = [info.draggedRowEntity.parent];
          this.audiencesService.updateParent(info.draggedRowEntity.id, info.draggedRowEntity.parents);
        });
      },
      columnDefs: [
        {
          field: 'segment_name',
          displayName: 'Name',
          width: '25%',
          cellTemplate: `<div class='ui-grid-cell-contents'>{{row.entity.segment_name}}</div>`,
          cellClass: (grid: any, row: any) => {
            let addClass: string = 'tree_level--' + row.entity.$$treeLevel;
            if(row.entity.is_empty) {
              addClass += ' segments__group_row';
            }
            addClass += ' audiences__cell_name';
            return addClass;
          }
        },
        {
          field: 'segment_id',
          displayName: 'ID',
          cellTemplate: `<div class='ui-grid-cell-contents cell-selectable'>{{row.entity.segment_id}}</div>`,
          width: 100
        },
        {
          field: 'pixel_pid',
          displayName: 'Pixel',
          width: 100
        },
        {
          field: 'is_active',
          displayName: 'Status',
          width: 100,
          cellFilter: 'IsActive|translate',
          headerCellFilter: 'translate'
        },
        {
          field: 'type',
          displayName: 'Type',
          cellClass: 'to-center capitalize',
          width: 100,
          headerCellFilter: 'translate'
        },
        {
          field: 'size',
          type: 'number',
          displayName: 'Size',
          // todo: (prokopenko) use template url
          headerCellTemplate: `<div role="columnheader" ng-class="{ 'sortable': sortable }" ui-grid-one-bind-aria-labelledby-grid="col.uid + '-header-text ' + col.uid + '-sortdir-text'" aria-sort="{{col.sort.direction == asc ? 'ascending' : ( col.sort.direction == desc ? 'descending' : (!col.sort.direction ? 'none' : 'other'))}}"><div role="button" tabindex="0" class="ui-grid-cell-contents ui-grid-header-cell-primary-focus"col-index="renderIndex" title="TOOLTIP"><span class="ui-grid-header-cell-label" ui-grid-one-bind-id-grid="col.uid + '-header-text'">{{ col.displayName CUSTOM_FILTERS }}</span>
          <span class="audiences__size_popover glyphicon glyphicon-info-sign" popover-append-to-body="true" uib-popover="${popupText}" popover-trigger="mouseenter"></span>
          <span ui-grid-one-bind-id-grid="col.uid + '-sortdir-text'" ui-grid-visible="col.sort.direction" aria-label="{{getSortDirectionAriaLabel()}}"> <i ng-class="{ 'ui-grid-icon-up-dir': col.sort.direction == asc, 'ui-grid-icon-down-dir': col.sort.direction == desc, 'ui-grid-icon-blank': !col.sort.direction }" title="{{isSortPriorityVisible() ? i18n.headerCell.priority + ' ' + ( col.sort.priority + 1 )  : null}}" aria-hidden="true"> </i> <sub ui-grid-visible="isSortPriorityVisible()" class="ui-grid-sort-priority-number"> {{col.sort.priority + 1}} </sub> </span> </div> <div role="button" tabindex="0" ui-grid-one-bind-id-grid="col.uid + '-menu-button'" class="ui-grid-column-menu-button" ng-if="grid.options.enableColumnMenus && !col.isRowHeader  && col.colDef.enableColumnMenu !== false" ng-click="toggleMenu($event)" ng-class="{'ui-grid-column-menu-button-last-col': isLastCol}" ui-grid-one-bind-aria-label="i18n.headerCell.aria.columnMenuButtonLabel" aria-haspopup="true"> <i class="ui-grid-icon-angle-down" aria-hidden="true"> &nbsp; </i> </div> <div ui-grid-filter></div> </div>`,
          // headerCellTemplate: "grid-header-cell.html",
          cellClass: 'to-right',
          cellTemplate: `<div class="ui-grid-cell-contents {{ row.entity.type === 'look-alike' && row.entity.size_updated ? grid.appScope.formatSize(row, 'class') : ''}}" title="{{ row.entity.type === 'look-alike' && row.entity.size_updated ? grid.appScope.formatSize(row, 'title') : ''}}">{{row.entity.size | number}}</div>`,
          cellFilter: 'number:0',
          width: 130,
          headerCellFilter: 'translate'
        },
        {
          field: 'size_updated',
          displayName: 'Updated',
          cellFilter: 'IsAvailable',
          cellClass: 'center',
          width: 150,
          headerCellFilter: 'translate'
        },
        {
          field: 'created_at',
          sort: {direction: 'desc'},
          displayName: 'Created',
          width: 150,
          headerCellFilter: 'translate'
        },
        {
          name: 'add',
          displayName: 'Add to Constructor',
          headerCellFilter: 'translate',
          visible: false,
          cellTemplate: `<div class='ui-grid-cell-contents center' ng-click='grid.appScope.$ctrl.toggleConstructorAudience(row.entity)'><span ng-class='grid.appScope.$ctrl.getIcon(row.entity)'></span></div>`,
          width: '150'
        }
      ]
    };

    this.audiencesService.getAudienceList().then((audience: any) => {
      this.preloader = false;
      this.gridConfig.data = audience;
    });

    this.notifyService.notification$.subscribe((message: string) => {
      if (message === ConstService.AUTH_EVENTS.changeAccount) {
        this.audiencesService.getAudienceList().then((audience: any) => {
          this.preloader = false;
          this.gridConfig.data = audience;
        });
      }
    });

    this.authService.text$.subscribe((text) => {
      this.textLoc = text;
      this.gridConfig.columnDefs[0].displayName = text.name;
      this.gridConfig.columnDefs[2].displayName = text.pixel;
      this.gridConfig.columnDefs[3].displayName = text.status;
      this.gridConfig.columnDefs[4].displayName = text.type;
      this.gridConfig.columnDefs[5].displayName = text.sizeL;
      this.gridConfig.columnDefs[6].displayName = text.updated;
      this.gridConfig.columnDefs[7].displayName = text.created;
      this.gridConfig.columnDefs[8].displayName = text.addToConstructor;
      if (this.gridApi) {
        this.gridApi.core.notifyDataChange(this.uiGridConstants.dataChange.COLUMN);
      }
    });

    this.authService.userTariff$.subscribe((tariff: any) => {
      if (!tariff) {
        return;
      }
      this.tariff = tariff;
      if (tariff.type === 'unlimited') {
        this.platformList = this.sharingService.platforms();
      } else {
        this.authService.getUserAccount().subscribe((result: any) => {
          const selectedPlatforms = result.data.selected_platforms;
          this.platformList = JSON.parse(JSON.stringify(this.sharingService.platforms()));
          this.platformList = this.platformList.filter((platform) => {
            platform.products = platform.products.filter((product) => selectedPlatforms.includes(product.system_name));
            return platform.products.length;
          });
        });
      }
    });
  }

  public getTooltipText(button: any) {
    if(!this.gridApi) {
      return '';
    }
    if (['split', 'predictive', 'journey'].indexOf(button) !== -1 && this.tariff.type === 'by_tariff') {
      return this.textLoc.tariffPermissionError;
    }
    if(['share', 'create', 'lookalike', 'folder', 'delete', 'split', 'predictive', 'analytic', 'journey', 'constructor'].indexOf(button) !== -1 && !this.tariff.type) {
      return this.textLoc.tariffPermissionError;
    }
    if(['analytic', 'code', 'edit',].indexOf(button) === -1 && _.get(this.authService.getSelectedAccount(), 'permission') !== 'edit') {
      return this.textLoc.accountPermission;
    }
    if(button === 'share' && this.gridApi.selection.getSelectedRows().length > 10) {
      return this.textLoc.selectTenOrLess;
    }
    if(this.gridApi.selection.getSelectedRows().length !== 1) {
      return this.textLoc.selectAtLeastOne;
    }
    if(this.gridApi.selection.getSelectedRows()[0].is_empty) {
      return this.textLoc.availableOnlyForAudiences;
    }
    if(!this.gridApi.selection.getSelectedRows()[0].is_active) {
      return this.textLoc.availableOnlyForActiveAudiences;
    }
    if(['lookalike', 'edit'].indexOf(button) !== -1) {
      return this.textLoc.availableOnlyForAudiencesWithNormalType;
    }
  }

  public getLink(source: any) {
    switch(source) {
      case 'edit':
        return this.getEditAudienceLink();
      case 'lookalike':
        return this.getLookalikeLink();
      case 'analytic':
        return this.getAnalyticLink();
      case 'journey':
        return this.getJourneyLink();
      case 'predictive':
        return this.getPredictiveLink();
      case 'constructor':
        return this.getConstructorLink();
      default:
        return '';
    }
  }

  public isDisabled(button: any) {
    let active: any = true;
    const rowsCount: any = this.gridApi.selection.getSelectedRows().length;
    if(button === 'share') {
      active *= Number(rowsCount >= 1 && rowsCount <= 10);
    }
    if(['lookalike', 'edit', 'analytic', 'delete', 'predictive', 'split'].indexOf(button) !== -1) {
      active *= Number(rowsCount === 1);
    }
    if(['delete'].indexOf(button) !== -1 && active) {
      active *= Number(['predictive'].indexOf(this.gridApi.selection.getSelectedRows()[0].type) === -1);
    }
    if(['predictive'].indexOf(button) !== -1 && active) {
      active *= Number(['look-alike', 'predictive'].indexOf(this.gridApi.selection.getSelectedRows()[0].type) === -1);
    }
    if(['split'].indexOf(button) !== -1 && active) {
      active *= Number(['split'].indexOf(this.gridApi.selection.getSelectedRows()[0].type) === -1);
    }
    if(['lookalike'].indexOf(button) !== -1 && active) {
      active *= Number(['normal', 'constructor', 'manual', 'analytics', 'split'].indexOf(this.gridApi.selection.getSelectedRows()[0].type) !== -1);
    }
    if(['share', 'lookalike', 'analytic', 'predictive', 'split'].indexOf(button) !== -1 && active) {
      active *= this.gridApi.selection.getSelectedRows()[0].is_active;
    }
    if(['share', 'lookalike', 'analytic', 'predictive', 'split'].indexOf(button) !== -1 && active) {
      active *= Number(!this.gridApi.selection.getSelectedRows()[0].is_empty);
    }
    if(['share', 'lookalike'].indexOf(button) !== -1 && active) {
      active *= Number(_.get(this.authService.getSelectedAccount(), 'permission') === 'edit');
    }
    if(['share', 'create', 'lookalike', 'folder', 'delete', 'split', 'predictive'].indexOf(button) !== -1 && active) {
      active *= Number(_.get(this.authService.getSelectedAccount(), 'permission') === 'edit');
    }
    if(['share', 'create', 'lookalike', 'folder', 'delete', 'split', 'predictive', 'analytic', 'journey', 'constructor'].indexOf(button) !== -1 && active) {
      if (this.tariff.type === 'unlimited') {
        active *= 1;
      } else if (this.tariff.type === 'by_tariff') {
        if (['share', 'create', 'lookalike', 'folder', 'delete', 'analytic', 'constructor'].indexOf(button) !== -1) {
          active *= 1;
        } else {
          active *= 0;
        }
      } else {
        active *= 0;
      }
    }
    return !active;
  }

  public toggleConstructorAudience(audience: any) {
    if(!audience.is_active) {
      return;
    }
    if(!this.constructorService.isAudienceInList(audience.id)) {
      this.constructorService.addAudience(new Audience(audience));
    } else {
      this.constructorService.removeAudience(new Audience(audience));
    }
  }

  public getIcon(audience: any) {
    if(!audience.is_active) {
      return 'glyphicon glyphicon-ban-circle';
    }
    if(!this.constructorService.isAudienceInList(audience.id)) {
      return 'glyphicon glyphicon-plus';
    } else {
      return 'glyphicon glyphicon-minus';
    }
  }

  public openModal(mode: any) {
    if(this.isDisabled(mode)) {
      return;
    }
    let modalInstance;
    const config: any = {
      animation: true,
      size: 'lg',
      resolve: {
        segment: () => {
          return this.gridApi.selection.getSelectedRows()[0];
        }
      }
    };
    let onConfirm: any = () => {};
    switch(mode) {
      case 'share':
        config.component = 'modalShareComponent';
        if (this.tariff.type !== 'unlimited') {
          config.size = 'md';
        }
        onConfirm = () => {
          this.Notification.success(this.textLoc.sharingCreated);
        };
        config.resolve.segments = () => {
          return this.gridApi.selection.getSelectedRows();
        };
        config.resolve.platforms = () => {
          return this.platformList;
        };
        config.resolve.tariff = () => {
          return this.tariff;
        };
        break;
      case 'split':
        config.component = 'modalSplitComponent';
        onConfirm = () => {
          this.Notification.success(this.textLoc.splitCreated);
        };
        break;
      case 'folder':
        config.component = 'modalEditFolderAudienceComponent';
        config.size = 'ls';
        config.resolve.segments = () => {
          return this.gridApi.selection.getSelectedRows();
        };
        onConfirm = () => {
          this.gridApi.core.notifyDataChange('all');
          this.Notification.success(this.textLoc.groupCreated);
        };
        break;
      case 'delete':
        config.component = 'modalPixelDeleteComponent';
        config.size = 'ls';
        config.resolve.type = () => {
          return 'audience';
        };
        config.resolve.itemData = config.resolve.segment;
        onConfirm = (resp: any) => {
          if(resp) {
            this.Notification.success(this.textLoc.audienceDeleted);
          }
        };
        break;
      default:
        break;
    }

    modalInstance = this.$uibModal.open({
      animation: config.animation,
      component: config.component,
      size: config.size,
      resolve: config.resolve
    });
    modalInstance.mode = mode;
    modalInstance.result.then(onConfirm).catch(() => {});
  }

  public getSearchData(search: any) {
    const SEARCH_RESULTS_LIMIT = 50;
    const searchStr = search.toLowerCase();
    this.searchData = [];
    let segment;
    let colMatch;
    for(let i = 0; i < this.gridConfig.data.length && this.searchData.length < SEARCH_RESULTS_LIMIT; i++) {
      colMatch = 1;
      segment = this.gridConfig.data[i];
      if(segment.segment_name.toLowerCase().includes(searchStr) || (String(segment.segment_id).includes(search) && !(colMatch = 0))) {
        this.searchData.push({
          segment_name: segment.segment_name,
          segment_id: segment.segment_id,
          index: i,
          colMatch: colMatch
        });
      }
    }
  }

  public uiSelect(item: any, select: any) {
    if(!item) { return; }
    var treeLevel = this.gridApi.grid.rows[item.index].treeLevel - 1;
    var parentRow = this.gridApi.grid.rows[item.index];
    while(treeLevel-- >= 0) {
      parentRow = parentRow.treeNode.parentRow;
      this.gridApi.treeBase.expandRow(parentRow);
    }
    this.gridApi.selection.clearSelectedRows();
    this.gridApi.selection.selectRow(this.gridConfig.data[item.index]);

    const scrollToFocus = () => {
      this.gridApi.core.scrollTo(this.gridConfig.data[item.index], this.gridConfig.columnDefs[item.colMatch]);
    };

    setTimeout(scrollToFocus, 0);

    var event = document.createEvent('Event');
    select.clear(event);
  }

  public navigate(button: string): void {
    if (this.isDisabled(button)) {
      return;
    }
    if(button === 'edit' && this.gridApi.selection.getSelectedRows()[0].type === 'constructor') {
      this.router.navigate(['/audiences/constructor/' + this.gridApi.selection.getSelectedRows()[0].id + '/edit']);
      return;
    }
    if(button === 'constructor') {
      this.router.navigate(['/audiences/constructor/']);
    }
    if(button === 'edit') {
      this.router.navigate(['/audiences/' + this.gridApi.selection.getSelectedRows()[0].id + '/edit']);
    }
    if(button === 'analytic' && this.gridApi.selection.getSelectedRows().length === 1) {
      this.router.navigate(['/audiences/' + this.gridApi.selection.getSelectedRows()[0].id + '/analytics']);
    }
    if(button === 'journey') {
      this.router.navigate(['/audiences/journey/']);
    }
    if(button === 'predictive') {
      this.router.navigate(['/audiences/' + this.gridApi.selection.getSelectedRows()[0].id + '/predictive']);
    }
  }

  private extractSelectedFromTreeLocal(segment: any) {
    if(!segment || segment.treeLevel === 0) {
      return;
    }
    var topSegment = segment.treeNode.parentRow;
    while(topSegment.treeLevel > 0) {
      topSegment = topSegment.treeNode.parentRow;
    }
    topSegment.entity.parents[0] ?
      (segment.entity.parents[0] = topSegment.entity.parents[0]) :
      segment.entity.parents.length = 0;
    segment.entity.parent = null;
    this.audiencesService.updateParent(segment.entity.id, segment.entity.parents);
  }

  private checkDragAndDropAvailable() {
    this.gridApi.dragndrop.setDragDisabled(_.get(this.authService.getSelectedAccount(), 'permission') !== 'edit');
  }

  private getAnalyticLink() {
    if(!this.gridApi || this.gridApi.selection.getSelectedRows().length !== 1 || this.gridApi.selection.getSelectedRows()[0].is_empty || !this.gridApi.selection.getSelectedRows()[0].is_active) {
      return '';
    } else {
      return '/audiences/' + this.gridApi.selection.getSelectedRows()[0].id + '/analytics';
    }
  }

  private getJourneyLink() {
    return '';
  }

  private getPredictiveLink() {
    if(!this.gridApi || this.gridApi.selection.getSelectedRows().length !== 1 || this.gridApi.selection.getSelectedRows()[0].is_empty || !this.gridApi.selection.getSelectedRows()[0].is_active || this.isDisabled('predictive')) {
      return '';
    } else {
      return '/audiences/' + this.gridApi.selection.getSelectedRows()[0].id + '/predictive';
    }
  }

  private getLookalikeLink() {
    if(!this.gridApi || this.isDisabled('lookalike')) {
      return '';
    } else {
      return '/audiences/' + this.gridApi.selection.getSelectedRows()[0].id + '/lookalike';
    }
  }

  private getEditAudienceLink() {
    if(!this.gridApi || this.gridApi.selection.getSelectedRows().length !== 1) {
      return '';
    } else if(this.gridApi.selection.getSelectedRows()[0].type === 'constructor') {
      return '';
    } else {
      return '/audiences/' + this.gridApi.selection.getSelectedRows()[0].id + '/edit';
    }
  }

  private getConstructorLink() {
    return '';
  }
}

