import * as angular from 'angular';
import { Component, OnInit } from 'angular-ts-decorators';
import * as _ from 'lodash';
import { Audience } from '../../core/classes/classes';

@Component({
  selector: 'segmentEditComponent',
  templateUrl: './segment-edit.pug'
})
export class SegmentEditComponent implements OnInit {

  static $inject: string[] = [
    '$scope',
    '$stateParams',
    '$state',
    'segmentsResource',
    'pixelService',
    'segmentsService',
    'Notification'
  ];
  copyPreloader: any;
  parentPreloader: any;
  public constructorRulesPreloader: boolean;
  public lookalikeParentPreloader: boolean;
  public mode: any = this.$state.current.data.mode;
  public preloader = true;
  public title: any;
  public relationsPreloader = false;
  public searchPixelPreloader = false;
  public searchExcludePixelPreloader = false;
  public segmentId: any = this.$stateParams.segmentId;
  public disableSubmit = false;
  public notFound = false;
  public segmentNotFound = false;
  public newPixel = '';
  public newPixelExclude = '';
  public copySegment: any = null;
  public relations: any[] = [];
  public segmentsList: any[] = [];
  public searchData: any[] = [];
  public searchFilterCopy = '';
  public searchFilterParent = '';
  public searchDataParent: any[] = [];
  public segmentsListPreloader = true;
  public $select: any;
  public visibilityTypes: any[] = [{
    name: 'public',
    descr: 'Выгружается во все батчи, где доступны public сегменты'
  }, {
    name: 'hidden',
    descr: 'Не выгружаются никому'
  }, {
    name: 'deactive',
    descr: 'Не выгружаются никому независимо от настроек фильтров сегментов'
  }];
  public domainFields = [{
    name: 'domains',
    descr: 'Необязательное поле. Позволяет собрать аудиторию сегмента по списку доменов (используется объединение аудиторий, если доменов несколько). Вести домены в формате “www.domain.com” / “domain.com”. Все домены из поля объединяются с правилами из раздела Ключевые слова.'
  }, {
    name: 'domains_only',
    descr: 'Необязательное поле. Позволяет ограничить аудиторию, которая отбирается по правилам из раздела Ключевые слова (все домены из поля пересекаются с правилами из раздела Ключевые слова). Вести домены в формате “www.domain.com” / “domain.com”'
  }, {
    name: 'urls',
    descr: ''
  }, {
    name: 'stop_domains',
    descr: 'Необязательное поле. Позволяет исключить часть аудитории, которая отбирается по правилам из раздела Ключевые слова (все домены из поля исключаются из аудитории, собираемой по правилами из раздела Ключевые слова). Вести домены в формате “www.domain.com” / “domain.com”. Важно (!): если правила в разделе Ключевые слова не заданы, то поле не сработает.'
  }];
  public keywordFields = [{
    name: 'keywords_single',
    descr: 'Необязательное поле. Список слов, которые ограничивают аудиторию по ключевым словам в url-страницах (Например, http://domain.com/слово1/abcd). При указании нескольких слов, аудитория подходящих url-страниц объединяется.'
  }, {
    name: 'keywords_ordered',
    descr: 'Необязательное поле. Список сочетаний слов, которые ограничивают аудиторию по ключевым словам в url-страницах (Например, http://domain.com/слово1/fge/слово2_abcd). В сочетании можно использовать любое количество слов, разделитель - ”;”, порядок слов из сочетания учитывается во вхождении в url-страницу. При указании нескольких сочетаний слов, аудитория подходящих url-страниц объединяется.'
  }, {
    name: 'keywords_shuffle',
    descr: 'Необязательное поле. Список сочетаний слов, которые ограничивают аудиторию по ключевым словам в url-страницах (Например, http://domain.com/слово2/fge/слово1_abcd). В сочетании можно использовать любое количество слов, разделитель - ”;”, порядок слов из сочетания не учитывается во вхождении в url-страницу. При указании нескольких сочетаний слов, аудитория подходящих url-страниц объединяется.'
  }, {
    name: 'rexps',
    descr: `Необязательное поле. Список сочетаний слов, которые ограничивают аудиторию по ключевым словам в url-страницах (Например, http://domain.com/jkl_слово1/fge/слово2_abcd). В сочетании можно использовать любое количество слов, разделитель - ”;”, порядок слов из сочетания учитывается во вхождении в url-страницу. При указании нескольких сочетаний слов, аудитория подходящих url-страниц объединяется. В отличии от поля Слова в любом порядке сочетание должно начинаться со структуры “<схема>://” (например, “http://”).`
  }, {
    name: 'domain_words_single',
    descr: 'Необязательное поле. Список слов, которые ограничивают аудиторию по ключевым словам в url-страницах внутри домена (Например, http://d_слово1_omain.com/abcd). При указании нескольких слов, аудитория подходящих url-страниц объединяется.'
  }];
  public segment: any = {};
  private tagFields: any[] = [
    'domains',
    'domains_only',
    'urls',
    'stop_domains',
    'keywords_single',
    'keywords_ordered',
    'keywords_shuffle',
    'rexps',
    'domain_words_single'
  ];
  private simpleRuleFields: any[] = [
    'domains',
    'domains_only',
    'urls',
    'stop_domains',
    'keywords_single',
    'domain_words_single'
  ];
  private compositeRuleFields: any[] = [
    'keywords_ordered',
    'keywords_shuffle',
    'rexps'
  ];

  constructor(private $scope: any,
              private $stateParams: any,
              private $state: any,
              private segmentsResource: any,
              private pixelService: any,
              private segmentsService: any,
              private Notification: any) {
  }

  ngOnInit(): void {

    this.clearSegment();

    if(this.mode === 'create') {
      this.preloader = false;
      this.title = 'Создание сегмента';
      this.segmentsService.updateSegmentsTree().then(() => {
        this.segmentsList = this.segmentsService.getSegmentsTreeData();
      }).finally(() => {
        this.segmentsListPreloader = false;
      });
    } else {
      this.segmentsResource.get({id: this.segmentId}).$promise.then((response: any) => {
        let segment = response.data;
        if(!!segment.parents.length) {
          this.searchParent(segment.id);
        }
        this.segment.segment_id = segment.segment_id;
        this.segment.segment_name = segment.segment_name;
        this.segment.segment_name_ru = segment.segment_name_ru;
        this.segment.description = segment.description;
        this.segment.visibility_type = segment.visibility_type;
        this.segment.expired_max = this.secondsToDays(segment.expired_max);
        this.segment.expired_min = this.secondsToDays(segment.expired_min);
        this.segment.intensity_min = segment.intensity_min;
        this.segment.intensity_max = segment.intensity_max;
        this.segment.is_empty = segment.is_empty;
        this.segment.is_inherited = segment.is_inherited;
        this.segment.created_at = segment.created_at;
        this.segment.updated_at = segment.updated_at;
        this.segment.partners_only = segment.partners_only;
        this.segment.stop_partners = segment.stop_partners;
        this.segment.is_total_audience = segment.is_total_audience;
        this.segment.type = segment.type;
        this.segment.owner = segment.owner;
        this.segment.is_lookalike = (segment.type === 'look-alike');
        this.segment.is_constructor = (segment.type === 'constructor');
        this.segment.is_analytics = (segment.type === 'analytics');
        this.segment.constructor = segment.constructor;
        this.segment.constructor_analytics = segment.constructor_analytics;
        this.segment.lookalike_percent = segment.lookalike_percent;
        this.segment.lookalike_excluded = segment.lookalike_excluded;
        this.segment.negative_segments = segment.negative_segments;

        let rule_groups: any = {};
        this.segment.constructor_rules = {
          excluded: [],
          intersected: []
        };
        this.segment.constructor.forEach(function(rule: any) {
          rule_groups[rule.intersected_group]
            ? rule_groups[rule.intersected_group].push(new Audience({id: rule.segment_rule}))
            : rule_groups[rule.intersected_group] = [new Audience({id: rule.segment_rule})];
        });
        if(rule_groups.exclude) {
          this.segment.constructor_rules.excluded.push(rule_groups.exclude);
          delete rule_groups.exclude;
        }
        for(let key in rule_groups) {
          if(rule_groups.hasOwnProperty(key)) {
            this.segment.constructor_rules.intersected.push(rule_groups[key]);
          }
        }


        this.constructorRulesPreloader = true;
        this.lookalikeParentPreloader = true;
        this.segmentsService.updateSegmentsTree().then(() => {
          let searchInfoList = (segments: any) => {
            let searchInfo = (segment: any) => {
              let info = this.segmentsService.getSegment(segment.id);
              if(info.segment_name) {
                segment.segment_id = info.segment_id;
                segment.segment_name = info.segment_name;
                segment.size = info.size;
              }
            };

            segments.forEach(searchInfo);
          };

          this.segment.constructor_rules.excluded.forEach(searchInfoList);
          this.segment.constructor_rules.intersected.forEach(searchInfoList);

          if(!!segment.lookalike_parent_id) {
            this.segment.lookalike_parent = this.segmentsService.getSegment(segment.lookalike_parent_id);
          }

          this.segment.lookalike_excluded_info = [];
          _.each(this.segment.lookalike_excluded, (segment_sid: any) => {
            this.segment.lookalike_excluded_info.push(this.segmentsService.getSegmentBySID(segment_sid));
          });

          this.segment.negative_segments_info = [];
          _.each(this.segment.negative_segments, (segment_sid: any) => {
            this.segment.negative_segments_info.push(this.segmentsService.getSegmentBySID(segment_sid));
          });

          this.segmentsList = this.segmentsService.getSegmentsTreeData();

        }).finally(() => {
          this.segmentsListPreloader = false;
          this.constructorRulesPreloader = false;
          this.lookalikeParentPreloader = false;
        });

        this.tagFields.forEach((field: any) => {
          if(segment[field].length > 50) {
            this.segment[field].json = JSON.stringify(segment[field]);
            this.segment[field].displayMode = 'json';
            this.segment[field].availableMods = ['json'];
          } else {
            if(this.compositeRuleFields.indexOf(field) !== -1) {
              segment[field].forEach(function(rule: any, i: any, self: any) {
                self[i] = rule.join(';');
              });
            }
            this.segment[field].tags = this.cleanArray(segment[field]);
          }
        });
        this.title = `Редактирование сегмента '${this.segment.segment_name}' (${this.segment.segment_id})`;
      }).catch(() => {
        this.notFound = true;
      }).finally(() => {
        this.preloader = false;
      });

      this.relationsPreloader = true;
      this.segmentsResource.get({id: this.segmentId, option: 'partner_relations'}).$promise.then((response: any) => {
        this.relations = response.data;
      }).finally(() => {
        this.relationsPreloader = false;
      });
    }
  }


  public clearParent() {
    this.segment.parent = {
      segment_id: '',
      segment_name: '',
      id: null,
      is_empty: false
    };
    this.$select && this.$select.clear(new Event('clear'));
    this.searchFilterParent = undefined;
    this.segmentNotFound = false;
  }

  public searchParent(id: any) {
    if(this.parentPreloader) { return; }
    this.segmentNotFound = false;
    if(!id) { return; }
    if(this.segmentsService.updated()) {
      let segment = this.segmentsService.getParent(id);
      this.segmentNotFound = !segment.segment_name;
      if(this.segmentNotFound) { return; }
      this.segment.parent.id = segment.id;
      this.segment.parent.segment_id = segment.segment_id;
      this.segment.parent.segment_name = segment.segment_name;
      this.segment.parent.is_empty = segment.is_empty;
      this.segment.parent.is_inherited = segment.is_inherited;
      this.searchFilterParent = this.segment.parent;
    } else {
      this.parentPreloader = true;
      this.segmentsResource.get({id: id, option: 'parent'}).$promise.then((response: any) => {
        this.segment.parent.id = response.data.id;
        this.segment.parent.segment_name = response.data.segment_name;
        this.segment.parent.segment_id = response.data.segment_id;
        this.segment.parent.is_empty = response.data.is_empty;
        this.searchFilterParent = this.segment.parent;
        this.segmentNotFound = false;
      }).catch(() => {
        this.segmentNotFound = true;
      }).finally(() => {
        this.parentPreloader = false;
      });
    }
  }

  public searchAndCopy() {
    if(this.copyPreloader) { return; }
    this.copyPreloader = true;
    this.segmentsResource.get({id: this.copySegment.id}).$promise.then((response: any) => {
      let segment = response.data;
      if(!!segment.parents.length) {
        this.searchParent(segment.id);
      }
      this.segment.visibility_type = segment.visibility_type;
      this.segment.expired_max = this.secondsToDays(segment.expired_max);
      this.segment.expired_min = this.secondsToDays(segment.expired_min);
      this.segment.intensity_min = segment.intensity_min;
      this.segment.intensity_max = segment.intensity_max;
      this.segment.is_empty = segment.is_empty;
      this.segment.is_inherited = segment.is_inherited;
      this.segment.created_at = segment.created_at;
      this.segment.updated_at = segment.updated_at;

      this.tagFields.forEach((field: any) => {
        if(segment[field].length > 50) {
          this.segment[field].json = JSON.stringify(segment[field]);
          this.segment[field].displayMode = 'json';
          this.segment[field].availableMods = ['json'];
        } else {
          if(this.compositeRuleFields.indexOf(field) !== -1) {
            segment[field].forEach(function(rule: any, i: any, self: any) {
              self[i] = rule.join(';');
            });
          }
          this.segment[field].tags = this.cleanArray(segment[field]);
        }
      });
    }).catch(() => {
      this.Notification.error('Segment not found');
    }).finally(() => {
      this.copyPreloader = false;
    });
  }

  public setMode(field: any, mode: any) {
    if(this.segment[field].displayMode === mode || this.segment[field].availableMods.indexOf(mode) === -1) { return; }
    let res: any = [];
    switch(mode) {
      case 'json':
        if(this.compositeRuleFields.indexOf(field) !== -1) {
          this.segment[field].tags.forEach((rule: any, i: any) => {
            res[i] = this.cleanArray(rule.split(';'));
          });
        } else {
          res = this.segment[field].tags;
        }
        this.segment[field].json = JSON.stringify(res);
        break;
      case 'tags':
        if(this.simpleRuleFields.indexOf(field) !== -1) {
          res = this.simpleRuleValidation(this.segment[field].json);
        } else if(this.compositeRuleFields.indexOf(field) !== -1) {
          res = this.compositeRuleValidation(this.segment[field].json);
        }
        if(res) {
          if(res.length > 50) {
            this.segment[field].availableMods = ['json'];
            this.Notification.error('To match elements count. Cant convert to tags view.');
            return;
          }
          res = res.filter(function(item: any, pos: any, self: any) {
            return self.indexOf(item) === pos;
          });
          this.segment[field].tags = res;
        } else {
          this.Notification.error('JSON not valid!');
          return;
        }
        break;
    }
    this.segment[field].displayMode = mode;
  }

  public clearField(field: any) {
    this.segment[field].json = '[]';
    this.segment[field].tags.length = 0;
    this.segment[field].availableMods = ['tags', 'json'];
  }

  public simpleRuleValidation(str: any) {
    let res: any;
    try {
      res = this.cleanArray(JSON.parse(str));
    } catch(e) {
      return false;
    }
    if(!Array.isArray(res)) {
      return false;
    }
    let isValid = true;
    res.forEach(function(rule: any, i: any) {
      if(rule instanceof Object) {
        isValid = false;
      } else {
        res[i] = rule.toString();
      }
    });
    if(isValid) {
      return res;
    }
    return false;
  }

  public compositeRuleValidation(str: any) {
    let res: any;
    try {
      res = this.cleanArray(JSON.parse(str));
    } catch(e) {
      return false;
    }
    if(!Array.isArray(res)) {
      return false;
    }
    let isValid = true;
    for(let i = 0; i < res.length; i++) {
      if(!Array.isArray(res[i])) {
        return false;
      } else {
        res[i] = this.cleanArray(res[i]);
        res[i].forEach(function(rule: any, j: any, self: any) {
          if(rule instanceof Object) {
            isValid = false;
          } else {
            self[j] = rule.toString();
          }
        });
      }
    }
    if(isValid) {
      res.forEach(function(rule: any, i: any) {
        res[i] = rule.join(';');
      });
      return res;
    }
    return false;
  }

  public addPixel() {
    if(this.segment.is_empty) {
      return;
    }
    if(!!this.newPixel && this.newPixel.indexOf(' ') === -1) {
      this.segment.partners_only.push(this.newPixel);
      this.newPixel = '';
    } else {
      this.Notification.error('PID пикселя не может содеражть пробелы');
    }
  }

  public addPixelExclude() {
    if(this.segment.is_empty) {
      return;
    }
    if(!!this.newPixelExclude && this.newPixelExclude.indexOf(' ') === -1) {
      this.segment.stop_partners.push(this.newPixelExclude);
      this.newPixelExclude = '';
    } else {
      this.Notification.error('PID пикселя не может содеражть пробелы');
    }
  }

  public removePixel(index: any) {
    if(this.segment.is_empty) {
      return;
    }
    this.segment.partners_only.splice(index, 1);
  }

  public removePixelExclude(index: any) {
    if(this.segment.is_empty) {
      return;
    }
    this.segment.stop_partners.splice(index, 1);
    !this.segment.stop_partners.length && (this.segment.is_total_audience = false);
  }

  public editSegment() {
    this.disableSubmit = true;
    let data: any = this.getValidData();
    if(!!data) {
      data.id = this.segmentId;
      this.segmentsResource.update(data, (response: any) => {
        this.disableSubmit = false;
        this.segmentsService.editSegment(response.data);
        this.Notification.success('Segment modified');
      }, (error: any) => {
        this.disableSubmit = false;
        this.Notification.error(error.data.error_message);
      });
    } else {
      this.disableSubmit = false;
    }
  }

  public createSegment() {
    this.disableSubmit = true;
    let data: any = this.getValidData();
    if(!!data) {
      this.segmentsResource.save(data).$promise.then((response: any) => {
        this.Notification.success('segment created: ' + response.data.segment_id);
        response.data.size = 0;
        this.segmentsService.addSegment(response.data);
        this.clearSegment();
      }).catch((error: any) => {
        this.Notification.error(error.data.error_message);
      }).finally(() => {
        this.disableSubmit = false;
      });
    } else {
      this.disableSubmit = false;
    }
  }

  public getSearchData(search: any) {
    let SEARCH_RESULTS_LIMIT = 50;
    let matcher = new RegExp(search, 'i');
    this.searchData = [];
    let segment;
    for(let i = 0; i < this.segmentsList.length && this.searchData.length < SEARCH_RESULTS_LIMIT; i++) {
      segment = this.segmentsList[i];
      if(segment.segment_name.match(matcher) || (String(segment.segment_id).match(matcher))) {
        this.searchData.push({
          segment_name: segment.segment_name,
          segment_id: segment.segment_id,
          id: segment.id
        });
      }
    }
  }

  public uiSelectCopy(item: any) {
    item && (this.copySegment = item);
  }

  public uiSelectParent(item: any) {
    item && (this.segment.parent = item);
  }

  public copySelectObjectfunction(select: any) {
    this.$select = select;
  }

  private getValidData() {
    function daysToSeconds(days: any) {
      return days === null ? null : days * 60 * 60 * 24;
    }

    let data: any = {
      segment_name: this.segment.segment_name,
      segment_name_ru: this.segment.segment_name_ru,
      description: this.segment.description,
      visibility_type: this.segment.visibility_type,
      expired_max: daysToSeconds(this.segment.expired_max),
      expired_min: daysToSeconds(this.segment.expired_min),
      intensity_min: this.segment.intensity_min,
      intensity_max: this.segment.intensity_max,
      is_empty: this.segment.is_empty,
      is_inherited: this.segment.is_inherited,
      partners_only: this.segment.partners_only,
      stop_partners: this.segment.stop_partners,
      is_total_audience: this.segment.is_total_audience,
      parents: [],
      domains: [],
      domains_only: [],
      urls: [],
      stop_domains: [],
      keywords_single: [],
      keywords_ordered: [[]],
      keywords_shuffle: [[]],
      rexps: [[]],
      domain_words_single: []
    };

    let isValid = true;
    this.simpleRuleFields.forEach((field: any) => {
      if(this.segment[field].displayMode === 'tags') {
        data[field] = this.segment[field].tags;
      } else {
        data[field] = this.simpleRuleValidation(this.segment[field].json);
        if(!data[field]) {
          this.Notification.error(`Validation error: field '${this.segment[field].name}' is not valid JSON`);
          isValid = false;
        }
      }
    });

    if(this.segmentNotFound && this.segment.parent.segment_id) {
      isValid = false;
      this.Notification.error(`Parent error: segment with ID: '${this.segment.parent.segment_id}' not found`);
    } else {
      if(this.segment.parent.segment_name) {
        data.parents = [this.segment.parent.segment_id];
      }
    }

    this.compositeRuleFields.forEach((field: any) => {
      if(this.segment[field].displayMode === 'tags') {
        data[field] = angular.copy(this.segment[field].tags);
      } else {
        data[field] = this.compositeRuleValidation(this.segment[field].json);
        if(!data[field]) {
          this.Notification.error(`Validation error: field '${this.segment[field].name}' is not valid JSON`);
          isValid = false;
        }
      }
      data[field].forEach((rule: any, i: any, self: any) => {
        self[i] = rule.split(';');
      });
    });

    isValid && data.domains.forEach((domain: any) => {
      if(domain.indexOf('.') === -1) {
        isValid = false;
        this.Notification.error(`Validation error: domain '${domain}' in field 'domains' not valid`);
      }
    });

    isValid && data.rexps.forEach((rule: any) => {
      if(rule[0].indexOf('https://') && rule[0].search('http://')) {
        isValid = false;
        this.Notification.error(`Validation error: rules in field 'rexps' should start from 'https://' or 'http://'`);
      }
    });

    return isValid ? data : false;
  }

  private clearSegment() {
    this.relations = [];
    this.segment = {
      segment_name: '',
      segment_name_ru: '',
      segment_id: null,
      description: '',
      visibility_type: 'public',
      parent: {
        segment_id: '',
        segment_name: '',
        id: null,
        is_empty: false
      },
      is_empty: false,
      is_inherited: false,
      created_at: '',
      updated_at: '',
      expired_max: null,
      expired_min: null,
      intensity_min: null,
      intensity_max: null,
      partners_only: [],
      stop_partners: [],
      is_total_audience: false,
      type: 'normal',
      negative_segments: []
    };

    this.tagFields.forEach((field: any) => {
      this.segment[field] = {
        json: '',
        tags: [],
        name: '',
        placeholder: '',
        displayMode: 'tags',
        availableMods: ['tags', 'json']
      };
    });

    this.segment.domains.name = 'Домены';
    this.segment.domains_only.name = 'Обязательные домены';
    this.segment.urls.name = 'Включая URLs';
    this.segment.stop_domains.name = 'Исключая домены';
    this.segment.keywords_single.name = 'Слова';
    this.segment.keywords_ordered.name = 'Упорядоченные слова';
    this.segment.keywords_shuffle.name = 'Слова в любом порядке';
    this.segment.rexps.name = 'Регулярные фильтры';
    this.segment.domain_words_single.name = 'Слова для доменов';

    this.segment.domains.placeholder = 'Введите домены';
    this.segment.domains_only.placeholder = 'Введите домены';
    this.segment.urls.placeholder = 'Введите URLs в формате: http://rbc.ru';
    this.segment.stop_domains.placeholder = 'Введите домены';
    this.segment.keywords_single.placeholder = 'Введите одиночные слова';
    this.segment.keywords_ordered.placeholder = 'Введите группы слов в формате: слово1; слово2';
    this.segment.keywords_shuffle.placeholder = 'Введите группы слов в формате: слово1; слово2';
    this.segment.rexps.placeholder = `Введите группы слов в формате: http://rbc.ru; /news; /moscow`;
    this.segment.domain_words_single.placeholder = 'Введите одиночные слова';
    this.clearParent();
  }

  private secondsToDays(seconds: any) {
    return seconds === null ? null : seconds / 60 / 60 / 24;
  }

  private cleanArray(actual: any) {
    let newArray = [];
    for(let i = 0; i < actual.length; i++) {
      actual[i] && newArray.push(actual[i]);
    }
    return newArray;
  }
}
