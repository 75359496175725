import { StateProvider } from '@uirouter/angularjs';
import { NgModule } from 'angular-ts-decorators';
import { audisenceRouting } from '../audiences/audience.routes';
import { CampaignAnalyticsComponent } from './analytics/campaign-analytics.component';
import { campaignRouting } from './campaign.routes';
import { CampaignListComponent } from './list/campaign-list.component';


@NgModule({
  id: 'CampaignModule',
  declarations: [
    CampaignListComponent,
    CampaignAnalyticsComponent
  ]
})
export class CampaignModule {
  static config = config;
}

function config($stateProvider: StateProvider) {
  campaignRouting($stateProvider);
}

config.$inject = ['$stateProvider'];
