import * as angular from 'angular';
import { Component, OnInit } from 'angular-ts-decorators';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ConstService } from '../../../app/shared/const.service';

@Component({
  selector: 'segmentListComponent',
  templateUrl: './segment-list.pug'
})
export class SegmentListComponent implements OnInit {

  static $inject: string[] = [
    '$scope',
    'segmentsService',
    'segmentsResource'
  ];
  public preloader = false;
  public gridApi: any;
  public groupSelection: any = false;
  public geoAnalyticsPreloader: any = false;
  public allDataPreloader: any = false;
  public searchData: any[] = [];
  public searchFilter = '';
  public gridConfig: any = {
    data: [],
    enableFiltering: false,
    enableRowSelection: true,
    enableSelectAll: true,
    enableColumnMenus: false,
    showGridFooter: true,
    showTreeExpandNoChildren: false,
    enableGridMenu: true,
    gridMenuShowHideColumns: true,
    exporterMenuPdf: false,
    enableGroupHeaderSelection: true,
    treeIndent: 1,
    treeRowHeaderBaseWidth: 30,

    exporterCsvFilename: 'segments_' + moment().format('DD_MM_YYYY') + '.csv',
    exporterCsvLinkElement: angular.element(document.querySelectorAll('.custom-csv-link-location')),
    exporterCsvColumnSeparator: ';',

    onRegisterApi: (gridApi: any) => {
      this.gridApi = gridApi;
      // $scope.gridApi.grid.registerRowsProcessor($scope.gridFilter, 200);
      this.gridApi.selection.on.rowSelectionChanged(this.$scope, (rowChanged: any) => {
        if(this.groupSelection && typeof(rowChanged.treeLevel) !== 'undefined' && rowChanged.treeLevel > -1) {
          let start = false;
          let rows = this.gridApi.grid.rows;
          for(let i = 0; (i < rows.length); i++) {
            if(start) {
              if(rows[i].treeLevel > rowChanged.treeLevel) {
                rows[i].setSelected(rowChanged.isSelected);
              } else {
                break;
              }
            } else if(rowChanged.uid === rows[i].uid) {
              start = true;
            }
          }
        }
      });
      this.getLink = (mode: any) => {
        if(this.gridApi.selection.getSelectedCount() !== 1) {
          return '';
        } else {
          return '/segments/' + this.gridApi.selection.getSelectedRows()[0].id + '/' + mode;
        }
      };
    },

    columnDefs: [
      {
        field: 'segment_id', displayName: 'ID',
        width: '120',
        cellClass: function(grid: any, row: any) {
          let addClass = 'tree_level--' + row.treeLevel;
          if(row.entity.is_empty) {
            addClass += ' segments__group_row';
          }
          return addClass;
        }
      },
      {
        field: 'parent',
        visible: false,
        width: '100'
      },
      {
        field: 'segment_name',
        displayName: 'Name',
        headerCellFilter: 'translate',
        width: '*'
      },
      /*{
        field: 'segment_name_ru',
        displayName: 'Name (ru)',
        headerCellFilter: 'translate',
        visible: false,
        width: '*'
      },
      {
        field: 'description',
        displayName: 'Description',
        headerCellFilter: 'translate',
        width: '*'
      },*/
      {
        field: 'size',
        displayName: 'Size',
        headerCellFilter: 'translate',
        width: '150',
        type: 'number',
        cellClass: function(grid: any, row: any) {
          let addClass = 'to-right';
          if(row.entity.is_decreased) {
            addClass += ' segments__size_fail';
          } else {
            addClass += ' segments__size_normal';
          }
          return addClass;
        },
        cellFilter: 'number:0'
      }/*,
      {
        field: 'is_decreased',
        displayName: 'Decreased',
        headerCellFilter: 'translate',
        width: '50',
        type: 'boolean',
        visible: false
      },
      {
        field: 'size_updated',
        displayName: 'Stats update',
        width: '150',
        cellFilter: 'IsAvailable',
        headerCellFilter: 'translate',
        cellClass: 'to-center'
      }*/
    ]
  };

  constructor(private $scope: any,
              private segmentsService: any,
              private segmentsResource: any) {
  }

  ngOnInit(): void {
    this.$scope.$on(ConstService.WORKER_EVENTS.updateTreeSegments, () => {
      this.gridApi.core.notifyDataChange('all');
    });
    this.getData();
  }

  public getLink(mode: any) {
    return '';
  }

  public filterRun() {
    this.gridApi.grid.refresh();
  }

  public getSearchData(search: any) {
    let SEARCH_RESULTS_LIMIT = 50;
    let matcher = new RegExp(search, 'i');
    this.searchData = [];
    let segment;
    let colMatch;
    for(let i = 0; i < this.gridConfig.data.length && this.searchData.length < SEARCH_RESULTS_LIMIT; i++) {
      colMatch = 1;
      segment = this.gridConfig.data[i];
      if(segment.segment_name.match(matcher) || (String(segment.segment_id).match(matcher) && !(colMatch = 0))) {
        this.searchData.push({
          segment_name: segment.segment_name,
          segment_id: segment.segment_id,
          index: i,
          colMatch: colMatch
        });
      }
    }
  }

  public uiSelect(item: any, select: any) {
    if(!item) { return; }
    let treeLevel = this.gridApi.grid.rows[item.index].treeLevel - 1;
    let parentRow = this.gridApi.grid.rows[item.index];
    while(treeLevel-- >= 0) {
      parentRow = parentRow.treeNode.parentRow;
      this.gridApi.treeBase.expandRow(parentRow);
    }
    this.gridApi.selection.clearSelectedRows();
    this.gridApi.selection.selectRow(this.gridConfig.data[item.index]);

    let scrollToFocus = () => {
      this.gridApi.core.scrollTo(this.gridConfig.data[item.index], this.gridConfig.columnDefs[item.colMatch]);
    };

    setTimeout(scrollToFocus, 0);

    let event = document.createEvent('Event');
    select.clear(event);
  }

  public getData() {
    // $scope.gridConfig.data = localStorageService.get('segments');
    // if(!!$scope.gridConfig.data) {
    // 	$scope.gridConfig.data = this.segmentsService.constructTree($scope.gridConfig.data);
    // 	return;
    // }
    this.preloader = true;
    this.segmentsService.updateSegmentsTree().then(() => {
      this.gridConfig.data = this.segmentsService.getSegmentsTreeData();
      // localStorageService.set('segments', $scope.gridConfig.data);
    }).finally(() => {
      this.preloader = false;
    });
  }

  public getAllData(): void {
    if(this.allDataPreloader) {
      return;
    }
    this.allDataPreloader = true;
    this.segmentsService.getSegmentsCSV().subscribe((response: any) => {
      let dataType = response.type;
      let binaryData = [];
      binaryData.push(response);
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
      downloadLink.setAttribute('download', 'csv_data');
      document.body.appendChild(downloadLink);
      downloadLink.click();
      this.allDataPreloader = false;
    });
  }

  public getGeoAnalytics() {
    if(this.geoAnalyticsPreloader) {
      return;
    }
    this.geoAnalyticsPreloader = true;
    this.segmentsService.getSegmnetsAnalytics().subscribe((response: any) => {
      let geoAnalytics: any = {};
      response.data.forEach((item: any) => {
        geoAnalytics[item.segment] || (geoAnalytics[item.segment] = {});
        geoAnalytics[item.segment][item.name] = item.size;
      });
      let segmentsGeoAnalytics: any[] = [];
      let str = ['id', 'segment_id', 'segment_name', 'segment_size'];
      let countries = _.keys(ConstService.COUNTRIES);
      let colEmpty;
      for(let i = countries.length - 1; i >= 0; i--) {
        colEmpty = true;
        for(let key in geoAnalytics) {
          if(geoAnalytics[key][countries[i]]) {
            colEmpty = false;
            break;
          }
        }
        if(colEmpty) {
          countries.splice(i, 1);
        }
      }

      countries.forEach(function(country: any) {
        str.push('"' + ConstService.COUNTRIES[country] + '"');
      });
      segmentsGeoAnalytics.push(str);
      let segmentGeodata: any = null;
      let countrySize: any = null;
      this.gridConfig.data.forEach((segment: any) => {
        segmentGeodata = geoAnalytics[segment.id];
        if(!segmentGeodata) {
          return;
        }
        let str = [segment.id, segment.segment_id, '"' + segment.segment_name + '"', segment.size];
        countries.forEach(function(country: any) {
          countrySize = segmentGeodata[country] || 0;
          str.push(countrySize);
        });
        segmentsGeoAnalytics.push(str);
      });

      let csvContent = 'data:text/csv;charset=utf-8,';
      let dataString = '';
      segmentsGeoAnalytics.forEach(function(infoArray: any, index: any) {
        dataString = infoArray.join(',');
        csvContent += index < segmentsGeoAnalytics.length ? dataString + '\n' : dataString;

      });
      let encodedUri = encodeURI(csvContent);
      let link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', 'segments_geo_' + moment().format('DD_MM_YYYY__hh_mm_ss') + '.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.geoAnalyticsPreloader = false;
    },(err: any) => {
      console.log(err);
      this.geoAnalyticsPreloader = false;
    });
  }

}
