import { resource } from 'angular';
import { Injectable } from 'angular-ts-decorators';

@Injectable('userResource')
export class UserResource {
  static $inject = ['$resource'];
  constructor($resource: resource.IResourceService) {
    return $resource('/api/v1/user/:action/',
      {
        action: '@action'
      },
      {
        login: {
          method: 'POST',
          params: {
            action: 'login'
          }
        },
        register: {
          method: 'POST',
          params: {
            action: 'register'
          }
        },
        logout: {
          method: 'POST',
          params: {
            action: 'logout'
          }
        },
        get: {
          method: 'GET',
          params: {
            action: 'current'
          }
        }
      });
  }
}

@Injectable('pixelResource')
export class PixelResource {
  static $inject = ['$resource'];
  constructor($resource: resource.IResourceService) {
    const url = '/api/v1/partner/pixels/:pixelId/:option/';
    return $resource(url,
      {
        pixelId: '@pixelId',
        option: '@option'
      },
      {
        update: {
          method: 'PUT'
        },
        delete: {
          method: 'DELETE'
        },
        analytics: {
          method: 'GET',
          params: {
            option: 'analytics'
          }
        },
        domains: {
          method: 'GET',
          params: {
            option: 'domains'
          }
        },
        analytics_common: {
          method: 'GET',
          params: {
            option: 'analytics_common'
          }
        },
        statistic_settings: {
          method: 'GET',
          params: {
            option: 'statistic_settings'
          }
        },
        constructor_analytics: {
          method: 'POST',
          params: {
            option: 'constructor_analytics'
          }
        },
        segment_geo: {
          method: 'POST',
          params: {
            option: 'segment_geo'
          }
        },
        constructor_analytics_event: {
          method: 'POST',
          params: {
            option: 'constructor_analytics_event'
          }
        },
        related: {
          method: 'GET',
          params: {
            option: 'related'
          }
        },
        query: {
          method: 'GET'
          // interceptor: {
          // 	response: function (response) {
          // 		//console.log('response in interceptor', response);
          // 		return $q.reject(response);
          // 	},
          // 	responseError: function (response) {
          // 		//console.log('error in interceptor', response);
          // 		return $q.reject(response);
          // 	}
          // },
        }
      });
  }
}

@Injectable('externalsResource')
export class ExternalsResource {
  static $inject = ['$resource'];
  constructor($resource: resource.IResourceService) {
    return $resource('/api/v1/partner/sharings/:id/',
      {
        id: '@id'
      },
      {
        update: {
          method: 'PUT'
        },
        save: {
          method: 'POST'
        },
        get: {
          method: 'GET'
        }
      });
  }
}

@Injectable('collectionsResource')
export class CollectionsResource {
  static $inject = ['$resource'];
  constructor($resource: resource.IResourceService) {
    return $resource('/api/v1/collection/:item/:type/',
      {
        item: '@item',
        type: '@type'
      },
      {
        platforms: {
          method: 'GET',
          params: {
            item: 'platforms'
          }
        },
        rates: {
          method: 'GET',
          params: {
            item: 'rates'
          }
        },
        geodata: {
          method: 'GET',
          params: {
            item: 'geodata'
          }
        }
      });
  }
}

@Injectable('billingResource')
export class BillingResource {
  static $inject = ['$resource'];
  constructor($resource: resource.IResourceService) {
    return $resource('/api/v1/partner/billing/');
  }
}

@Injectable('marketplaceResource')
export class MarketplaceResource {
  static $inject = ['$resource'];
  constructor($resource: resource.IResourceService) {
    return $resource('/api/v1/marketplace/:id/:option/',
      {
        id: '@id',
        option: '@option'
      },
      {
        query: {
          method: 'GET'
        },
        analytics: {
          method: 'GET',
          params: {
            option: 'analytics'
          }
        },
        intersections: {
          method: 'GET',
          params: {
            option: 'intersections'
          }
        },
        top_cities: {
          method: 'GET',
          params: {
            option: 'top_cities'
          }
        },
        constructor_capacity: {
          method: 'POST',
          params: {
            option: 'constructor_capacity'
          },
          cancellable: true
        },
        public: {
          method: 'GET',
          params: {
            option: 'public'
          }
        }
      });
  }
}

@Injectable('classifierResource')
export class ClassifierResource {
  static $inject = ['$resource'];
  constructor($resource: resource.IResourceService) {
    return $resource('/api/v1/classifier/:object/:id/',
      {
        id: '@id',
        object: '@object'
      },
      {
        post: {
          method: 'POST'
        },
        tag: {
          method: 'GET',
          params: {
            object: 'tag'
          }
        },
        urls: {
          method: 'GET',
          params: {
            object: 'url'
          }
        },
        classifier: {
          method: 'GET',
          params: {
            object: 'classifier'
          }
        },
        historymodel: {
          method: 'GET',
          params: {
            object: 'historymodel'
          },
          cancellable: true
        },
        predictreport: {
          method: 'GET',
          params: {
            object: 'predictreport'
          }
        },
        save: {
          method: 'PUT'
        },
        create: {
          method: 'POST'
        }
      });
  }
}

@Injectable('audiencesResource')
export class AudiencesResource {
  static $inject = ['$resource'];
  constructor($resource: resource.IResourceService) {
    return $resource('/api/v1/user/segments/:id/:option/',
      {
        id: '@id',
        option: '@option'
      },
      {
        update: {
          method: 'PUT'
        },
        patch: {
          method: 'PATCH'
        },
        save: {
          method: 'POST'
        },
        get: {
          method: 'GET'
        },
        delete: {
          method: 'DELETE'
        },
        query: {
          method: 'GET'
        },
        lookalike: {
          method: 'POST',
          params: {
            option: 'lookalike'
          }
        },
        folder: {
          method: 'POST',
          params: {
            option: 'folder'
          }
        },
        constructor: {
          method: 'POST',
          params: {
            option: 'constructor'
          }
        },
        constructor_rules: {
          method: 'GET',
          params: {
            option: 'constructor_rules'
          }
        },
        analytics: {
          method: 'GET',
          params: {
            option: 'analytics'
          }
        },
        intersections: {
          method: 'GET',
          params: {
            option: 'intersections'
          }
        },
        top_cities: {
          method: 'GET',
          params: {
            option: 'top_cities'
          }
        },
        related: {
          method: 'GET',
          params: {
            option: 'related'
          }
        }
      }
    );
  }
}

@Injectable('segmentsResource')
export class SegmentsResource {
  static $inject = ['$resource'];
  constructor($resource: resource.IResourceService) {
    return $resource('/api/v1/partner/segments/:id/:option/',
      {
        id: '@id',
        option: '@option'
      },
      {
        update: {
          method: 'PUT'
        },
        save: {
          method: 'POST'
        },
        get: {
          method: 'GET'
        },
        patch: {
          method: 'PATCH'
        }
      }
    );
  }
}

@Injectable('campaignsResource')
export class CampaignsResource {
  static $inject = ['$resource'];
  constructor($resource: resource.IResourceService) {
    return $resource('/api/v1/user/campaigns/:id/:option/',
      {
        id: '@id',
        option: '@option'
      },
      {
        query: {
          method: 'GET'
        },
        analytics: {
          method: 'GET',
          params: {
            option: 'analytics'
          }
        }
      });
  }
}

@Injectable('domainsResource')
export class DomainsResource {
  static $inject = ['$resource'];
  constructor($resource: resource.IResourceService) {
    return $resource('/api/v1/domain/search/',
      {},
      {
        query: {
          method: 'GET'
        }
      });
  }
}


// for dev
@Injectable('impersonateResource')
export class ImpersonateResource {
  static $inject = ['$resource'];
  constructor($resource: resource.IResourceService) {
    return $resource('/impersonate/:id/',
      {
        id: '@id'
      },
      {
        stop: {
          method: 'GET',
          params: {
            id: 'stop'
          }
        }
      }
    );
  }
}

@Injectable('affinityResource')
export class AffinityResource {
  static $inject = ['$resource'];
  constructor($resource: resource.IResourceService) {
    return $resource('/api/v1/user/affinity/:item/:id/',
      {
        id: '@id',
        item: '@item'
      },
      {
        save_profile: {
          method: 'PUT',
          params: {
            item: 'account-profile'
          }
        },
        delete_profile: {
          method: 'DELETE',
          params: {
            item: 'account-profile'
          }
        },
        get_profile: {
          method: 'GET',
          params: {
            item: 'settings'
          }
        }
      }
    );
  }
}
