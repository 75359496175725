import { Injectable } from '@angular/core';
// RxJS
import { Observable } from 'rxjs';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { Observer } from 'rxjs/internal/types';
// Services
import { AudiencesService } from './audiences.service';
import { MarketplaceService } from './marketplace.service';

@Injectable({
  providedIn: 'root'
})
export class UserMarketplaceService {
  constructor(private marketplaceService: MarketplaceService,
              private audiencesService: AudiencesService) {
  }

  public getAudienceList(): Promise<any> {
    return new Observable((observer: Observer<any>) => {
      forkJoin(
        this.marketplaceService.getAudienceList(),
        this.audiencesService.getAudienceList()
      ).subscribe((responses: any) => {
        observer.next(this.mergeAudiences(responses[0], responses[1]));
        observer.complete();
      });
    }).toPromise();
  }

  private mergeAudiences(marketplaces: any, audiences: any): any {
    const marketplaceList: any = [].concat(marketplaces);
    audiences.forEach((audience: any) => {
      for(let i: number = 0; i < marketplaceList.length; i++) {
        if(marketplaceList[i].segment_id === audience.segment_id) {
          return;
        }
      }
      audience.isAudience = true;
      marketplaceList.push(audience);
    });
    return marketplaceList;
  }
}
