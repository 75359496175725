import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// RxJS
import { forkJoin, Observable } from 'rxjs';
import { Observer } from 'rxjs/internal/types';
import { finalize } from 'rxjs/operators';
// Services
import { Classifier, Tag } from '../../../app-js/core/classes/classes';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class ClassifierService {
  private classifierUrl: string = '/api/v1/classifier/';
  private classifier_grid_data: any[] = [];
  private classifiers: any[] = [];
  private dataset_list: any[] = [];
  private tags: any[] = [];
  private isClassifierGridDataUpdated: boolean = false;
  private isDatasetListUpdated: boolean = false;

  constructor(private utilsService: UtilsService,
              private http: HttpClient) {
  }

  public getClassifiers(): Promise<any> {
    return new Observable((observer: Observer<any>) => {
      if(this.isClassifierGridDataUpdated) {
        observer.next(this.classifier_grid_data);
        observer.complete();
      } else {
        forkJoin(
          this.getClassifierTagRequest(),
          this.getClassifierRequest()
        ).subscribe((responses: any) => {
          this.isClassifierGridDataUpdated = true;
          this.classifier_grid_data.length = 0;
          this.tags = this.utilsService.constructTree(responses[0].data.map((tag: any) => {
            return new Tag(tag);
          }), 'id');
          this.classifiers = responses[1].data.map((classifier: any) => {
            return new Classifier(classifier);
          });

          this.tags.forEach((tag: any) => {
            this.classifier_grid_data.push(tag);
            this.classifiers.forEach((classifier: any) => {
              if(classifier.tag === tag.id) {
                classifier.$$treeLevel = tag.$$treeLevel + 1;
                this.classifier_grid_data.push(classifier);
              }
            });
          });
          observer.next(this.classifier_grid_data);
          observer.complete();
        }, () => {
          observer.next(this.classifier_grid_data);
          observer.complete();
        });
      }
    }).toPromise();
  }

  public getClassifierDetails(classifierId: any): Promise<any> {
    return new Observable((observer: Observer<any>) => {
      this.getClassifierRequest(classifierId).subscribe((response: any) => {
        observer.next(new Classifier(response.data));
        observer.complete();
      }, (error: any) => {
        observer.error(error);
        observer.complete();
      });
    }).toPromise();
  }

  public getDatasetList(): Promise<any> {
    return new Observable((observer: Observer<any>) => {
      // if(isDatasetListUpdated) {
      // 	resolve(dataset_list);
      // } else {
      this.getClassifierDatasetRequest().pipe(finalize(() => {
        observer.next(this.dataset_list);
        observer.complete();
      })).subscribe((response: any) => {
        this.dataset_list = response.data;
      });
      // }
    }).toPromise();
  }

  public getTagDetails(tagId: any): Promise<any> {
    return new Observable((observer: Observer<any>) => {
      this.getClassifierTagRequest(tagId).subscribe((response: any) => {
        observer.next(new Tag(response.data));
        observer.complete();
      }, (error: any) => {
        observer.error(error);
        observer.complete();
      });
    }).toPromise();
  }

  public addClassifier(classifier: any): void {
    if(!this.isClassifierGridDataUpdated || !(classifier instanceof Classifier)) {
      return;
    }
    classifier = new Classifier(classifier);
    for(let i: number = 0; i < this.classifier_grid_data.length; i++) {
      if((this.classifier_grid_data[i] instanceof Tag) && this.classifier_grid_data[i].id === classifier.tag) {
        classifier.$$treeLevel = this.classifier_grid_data[i].$$treeLevel + 1;
        this.classifier_grid_data.splice(i + 1, 0, classifier);
        this.classifiers.push(classifier);
        return;
      }
    }
  }

  public updateClassifier(classifier: any): void {
    if(!this.isClassifierGridDataUpdated || !(classifier instanceof Classifier)) {
      return;
    }
    const index: number = this.classifiers.findIndex((item) => item.id === classifier.id);
    (index === -1) || this.classifiers[index].copy(classifier);
  }

  private getClassifierTagRequest(id?: number): Observable<any> {
    const param: string = (id) ? `${id}/` : '';
    return this.http.get(`${this.classifierUrl}tag/${param}`);
  }

  private getClassifierRequest(id?: number): Observable<any> {
    const param: string = (id) ? `${id}/` : '';
    return this.http.get(`${this.classifierUrl}classifier/${param}`);
  }

  private getClassifierDatasetRequest(): Observable<any> {
    return this.http.get(`${this.classifierUrl}dataset/`);
  }

  // this.getTags = function () {
  // 	return $q(function(resolve) {
  // 		if(isTagsUpdated) {
  // 			resolve(tags);
  // 		} else {
  // 			this.classifierResource.tag().$promise.then((response: any) => {
  // 				isTagsUpdated = true;
  // 				tags.length = 0;
  // 				response.data = this.utilsService.constructTree(response.data, 'id');
  // 				response.data.forEach(function (tag) {
  // 					tags.push(tag);
  // 				});
  // 				resolve(tags);
  // 			}).catch(() => {
  // 				resolve(tags);
  // 			})
  // 		}
  // 	});
  // };

  // this.getPublicAudiences = function (resolve) {
  // 	if (isPublicUpdated) {
  // 		resolve(publicSegments)
  // 	} else {
  // 		MarketplaceResource.public(
  // 			function (response) {
  // 				isPublicUpdated = true;
  // 				publicSegments.length = 0;
  // 				response.data.forEach(function (segment) {
  // 					publicSegments.push(segment);
  // 				});
  // 				resolve(publicSegments);
  // 			},
  // 			function (error) {
  // 				resolve(publicSegments);
  // 			});
  // 	}
  // };
  //
  // this.getAudience = function (id) {
  // 	if (!isUpdated) {
  // 		console.warn('Marketplace data not updated');
  // 	}
  // 	for (var i = 0; i < audiences.length; i++) {
  // 		if (audiences[i].id == id) {
  // 			return audiences[i];
  // 		}
  // 	}
  // 	return {
  // 		segment_name: ""
  // 	}
  // };
  // var addAudienceLocal = function (audience) {
  // 	audience.parent = (audience.parents && audience.parents[0]) ? audience.parents[0] : null;
  // 	!audience.type && (audience.type = 'normal');
  // 	audience.$$treeLevel = 0;
  // 	if (!audience.parent) {
  // 		audiences.unshift(audience);
  // 	} else {
  // 		for (var i = 0; i < audiences.length; i++) {
  // 			if (audiences[i].segment_id == audience.parent) {
  // 				audience.$$treeLevel = audiences[i].$$treeLevel + 1;
  // 				audiences.splice(i + 1, 0, audience);
  // 				return
  // 			}
  // 		}
  // 		audiences.unshift(audience);
  // 	}
  // };
  //
  // this.addAudience = function (audience) {
  // 	addAudienceLocal(audience);
  // 	$sharedTabsWorker.send({audience: audience}, 'audiences.add');
  // };
  //
  // var addAudienceEvent = new $sharedTabsWorker.SharedTabsEvent('audiences.add', function (e) {
  // 	addAudienceLocal(e.data.msg.audience);
  // });
  //
  // $sharedTabsWorker.addEvent(addAudienceEvent);
  //
  //
  // var editAudienceLocal = function (segmentId, segment_name, lookalike_percent) {
  // 	var segment = self.getAudience(segmentId);
  // 	segment.segment_name = segment_name;
  // 	segment.lookalike_percent = lookalike_percent;
  // 	(segment.type == 'look-alike') && (segment.pixel_pid = segment.lookalike_percent + '% lookalike');
  // };
  //
  // this.editAudience = function (segmentId, segment_name, lookalike_percent) {
  // 	editAudienceLocal(segmentId, segment_name, lookalike_percent);
  // 	$sharedTabsWorker.send({
  // 		segmentId: segmentId,
  // 		segment_name: segment_name,
  // 		lookalike_percent: lookalike_percent
  // 	}, 'audiences.edit');
  // };
  //
  // var editAudienceEvent = new $sharedTabsWorker.SharedTabsEvent('audiences.edit', function (e) {
  // 	editAudienceLocal(e.data.msg.segmentId, e.data.msg.segment_name, e.data.msg.lookalike_percent);
  // });
  //
  // $sharedTabsWorker.addEvent(editAudienceEvent);
  //
  //
  // var updateParentLocal = function (id, parents) {
  // 	var segment = self.getAudience(id);
  // 	segment.parents = parents;
  // 	segment.parent = (parents[0] || null);
  // 	self.updateTree();
  // 	$rootScope.$broadcast(WORKER_EVENTS.updateTreeAudiences);
  // };
  //
  // this.updateParent = function (id, parents) {
  // 	updateParentLocal(id, parents);
  // 	AudiencesResource.patch({
  // 		id: id,
  // 		parents: parents
  // 	});
  // 	$sharedTabsWorker.send({
  // 		id: id,
  // 		parents: parents
  // 	}, 'audiences.update_parent');
  // };
  //
  // var updateAudienceParentEvent = new $sharedTabsWorker.SharedTabsEvent('audiences.update_parent', function (e) {
  // 	updateParentLocal(e.data.msg.id, e.data.msg.parents);
  // });
  //
  // $sharedTabsWorker.addEvent(updateAudienceParentEvent);
  //
  // this.updateTree = function () {
  // 	var newTree = constructTree(audiences);
  // 	audiences.length = 0;
  // 	newTree.forEach(function (segment) {
  // 		audiences.push(segment);
  // 	});
  // };
  //
  // this.update = function () {
  // 	isUpdated = false;
  // 	self.getAudienceList(function () {
  // 	});
  // };
  //
  // this.isParentOf = function (child, parent) {
  // 	var start = null;
  // 	for (var i = 0; (i < audiences.length); i++) {
  // 		if (start !== null) {
  // 			if (audiences[i].$$treeLevel > audiences[start].$$treeLevel) {
  // 				if (audiences[i].segment_id == child.segment_id) {
  // 					return true;
  // 				}
  // 			} else {
  // 				break;
  // 			}
  // 		} else if (parent.segment_id == audiences[i].segment_id) {
  // 			start = i;
  // 		}
  // 	}
  // 	return false;
  // };
  //
  // this.notUpdated = function () {
  // 	isUpdated = false;
  // };
}
