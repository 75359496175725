import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// RxJS
import { Observable } from 'rxjs';
import { Observer } from 'rxjs/internal/types';
import { map } from 'rxjs/operators';
// Services
import { ConstService } from '../../shared/const.service';
import { UtilsService } from './utils.service';
import { NotifyService } from '../../shared/notify.service';
// Types
import { DjangoResponse } from '../../shared/types/django-response.type';
import { ConstructorSendDataRule } from '../../../app-js/audiences/shared/constructor.type';
import { CapacityResponse } from '../../../app-js/audiences/shared/response.type';

@Injectable({
  providedIn: 'root'
})
export class MarketplaceService {

  private marketplaceUrl: string = '/api/v1/marketplace/';
  private affinityUrl: string = '/api/v1/user/affinity/';
  private journeyUrl: string = '/api/v1/user/journey/';
  private marketplace: any[] = [];
  private isUpdated: boolean = false;

  constructor(private notifyService: NotifyService,
              private utilsService: UtilsService,
              private http: HttpClient) {
    this.notifyService.notification$.subscribe((message: string) => {
      switch(message) {
        case ConstService.AUTH_EVENTS.impersonated:
          this.update();
          break;
        case ConstService.AUTH_EVENTS.login:
          this.update();
          break;
        case ConstService.AUTH_EVENTS.logout:
          this.notUpdated();
          break;
        case ConstService.AUTH_EVENTS.changeAccount:
          break;
      }
    });
  }


  public getAudienceList(): Promise<any> {
    return new Observable((observer: Observer<any>) => {
      if(this.isUpdated) {
        observer.next(this.marketplace);
        observer.complete();
      } else {
        this.getMarketplaceRequest().subscribe((response: any) => {
          this.isUpdated = true;
          this.marketplace.length = 0;
          response.data.forEach((segment: any) => {
            segment.parent = segment.parents[0] ? segment.parents[0] : null;
            if(!segment.type) { (segment.type = 'normal'); }
            if(segment.type === 'look-alike') { (segment.pixel_pid = segment.lookalike_percent + '% lookalike'); }
          });
          response.data = this.utilsService.constructTree(response.data);
          response.data.forEach((segment: any) => {
            this.marketplace.push(segment);
          });
          observer.next(this.marketplace);
          observer.complete();
        }, (error: any) => {
          if(error.status_code === 403) {
            this.isUpdated = true;
            this.marketplace.length = 0;
          }
          observer.next(this.marketplace);
          observer.complete();
        });
      }
    }).toPromise();
  }

  public getPublicMarketplace(): Promise<any> {
    return new Observable((observer: Observer<any>) => {
      this.getPublicMarketplaceRequest({is_public: true}).subscribe((response: any) => {
        response.data.forEach((segment: any) => {
          segment.parent = segment.parents[0] ? segment.parents[0] : null;
          if(!segment.type) { (segment.type = 'normal'); }
          if(segment.type === 'look-alike') { (segment.pixel_pid = segment.lookalike_percent + '% lookalike'); }
        });
        response.data = this.utilsService.constructTree(response.data);
        observer.next(response.data);
        observer.complete();
      }, () => {
        observer.next([]);
        observer.complete();
      });
    }).toPromise();
  }

  public getAudience(id: any): any {
    if(!this.isUpdated) {
      console.warn('Marketplace data not updated');
    }
    for(let i: number = 0; i < this.marketplace.length; i++) {
      if(this.marketplace[i].id === id) {
        return this.marketplace[i];
      }
    }
    return {
      segment_name: ''
    };
  }

  public getAudienceName(id: any): string {
    for(let i: number = 0; i < this.marketplace.length; i++) {
      if(this.marketplace[i].id === id) {
        return this.marketplace[i].segment_name;
      }
    }
    return '';
  }

  // public updateTree() {
  //   const newTree = this.utilsService.constructTree(this.marketplace);
  //   this.marketplace.length = 0;
  //   newTree.forEach((segment) => {
  //     this.marketplace.push(segment);
  //   });
  // }

  public update(): void {
    this.isUpdated = false;
    this.getAudienceList();
  }

  public notUpdated(): void {
    this.isUpdated = false;
  }

  public getAudienceById(id: number): Observable<any> {
    return this.http.get(`${this.marketplaceUrl}${id}/`)
      .pipe(map((response: DjangoResponse<any>) =>  response.data));
  }

  public getTopCities(id: number): Observable<any> {
    return this.http.get(`${this.marketplaceUrl}${id}/top_cities/`)
      .pipe(map((response: DjangoResponse<any>) =>  response.data));
  }

  public getAnalytics(id: number): Observable<any> {
    return this.http.get(`${this.marketplaceUrl}${id}/analytics/`)
      .pipe(map((response: DjangoResponse<any>) =>  response.data));
  }

  public getIntersections(id: number, limit: string): Observable<any> {
    return this.http.get(`${this.marketplaceUrl}${id}/intersections/`, {params: {limit: limit}})
      .pipe(map((response: DjangoResponse<any>) =>  response.data));
  }

  public getConstructorCapacity(params: ConstructorSendDataRule): Observable<CapacityResponse> {
    return this.http.post(`${this.marketplaceUrl}constructor_capacity/`, params)
      .pipe(map((response: DjangoResponse<CapacityResponse>) =>  response.data));
  }

  public getAffinityGetProfile(id: number): Observable<any> {
    return this.http.get(`${this.affinityUrl}settings/${id}/`)
      .pipe(map((response: DjangoResponse<any>) =>  response.data));
  }

  public getAffinitySaveProfile(params: any): Observable<any> {
    return this.http.put(`${this.affinityUrl}account-profile/${params.id}/`, params)
      .pipe(map((response: DjangoResponse<any>) =>  response.data));
  }

  public getAffinityDeleteProfile(id: number): Observable<any> {
    return this.http.delete(`${this.affinityUrl}account-profile/${id}/`);
  }

  public getUserJourneyGetProfile(): Observable<any> {
    return this.http.get(`${this.journeyUrl}settings/`)
      .pipe(map((response: DjangoResponse<any>) =>  response.data));
  }

  public getUserJourneySaveProfile(params: any): Observable<any> {
    return this.http.put(`${this.journeyUrl}account-profile/`, params)
      .pipe(map((response: DjangoResponse<any>) =>  response.data));
  }

  public getUserJourneyDeleteProfile(id: number): Observable<any> {
    return this.http.delete(`${this.journeyUrl}account-profile/${id}/`);
  }

  private getMarketplaceRequest(): Observable<any> {
    return this.http.get(this.marketplaceUrl);
  }

  private getPublicMarketplaceRequest(params: any): Observable<any> {
    return this.http.get(`${this.marketplaceUrl}`, {params: params});
  }
}
