import { StateProvider, UrlRulesApi } from '@uirouter/angularjs';
import { NgModule } from 'angular-ts-decorators';
import { audisenceRouting } from '../audiences/audience.routes';
import { BillingComponent } from './billing.component';
import { billingRouting } from './billing.routes';

@NgModule({
  id: 'BillingModule',
  declarations: [
    BillingComponent
  ]
})
export class BillingModule {
  static config = config;
}

function config($stateProvider: StateProvider) {
  billingRouting($stateProvider);
}

config.$inject = ['$stateProvider'];
