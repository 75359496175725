import { Component, Input, OnChanges } from 'angular-ts-decorators';
import * as d3 from 'd3';

@Component({
  selector: 'svgSparkChart',
  template: '<div></div>'
})
export class SvgSparkChartDirective implements OnChanges {

  static $inject: string[] = [
    '$element'
  ];
  @Input() history: any;

  private width = 180;
  private height = 20;

  constructor(private $element: any) {
  }

  ngOnChanges(changes: any) {
    this.update();
  }

  private update() {
    d3.select(this.$element[0]).selectAll('*').remove();
    let x = d3.scale.linear().range([0, this.width]);
    let y = d3.scale.linear().range([this.height, 0]);
    let line = d3.svg.line()
      .x(function(d: any) {
        return x(d.date);
      })
      .y(function(d: any) {
        return y(d.close);
      });

    this.history.forEach(function(d: any) {
      d.date = d.dt;
      d.close = +d.cnt;
    });
    x.domain(d3.extent(this.history, function(d: any) {
      return d.date;
    }));
    y.domain(d3.extent(this.history, function(d: any) {
      return d.close;
    }));

    d3.select(this.$element[0])
      .append('svg')
      .attr('width', this.width)
      .attr('height', this.height)
      .append('path')
      .datum(this.history)
      .attr('class', 'sparkline')
      .attr('fill', 'none')
      .attr('stroke-width', 1)
      .attr('stroke', 'black')
      .attr('d', line);
  }

}
