import * as angular from 'angular';
import { Component, OnInit } from 'angular-ts-decorators';
import { ConstService } from '../../app/shared/const.service';

@Component({
  selector: 'billingComponent',
  templateUrl: './billing.pug'
})
export class BillingComponent implements OnInit {

  static $inject: string[] = [
    '$scope',
    '$filter',
    'collectionsResource',
    'billingService'
  ];
  // data
  public platformList: any[] = [];
  public chartData: any;
  public pixelData: any = {};
  public gridApi: any;
  // configs
  public chartConfig: any;
  public gridConfig: any;
  public multiSelectConfig = {
    externalIdProp: ''
  };
  public multiselectEvents = {
    onToggle: (toggle: any) => {
      if(!toggle) {
        this.getData();
      }
    }
  };
  // constants
  public periodList = ['Quarter', 'Half Year', 'This Year', 'Year To Date'];
  public dataFieldsList = ['Earned', 'Impressions', 'Clicks', 'CTR', 'Not selected'];
  public nowDate = new Date();
  public previousMonth = new Date(this.nowDate.getFullYear(), this.nowDate.getMonth() - 1, 1);
  // parameters
  public selectedPlatforms: any[] = [];
  public selectedPeriod = '';
  public dateFrom = this.previousMonth;
  public dateTo = this.previousMonth;
  public dateMin: any = 0;
  public dateFromList: any[] = [];
  public dateToList: any[] = [];
  public totalEarned = 0;
  public totalImpressions = 0;
  public totalClicks = 0;
  public totalCTR = 'n/a';
  public filterValue = '';
  public dataLoading = false;
  public chartParameters = [this.dataFieldsList[0], this.dataFieldsList[1]];

  constructor(private $scope: any,
              private $filter: any,
              private collectionsResource: any,
              private billingService: any) {

  }

  ngOnInit(): void {
    this.chartData = this.billingService.getChartData(null);
    this.chartConfig = this.billingService.getChartConfig();

    const gridFilter = (renderableRows: any) => {
      const matcher = new RegExp(this.filterValue.toLowerCase());
      renderableRows.forEach((row: any) => {
        let match = false;
        ['segment_name', 'segment_id', 'description'].forEach((field: any) => {
          if(row.entity[field].toString().toLowerCase().match(matcher)) {
            match = true;
          }
        });
        if(!match) {
          row.visible = false;
        }
      });
      return renderableRows;
    };

    this.gridConfig = {
      data: this.billingService.getGridData(),
      enableFiltering: false,
      enableRowSelection: true,
      enableSelectAll: true,
      enableColumnMenus: false,
      showGridFooter: true,

      // showTreeExpandNoChildren: false,
      // useUiGridDraggableRowsHandle: true,

      // rowTemplate: '<div grid="grid" class="ui-grid-draggable-row" draggable="true"><div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ng-style="col.colDef.name == \'pid\' && {\'padding-left\': grid.options.treeIndent * row.treeLevel + \'px\'}" ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader, \'custom\': true }" ui-grid-cell></div></div>',

      exporterCsvFilename: 'my_billing.csv',
      exporterCsvLinkElement: angular.element(document.querySelectorAll('.custom-csv-link-location')),
      exporterCsvColumnSeparator: ';',
      exporterFieldCallback: function(grid: any, row: any, col: any, value: any) {
        if(col.colDef.type === 'number') {
          value = value.toString().replace('.', ',');
        }
        return value;
      },

      exporterPdfDefaultStyle: {fontSize: 9},
      exporterPdfTableStyle: {margin: [30, 20, 30, 20]},
      exporterPdfTableHeaderStyle: {fontSize: 10, bold: true, italics: true, color: 'red'},
      exporterPdfHeader: {text: '', style: 'headerStyle'},
      exporterPdfFooter: {text: '', style: 'footerStyle'},
      exporterPdfCustomFormatter: function(docDefinition: any) {
        docDefinition.styles.headerStyle = {fontSize: 22, bold: true};
        docDefinition.styles.footerStyle = {fontSize: 10, bold: true};
        docDefinition.content[0].table.widths = ['*', '*', 'auto', 'auto', 'auto', 'auto'];

        return docDefinition;
      },
      exporterPdfOrientation: 'landscape',
      exporterPdfPageSize: 'LETTER',
      exporterPdfMaxGridWidth: 600,

      onRegisterApi: (gridApi: any) => {
        this.gridApi = gridApi;
        this.gridApi.grid.registerRowsProcessor(gridFilter, 200);
        // this.gridApi.draggableRows.on.rowDropped(this.$scope, function (info, dropTarget) {
        //
        // 	this.gridApi.treeBase.toggleRowTreeState(this.gridApi.grid.renderContainers.body.visibleRowCache[info.toIndex - 1]);
        // 	this.gridApi.treeBase.toggleRowTreeState(this.gridApi.grid.renderContainers.body.visibleRowCache[info.toIndex - 1]);
        //
        // });
      },

      columnDefs: [
        {
          field: 'segment_id',
          displayName: 'ID',
          type: 'text',
          width: 120
        },
        {
          field: 'segment_name',
          displayName: 'Name',
          headerCellFilter: 'translate',
          width: '*'
        },
        {
          field: 'description',
          headerCellFilter: 'translate',
          displayName: 'Description'
        },
        {
          field: 'earned',
          type: 'number',
          displayName: 'Earned',
          cellClass: 'col_earned to-right',
          cellFilter: 'number:0',
          headerCellFilter: 'translate',
          enableColumnResizing: false,
          width: 90
        },
        {
          field: 'impressions',
          type: 'number',
          displayName: 'Impressions',
          cellClass: 'to-right',
          cellFilter: 'number:0',
          headerCellFilter: 'translate',
          enableColumnResizing: false,
          width: 110
        },
        {
          field: 'clicks',
          type: 'number',
          displayName: 'Clicks',
          cellClass: 'to-right',
          headerCellFilter: 'translate',
          // cellFilter: "awnum:3:',':'round':0:true:' '",
          enableColumnResizing: false,
          width: 90
        },
        {
          field: 'ctr',
          type: 'number',
          displayName: 'CTR %',
          cellClass: 'to-right',
          headerCellFilter: 'translate',
          enableColumnResizing: false,
          width: 60
        }
      ]
    };

    this.$scope.$on(ConstService.AUTH_EVENTS.impersonated, () => {
      this.billingService.notUpdated();
      this.getData();
    });

    this.collectionsResource.platforms({type: 'billing'}).$promise
      .then((response: any) => {
        const data = response.data;
        this.selectedPlatforms = [];
        data.forEach((platform: any, index: any) => {
          this.platformList.push({id: index, label: platform});
          this.selectedPlatforms.push({id: index, label: platform});
          // this.getRates();
        });
        return this.collectionsResource.rates().$promise;
      })
      .then((response: any) => {
        const data = response.data;
        const currencyRates: any = {};
        data.forEach((rate: any) => {
          currencyRates[rate.date] = {};
          currencyRates[rate.date][rate.rate_currency] = rate.rate_amount;
          if((this.dateMin === 0) || (this.dateMin > new Date(rate.date).getTime())) {
            this.dateMin = new Date(rate.date);
          }
        });
        this.billingService.setCurrencyRate(currencyRates);
        this.getListDateFrom();
        this.selectPeriod('Year To Date');
      })
      .catch((error: any) => {
      });

    this.getListDateFrom();
    this.getListDateTo();
  }

  public getSelectedPlatformsList() {
    const partnersList: any[] = [];
    this.selectedPlatforms.forEach((item: any) => {
      partnersList.push(item.label);
    });
    return partnersList;
  }

  public getData() {
    this.dataLoading = true;
    this.billingService.getBillingData(this.dateFrom, this.dateTo, this.getSelectedPlatformsList())
      .then(() => {
        this.billingService.getGridData();
        this.billingService.getChartData(null);
        this.updateChartParameters();
        this.selectChartParameter(this.chartParameters[0], 0);
        this.selectChartParameter(this.chartParameters[1], 1);
        this.dataLoading = false;
      })
      .catch(() => {
        this.dataLoading = false;
      });
  }

  public updateChartData() {
    const selectedSegmentsId: any[] = [];
    this.gridApi.selection.getSelectedRows().forEach((row: any) => {
      selectedSegmentsId.push(row.segment_id);
    });
    this.billingService.getChartData(selectedSegmentsId);
    this.selectChartParameter(this.chartParameters[0], 0);
    this.selectChartParameter(this.chartParameters[1], 1);
  }

  public selectChartParameter(parameter: any, parameterNumber: any) {
    if(parameter === 'Not selected') {
      this.chartConfig.yAxis[parameterNumber].visible = false;
      this.chartConfig.series.pop();
      return;
    }
    this.chartParameters[parameterNumber] = parameter;
    this.chartConfig.yAxis[parameterNumber] = {
      labels: {
        format: this.chartData[parameter].format
      },
      title: {
        text: this.chartData[parameter].text
      },
      opposite: !!parameterNumber,
      visible: true
    };
    const series: any = {
      name: this.chartData[parameter].name,
      type: 'column',
      yAxis: parameterNumber,
      data: this.chartData[parameter].data,
      tooltip: {
        valueSuffix: this.chartData[parameter].suffix,
        valuePrefix: this.chartData[parameter].prefix
      },
      color: this.chartData[parameter].color
    };
    if(this.chartConfig.series.length < (parameterNumber + 1)) {
      this.chartConfig.series.push(series);
    } else {
      this.chartConfig.series[parameterNumber] = series;
    }
  }

  public getListDateFrom() {
    this.dateFromList = [];
    for(let i = 0; i < 12; i++) {
      let newDate: any = new Date(this.previousMonth.getFullYear(), this.previousMonth.getMonth() - i, 1);
      if((newDate <= this.dateTo) && ((newDate - this.dateMin) > -8.64e+7)) {
        this.dateFromList.push(newDate);
      }
    }
  }

  public getListDateTo() {
    this.dateToList = [];
    for(let i = 0; i < 12; i++) {
      const newDate = new Date(this.dateFrom.getFullYear(), this.dateFrom.getMonth() - i + 11, 1);
      if(newDate <= this.previousMonth) {
        this.dateToList.push(newDate);
      }
    }
  }

  public selectDateFrom(date: any) {
    this.dateFrom = date;
    this.selectedPeriod = '';
    this.getListDateTo();
    this.getData();
  }

  public selectDateTo(date: any) {
    this.dateTo = date;
    this.selectedPeriod = '';
    this.getListDateFrom();
    this.getData();
  }

  public selectPeriod(period: any) {
    this.selectedPeriod = period;
    switch(period) {
      case 'Quarter':
        this.dateFrom = new Date(this.previousMonth.getFullYear(), this.previousMonth.getMonth() - 2, 1);
        this.dateTo = this.previousMonth;
        break;
      case 'Half Year':
        this.dateFrom = new Date(this.previousMonth.getFullYear(), this.previousMonth.getMonth() - 5, 1);
        this.dateTo = this.previousMonth;
        break;
      case 'This Year':
        this.dateFrom = new Date(this.previousMonth.getFullYear(), 0, 1);
        this.dateTo = this.previousMonth;
        break;
      case 'Year To Date':
        this.dateFrom = new Date(this.previousMonth.getFullYear(), this.previousMonth.getMonth() - 11, 1);
        this.dateTo = this.previousMonth;
        break;
      default:
        this.selectedPeriod = '';
    }
    if(this.dateFrom < this.dateMin) {
      this.dateFrom = this.dateMin;
    }
    this.getListDateFrom();
    this.getListDateTo();
    this.getData();
  }

  public exportCSV() {
    this.gridConfig.exporterCsvFilename = 'billing ' + this.getSelectedPlatformsList()
      .toString() + ' from ' + this.$filter('date')(this.dateFrom, 'dd-MM-yy') + ' to ' + this.$filter('date')(this.dateTo, 'dd-MM-yy');
    this.gridApi.exporter.csvExport('all', 'all');
  }

  public exportPDF() {
    this.gridConfig.exporterPdfHeader.text = 'Partners: ' + this.getSelectedPlatformsList().toString();
    this.gridConfig.exporterPdfFooter.text = 'From ' + this.dateFrom.toDateString() + ' to ' + this.dateTo.toDateString();
    this.gridApi.exporter.pdfExport('all', 'all');
  }


  public filterRun() {
    this.gridApi.grid.refresh();
  }

  private updateChartParameters() {
    if(this.chartData.totalClicks === 'n/a') {
      this.dataFieldsList = ['Earned', 'Impressions', 'Not selected'];
      if(this.chartParameters[0] === 'Clicks' || this.chartParameters[0] === 'CTR') {
        this.chartParameters[0] = 'Earned';
      }
      if(this.chartParameters[1] === 'Clicks' || this.chartParameters[1] === 'CTR') {
        this.chartParameters[1] = 'Not selected';
      }
    } else {
      this.dataFieldsList = ['Earned', 'Impressions', 'Clicks', 'CTR', 'Not selected'];
    }
  }
}
