import { Component, Input, OnInit } from 'angular-ts-decorators';
import {Router} from '@angular/router';

@Component({
  selector: 'modalShareComponent',
  templateUrl: './audience-share.modal.pug'
})
export class ModalShareComponent implements OnInit {

  static $inject: string[] = [
    'externalsResource',
    'sharingService',
    'authService',
    '$scope',
    'router',
    '$q'
  ];
  @Input() modalInstance: any;
  @Input() resolve: any;
  public modalTitle: string;
  public segmentList: any;
  public selectedPlatform: any = {
    name: '',
    products: []
  };
  public platformUrl: any = {
    name: 'url',
    logo: 'public/img/url_text.png'
  };
  public sharing: any = {
    cost: 0,
    externalId: '',
    product: '',
    url: '',
    currency: ''
  };
  public submitDisable = false;
  public activeForm: any = {};
  public message: any;
  public errorMessageList: any = [];
  public successMessageList: any = [];
  public platformList = this.sharingService.platforms();
  public textLoc: any = {};
  public isUnlimTariff = true;
  public slideToShow = 5;

  private prices: any[] = this.authService.getSelectedAccount().prices;

  constructor(private externalsResource: any,
              private sharingService: any,
              private authService: any,
              private $scope: any,
              private router: Router,
              private $q: any) {

  }

  ngOnInit(): void {
    this.segmentList = this.resolve.segments;
    this.authService.text$.subscribe((text) => {
      this.textLoc = text;
      this.modalTitle = (this.segmentList.length > 1) ? this.textLoc.shareMultipleSegments : `${this.textLoc.share} '${this.segmentList[0].segment_name}' ${this.textLoc.segment}`;
    });

    if (this.resolve.tariff.type !== 'unlimited') {
      this.slideToShow = 3;
      this.isUnlimTariff = false;
      this.platformList = this.resolve.platforms;
    }
  }

  public cancel() {
    this.modalInstance.dismiss('cancel');
  }

  public selectProduct(product: any) {
    if(product.name === 'Google Reseller') {
      this.sharing.externalId = 1169866720;
    } else {
      this.sharing.externalId = '';
    }

    this.sharing.cost = 0;
    this.sharing.product = product;
  }

  public getCurrency() {
    for(let i = 0; i < this.selectedPlatform.products.length; i++) {
      if(this.sharing.product.name === this.selectedPlatform.products[i].name) {
        return this.selectedPlatform.products[i].currency;
      }
    }
    return '-';
  }

  public addShare() {
    this.errorMessageList.length = 0;
    this.successMessageList.length = 0;
    this.submitDisable = true;

    const requestList: any = this.segmentList.map((segment) => {
      return {
        item: segment,
        isSubmit: false,
        isError: false,
        response: null
      };
    });

    requestList.forEach((request) => {
      const dataShare: any = this.mapShareDate(request.item);
      this.sharingService.saveSharing(dataShare).subscribe((response) => {
        request.isSubmit = true;
        request.response = request.item.segment_name + ' ' + this.textLoc.sharingIsAdded;
        this.sharingService.addSharing(response);
        this.checkRequestList(requestList);
      },(error: any) => {
        request.isSubmit = true;
        request.isError = true;

        let message: string = '';
        if(error.error.data && error.error.data.error_message) {
          for(const item in error.error.data.error_message) {
            if(error.error.data.error_message.hasOwnProperty(item)) {
              message += error.error.data.error_message[item][0] + ' ';
            }
          }
          if (message.length < 4 && error.error.data.error_message[0]) {
            message = error.error.data.error_message[0];
          }
          if (message.length < 4 && typeof error.error.data.error_message === 'string') {
            message = error.error.data.error_message;
          }
        }

        if(message === 'Object with system_name=' + this.sharing.product.system_name + ' does not exist. ') {
          message = 'Customer with name \'' + this.sharing.product.system_name + '\' does not exist.';
        }

        request.response = request.item.segment_name + ' ' + this.textLoc.sharingNotAddedWithError + ' ' + message;
        this.checkRequestList(requestList);
      });
    });
  }

  public selectPlatform(platform: any) {
    this.message = '';
    this.selectedPlatform = platform;
    if(['Aidata.me', 'url'].indexOf(platform.name) === -1) {
      this.selectProduct(platform.products[0]);
    } else {
      this.sharing.externalId = '';
      this.sharing.product = {};
    }
    this.$scope.$emit('Event', this.$scope.form);
  }

  public setActiveForm(form: any) {
    this.activeForm = form;
  }

  public getMinCost() {
    return (this.sharing.product && this.sharing.product.product_price && this.sharing.product.product_price[0])
      ? parseFloat(this.sharing.product.product_price[0].min_price)
      : 0;
  }

  public checkExactPrice() {
    const result: any = this.prices.find((price: any) => {
      return price.product_name === this.sharing.product.name && this.segmentList[0].type === price.segment_type;
    });
    if(!result) {
      return false;
    }
    this.sharing.cost = Number(result.exact_price);
    return true;
  }

  public getCostErrorText() {
    return this.textLoc.costValueMustBeMoreThanOrEqualTo + ' ' + this.getMinCost() + this.textLoc.lessThanOrEqual;
  }

  public navigate(): void {
    this.router.navigate(['/account']);
    this.modalInstance.dismiss('cancel');
  }

  private checkRequestList(list): void {
    if (!list.every(el => el.isSubmit)) {
      return;
    }

    if (list.some(el => el.isError)) {
      this.errorMessageList = list.filter(el => el.isError).map(el => el.response);
      this.successMessageList = list.filter(el => !el.isError).map(el => el.response);
    } else {
      this.modalInstance.close(true);
    }
  }

  private mapShareDate(segment: any): any {
    const dataShare: any = {
      segment_id: segment.segment_id,
      cost_amount: this.sharing.cost,
      customer_id: this.sharing.externalId
    };
    if(this.selectedPlatform.name === 'url') {
      dataShare.url = this.sharing.url;
    } else {
      dataShare.product_system_name = this.sharing.product.system_name;
    }
    if(this.selectedPlatform.name === 'Aidata.me') {
      dataShare.product_system_name = this.sharing.product.name;
      this.selectedPlatform.products.forEach((product: any) => {
        if(this.sharing.product.name === product.name) {
          dataShare.product_system_name = product.system_name;
        }
      });
    }
    return dataShare;
  }
}
