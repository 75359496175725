// Angular
import { Injectable } from '@angular/core';
// RxJs
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotifyService {

  private notification$$: ReplaySubject<string> = new ReplaySubject();
  public notification$: Observable<string> = this.notification$$.asObservable();

  public sendNotification(message: string): void {
    this.notification$$.next(message);
  }
}
