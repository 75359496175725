import { Component, Input, OnInit } from 'angular-ts-decorators';
import {AuthService} from '../../../app/core/services/auth.service';

@Component({
  selector: 'modalPixelCodeComponent',
  templateUrl: './pixel-code.modal.pug'
})
export class ModalPixelCodeComponent implements OnInit {

  @Input() modalInstance: any;
  @Input() resolve: any;

  static $inject: string[] = [
    'authService'
  ];

  public modalTitle = 'Use these pixel code snippets';
  public pixelCodeAdvertizing = '';
  public pixelCodeWebsite = '';
  public textLoc = {};

  constructor(private authService: AuthService) {
    this.authService.text$.subscribe((text) => {
      this.textLoc = text;
      this.modalTitle = text.useThesePixelCodeSnippets;
    });
  }

  public cancel() {
    return this.modalInstance.dismiss();
  }

  public ngOnInit(): void {
    this.pixelCodeAdvertizing = `<img src='//x01.aidata.io/0.gif?pid=${this.resolve.pixelId}' style='position: absolute; left: -9999px;' />`;
    this.pixelCodeWebsite =
      `<script type='text/javascript'>
  (function (window, document) {
    var elem = document.createElement('script');
    elem.src = '//x01.aidata.io/pixel.js?pixel=${this.resolve.pixelId}&v=\' + Date.now();
    elem.type='text/javascript';elem.async = true;
    var s = document.getElementsByTagName(\'script\')[0];
    s.parentNode.insertBefore(elem, s);
  })(window, window.document);
</script>`;
  }
}
