import { StateProvider } from '@uirouter/angularjs';
import { NgModule } from 'angular-ts-decorators';
import { pixelRouting } from '../pixels/pixel.routes';
import { ClassifierComponent } from './classifier/classifier.component';
import { DatasetComponent } from './classifier/dataset/dataset.component';
import { ClassifierEditComponent } from './classifier/edit/classifier-edit.component';
import { DomainsSearchComponent } from './domains/domains-search.component';
import { SegmentEditComponent } from './edit/segment-edit.component';
import { SegmentListComponent } from './list/segment-list.component';
import { segmentRouting } from './segment.routes';
import { SegmentStatsComponent } from './stats/segment-stats.component';

@NgModule({
  id: 'SegmentModule',
  declarations: [
    SegmentListComponent,
    SegmentStatsComponent,
    SegmentEditComponent,
    DomainsSearchComponent,
    ClassifierComponent,
    ClassifierEditComponent,
    DatasetComponent
  ]
})
export class SegmentModule {
  static config = config;
}

function config($stateProvider: StateProvider) {
  segmentRouting($stateProvider);
}

config.$inject = ['$stateProvider'];
