import { Component, Input, OnInit } from 'angular-ts-decorators';
import * as moment from 'moment';
import {AudiencesService} from '../../../app/core/services/audiences.service';
import {SharingService} from '../../../app/core/services/sharing.service';
import {forkJoin} from 'rxjs';
import {UtilsService} from '../../../app/core/services/utils.service';

@Component({
  selector: 'modalExportAnalyticsComponent',
  templateUrl: './analytics.modal.pug'
})
export class ModalExportAnalyticsComponent implements OnInit {
  static $inject: string[] = [
    '$scope',
    '$filter',
    '$uibModal',
    'audiencesService',
    'sharingService',
    'utilsService'
  ];

  @Input() modalInstance: any;
  @Input() resolve: any;

  public sharings: any = [];
  public audiences: any = [];
  public preloader: boolean = true;
  public datePicker: any = {
    date: {
      startDate: moment().subtract(1, 'months'),
      endDate: moment()
    },
    opts: {
      separator: '.'
    }
  };

  constructor(private $scope: any,
              private $filter: any,
              private $uibModal: any,
              private audiencesService: AudiencesService,
              private sharingService: SharingService,
              private utilsService: UtilsService) {
  }

  ngOnInit(): void {
    this.sharings = this.resolve.sharings;
    this.audiencesService.getAudienceList().then((list: any) => {
      this.preloader = false;
      this.audiences = list;
    });
  }

  public cancel(): void {
    this.modalInstance.dismiss('cancel');
  }

  public save(): void {
    if (this.preloader) {
      return;
    }
    this.preloader = true;
    const params: any = {
      date_from: this.datePicker.date.startDate.format('YYYY-MM-DD'),
      date_to: this.datePicker.date.endDate.format('YYYY-MM-DD'),
      ids: this.sharings.map((sharing) => sharing.id).join(',')
    };
    this.sharingService.getStats(params).subscribe((response) => {
      const header: any = [['Audience ID', 'Audience Name', 'Size', 'Status', 'Platform ID', 'Customer ID', 'Created', 'Date', 'Clicks', 'Impressions', 'CTR']];
      let CSVData: any = [];

      CSVData = CSVData.concat(response.data.map((item) => {
        const sharing: any = this.sharings.find((shar) => shar.id === item.sharing_id);
        const audience: any = this.audiences.find((segment) => segment.segment_id === sharing.segment_id);
        return [
          audience.segment_id,
          audience.segment_name,
          audience.size,
          sharing.is_active ? 'Active' : 'Inactive',
          sharing.product_name,
          sharing.customer_id,
          sharing.created_at,
          item.created_at,
          item.clicks,
          item.shows,
          Number(item.clicks / item.shows).toFixed(5)];
      }));

      this.utilsService.exportCSVTable(header.concat(CSVData), 'sharing_analytics', '');
      this.modalInstance.close(true);
    });
  }
}
