import { IHttpInterceptor, IPromise, IQService } from 'angular';
import { Injectable } from 'angular-ts-decorators';
import * as _ from 'lodash';

@Injectable('ConnectionInterceptor')
export class ConnectionInterceptor implements IHttpInterceptor {

  static factory = connectionFactory;
  public requestError = (rejection: any): IPromise<any> => {
    return this.$q.reject(rejection);
  };
  public responseError = (response: any): IPromise<any> => {
    return this.$q.reject(response);
  };

  constructor(private $q: IQService) {
  }

}

function connectionFactory($q: IQService): ConnectionInterceptor {
  return new ConnectionInterceptor($q);
}

connectionFactory.$inject = ['$q'];


/**
 * Authorisation error handling
 */
@Injectable('AuthInterceptor')
export class AuthInterceptor {

  static factory = authFactory;
  public responseError = (response: any): IPromise<any> => {
    if(response.status === 500) {
      response.data = {
        error_message: response.statusText
      };
    } else if(!response.data) {
      // this.$log.error(response);
    } else if(!response.data.error_message) {
      // this.$log.error(response);
      response.data.error_message = 'Sorry, some error occurred! Please, try again.';
    } else {
      switch(response.data.error_code) {
        case 'validation_error':
          let messages: any[] = [];
          if(Array.isArray(response.data.error_message)) {
            messages = response.data.error_message;
          } else {
            // todo: (prokopenko) 2 level deep
            _.forOwn(response.data.error_message, (data: any, key: any) => {
              if(typeof data[0] === 'string') {
                messages.push(data[0].replace('This', key));
              } else {
                _.forOwn(data[0], (data1: any, key1: any) => {
                  messages.push(data1[0].replace('This', key1));
                });
              }
            });
          }
          response.data.error_message = messages.join('\n');
          break;
        default:
        // this.$log.error(response);
      }
    }
    return this.$q.reject(response);
  }

  constructor(private $q: IQService, private $rootScope: any, private $log: any) {
  }
}

function authFactory($q: IQService, $rootScope: any, $log: any): AuthInterceptor {
  return new AuthInterceptor($q, $rootScope, $log);
}

authFactory.$inject = ['$q', '$rootScope', '$log'];
