import { Component, Input, OnInit } from 'angular-ts-decorators';
import { AudiencesService } from '../../../app/core/services/audiences.service';
import { PixelService } from '../../../app/core/services/pixel.service';
import { SharingService } from '../../../app/core/services/sharing.service';
import {AuthService} from '../../../app/core/services/auth.service';

@Component({
  selector: 'modalPixelDeleteComponent',
  templateUrl: './delete.modal.pug'
})
export class ModalPixelDeleteComponent implements OnInit {

  static $inject: string[] = [
    'sharingService',
    'pixelResource',
    '$q',
    'audiencesService',
    'pixelService',
    'audiencesResource',
    'authService'
  ];
  @Input() modalInstance: any;
  @Input() resolve: any;
  public sharingList: any[] = [];
  public constructorList: any[] = [];
  public lookalikeList: any[] = [];
  public segmentList: any[] = [];
  public name: string;
  public type: string;
  public itemData: any;
  public preloader = true;
  private service: any;
  public textLoc = {};

  constructor(private sharingService: SharingService,
              private pixelResource: any,
              private $q: any,
              private audiencesService: AudiencesService,
              private pixelService: PixelService,
              private audiencesResource: any,
              private authService: AuthService) {
    this.authService.text$.subscribe((text) => {
      this.textLoc = text;
    });
  }

  ngOnInit(): void {
    this.type = this.resolve.type;
    this.itemData = this.resolve.itemData;
    this.name = (this.type === 'pixel') ? this.itemData.pid : this.itemData.segment_name;
    this.service = (this.type === 'pixel') ? this.pixelService : this.audiencesService;

    this.service.getRelated(this.itemData.id).subscribe((response: any) => {
      this.sortResponseData(response);
      this.preloader = false;
    },() => {
      this.preloader = false;
    });

  }

  public cancel() {
    this.modalInstance.close();
  }

  public deletePixel() {
    this.preloader = true;
    this.service.delete(this.itemData.id).subscribe(() => {
      this.updateData();
    });
  }

  private updateData() {
    if(this.lookalikeList.length) {
      this.lookalikeList.forEach((item: any) => {
        this.audiencesService.deleteAudience(item.id);
      });
    }

    if(this.segmentList.length) {
      this.segmentList.forEach((item: any) => {
        this.audiencesService.deleteAudience(item.id);
      });
    }

    if(this.sharingList.length) {
      this.sharingService.update();
    }

    if(this.type === 'pixel') {
      this.pixelService.deletePixel(this.itemData.id);
    } else {
      this.audiencesService.deleteAudience(this.itemData.id);
    }

    this.modalInstance.close(true);
  }

  private sortResponseData(data: any) {
    if(this.type === 'pixel') {
      data.forEach((item: any) => {
        this.segmentList.push(item);
        this.addDataInArrays(item);
      });
    } else {
      this.addDataInArrays(data);
    }
  }

  private addDataInArrays(data: any) {
    if(data.hasOwnProperty('children')) {
      data.children.forEach((item: any) => {
        this.lookalikeList.push(item);
      });
    }
    if(data.hasOwnProperty('constructor_as_rule')) {
      data.constructor_as_rule.forEach((item: any) => {
        this.constructorList.push(item);
      });
    }
    if(data.hasOwnProperty('sharings')) {
      data.sharings.forEach((item: any) => {
        this.sharingList.push(item);
      });
    }
  }
}
