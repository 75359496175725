import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { uiNotification } from 'angular';
// RxJS
import { Observable } from 'rxjs';
import { Observer } from 'rxjs/internal/types';
// Services
import { SharedTabsEvent, SharedWorkerService } from './shared-worker.service';
import { AuthService } from './auth.service';
import { NotifyService } from '../../shared/notify.service';
import { ConstService } from '../../shared/const.service';
import { UserMarketplaceService } from './user-marketplace.service';
import {map} from 'rxjs/operators';
import {DjangoResponse} from '../../shared/types/django-response.type';

@Injectable({
  providedIn: 'root'
})
export class SharingService {
  private externalsUrls: string = '/api/v1/partner/sharings/';
  private collectionsUrls: string = '/api/v1/collection/';

  private externalsAll: any[] = [];
  private externals: any[] = [];
  private isUpdated: boolean = false;
  private _platforms: any[] = [];
  private textLoc: any = {};

  constructor(private notifyService: NotifyService,
              private $sharedTabsWorker: SharedWorkerService,
              @Inject('Notification') private Notification: uiNotification.INotificationService,
              private authService: AuthService,
              private http: HttpClient
  ) {

    this.notifyService.notification$.subscribe((message: string) => {
      switch(message) {
        case ConstService.AUTH_EVENTS.impersonated:
          this.update();
          break;
        case ConstService.AUTH_EVENTS.login:
          this.update();
          break;
        case ConstService.AUTH_EVENTS.logout:
          this.notUpdated();
          break;
        case ConstService.AUTH_EVENTS.changeAccount:
          this.getExternalsRequest().subscribe((response: any) => {
            this.isUpdated = true;
            this.externals.length = 0;
            this.externalsAll.length = 0;
            response.data.forEach((item: any) => {
              this.externalsAll.push(item);
              this.externals.push(item);
            });
          });
          break;
      }
    });

    this.$sharedTabsWorker.addEvent(new SharedTabsEvent('sharing.add', (e: any) => {
      this.addSharingLocal(e.data.msg.sharing);
    }));

    this.$sharedTabsWorker.addEvent(new SharedTabsEvent('sharing.edit', (e: any) => {
      this.editSharingLocal(e.data.msg.id, e.data.msg.is_active);
    }));

    this.getPlatforms();

    this.authService.text$.subscribe((text) => {
      this.textLoc = text;
    });

  }

  public getSharings(): Promise<any> {
    return new Observable((observer: Observer<any>) => {
      if(this.isUpdated) {
        observer.next(this.externals);
        observer.complete();
      } else {
        this.getExternalsRequest().subscribe((response: any) => {
          this.isUpdated = true;
          this.externals.length = 0;
          this.externalsAll.length = 0;
          response.data.forEach((item: any) => {
            this.externalsAll.push(item);
            this.externals.push(item);
          });
          observer.next(this.externals);
          observer.complete();
        }, () => {
          observer.next(this.externals);
          observer.complete();
        });
      }
    }).toPromise();
  }

  public addSharing(sharing: any): void {
    this.addSharingLocal(sharing);
    this.$sharedTabsWorker.send({sharing: sharing}, 'sharing.add');
  }

  public editSharing(id: any, is_active: any): void {
    this.editSharingLocal(id, is_active);
    this.$sharedTabsWorker.send({id: id, is_active: is_active}, 'sharing.edit');
  }

  public update(): void {
    this.isUpdated = false;
    this.getSharings();
  }

  public saveSharing(params): Observable<any> {
    return this.http.post(this.externalsUrls, params)
      .pipe(map((response: DjangoResponse<any>) =>  response.data));
  }

  public platforms(): any {
    return this._platforms;
  }

  public notUpdated(): void {
    this.isUpdated = false;
  }

  public getStats(params: any): any {
    return this.http.get(`${this.externalsUrls}stats/`, {params});
  }

  public getSharingById(id: number): any {
    return this.http.get(`${this.externalsUrls}${id}/`);
  }

  public getPlatformList(): any {
    return this.http.get(`${this.collectionsUrls}platforms/`);
  }

  private addSharingLocal(sharing: any): void {
    if(this.isUpdated) {
      this.externalsAll.unshift(sharing);
      this.externals.unshift(sharing);
    }
  }

  private editSharingLocal(id: any, is_active: any): void {
    this.http.put(`${this.externalsUrls}${id}/`, {
      is_active: is_active
    }).subscribe( () => {
      is_active ? this.Notification.success(this.textLoc.sharingActivated) : this.Notification.success(this.textLoc.sharingDeactivated);
      if(this.isUpdated) {
        for(let i: number = 0; i < this.externals.length; i++) {
          if(this.externalsAll[i].id === id) {
            this.externalsAll[i].is_active = is_active;
            break;
          }
        }
      }
    });
  }

  private getPlatforms(): void {
    this.http.get(`${this.collectionsUrls}platforms/`).subscribe((response: any) => {
      this._platforms = response.data;
    });
  }

  private getExternalsRequest(): Observable<any> {
    return this.http.get(this.externalsUrls);
  }
}
