import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedWorkerService {

  /**
   * @description
   * Hold the worker url
   */
  private sharedWorkerUrl: string = '/public/workers/shared-worker.js';

  /**
   * @description
   * Hold the worker instance
   */
  private worker: any;

  /**
   * @description
   * Hold the connectionId for the single page app.
   * Each tab essentially is an instance of the whole single page app
   */
  private connectionId: any;

  /**
   * @description
   * This array holds all the events that need to be triggered when the worker
   * publishes a new message
   * @type {Array}
   */
  private events: any[] = [];

  private type: any;
  private action: any;

  constructor() {
    if(!this.hasWorker()) {
      this.start();
    }
  }

  /**
   * @description
   * Register an event callback
   */
  public addEvent(event: SharedTabsEvent): void {
    this.events.push(event);
  }

  /**
   * @description
   * Publish a message to the worker passing the actual message and its type
   */
  public send(message: any, type: any): void {
    if(this.hasWorker()) {
      this.worker.port.postMessage({
        port: this.connectionId,
        msg: message,
        type: type
      });
    }
  }

  /**
   * @description
   * The connection event will set the instance if the event is a type of instance
   */
  private connectionEvent(e: any, instance: any): void {
    if(e.data.type === 'CONNECTION') {
      this.worker = instance;
      this.connectionId = e.data.connectionId;
    }
  }

  /**
   * @description
   * Checks if there is a worker and a connection to it
   */
  private hasWorker(): boolean {
    return (this.worker && this.connectionId && this.connectionId > 0);
  }

  /**
   * @description
   * Helper method that filters the events by type.
   */
  private filterByType(type: any): any {
    return (event: any) => {
      return event.type === type;
    };
  }

  /**
   * @description
   * Helper method that trigers for a given even their action passing also to the action the event context
   */
  private triggerAction(e: any): any {
    return (event: any) => {
      event.action(e);
    };
  }

  /**
   * @description
   * Creates a shared worker
   */
  private createWorker(): void {
    const workerInstance: any = new (window as any).SharedWorker(this.sharedWorkerUrl, 'shared');
    workerInstance.port.onmessage = (e: any) => {
      this.connectionEvent(e, workerInstance);
      this.events
        .filter(this.filterByType(e.data.type))
        .forEach(this.triggerAction(e));
    };
  }

  /**
   * @description
   * Starts a worker and returns a promise
   */
  private start(): void {
    if(this.isSupported()) {
      try {
        this.createWorker();
      } catch(err) {
        console.log('worker start error');
        return;
      }
    }
  }

  /**
   * @description
   * Checks if the browser supports web workers and returns a promise
   */
  private isSupported(): any {
    return !!((<any>window).SharedWorker);
  }
}

export class SharedTabsEvent {
  constructor(public type: any, public action: any) {
    return;
  }
}
