import { IHttpService } from 'angular';
import * as angular from 'angular';
import { Component, OnInit } from 'angular-ts-decorators';
import * as Highcharts from 'highcharts';
import * as $ from 'jquery';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ConstService } from '../../../app/shared/const.service';

@Component({
  selector: 'campaignAnalyticsComponent',
  templateUrl: './campaign-analytics.pug'
})
export class CampaignAnalyticsComponent implements OnInit {

  static $inject: string[] = [
    '$stateParams',
    '$http',
    'uiGridConstants',
    '$scope'
  ];
  public preloader = true;
  public campaignId: any = this.$stateParams.campaignId;
  public analytics: any = null;
  public path = 'Branded segments » RGI » Alco_lovers';
  public campaign = {
    segment_name: 'Alco_lovers',
    segment_id: '40014314',
    size: 5881
  };
  public gridApi: any;
  public performanceSeriesList = [
    {
      name: 'Impressions',
      color: ConstService.COLORS.GREEN,
      format: '{value:,.0f}',
      text: 'Impressions',
      suffix: '',
      series: ['01.11.2016', '02.11.2016', '03.11.2016', '04.11.2016', '05.11.2016', '06.11.2016', '07.11.2016',
        '08.11.2016'],
      data: {
        'mail.ru': [2134, 4321, 543, 34245, 546, 2453, 435, 345],
        'Яндекс': [1234, 324, 435, 465, 675, 345, 647, 2315],
        'Google DoubleClick': [456, 4567, 235, 759, 1235, 769, 2315, 784]
      }
    },
    {
      name: 'Reach',
      format: '{value:,.0f}',
      text: 'Reach',
      suffix: '',
      color: ConstService.COLORS.BLUE,
      series: ['01.11.2016', '02.11.2016', '03.11.2016', '04.11.2016', '05.11.2016', '06.11.2016', '07.11.2016',
        '08.11.2016'],
      data: {
        'mail.ru': [21.34, 43.21, 54.3, 34.245, 54.6, 24.53, 43.5, 34.5],
        'Яндекс': [123.4, 32.4, 4.35, 46.5, 675, 34.5, 64.7, 231.5],
        'Google DoubleClick': [45.6, 45.67, 2.35, 75.9, 1.235, 76.9, 2.315, 78.4]
      }
    },
    {
      name: 'Freq',
      format: '{value:,.0f}',
      text: 'Freq',
      suffix: '',
      color: ConstService.COLORS.RED,
      series: ['01.11.2016', '02.11.2016', '03.11.2016', '04.11.2016', '05.11.2016', '06.11.2016', '07.11.2016',
        '08.11.2016'],
      data: {
        'mail.ru': [2.134, 432.1, 54.3, 34.245, 5.46, 24.53, 43.5, 34.5],
        'Яндекс': [12.34, 3.24, 4.35, 46.5, 67.5, 3.45, 6.47, 2.315],
        'Google DoubleClick': [45.6, 456.7, 235, 75.9, 12.35, 7.69, 2.315, 78.4]
      }
    }
  ];
  public performanceDataFieldsList = ['Impressions', 'Reach', 'Freq'];
  public ageCategories = ['18-24', '25-29', '30-34', '35-39', '40-44', '45-49', '50-54', '55-59', '60+'];
  public educationCategories = ['school', 'college', 'high', 'phd', 'doctoral'];
  public incomeCategories = ['micro', 'small', 'average', 'bigger', 'large'];
  public brandCategories = ['Heinz', 'Calve', 'Ricco', 'Балтимор', 'Слобода'];
  public salesUpliftCategories = ['Тестовая группа', 'Контрольная группа'];
  public benchmarksCategories = ['Heinz', 'Calve', 'Ricco', 'Балтимор', 'Слобода', 'Махеевъ', 'Моя семья', 'GV(CTM)', 'ЕЖК'];
  public performanceSeriesSelected: any = [null, null];
  public dataBasisDemographicList: any = [
    'Households seen ads',
    'All consumers of the brand',
    'Consumers all categories',
    'Active consumers of the brand',
    'Moderate consumers brand',
    'Inactive customers brand'
  ];
  public dataBasisDemographicSelected: any = this.dataBasisDemographicList[0];
  public benchmarksSeriesList: any = [
    {
      name: 'Доля бренда по объему покупки в натуральном выражении',
      householde: [10, 9, 8, 7, 6, 5, 4, 3, 2],
      test: [12, 11, 10, 9, 8, 7, 6, 5, 4]
    },
    {
      name: 'Доля бренда по объему покупки в денежном выражении',
      householde: [12, 11, 10, 9, 8, 7, 6, 5, 4],
      test: [10, 9, 8, 7, 6, 5, 4, 3, 2]
    },
    {
      name: 'Доля бренда по кол-ву домохозяйств сделавших хотя бы одну покупку',
      householde: [435, 49, 68, 73, 46, 65, 124, 553, 322],
      test: [125, 113, 101, 95, 84, 17, 62, 53, 423]
    },
    {
      name: 'Доля бренда по кол-ву домохозяйств сделавших больше одной покупки',
      householde: [10, 2349, 83, 37, 36, 55, 43, 31, 32],
      test: [125, 115, 105, 93, 81, 73, 26, 56, 44]
    },
    {
      name: 'По частоте покупок',
      householde: [510, 19, 38, 57, 66, 35, 54, 33, 62],
      test: [125, 151, 140, 19, 18, 74, 63, 54, 43]
    }
  ];
  public benchmarksSeriesSelected: any = null;
  public salesUpliftSeriesList: any = [
    {
      name: 'Средний объем покупки в натуральном выражении'
    },
    {
      name: 'Средний чек'
    },
    {
      name: 'Частота покупок'
    },
    {
      name: 'Проникновение'
    }
  ];
  public salesUpliftSeriesSelected: any = null;
  public datePickerSU: any = {
    date: {
      startDate: moment(this.performanceSeriesList[0].series.slice(0, 3).shift(), 'DD.MM.YYYY'),
      endDate: moment(this.performanceSeriesList[0].series.slice(0, 3).pop(), 'DD.MM.YYYY')
    },
    opts: {
      locale: {
        format: 'DD/MM/YYYY'
      },
      separator: '.',
      opens: 'left',
      eventHandlers: {
        'apply.daterangepicker': (ev: any, picker: any) => {
          this.getSalesUpliftSeries(this.salesUpliftSeriesList.filter((item: any) => {
            return !this.salesUpliftSeriesSelected || item.name === this.salesUpliftSeriesSelected;
          })[0]);
        }
      }
    },
    min: moment(this.performanceSeriesList[0].series[0], 'DD.MM.YYYY'),
    max: moment(this.performanceSeriesList[0].series.slice(-1).pop(), 'DD.MM.YYYY')
  };
  public datePickerSUcompare: any = {
    date: {
      startDate: moment(this.performanceSeriesList[0].series.slice(-3).shift(), 'DD.MM.YYYY'),
      endDate: moment(this.performanceSeriesList[0].series.slice(-3).pop(), 'DD.MM.YYYY')
    },
    opts: {
      locale: {
        format: 'DD/MM/YYYY'
      },
      separator: '.',
      opens: 'left',
      eventHandlers: {
        'apply.daterangepicker': (ev: any, picker: any) => {
          this.getSalesUpliftSeries(this.salesUpliftSeriesList.filter((item: any) => {
            return !this.salesUpliftSeriesSelected || item.name === this.salesUpliftSeriesSelected;
          })[0]);
        }
      }
    },
    min: moment(this.performanceSeriesList[0].series[0], 'DD.MM.YYYY'),
    max: moment(this.performanceSeriesList[0].series.slice(-1).pop(), 'DD.MM.YYYY')
  };
  public salesChannels: any = [
    'Hypermarkets',
    'Discounters',
    'Supermarkets',
    'Minimarkets',
    'Convenience stores',
    'Other channels'
  ];
  public Retailers: any = [
    'Ашан',
    'Пятерочка',
    'Дикси',
    'Азбука Вкуса'
  ];
  public distributionSeriesList: any = [
    {
      name: 'Sales Channels',
      data: this.salesChannels
    },
    {
      name: 'Retailers',
      data: this.Retailers
    }
  ];
  public selectedDistributionSeries: any = null;
  public monthes: any = [0, 1, 2, 3, 4, 5, 6];
  public retention: any = [{
    name: 'All coustomers',
    data: [78, 57, 46, 32, 22, 17, 13]
  }, {
    name: 'June \'16',
    data: [65, 50, 37, 27, 21, 16, 12]
  }, {
    name: 'July \'16',
    data: [59, 43, 28, 18, 12, 8]
  }, {
    name: 'August \'16',
    data: [76, 53, 40, 28, 21]
  }, {
    name: 'September \'16',
    data: [64, 45, 33, 23]
  }, {
    name: 'October \'16',
    data: [70, 52, 36]
  }, {
    name: 'November \'16',
    data: [62, 49]
  }
  ];
  public performanceChartConfig = this.getChartConfig({
    title: 'Performance',
    type: 'column',
    legend: true,
    categories: this.performanceSeriesList[0].series.map((date: any) => {
      return moment(date, 'DD.MM.YYYY').format('x');
    })
  });
  public genderChartConfig = this.getChartConfig({title: 'Gender', type: 'pie'});
  public ageFemaleChartConfig = this.getChartConfig({
    title: 'Females',
    type: 'bar',
    opposite: true,
    legend: false,
    categories: this.ageCategories,
    color: ConstService.COLORS.RED
  });
  public ageMaleChartConfig = this.getChartConfig({
    title: 'Males',
    type: 'bar',
    legend: false,
    categories: this.ageCategories,
    color: ConstService.COLORS.BLUE
  });
  public educationChartConfig = this.getChartConfig({
    title: 'Education',
    type: 'column',
    legend: false,
    categories: this.educationCategories,
    color: ConstService.COLORS.GREEN
  });
  public incomeChartConfig = this.getChartConfig({
    title: 'Income',
    type: 'column',
    legend: false,
    categories: this.incomeCategories,
    color: ConstService.COLORS.ORANGE
  });
  public benchmarksChartConfig = this.getChartConfig({
    title: 'Benchmarks',
    type: 'column',
    legend: true,
    categories: this.benchmarksCategories
  });
  public salesUpliftCategoryChartConfig = this.getChartConfig({
    title: 'Категория - Кетчуп',
    type: 'column',
    legend: false,
    categories: this.salesUpliftCategories
  });
  public salesUpliftBrandChartConfig = this.getChartConfig({
    title: 'Бренд - Heinz',
    type: 'column',
    legend: false,
    categories: this.salesUpliftCategories
  });
  public distributionChartConfig = this.getChartConfig({
    title: 'Distribution',
    type: 'column',
    legend: true,
    categories: this.brandCategories,
    color: ConstService.COLORS.RED
  });
  public performanceGridConfig = {
    data: this.getPerformanceGridData(null, null),
    enableFiltering: false,
    enableRowSelection: true,
    enableSelectAll: true,
    enableColumnMenus: false,
    showGridFooter: false,
    showColumnFooter: true,


    columnDefs: [
      {
        field: 'name',
        displayName: 'Ad Network',
        headerCellFilter: 'translate',
        type: 'text',
        width: '*'
      },
      {
        field: 'hit_light',
        displayName: 'Hit (light)',
        headerCellFilter: 'translate',
        type: 'number',
        width: 100,
        footerCellFilter: 'number:0',
        aggregationType: this.uiGridConstants.aggregationTypes.avg,
        cellTemplate: `<div class='ui-grid-cell-contents'><uib-progressbar value='row.entity.hit_light' class='campaigns_analytics__performance_progress_bar'>{{row.entity.hit_light}}%</uib-progressbar></div>`
      },
      {
        field: 'hit_avg',
        displayName: 'Hit (avg)',
        headerCellFilter: 'translate',
        type: 'number',
        width: 100,
        footerCellFilter: 'number:0',
        aggregationType: this.uiGridConstants.aggregationTypes.avg,
        cellTemplate: `<div class='ui-grid-cell-contents'><uib-progressbar value='row.entity.hit_avg' class='campaigns_analytics__performance_progress_bar'>{{row.entity.hit_avg}}%</uib-progressbar></div>`
      },
      {
        field: 'hit_avg',
        displayName: 'Hit (heavy)',
        headerCellFilter: 'translate',
        type: 'number',
        width: 100,
        footerCellFilter: 'number:0',
        aggregationType: this.uiGridConstants.aggregationTypes.avg,
        cellTemplate: `<div class='ui-grid-cell-contents'><uib-progressbar value='row.entity.hit_avg' class='campaigns_analytics__performance_progress_bar'>{{row.entity.hit_avg}}%</uib-progressbar></div>`
      },
      {
        field: 'impressions',
        displayName: 'Impressions',
        headerCellFilter: 'translate',
        cellFilter: 'number:0',
        footerCellFilter: 'number:0',
        cellClass: 'to-right',
        type: 'number',
        width: 120,
        aggregationType: this.uiGridConstants.aggregationTypes.sum
      },
      {
        field: 'reach',
        displayName: 'Reach',
        cellClass: 'to-right',
        cellFilter: 'NumberIsAvailable:2',
        footerCellFilter: 'NumberIsAvailable:2',
        type: 'number',
        width: 120,
        aggregationType: this.uiGridConstants.aggregationTypes.sum
      },
      {
        field: 'freq',
        displayName: 'Freq',
        headerCellFilter: 'translate',
        cellClass: 'to-right',
        cellFilter: 'NumberIsAvailable:2',
        footerCellFilter: 'NumberIsAvailable:2',
        type: 'number',
        width: 120,
        aggregationType: this.uiGridConstants.aggregationTypes.sum
      }
    ],

    onRegisterApi: (gridApi: any) => {
      this.gridApi = gridApi;
      gridApi.selection.on.rowSelectionChanged(this.$scope, this.performanceSelectionUpdate);

      gridApi.selection.on.rowSelectionChangedBatch(this.$scope, this.performanceSelectionUpdate);
    }
  };
  public datePicker = {
    date: {
      startDate: moment(this.performanceSeriesList[0].series[0], 'DD.MM.YYYY'),
      endDate: moment(this.performanceSeriesList[0].series.slice(-1).pop(), 'DD.MM.YYYY')
    },
    opts: {
      locale: {
        format: 'DD/MM/YYYY'
      },
      separator: '.',
      opens: 'left',
      eventHandlers: {
        'apply.daterangepicker': (ev: any, picker: any) => {
          this.performanceChartConfig.xAxis.min = parseInt(this.datePicker.date.startDate.format('x'), 10);
          this.performanceChartConfig.xAxis.max = parseInt(this.datePicker.date.endDate.hour(0)
            .minute(0)
            .second(0)
            .millisecond(0)
            .format('x'), 10);
          this.performanceGridConfig.data = this.getPerformanceGridData(moment(this.performanceChartConfig.xAxis.min)
            .format('DD.MM.YYYY'), moment(this.performanceChartConfig.xAxis.max).format('DD.MM.YYYY'));
        }
      }
    },
    min: moment(this.performanceSeriesList[0].series[0], 'DD.MM.YYYY'),
    max: moment(this.performanceSeriesList[0].series.slice(-1).pop(), 'DD.MM.YYYY')
  };
  private ACCURACY: any = {
    99: 2.58,
    95: 1.96,
    90: 1.65
  };

  constructor(private $stateParams: any,
              private $http: IHttpService,
              private uiGridConstants: any,
              private $scope: any) {
  }

  ngOnInit(): void {

    this.getPerformanceSeries(this.performanceSeriesList[0], 0);
    this.getPerformanceSeries(this.performanceSeriesList[1], 1);
    this.salesUpliftCategoryChartConfig.options.plotOptions.column.dataLabels = {
      enabled: true,
      color: 'black',
      formatter: function() {
        return this.point.y + ' ( ' + (this.point.gain >= 0 ? '+' : '-') + Math.abs(this.point.gain) + '% )';
      }
    };

    this.salesUpliftBrandChartConfig.options.plotOptions.column.dataLabels = {
      enabled: true,
      color: 'black',
      formatter: function() {
        return this.point.y + ' ( ' + (this.point.gain >= 0 ? '+' : '-') + Math.abs(this.point.gain) + '% )';
      }
    };

    this.salesUpliftCategoryChartConfig.options.chart.events = {
      click: function() {
        this.myTooltip.hide();
      },
      load: function() {
        let options = this.options.tooltip;
        options.formatter = () => {
          let self = this;
          let chartTooltipConfig: any = {
            chart: {
              type: 'column'
            },
            series: [{
              data: [
                ['Google', _.random(400, 500)],
                ['Mail.ru', _.random(380, 400)],
                ['Rambler', _.random(350, 380)],
                ['YT', _.random(300, 350)],
                ['Yandex', _.random(200, 300)]
              ],
              dataLabels: {
                enabled: true
              },
              color: self.color
            }],
            xAxis: {
              type: 'category',
              labels: {
                rotation: -45
              }
            },
            yAxis: {
              visible: false
            },
            credits: {
              enabled: false
            },
            exporting: {
              enabled: false
            },
            title: {
              text: ''
            },
            legend: {
              enabled: false
            }
          };
          setTimeout(() => {
            $('#hc-tooltip__category').highcharts(chartTooltipConfig);
          }, 10);
          return '<div id="hc-tooltip__category" class="campaigns_analytics__hc-tooltip"></div>';
        };
        options.useHTML = true;
        this.myTooltip = new (Highcharts as any).Tooltip(this, options);
      }
    };

    this.salesUpliftCategoryChartConfig.options.plotOptions.column.events = {
      click: function(event: any) {
        this.chart.myTooltip.refresh(event.point, event);
      }
    };

    this.salesUpliftBrandChartConfig.options.chart.events = {
      click: function() {
        this.myTooltip.hide();
      },
      load: function() {
        let options = this.options.tooltip;
        options.formatter = () => {
          let self = this;
          let chartTooltipConfig: any = {
            chart: {
              type: 'column'
            },
            series: [{
              data: [
                ['Google', _.random(400, 500)],
                ['Mail.ru', _.random(380, 400)],
                ['Rambler', _.random(350, 380)],
                ['YT', _.random(300, 350)],
                ['Yandex', _.random(200, 300)]
              ],
              dataLabels: {
                enabled: true
              },
              color: self.color
            }],
            xAxis: {
              type: 'category',
              labels: {
                rotation: -45
              }
            },
            yAxis: {
              visible: false
            },
            credits: {
              enabled: false
            },
            exporting: {
              enabled: false
            },
            title: {
              text: ''
            },
            legend: {
              enabled: false
            }
          };
          setTimeout(() => {
            $('#hc-tooltip__brand').highcharts(chartTooltipConfig);
          }, 10);
          return '<div id="hc-tooltip__brand" class="campaigns_analytics__hc-tooltip"></div>';
        };
        options.useHTML = true;
        this.myTooltip = new (Highcharts as any).Tooltip(this, options);
      }
    };

    this.salesUpliftBrandChartConfig.options.plotOptions.column.events = {
      click: function(event: any) {
        this.chart.myTooltip.refresh(event.point, event);
      }
    };

    this.salesUpliftCategoryChartConfig.series = [
      {
        color: ConstService.COLORS.ORANGE,
        data: []
      },
      {
        color: ConstService.COLORS.RED,
        data: []
      }
    ];
    this.salesUpliftBrandChartConfig.series = [
      {
        color: ConstService.COLORS.ORANGE,
        data: []
      },
      {
        color: ConstService.COLORS.RED,
        data: []
      }
    ];
    this.benchmarksChartConfig.series = [
      {
        name: 'Все домохозяйства',
        color: ConstService.COLORS.ORANGE,
        data: []
      },
      {
        name: 'Тестовая группа',
        color: ConstService.COLORS.RED,
        data: []
      }
    ];

    this.generateSalesUpliftData();
    this.getBenchmarksSeries(this.benchmarksSeriesList[0]);
    this.getDistributionSeries(this.distributionSeriesList[0]);
    this.getSalesUpliftSeries(this.salesUpliftSeriesList[0]);

    this.$http.get('/public/json/demo/campaign_analytics.json').then((response: any) => {
      this.analytics = response.data;
      this.getPercentage();
      this.removeHCTooltips();
    }).finally(() => {
      this.preloader = false;
    });
  }

  public getPerformanceSeries(series: any, seriesNumber: any) {
    let selectedNames = [];
    if(this.gridApi) {
      selectedNames = this.gridApi.selection.getSelectedRows().map((item: any) => {
        return item.name;
      });
    }

    this.performanceSeriesSelected[seriesNumber] = series;
    this.performanceChartConfig.yAxis[seriesNumber].visible = true;
    this.performanceChartConfig.yAxis[seriesNumber].labels.format = series.format;
    this.performanceChartConfig.yAxis[seriesNumber].title.text = series.text;
    let data: any[] = [];
    series.series.forEach((date: any) => {
      // data.push(0);
      data.push([parseInt(moment(date, 'DD.MM.YYYY').format('x'), 10), 0]);
    });
    for(let key in series.data) {
      if(series.data.hasOwnProperty(key) && (!selectedNames.length || selectedNames.indexOf(key) !== -1)) {
        for(let i = 0; i < data.length; i++) {
          data[i][1] += series.data[key][i];
        }
      }
    }
    let newSeries = {
      name: series.name,
      type: 'column',
      yAxis: seriesNumber,
      data: data,
      color: series.color,
      tooltip: {
        valueSuffix: series.suffix,
        valuePrefix: series.prefix
      }
    };

    if(this.performanceChartConfig.series.length < (seriesNumber + 1)) {
      this.performanceChartConfig.series.push(newSeries);
    } else {
      this.performanceChartConfig.series[seriesNumber] = newSeries;
    }
  }

  public selectDataBasisDemographic(field: any) {
    this.dataBasisDemographicSelected = field;
    this.updateFakeData();
  }

  public getSVG(charts: any, options: any, callback: any) {
    let svgArr: any[] = [],
      top = 0,
      width = 0,
      i: any,
      svgResult = function(svgres: any) {
        // let svg = svgres.replace('<svg', '<g transform="translate(0,' + top + ')" ');
        let svg = svgres.replace('<svg', '<g transform="translate(' + width + ', 0)"');
        svg = svg.replace('</svg>', '</g>');
        top = Math.max(top, charts[i].chartHeight);
        width += charts[i].chartWidth;
        svgArr.push(svg);
        if(svgArr.length === charts.length) {
          callback('<svg height="' + top + '" width="' + width + '" version="1.1" xmlns="http://www.w3.org/2000/svg">' + svgArr.join('') + '</svg>');
        }
      };
    for(i = 0; i < charts.length; ++i) {
      charts[i].getSVGForLocalExport(options, {}, () => {
        console.log('Failed to get SVG');
      }, svgResult);
    }
  }

  public exportCharts(charts: any, options: any) {
    // merge the options
    options = (Highcharts as any).merge(Highcharts.getOptions().exporting, options);

    let imageType = options && options.type || 'image/png';
    charts.forEach(function(chart: any) {
      chart.options.exporting.sourceWidth || (chart.options.exporting.sourceWidth = chart.chartWidth);
    });
    // get SVG asynchronously and then download the resulting SVG
    this.getSVG(charts, options, function(svg: any) {
      (Highcharts as any).downloadSVGLocal(svg,
        (options.filename || 'chart') + '.' + (imageType === 'image/svg+xml' ? 'svg' : imageType.split('/')[1]),
        imageType,
        options.scale || 2,
        () => {
          console.log('Failed to export on client side');
        });
    });
  }

  public getToleranceInfo(chartConfig: any) {
    let averageErrorPercent = chartConfig.series[0].averageErrorPercent;
    if(!isFinite(averageErrorPercent) || !chartConfig.series[0].accuracy) {
      return '';
    }
    averageErrorPercent = averageErrorPercent === 0 ? '<0.1%' : averageErrorPercent + '%';
    return `<div class='analytics__popover'><b>Tolerance</b><br>sample: ${chartConfig.series[0].sampleSize} <br>probability: ${chartConfig.series[0].accuracy}%<br>deviation: ${averageErrorPercent}</div>`;
  }

  public getBenchmarksSeries(series: any) {
    this.benchmarksSeriesSelected = series.name;
    let householdeSum = series.householde.reduce((a: any, b: any) => {
      return a + b;
    }, 0);
    let testSum = series.test.reduce((a: any, b: any) => {
      return a + b;
    }, 0);
    this.benchmarksChartConfig.series[0].data.length = 0;
    this.benchmarksChartConfig.series[1].data.length = 0;
    series.householde.forEach((item: any) => {
      this.benchmarksChartConfig.series[0].data.push(item / householdeSum * 100);
    });
    series.test.forEach((item: any) => {
      this.benchmarksChartConfig.series[1].data.push(item / testSum * 100);
    });
  }

  public getDistributionSeries(series: any) {
    if(this.selectedDistributionSeries === series.name) {
      return;
    }
    this.selectedDistributionSeries = series.name;
    series = series.data;
    this.distributionChartConfig.series.length = 0;
    let colors = [ConstService.COLORS.BLUE, ConstService.COLORS.RED, ConstService.COLORS.GREEN, ConstService.COLORS.ORANGE, ConstService.COLORS.PURPLE, ConstService.COLORS.PINK];
    series.forEach((item: any, index: any) => {
      let data: any[] = [];
      this.brandCategories.forEach(() => {
        data.push(Math.floor(Math.random() * 1000));
      });
      this.distributionChartConfig.series.push({
        name: item,
        color: colors[index],
        data: data
      });
    });
  }

  public getSalesUpliftSeries(series: any) {
    function getCategoryValues(categoryPrefix: any, dateRanger: any) {
      let floorPrecision: any = {
        'Средний объем покупки в натуральном выражении': 0,
        'Средний чек': 0,
        'Частота покупок': 2,
        'Проникновение': 3
      };
      let catBeforeVal = series[categoryPrefix + 'Before'].reduce(function(sum: any, cur: any) {
        if(moment(cur.date, 'DD.MM.YYYY').isBetween(dateRanger.startDate, dateRanger.endDate, 'days', '[]')) {
          sum.y += cur.y;
          sum.count++;
        }
        return sum;
      }, {y: 0, count: 0});
      let catBeforeValCompare = series[categoryPrefix + 'After'].reduce(function(sum: any, cur: any) {
        if(moment(cur.date, 'DD.MM.YYYY').isBetween(dateRanger.startDate, dateRanger.endDate, 'days', '[]')) {
          sum.y += cur.y;
          sum.count++;
        }
        return sum;
      }, {y: 0, count: 0});
      catBeforeValCompare.y = _.floor((catBeforeValCompare.y / catBeforeValCompare.count), floorPrecision[series.name]);
      catBeforeVal.y = _.floor(catBeforeVal.y / catBeforeVal.count, floorPrecision[series.name]);

      catBeforeVal.gain = Math.floor((catBeforeVal.y - catBeforeValCompare.y) / catBeforeValCompare.y * 100);
      catBeforeValCompare.gain = Math.floor((catBeforeValCompare.y - catBeforeVal.y) / catBeforeVal.y * 100);
      return [catBeforeVal, catBeforeValCompare];
    }

    this.salesUpliftSeriesSelected = series.name;
    this.salesUpliftCategoryChartConfig.series[0].data = getCategoryValues('category', this.datePickerSU.date);
    this.salesUpliftCategoryChartConfig.series[1].data = getCategoryValues('category', this.datePickerSUcompare.date);
    this.salesUpliftBrandChartConfig.series[0].data = getCategoryValues('brand', this.datePickerSU.date);
    this.salesUpliftBrandChartConfig.series[1].data = getCategoryValues('brand', this.datePickerSUcompare.date);
  }

  public getColor(percent: any, col: any) {
    if(!col) {
      return '';
    }
    return {'background-color': 'rgba(0,0,255,' + percent / 100 + ')'};
  }

  private getFakeSeriesData(config: any) {
    if(config.options.title.text === 'Gender') {
      config.series[0].data[0] = {
        name: 'Male',
        size: 50,
        default_percent: 0.5,
        y: 50,
        color: ConstService.COLORS.BLUE
      };
      config.series[0].data[1] = {
        name: 'Female',
        size: 50,
        default_percent: 0.5,
        y: 50,
        color: ConstService.COLORS.RED
      };
      return;
    }
    if(config.options.title.text === 'Population') {
      config.series[0].data[0] = {
        name: 'less 100k',
        size: 40,
        default_percent: 0.25,
        y: 50,
        color: ConstService.COLORS.ORANGE
      };
      config.series[0].data[1] = {
        name: '100-400k',
        size: 60,
        default_percent: 0.25,
        y: 60,
        color: ConstService.COLORS.BLUE
      };
      config.series[0].data[2] = {
        name: '400-700k',
        size: 70,
        default_percent: 0.25,
        y: 70,
        color: ConstService.COLORS.GREEN
      };
      config.series[0].data[3] = {
        name: 'more 700k',
        size: 30,
        default_percent: 0.25,
        y: 30,
        color: ConstService.COLORS.RED
      };
      return;
    }
    if(config.options.title.text === 'Cities') {
      config.series[0].data = [{
        name: 'Moscow (RU)',
        y: 1000,
        size: 1000
      }, {
        name: 'St. Petersburg (RU)',
        y: 500
      }, {
        name: 'New York (US)',
        y: 300
      }, {
        name: 'Paris (FR)',
        y: 200
      }, {
        name: 'London (UK)',
        y: 100
      }];
      return;
    }
    config.series[0].data.forEach(function(data: any) {
      data.y = data.size = Math.floor(Math.random() * 1000 / config.xAxis.categories.length);
    });
  }

  private getPerformanceGridData(startDate: any, endDate: any) {
    let startIndex = this.performanceSeriesList[0].series.indexOf(startDate);
    let endIndex = this.performanceSeriesList[0].series.indexOf(endDate);
    (startIndex === -1) && (startIndex = 0);
    (endIndex === -1) && (endIndex = this.performanceSeriesList[0].series.length);
    let data: any[] = [];
    let dataObj: any = {};
    this.performanceSeriesList.forEach((series: any) => {
      let name = series.name.toLowerCase();
      for(let key in series.data) {
        if(series.data.hasOwnProperty(key)) {
          dataObj[key] || (dataObj[key] = {});
          dataObj[key][name] = series.data[key].slice(startIndex, endIndex).reduce((a: any, b: any) => {
            return a + b;
          }, 0);
        }
      }
    });
    for(let key in dataObj) {
      if(dataObj.hasOwnProperty(key)) {
        dataObj[key] || (dataObj[key] = {});
        dataObj[key].hit_light = Math.floor(Math.random() * 100);
        dataObj[key].hit_avg = Math.floor(Math.random() * 100);
        dataObj[key].hit_heavy = Math.floor(Math.random() * 100);
        dataObj[key].name = key;
        data.push(dataObj[key]);
      }
    }
    return data;
  }

  private performanceSelectionUpdate() {
    this.getPerformanceSeries(this.performanceSeriesSelected[0], 0);
    this.getPerformanceSeries(this.performanceSeriesSelected[1], 1);
  }

  private generateSalesUpliftData() {
    let categoriesNameList = ['categoryBefore', 'categoryAfter', 'brandBefore', 'brandAfter'];
    let randomSettings: any = {
      'Средний объем покупки в натуральном выражении': {
        max: 15,
        min: 2,
        step: 0
      },
      'Средний чек': {
        max: 1200,
        min: 100,
        step: 0
      },
      'Частота покупок': {
        max: 0.5,
        min: 0.3,
        step: 2
      },
      'Проникновение': {
        max: 0.9,
        min: 0.1,
        step: 3
      }
    };

    const generateData = (settings: any) => {
      return this.performanceSeriesList[0].series.map((date: any) => {
        return {
          date: date,
          y: _.floor(_.random(settings.min, settings.max, true), settings.step)
        };
      });
    };

    this.salesUpliftSeriesList.forEach((series: any) => {
      categoriesNameList.forEach((categoryName: any) => {
        series[categoryName] = generateData(randomSettings[series.name]);
      });
    });
  }

  private removeHCTooltips() {
    $('svg > text[text-anchor=end]').hide();
  }

  private updateFakeData() {
    [
      this.educationChartConfig,
      this.ageFemaleChartConfig,
      this.ageMaleChartConfig,
      this.incomeChartConfig,
      this.genderChartConfig
    ].forEach((config: any) => {
      config.series[0].data.forEach((item: any) => {
        item.size = 0;
      });
      this.getPercentage();
    });
  }

  private getPercentage() {
    let segmentSize = this.analytics.size;
    let accuracy = '95';
    let z = this.ACCURACY[accuracy];
    [
      this.educationChartConfig,
      this.ageFemaleChartConfig,
      this.ageMaleChartConfig,
      this.incomeChartConfig,
      this.genderChartConfig,
    ].forEach((config: any) => {
      let series = config.series[0];
      let sampleSize = series.data.reduce((x: any, item: any) => {
        return x + item.y;
      }, 0);
      series.sampleSize = series.data.reduce((x: any, item: any) => {
        return x + item.size;
      }, 0);
      if(series.sampleSize < 10) {
        this.getFakeSeriesData(config);
        // demo only
        series.sampleSize = series.data.reduce((x: any, item: any) => {
          return x + item.size;
        }, 0);
      }
      series.data.forEach((item: any, index: any, arr: any) => {
        item.percent = item.y / sampleSize * 100;
      });
      series.accuracy = accuracy;
      series.averageErrorPercent = 0;
      series.data.forEach((item: any, index: any, arr: any) => {
        let defaultPercent = item.default_percent ? item.default_percent : 0.5;
        if(!!segmentSize) {
          item.error = z * Math.sqrt(defaultPercent * (1 - defaultPercent) * (segmentSize - series.sampleSize) / (series.sampleSize * segmentSize));
        } else {
          item.error = z * Math.sqrt(defaultPercent * (1 - defaultPercent) / series.sampleSize);
        }
        item.error_percent = Math.floor(item.error * 1000) / 10;
        series.averageErrorPercent += item.error_percent / arr.length;
      });
      series.averageErrorPercent = Math.floor(series.averageErrorPercent * 10) / 10;
    });
  }

  private getChartConfig(opts: any) {
    let config: any = {};
    let title = opts.title || '';
    let type = opts.type || 'bar';
    let opposite = !!opts.opposite;
    let legend = (typeof opts.legend === 'boolean') ? opts.legend : true;
    let xAxisVisible = (typeof opts.xAxisVisible === 'boolean') ? opts.xAxisVisible : true;
    let categories = opts.categories || [];
    let color = opts.color || '';

    const exportAgeGender = () => {
      let charts = Array.prototype.map.call(document.getElementsByClassName('analytics__age_gander_chart'),
        function(el: any) {
          return angular.element(el).highcharts();
        });
      this.exportCharts(charts, null);
    };

    config.options = {
      title: {
        text: title
      },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: type
      },
      tooltip: {
        enabled: false,
        pointFormat: '<b>{point.name}: {point.percentage:.1f}%</b>',
        style: {
          padding: 10
        }
      },
      credits: {
        enabled: false
      },
      exporting: {
        enabled: true,
        buttons: {
          contextButton: {
            menuItems: [{
              text: 'Export to PNG',
              onclick: function() {
                this.exportChart();
              }
            }]
          }
        }
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
            inside: false,
            color: '#000000',
            formatter: function() {
              let dataSum = this.series.data.reduce((x: any, item: any) => {
                return x + item.total;
              }, 0);
              let pcnt = (this.y / dataSum) * 100;
              return Highcharts.numberFormat(pcnt, 0, ',') + '%';
            }
          }
        },
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },
          showInLegend: true
        },
        column: {
          dataLabels: {
            enabled: true,
            inside: false,
            color: '#000000',
            formatter: function() {
              let dataSum = this.series.data.reduce((x: any, item: any) => {
                return x + item.total;
              }, 0);
              let pcnt = (this.y / dataSum) * 100;
              return Highcharts.numberFormat(pcnt, 0, ',') + '%';
            }
          }
        },
        // series: {
        // 	stacking: 'normal'
        // }
      },
      legend: {
        enabled: legend
      }
    };
    config.xAxis = {
      categories: categories,
      opposite: opposite,
      title: {
        text: null
      },
      visible: xAxisVisible
    };
    config.yAxis = {
      visible: false,
      reversed: opposite
    };
    config.series = [{
      name: title,
      color: color,
      // colorByPoint: true,
      data: []
    }];

    for(let i = 0; i < categories.length; i++) {
      config.series[0].data.push({
        name: '',
        size: 0,
        default_percent: 0,
        y: 0
      });
    }

    switch(title) {
      case 'Gender':
        config.options.exporting.enabled = false;
        config.options.tooltip.pointFormat = '<b>{point.percentage:.1f}%</b>';
        config.options.tooltip.enabled = true;
        break;
      case 'Population':
        config.options.tooltip.pointFormat = '<b>{point.percentage:.1f}%</b>';
        config.options.tooltip.enabled = true;
        break;
      case 'Females':
        config.options.exporting.enabled = false;
        config.options.exporting.buttons.contextButton.menuItems[0].onclick = exportAgeGender;
        config.options.plotOptions.series = {stacking: 'normal'};
        config.options.tooltip.pointFormat = '<b>{point.name}: {point.percent:.1f}%</b>';
        break;
      case 'Males':
        config.options.exporting.buttons.contextButton.menuItems[0].onclick = exportAgeGender;
        config.options.plotOptions.series = {stacking: 'normal'};
        config.options.tooltip.pointFormat = '<b>{point.name}: {point.percent:.1f}%</b>';
        break;
      case 'Education':
      case 'Income':
        config.options.plotOptions.series = {stacking: 'normal'};
        config.options.tooltip.pointFormat = '<b>{point.name}: {point.percent:.1f}%</b>';
        break;
      case 'Uniqs':
        config.options.tooltip.pointFormat = '<b>uniqs: {point.y}</b>';
        config.options.plotOptions.bar.dataLabels.enabled = false;
        config.options.tooltip.enabled = true;
        config.series[0].pointWidth = 20;
        config.xAxis.width = 200;
        config.xAxis.labels = {
          style: {
            // "width": "300px",
            // "min-width": "300px",
            'font-size': '14px'
          },
          // useHTML : true
        };
        break;
      case 'Affinity':
        config.options.tooltip.pointFormat = 'Affinity index: <b>{point.real}</b>';
        config.options.tooltip.enabled = true;
        config.options.plotOptions.bar.dataLabels.enabled = false;
        config.series[0].pointWidth = 20;
        config.yAxis.min = -100;
        config.yAxis.max = 100;
        break;
      case 'Distribution':
        config.options.plotOptions.series = {
          stacking: 'percent'
        };
        config.options.plotOptions.column.dataLabels = {
          enabled: true,
          color: 'black',
          format: '{percentage:.0f} %'
        };
        config.options.legend = {
          layout: 'vertical',
          align: 'right',
          verticalAlign: 'middle'
        };
        break;
      case 'Benchmarks':
        config.options.plotOptions.column.dataLabels = {
          enabled: true,
          color: 'black',
          format: '{y:.0f} %'
        };
        break;
      case 'Performance':
        config.yAxis = [{
          labels: {
            style: {
              color: Highcharts.getOptions().colors[1]
            }
          },
          title: {
            style: {
              color: Highcharts.getOptions().colors[1]
            }
          },
          opposite: false,
          visible: false
        },
          {
            labels: {
              style: {
                color: Highcharts.getOptions().colors[0]
              }
            },
            title: {
              style: {
                color: Highcharts.getOptions().colors[0]
              }
            },
            opposite: true,
            visible: false
          }];
        config.xAxis.type = 'datetime';
        config.xAxis.categories = null;
        config.options.plotOptions.column.dataLabels.enabled = false;
        config.options.tooltip.pointFormat = '<b>{point.y:.f}</b>';
        config.options.tooltip.enabled = true;
        config.options.chart.zoomType = 'x';
        break;
      default:
        config.options.tooltip.pointFormat = '<b>{point.name}: {point.percentage:.1f}%</b>';
        break;
    }
    return config;
  }
}
