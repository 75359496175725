import { StateProvider } from '@uirouter/angularjs';
import { NgModule } from 'angular-ts-decorators';
import { AudienceAnalyticsComponent } from './analytics/audience-analytics.component';
import { ModalCSVOrderComponent } from './analytics/csv-order.modal/csv-order.modal.component';
import { ModalFilterSizeComponent } from './analytics/filter-size.modal/filter-size.modal.component';
import { audisenceRouting } from './audience.routes';
import { AudienceEditComponent } from './edit/audience-edit.component';
import { ModalEditFolderAudienceComponent } from './group.modal/audience-group-edit.component';
import { AudienceListComponent } from './list/audience-list.component';
import { AudienceLookalikeComponent } from './lookalike/audience-lookalike.component';
import { ModalShareComponent } from './share.modal/audience-share.modal.component';
import {ModalSplitComponent} from './split.modal/audience-split.modal.component';

@NgModule({
  id: 'AudienceModule',
  declarations: [
    AudienceListComponent,
    ModalEditFolderAudienceComponent,
    ModalShareComponent,
    ModalSplitComponent,
    AudienceLookalikeComponent,
    AudienceEditComponent,
    AudienceAnalyticsComponent,
    ModalFilterSizeComponent,
    ModalCSVOrderComponent
  ]
})
export class AudienceModule {
  static config = config;
}

function config($stateProvider: StateProvider) {
  audisenceRouting($stateProvider);
}

config.$inject = ['$stateProvider'];
