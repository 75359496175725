import { StateProvider } from '@uirouter/angularjs';
import { NgModule } from 'angular-ts-decorators';
import { PixelAnalyticsComponent } from './analytics/pixel-analytics.component';
import { ModalPixelCodeComponent } from './code.modal/pixel-code.modal.component';
import { ModalPixelDeleteComponent } from './delete.modal/delete.modal.component';
import { PixelEditComponent } from './edit/pixel-edit.component';
import { PixelListComponent } from './list/pixel-list.component';
import { pixelRouting } from './pixel.routes';

@NgModule({
  id: 'PixelModule',
  declarations: [
    PixelListComponent,
    PixelEditComponent,
    ModalPixelCodeComponent,
    ModalPixelDeleteComponent,
    PixelAnalyticsComponent
  ],
  providers: []
})
export class PixelModule {
  static config = config;
}

function config($stateProvider: StateProvider) {
  pixelRouting($stateProvider);
}

config.$inject = ['$stateProvider'];
