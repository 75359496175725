import { Component, Input, OnInit } from 'angular-ts-decorators';

@Component({
  selector: 'modalSplitComponent',
  templateUrl: './audience-split.modal.pug'
})
export class ModalSplitComponent implements OnInit {

  static $inject: string[] = [
    'authService',
    'audiencesService'
  ];
  @Input() modalInstance: any;
  @Input() resolve: any;

  public submitDisable: boolean = false;
  public message: any = '';
  public segment: any;
  public audienceList: any = [];
  public splitNumber: number = 2;
  public minSplit: number = 2;
  public maxSplit: number = 10;
  public textLoc: any = {};

  constructor(private authService: any,
              private audiencesService: any,
              ) {
    this.authService.text$.subscribe((text) => {
      this.textLoc = text;
    });
  }

  ngOnInit(): void {
    this.segment = this.resolve.segment;
    this.audiencesService.getAudienceList().then((audience: any) => {
      this.audienceList = audience;
      this.getSplitList();
    });
  }

  public cancel(): void {
    this.modalInstance.dismiss('cancel');
  }

  public split(): void {
    this.submitDisable = true;
    const params: any = {
      account: this.authService.getSelectedAccount().account_id,
      split_number: this.splitNumber
    };

    this.audiencesService.splitAudience(this.segment.id, params).subscribe((response) => {
      response.forEach((audience) => {
        this.audiencesService.addAudience(audience);
      });
      this.modalInstance.close(response);
      this.submitDisable = false;
    }, (error) => {
      this.message = '';
      this.submitDisable = false;
      if(error.error.data && error.error.data.error_message) {
        for(const item in error.error.data.error_message) {
          if(error.error.data.error_message.hasOwnProperty(item)) {
            this.message += error.error.data.error_message[item] + ' ';
          }
        }
      }
    });
  }

  private getSplitList(): void {
    const splitList: any = this.audienceList.filter((segment: any) => segment.type === 'split' && segment.parents[0] === this.segment.segment_id);
    if (splitList.length) {
      this.minSplit = 1;
      this.splitNumber = 1;
      this.maxSplit = this.maxSplit - splitList.length;
    }
    if (this.maxSplit < 1) {
      this.message = this.textLoc.maximumSplitAudiencesForThisAudience;
      this.submitDisable = true;
    }
  }
}
