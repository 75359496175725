import { Component, Input } from 'angular-ts-decorators';

@Component({
  selector: 'modalCSVOrderComponent',
  templateUrl: './csv-order.modal.pug'
})
export class ModalCSVOrderComponent {

  @Input() modalInstance: any;

  public order: any = {
    name: 'Demographic-Geography-Affinity',
    value: 'genderCSVData-citiesCSVData-allCSVdata'
  };
  public orderList: any = [
    { name: 'Demographic-Geography-Affinity', value: 'genderCSVData-citiesCSVData-allCSVdata' },
    { name: 'Demographic-Affinity-Geography', value: 'genderCSVData-allCSVdata-citiesCSVData' },
    { name: 'Geography-Demographic-Affinity', value: 'citiesCSVData-genderCSVData-allCSVdata' },
    { name: 'Geography-Affinity-Demographic', value: 'citiesCSVData-allCSVdata-genderCSVData' },
    { name: 'Affinity-Geography-Demographic', value: 'allCSVdata-citiesCSVData-genderCSVData' },
    { name: 'Affinity-Demographic-Geography', value: 'allCSVdata-genderCSVData-citiesCSVData' }
  ];

  constructor() {

  }

  public cancel(): void {
    this.modalInstance.close();
  }

  public download(): void {
    this.modalInstance.close(this.order);
  }
}
