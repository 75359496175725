import { StateProvider } from '@uirouter/angularjs';
import { NgModule } from 'angular-ts-decorators';
import { audisenceRouting } from '../audiences/audience.routes';
import { ModalDeactivateComponent } from './deactivate.modal/deactivate.modal.component';
import { ModalEditSharingComponent } from './edit.modal/sharing-edit.modal.component';
import { SharingComponent } from './shares.component';
import { sharesRouting } from './shares.routes';
import {ModalSharingFilterComponent} from './filter.modal/sharing-filter.modal.component';
import {ModalExportAnalyticsComponent} from './analytics.modal/analytics.modal.component';

@NgModule({
  id: 'SharesModule',
  declarations: [
    SharingComponent,
    ModalEditSharingComponent,
    ModalDeactivateComponent,
    ModalSharingFilterComponent,
    ModalExportAnalyticsComponent
  ]
})
export class SharesModule {
  static config = config;
}

function config($stateProvider: StateProvider) {
  sharesRouting($stateProvider);
}

config.$inject = ['$stateProvider'];
