import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
// import { IStateService } from 'angular-ui-router';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map, skip, tap } from 'rxjs/operators';
import { isNull } from 'util';
import { SharedTabsEvent, SharedWorkerService } from './shared-worker.service';
import { ConstService } from '../../shared/const.service';
import { NotifyService } from '../../shared/notify.service';
import { DjangoResponse } from '../../shared/types/django-response.type';
import { UtilService } from '../../shared/util.service';
// import { $steteToken } from '../../upgraded.provider';
import { User, UserAccount, UserPermission } from '../../auth/shared/user.type';
import * as moment from 'moment';

// TODO: (prokopenko) implement broadcast reaction
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly currentUserUrl: string = 'api/v1/user/current/';
  private readonly logoutUserUrl: string = 'api/v1/user/logout/';
  private readonly registerUserUrl: string = 'p/user/register/';
  private readonly loginUserUrl: string = 'p/user/login/';
  private readonly enLocaleUrl: string = '../public/json/locale/en.json';
  private readonly ruLocaleUrl: string = '../public/json/locale/ru.json';
  private readonly lsSelectedAccountKey: string = 'selected_account_id';

  private readonly localeMap: any = {
    en: this.enLocaleUrl,
    ru: this.ruLocaleUrl
  };

  /**
   * Управляемая последовательность отвечает за текущего пользователя.
   * Инициализируется значением из locale storage
   * @type {BehaviorSubject<User>}
   */
  private user$$: BehaviorSubject<User> = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user') || null) as User);
  public user$: Observable<User> = this.user$$.asObservable();

  private account$$: BehaviorSubject<UserAccount> = new BehaviorSubject<UserAccount>(null);
  public account$: Observable<UserAccount> = this.account$$.asObservable();

  private userTariff$$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public userTariff$: Observable<any> = this.userTariff$$.asObservable();

  private langId: any = localStorage.getItem('langId') || 'ru';
  private text$$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public text$: Observable<any> = this.text$$.asObservable();

  constructor(private router: Router,
              private http: HttpClient,
              private notifyService: NotifyService,
              // @Inject($steteToken) private $state: IStateService,
              @Inject(DOCUMENT) private document: Document,
              private $sharedTabsWorker: SharedWorkerService
  ) {

    this.user$.subscribe((user: User) => {
      localStorage.setItem('user', JSON.stringify(user));
    });

    this.account$.pipe(
      skip(1),
      map((account: UserAccount) => account ? account.account_id : null)
    ).subscribe((accountId: number) => {
      if(isNull(accountId)) {
        localStorage.removeItem(this.lsSelectedAccountKey);
      } else {
        localStorage.setItem(this.lsSelectedAccountKey, String(accountId));
      }
    });

    this.user$.pipe(
      map((user: User) => !isNull(user))
    ).subscribe((hasUser: boolean) => {
      if(!hasUser) {
        this.notifyService.sendNotification(ConstService.AUTH_EVENTS.logout);
        this.account$$.next(null);
        return;
      }
      this.notifyService.sendNotification(ConstService.AUTH_EVENTS.login);
      const selectedAccountId: number = Number(localStorage.getItem(this.lsSelectedAccountKey));
      const selectedAccount: UserAccount = this.user.accounts.find(
        (account: UserAccount) => account.account_id === selectedAccountId
      ) || this.user.accounts[0];
        this.account$$.next(selectedAccount);
        if (this.user.is_impersonate && selectedAccount.account_id !== selectedAccountId) {
          this.notifyService.sendNotification(ConstService.AUTH_EVENTS.impersonated);
          this.$sharedTabsWorker.send({}, 'auth.impersonated');
          this.changeUserTariff();
        }
    });

    this.updateUser();

    this.notifyService.notification$.subscribe((action) => {
      if(this.isLoggedIn && action === ConstService.AUTH_EVENTS.notAuthorized) {
        this.clearUser();
        this.$sharedTabsWorker.send({}, 'auth.logout');
      }
    });
    $sharedTabsWorker.addEvent(new SharedTabsEvent('auth.logout', () => {
      this.clearUser();
    }));


    $sharedTabsWorker.addEvent(new SharedTabsEvent('auth.login', (e: any) => {
      this.router.navigate(['/pixel/list']);
      this.user$$.next(e.data.msg.user);
    }));


    $sharedTabsWorker.addEvent(new SharedTabsEvent('auth.impersonated', () => {
      this.impersonateLocal();
    }));

    $sharedTabsWorker.addEvent(new SharedTabsEvent('auth.change_account', (e: any) => {
      // TODO сервис вызывается до вызова Angular JS
      // if(['layout.pixel_create', 'layout.pixel_edit', 'layout.audiences_create', 'layout.audiences_edit', 'layout.constructor',
        // 'layout.constructor_edit', 'layout.lookalike'].indexOf(this.$state.current.name) !== -1) {
        // this.$state.reload();
      // }
      const newSelectedAccount: UserAccount = this.accountList.find((account: UserAccount) => account.account_id === e.data.msg.account_id);
      this.selectAccount(newSelectedAccount);
      this.notifyService.sendNotification(ConstService.AUTH_EVENTS.changeAccount);
    }));

    this.changeLocale(this.langId);
    this.changeUserTariff();
  }

  public get user(): User {
    return this.user$$.getValue();
  }

  public get tariff(): any {
    return this.userTariff$$.getValue();
  }

  public get selectedAccount(): UserAccount {
    return this.account$$.getValue();
  }

  public get accountList(): UserAccount[] {
    return this.isLoggedIn ? this.user.accounts : [];
  }

  /**
   * Если объект пользователя есть, возвращает true.
   * Если пользователя нет, возвращает false
   * @returns {boolean}
   */
  public get isLoggedIn(): boolean {
    return !isNull(this.user);
  }

  /**
   * Если пользователь залогинен и у него есть флаг is_impersonate, возвращает true.
   * В противном случае возвращает возвращает false
   * @returns {boolean}
   */
  public get isImpersonate(): boolean {
    return this.isLoggedIn && this.user.is_impersonate;
  }

  private get userPermissionList(): UserPermission[] {
    return this.isLoggedIn ? this.user.permissions : [];
  }

  /**
   * Посылает запрос на обновление пользователя.
   * Если пользователь не был залогинен, редиректит на страницу списка аккаунтов
   */
  public updateUser(): void {
    this.http.get<DjangoResponse<User>>(this.currentUserUrl)
      .pipe(map(UtilService.getDjangoData))
      .subscribe((user: User) => {
        this.loginLocal(user);
        this.changeUserTariff();
      });
  }

  public updateUserAfterConfirm(): void{
    this.http.get<DjangoResponse<User>>(this.currentUserUrl)
      .pipe(map(UtilService.getDjangoData))
      .subscribe((user: User) => {
        this.user$$.next(user);
        this.changeUserTariff();
      });
  }

  public getUserAccount(): any {
    const accountId: any = localStorage.getItem('selected_account_id');
    return this.http.get(`api/v1/account/${accountId}/selected/plarforms/`);
  }

  public getTariffs(): any {
    return this.http.get(`api/v1/payments/tariff/plans/`);
  }

  public getUserTariff(): any {
    const accountId: any = localStorage.getItem('selected_account_id');
    return this.http.get(`api/v1/payments/account/${accountId}/subscription/`);
  }

  public getPaymentOptions(params: any): any {
    const accountId: any = localStorage.getItem('selected_account_id');
    return this.http.get(`api/v1/payments/account/${accountId}/setup/payment/`, {params});
  }

  public editUserAccount(data: any): any {
    const accountId: any = localStorage.getItem('selected_account_id');
    return this.http.patch(`api/v1/account/${accountId}/selected/plarforms/`, data);
  }

  public editUser(data: any): any {
    return this.http.patch(this.currentUserUrl, data);
  }

  public changeRecurrent(): any {
    const accountId: any = localStorage.getItem('selected_account_id');
    return this.http.post(`api/v1/payments/account/${accountId}/recurrent/payment/change/`, {});
  }

  public confirmEmail(token: any): any {
    return this.http.post(`p/user/confirm/`, {token});
  }

  public resetPassword(email: any): any {
    return this.http.post(`p/user/password/reset/`, {email});
  }

  public confirmPassword(params: any): any {
    return this.http.post(`p/user/password/confirm/`, params);
  }

  public hasPermission(permission: UserPermission): boolean {
    return this.userPermissionList.includes(permission);
  }

  /**
   * Разлогинивает пользователя и отправляет на страницу логина
   */
  public logout(): void {
    this.http.post(this.logoutUserUrl, {}).subscribe(() => {
      this.clearUser();
      this.$sharedTabsWorker.send({}, 'auth.logout');
    });
  }

  public login(email: string, pass: string): Observable<User> {
    return this.http.post(this.loginUserUrl, {email: email, password: pass}).pipe(
      map(UtilService.getDjangoData),
      tap((user: User) => {
        localStorage.setItem(this.lsSelectedAccountKey, String(user.accounts[0].account_id));
        this.$sharedTabsWorker.send({user: user}, 'auth.login');
        this.loginLocal(user);
        this.changeUserTariff();
      })
    );
  }

  public getSelectedAccount(): UserAccount {
    return this.account$$.getValue();
  }

  public selectAccount(account: UserAccount): void {
    // TODO сервис вызывается до вызова Angular JS
    /* if([
      'layout.pixel_create',
      'layout.pixel_edit',
      'layout.audiences_create',
      'layout.audiences_edit',
      'layout.constructor',
      'layout.constructor_edit',
      'layout.lookalike'
    ].indexOf(this.$state.current.name) !== -1) {
      if(confirm('Account changed. All unsaved changes will be lost.')) {
        this.$state.reload();
      } else {
        return;
      }
    } */
    this.account$$.next(account);
    this.notifyService.sendNotification(ConstService.AUTH_EVENTS.changeAccount);
    this.$sharedTabsWorker.send({account_id: account.account_id}, 'auth.change_account');
  }

  public stopImpersonating(isRedirectToAdminPage: boolean): void {
    this.$sharedTabsWorker.send({}, 'auth.impersonated');
    this.document.location.href = '/impersonate/stop';
    // this.http.get(`/impersonate/stop`).subscribe(() => {},() => {
    //   if (isRedirectToAdminPage) {
    //     this.document.location.href = '/admin/user/user/';
    //   }
    //   this.impersonateLocal();
    //   this.$sharedTabsWorker.send({}, 'auth.impersonated');
    // });
  }

  public impersonate(userId: number): void {
    this.http.get(`/impersonate/${userId}`).subscribe(() => {
      this.impersonateLocal();
      this.$sharedTabsWorker.send({}, 'auth.impersonated');
    });
  }

  /**
   * @deprecated use selected account instead
   * @param accountId
   * @returns {UserAccount}
   */
  public getAccountById(accountId: number): UserAccount {
    return this.accountList.find((account: UserAccount) => {
      return account.account_id === accountId;
    });
  }

  public isMultiAccount(): boolean {
    return this.accountList.length > 1;
  }

  public registration(userData: any): Observable<User> {
    return this.http.post(this.registerUserUrl, userData).pipe(
      map(UtilService.getDjangoData)
    );
  }

  /**
   * @deprecated use user instead
   * @returns {string}
   */
  public getUsername(): string {
    if(!this.isLoggedIn) {
      return '';
    }
    return this.user.email;
  }

  /**
   * @deprecated use user instead
   * @returns {number}
   */
  public getUserId(): number | null {
    return this.isLoggedIn ? this.user.id : null;
  }

  private clearUser(): void {
    this.user$$.next(null);
    localStorage.removeItem('user');
    localStorage.removeItem('selected_account_id');
    this.router.navigate(['/login']);
  }

  private impersonateLocal(): void {
    this.updateUser();
    this.notifyService.sendNotification(ConstService.AUTH_EVENTS.impersonated);
  }

  private loginLocal(user: User): void {
    if(!this.isLoggedIn) {
      this.router.navigate(['/pixel/list']);
    }
    this.user$$.next(user);
  }

  public changeLocale(langId: string): void {
    localStorage.setItem('langId', langId);
    moment.locale(langId);
    this.http.get(this.localeMap[langId]).subscribe((text: any) => {
      this.text$$.next(text);
    });
  }

  public changeUserTariff(): void {
    this.getUserTariff().subscribe((response: any) => {
      this.userTariff$$.next(response.data);
    });
  }
}
