import { Component, OnInit } from 'angular-ts-decorators';
import { ConstService } from '../../app/shared/const.service';
import { ModalDeactivateComponent } from './deactivate.modal/deactivate.modal.component';
import * as moment from 'moment';
import * as _ from 'lodash';
import {Router} from '@angular/router';

@Component({
  selector: 'sharingComponent',
  templateUrl: './shares.pug'
})
export class SharingComponent implements OnInit {
  private sharingList: any = [];
  static $inject: string[] = [
    '$scope',
    '$filter',
    '$uibModal',
    'externalsResource',
    'sharingService',
    'authService',
    'uiGridConstants',
    'router',
    'notifyService',
    'i18nService'
  ];
  public gridConfig: any;
  public gridApi: any;
  public showAlert = false;
  public preloader = true;
  public isDisabled = this.authService.getSelectedAccount().permission !== 'edit';
  public searchData: any = [];
  public searchFilter = '';
  public isFilterExist: boolean = false;
  public textLoc: any = {};
  private langId: any = localStorage.getItem('langId') || 'ru';

  constructor(private $scope: any,
              private $filter: any,
              private $uibModal: any,
              private externalsResource: any,
              private sharingService: any,
              private authService: any,
              private uiGridConstants: any,
              private router: Router,
              private notifyService: any,
              private i18nService: any) {
  }

  ngOnInit(): void {
    const self: any = this;
    this.i18nService.setCurrentLang(this.langId);
    const tooltip = this.langId === 'ru' ? 'Копировать' : 'Copy';
    this.gridConfig = {
      data: [],
      enableRowSelection: true,
      enableSelectAll: false,
      enableColumnMenus: false,
      exporterMenuVisibleData : false,
      multiSelect: true,
      enableGridMenu: true,
      exporterCsvFilename: 'my_sharing.csv',
      exporterFieldCallback: (grid: any, row: any, col: any, value: any) => {
        if(col.name === 'is_active') {
          value = this.$filter('IsActive')(value);
        }
        if(col.name === 'updated_at') {
          value = this.$filter('IsAvailable')(value);
        }
        return value;
      },
      exporterMenuPdf: false,
      exporterMenuExcel: false,
      gridMenuCustomItems: [
        {
          title: self.langId === 'ru' ? 'Экспортировать аналитику' : 'Export Analytics',
          shown: function ($event) {
            if (!this.grid) {
              return false;
            }
            const rowsCount: any = this.grid.api.selection.getSelectedRows().length;
            return this.grid.api.selection.getSelectedRows().every((item) => item.product_name === 'Yandex') && rowsCount >= 1 && rowsCount <= 10;
          },
          action: function ($event) {
            let modalInstance: any;
            const sharings: any = this.grid.api.selection.getSelectedRows();

            modalInstance = self.$uibModal.open({
              component: 'ModalExportAnalyticsComponent',
              size: 'sm',
              resolve: {
                sharings: () => {
                  return sharings;
                }
              }
            });

            modalInstance.result.then((sharing: any) => {}, () => {});
          },
          order: 210
        },
        {
          title: self.langId === 'ru' ? 'Экспортировать всю Яндекс аналитику' : 'Export all yandex analytics',
          shown: function ($event) {
            if (!this.grid) {
              return false;
            }
            return this.grid.rows.some((item) => item.entity.product_name === 'Yandex');
          },
          action: function ($event) {
            let modalInstance: any;
            const sharings: any = this.grid.rows.filter((item) => item.entity.product_name === 'Yandex').map((item) => item.entity);

            modalInstance = self.$uibModal.open({
              component: 'ModalExportAnalyticsComponent',
              size: 'sm',
              resolve: {
                sharings: () => {
                  return sharings;
                }
              }
            });

            modalInstance.result.then((sharing: any) => {}, () => {});
          },
          order: 220
        }
      ],


      onRegisterApi: (gridApi: any) => {
        this.gridApi = gridApi;
        // $scope.gridApi.grid.registerRowsProcessor($scope.gridFilter, 200);
      },
      columnDefs: [
        {
          field: 'segment_id',
          displayName: 'Audience ID',
          sort: {direction: 'desc'},
          width: 130
        },
        {
          field: 'segment_name',
          displayName: 'Audience Name',
          width: 200
        },
        {
          field: 'is_active',
          displayName: 'Status',
          width: 100,
          cellFilter: 'IsActive'
        },
        {
          field: 'product_name',
          displayName: 'Platform ID',
          width: 130,
          cellTemplate: `<div class='ui-grid-cell-contents'><span ng-show='row.entity.product_name'>{{row.entity.product_name}}</span><span ng-show='!row.entity.product_name && row.entity.url' uib-tooltip='{{row.entity.url}}' tooltip-append-to-body='true' tooltip-class='sharing__grid_tooltip'>URL</span></div>`,
        },
        {
          field: 'customer_id',
          displayName: 'Customer ID',
          width: 100
        },
        {
          field: 'cost_amount',
          type: 'number',
          displayName: 'Cost',
          cellClass: 'to-right',
          cellFilter: 'number:2',
          width: 100
        },
        {
          field: 'cost_currency',
          displayName: 'Currency',
          cellClass: 'center',
          width: 75,
          headerCellFilter: 'translate'
        },
        {
          field: 'created_at',
          cellFilter: 'IsAvailable',
          displayName: 'Created',
          width: 150
        },
        {
          field: 'mytarget_sharing_key',
          displayName: 'Sharing Key',
          width: 150,
          cellTemplate: `<div class='ui-grid-cell-contents'><span ng-show='row.entity.mytarget_sharing_key'><span class='glyphicon glyphicon-duplicate' style='cursor:pointer' ngclipboard data-clipboard-text='{{row.entity.mytarget_sharing_key}}' tooltip-append-to-body='true' tooltip-class='sharing__grid_tooltip' uib-tooltip='${tooltip}'></span>&nbsp;{{row.entity.mytarget_sharing_key}}</span></div>`,
        }
      ]
    };


    this.sharingService.getSharings().then((externals: any) => {
      this.preloader = false;
      this.sharingList = externals;
      this.filterData();
    });

    this.notifyService.notification$.subscribe((message: string) => {
      if (message === ConstService.AUTH_EVENTS.changeAccount) {
        this.sharingService.getSharings().then((externals: any) => {
          this.preloader = false;
          this.sharingList = externals;
          this.filterData();
        });
      }
    });

    this.$scope.$on(ConstService.AUTH_EVENTS.impersonated, (event: any) => {
      this.sharingService.update();
    });

    this.authService.text$.subscribe((text) => {
      this.textLoc = text;
      this.gridConfig.columnDefs[0].displayName = text.audienceID;
      this.gridConfig.columnDefs[1].displayName = text.audienceName;
      this.gridConfig.columnDefs[2].displayName = text.status;
      this.gridConfig.columnDefs[3].displayName = text.platformID;
      this.gridConfig.columnDefs[4].displayName = text.customerID;
      this.gridConfig.columnDefs[5].displayName = text.cost;
      this.gridConfig.columnDefs[6].displayName = text.currency;
      this.gridConfig.columnDefs[7].displayName = text.created;
      this.gridConfig.columnDefs[8].displayName = text.sharingKey;

      if (this.gridApi) {
        this.gridApi.core.notifyDataChange(this.uiGridConstants.dataChange.COLUMN);
      }
    });
  }

  public isDisabledButton(button: any) {
    let active: any = true;
    const rowsCount: any = this.gridApi.selection.getSelectedRows().length;
    if(['analytic'].indexOf(button) !== -1) {
      active *= Number(rowsCount === 1);
    }
    if(button === 'export') {
      active *= Number(rowsCount >= 1 && rowsCount <= 10);
    }
    if(['export', 'analytic'].indexOf(button) !== -1 && active) {
      active *= this.gridApi.selection.getSelectedRows().every((item) => item.product_name === 'Yandex');
    }
    return !active;
  }

  public getTooltipText(button: any) {
    if(!this.gridApi) {
      return '';
    }
    if(button === 'export' && this.gridApi.selection.getSelectedRows().length > 10) {
      return this.textLoc.selectTenOrLessSharing;
    }
    if(this.gridApi.selection.getSelectedRows().length !== 1) {
      return this.textLoc.selectAtLeastOneSharingFromTheTable;
    }
    if(!this.gridApi.selection.getSelectedRows().every((item) => item.product_name === 'Yandex')) {
      return this.textLoc.notAvailableForSelectedPlatform;
    }
  }

  public getLink(source: any) {
    switch(source) {
      case 'analytic':
        return this.getAnalyticLink();
      default:
        return '';
    }
  }

  // not used
  public openEditModal() {
    let modalInstance;
    const sharing = this.gridApi.selection.getSelectedRows()[0];

    modalInstance = this.$uibModal.open({
      component: 'modalEditSharingComponent',
      size: 'ls',
      resolve: {
        sharing: () => {
          return sharing;
        }
      }
    });

    return modalInstance.result.then((sharing: any) => {
      this.gridApi.selection.getSelectedRows()[0].cost_amount = sharing.cost_amount;
    });
  }

  public openAnalyticsModal(): void {
    let modalInstance: any;
    const sharings: any = this.gridApi.selection.getSelectedRows();

    modalInstance = this.$uibModal.open({
      component: 'ModalExportAnalyticsComponent',
      size: 'sm',
      resolve: {
        sharings: () => {
          return sharings;
        }
      }
    });

    return modalInstance.result.then((sharing: any) => {

    }, () => {});
  }

  public openFilterModal(): void {
    let modalInstance;

    modalInstance = this.$uibModal.open({
      component: 'modalSharingFilter',
      size: 'ls',
      resolve: {}
    });

    return modalInstance.result.then((sharing: any) => {
      this.filterData();
    }, () => {});
  }

  public clearFilter(): void {
    localStorage.removeItem('sharingFilter');
    this.filterData();
  }

  public getButtonText() {
    if(!this.gridApi.selection.getSelectedCount() || !this.gridApi.selection.getSelectedRows()[0].is_active) {
      return this.textLoc.activate;
    } else {
      return this.textLoc.deactivate;
    }
  }

  public toggleFilter(): void {
    this.gridConfig.enableFiltering = !this.gridConfig.enableFiltering;
    this.gridApi.core.notifyDataChange( this.uiGridConstants.dataChange.COLUMN );
  }

  public checkDisabled(): boolean {
    return !this.gridApi.selection.getSelectedCount() || this.gridApi.selection.getSelectedCount() > 10 ||
      !(this.gridApi.selection.getSelectedRows().every(row => row.is_active) || this.gridApi.selection.getSelectedRows().every(row => !row.is_active));
  }

  public disableSharing(): void {
    if(!this.gridApi.selection.getSelectedRows().length) {
      return;
    }
    if(!this.gridApi.selection.getSelectedRows()[0].is_active) {
      this.gridApi.selection.getSelectedRows().forEach((row) => {
        this.sharingService.editSharing(row.id, true);
      });
      return;
    }
    let modalInstance: any;
    const config: any = {
      component: 'ModalDeactivateComponent',
      size: 'md'
    };

    modalInstance = this.$uibModal.open(config);
    modalInstance.result.then(() => {
      this.gridApi.selection.getSelectedRows().forEach((row) => {
        this.sharingService.editSharing(row.id, false);
      });
    });
  }

  public navigate(button: string): void {
    if (this.isDisabledButton(button)) {
      return;
    }
    if(button === 'analytic' && this.gridApi.selection.getSelectedRows().length === 1) {
      this.router.navigate(['/sharing/' + this.gridApi.selection.getSelectedRows()[0].id + '/analytics']);
    }
  }

  public getSearchData(search: any) {
    const SEARCH_RESULTS_LIMIT = 50;
    const matcher = new RegExp(search, 'i');
    this.searchData = [];
    let segment;
    let colMatch;
    for(let i = 0; i < this.gridConfig.data.length && this.searchData.length < SEARCH_RESULTS_LIMIT; i++) {
      colMatch = 1;
      segment = this.gridConfig.data[i];
      if(segment.segment_name.match(matcher) || (String(segment.segment_id).match(matcher) && !(colMatch = 0))) {
        this.searchData.push({
          segment_name: segment.segment_name,
          segment_id: segment.segment_id,
          index: i,
          colMatch: colMatch
        });
      }
    }
  }

  public uiSelect(item: any, select: any) {
    if(!item) { return; }
    var treeLevel = this.gridApi.grid.rows[item.index].treeLevel - 1;
    var parentRow = this.gridApi.grid.rows[item.index];
    while(treeLevel-- >= 0) {
      parentRow = parentRow.treeNode.parentRow;
      this.gridApi.treeBase.expandRow(parentRow);
    }
    this.gridApi.selection.clearSelectedRows();
    this.gridApi.selection.selectRow(this.gridConfig.data[item.index]);

    const scrollToFocus = () => {
      this.gridApi.core.scrollTo(this.gridConfig.data[item.index], this.gridConfig.columnDefs[item.colMatch]);
    };

    setTimeout(scrollToFocus, 0);

    var event = document.createEvent('Event');
    select.clear(event);
  }

  private filterData(): any {
    let filters: any = null;
    let result: any = this.sharingList;
    this.isFilterExist = false;

    try {
      filters = JSON.parse(localStorage.getItem('sharingFilter'));
    } catch (e) {}

    if (filters) {
      this.isFilterExist = true;

      if (filters.operator === 'and') {
        filters.list.forEach((fil) => {
          result = this.getFilterData(result, fil);
        });
      } else {
        result = [];
        filters.list.forEach((fil) => {
          const itemList: any = this.getFilterData(this.sharingList, fil);
          itemList.forEach(item => {
            const itemExist: any = result.find((el) => el.id === item.id);
            if (!itemExist) {
              result.push(item);
            }
          });
        });
      }
    }

    this.gridConfig.data = result;
  }

  private getFilterData(data: any, filter: any): any {
    let result: any = [];
    switch (filter.field) {
      case 'segment_name':
        result = data.filter((item) => item.segment_name.toLowerCase().includes(filter.value.toLowerCase()));
        break;
      case 'is_active':
        result = data.filter((item) => item.is_active === filter.value);
        break;
      case 'product_name':
        result = data.filter((item) => item.product_name.toLowerCase().includes(filter.value.toLowerCase()));
        break;
      case 'customer_id':
        result = data.filter((item) => item.customer_id.toLowerCase().includes(filter.value.toLowerCase()));
        break;
      case 'cost_amount':
        result = data.filter((item) => {
          if (filter.operator === 'more') {
            return parseFloat(item.cost_amount) > filter.value;
          } else {
            return parseFloat(item.cost_amount) < filter.value;
          }
        });
        break;
      case 'created_at':
        result = data.filter((item) => {
          const itemData: number = moment(item.created_at).unix();
          const subtractData: number = moment().subtract(filter.value, 'days').unix();
          return itemData > subtractData;
        });
        break;
    }
    return result;
  }

  private getAnalyticLink() {
    if(!this.gridApi || this.gridApi.selection.getSelectedRows().length !== 1 || this.gridApi.selection.getSelectedRows()[0].product_name !== 'Yandex') {
      return '';
    } else {
      return '/sharing/' + this.gridApi.selection.getSelectedRows()[0].id + '/analytics';
    }
  }
}
