import * as angular from 'angular';
import { Component, OnInit } from 'angular-ts-decorators';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'domainsSearchComponent',
  templateUrl: './domains-search.pug'
})
export class DomainsSearchComponent implements OnInit {

  static $inject: string[] = [
    '$scope',
    'domainsResource',
    'Notification',
    '$uibModal'
  ];
  public preloader = true;
  public searchPreloader = false;
  public selectedDomains: any[] = [];
  public selectedDomainsText = '';
  public query = '';
  public gridApi: any;
  public gridConfig: any = {
    data: [],
    enableFiltering: false,
    enableRowSelection: false,
    enableSelectAll: true,
    enableColumnMenus: false,
    showGridFooter: false,
    showTreeExpandNoChildren: false,
    enableGridMenu: true,
    gridMenuShowHideColumns: false,
    exporterMenuPdf: false,
    enableGroupHeaderSelection: false,
    exporterOlderExcelCompatibility: true,

    paginationPageSizes: [250, 1000, 10000, 100000, 300000, 1000000],
    paginationPageSize: 250,
    useExternalPagination: true,

    exporterCsvFilename: 'domains_' + moment().format('DD_MM_YYYY') + '.csv',
    exporterCsvLinkElement: angular.element(document.querySelectorAll('.custom-csv-link-location')),
    exporterCsvColumnSeparator: ';',

    onRegisterApi: (gridApi: any) => {
      this.gridApi = gridApi;

      gridApi.pagination.on.paginationChanged(this.$scope, (newPage: any, pageSize: any) => {
        this.searchDomains(newPage);
      });
    },

    columnDefs: [
      {
        field: 'domain',
        displayName: 'Domain',
        headerCellFilter: 'translate',
        cellTemplate: `<div class='ui-grid-cell-contents'><a href='http://{{row.entity.domain}}' target='_blank'>{{row.entity.domain}}</a></div>`,
        width: '200'
      },
      {
        field: 'title',
        displayName: 'Title',
        headerCellFilter: 'translate',
        width: '*'
      },
      {
        field: 'uniqs',
        displayName: 'Uniqs',
        headerCellFilter: 'translate',
        cellFilter: 'number:0',
        type: 'number',
        width: '100'
      },
      {
        name: 'add',
        displayName: 'Add',
        headerCellFilter: 'translate',
        cellTemplate: `<div class='ui-grid-cell-contents center' ng-click='grid.appScope.$ctrl.toggleDomain(row.entity.domain)'><span ng-class='grid.appScope.$ctrl.getIcon(row.entity.domain)'></span></div>`,
        width: '50'
      }
      // {
      // 	name: 'info',
      // 	displayName: 'Info',
      // 	headerCellFilter: 'translate',
      // 	cellTemplate: "<div class='ui-grid-cell-contents center' ng-click='grid.appScope.$ctrl.showInfo(row.entity)'><span class='glyphicon glyphicon-info-sign'></span></div>",
      // 	width: '50'
      // }
    ]
  };

  constructor(private $scope: any,
              private domainsResource: any,
              private Notification: any,
              private $uibModal: any) {
  }

  ngOnInit(): void {
    this.searchDomains(null);
  }

  public toggleDomain(domain: any) {
    let index = this.selectedDomains.indexOf(domain);
    if(index === -1) {
      this.selectedDomains.push(domain);
    } else {
      this.selectedDomains.splice(index, 1);
    }
    this.selectedDomainsText = this.selectedDomains.join(' ');
  }

  public addSelectedDomains() {
    this.selectedDomains = _.uniq(this.gridApi.selection.getSelectedRows().map((item: any) => {
      return item.domain;
    }).concat(this.selectedDomains));
    this.selectedDomainsText = this.selectedDomains.join(' ');
  }

  public clearDomains() {
    this.selectedDomains.length = 0;
    this.selectedDomainsText = '';
  }

  public getIcon(domain: any) {
    if(this.selectedDomains.indexOf(domain) === -1) {
      return 'glyphicon glyphicon-plus';
    } else {
      return 'glyphicon glyphicon-minus';
    }
  }

  public searchDomains(page: any) {
    if(this.searchPreloader) {
      return;
    }
    this.searchPreloader = true;
    let data: any = {page_size: this.gridConfig.paginationPageSize};
    this.query && (data.q = this.query);
    page && (data.page = page);
    this.domainsResource.query(data).$promise.then((response: any) => {
      let data = response.data;
      this.gridConfig.data = data.results;
      this.gridConfig.totalItems = data.count;
    }).catch((error: any) => {
      let messgae = error.data.error_message || error.data;
      this.Notification.error(messgae);
    }).finally(() => {
      this.preloader = false;
      this.searchPreloader = false;
    });
  }

}
