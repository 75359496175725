import * as angular from 'angular';
import * as moment from 'moment';

export class Audience {
  segment_name: any;
  segment_id: any;
  id: any;
  size: any;

  constructor(audience: any = {}) {
    this.segment_name = audience.segment_name || '';
    this.segment_id = audience.segment_id || null;
    this.id = audience.id || null;
    this.size = audience.size || 0;
  }

  clean() {
    this.segment_name = '';
    this.segment_id = null;
    this.id = null;
    this.size = 0;
  }
}

// let $injector = angular.element(document.body).injector();

export class GlobalInjector {
  protected $injector = angular.element(document.body).injector();
}

export class Tag extends GlobalInjector {
  public id: any;
  public parent: any;
  public name: any;
  public name_ru: string;
  private classifierResource: any = this.$injector.get('classifierResource');

  constructor(tag: any = {}) {
    super();
    this.name_ru = tag.name_ru || '';
    this.name = tag.name || this.name_ru;
    this.parent = tag.parent || null;
    this.id = tag.id || null;
  }

  public getUrls() {
    return this.classifierResource.urls({tag: this.id}).$promise;
  }

}


export class ClassifierHistoryModel extends GlobalInjector {

  model_hash: any;
  metric_mean_error: any;
  id: any;
  classifier: any;
  created_at: any;
  metric_mean_squared_error: any;
  metric_score: any;
  metric_error: any;
  public thresholds: any;
  private classifierResource: any = this.$injector.get('classifierResource');
  private $q: any = this.$injector.get('$q');

  constructor(historyModel: any = {}) {
    super();
    this.thresholds = historyModel.thresholds || {};
    this.model_hash = historyModel.model_hash || '';
    this.metric_error = historyModel.metric_error || 0;
    this.metric_mean_error = historyModel.metric_mean_error || 0;
    this.metric_score = historyModel.metric_score || 0;
    this.metric_mean_squared_error = historyModel.metric_mean_squared_error || 0;
    this.created_at = historyModel.created_at || null;
    this.classifier = historyModel.classifier || null;
    this.id = historyModel.id || null;
  }

  public getPredictReport() {
    return this.$q((resolve: any, reject: any) => {
      this.classifierResource.predictreport({historymodel: this.id}).$promise.then((response: any) => {
        return resolve(response.data);
      }).catch(reject);
    });
  }

  public getDefaultReport() {
    return this.$q((resolve: any, reject: any) => {
      this.classifierResource.get({object: 'report', model: this.id, is_default: 'True'})
        .$promise
        .then((response: any) => {
          let defaultReport = response.data.reduce((previousValue: any, currentItem: any) => {
            if(!previousValue) {
              return currentItem;
            }
            return moment(previousValue.created_at) < moment(currentItem.created_at) ?
              currentItem :
              previousValue;
          }, null);
          return resolve(defaultReport);
        })
        .catch(reject);
    });
  }
}

export class Classifier extends GlobalInjector {

  id: any;
  tag: any;
  sgd_penalty: any;
  sgd_n_iter: any;
  sgd_loss: any;
  sgd_learning_rate: any;
  sgd_l1_ratio: number;
  sgd_epsilon: number;
  sgd_alpha: number;
  kind: any;
  percent: any;
  is_active: any;
  group: any;
  name: string;
  public kind_list = ['sgdclassifier'];
  public sgd_learning_rate_list = ['optimal'];
  public sgd_penalty_list = ['l2'];
  public sgd_loss_list = ['modified_huber'];

  private $q: any = this.$injector.get('$q');
  private classifierResource: any = this.$injector.get('classifierResource');

  constructor(classifier: any = {}) {
    super();
    this.name = 'classifier';
    this.is_active = classifier.is_active || true;
    this.percent = classifier.percent || 75;
    this.kind = classifier.kind || this.kind_list[0];
    this.sgd_alpha = parseFloat(classifier.sgd_alpha) || 0.0001;
    this.sgd_epsilon = parseFloat(classifier.sgd_epsilon) || 0.1;
    this.sgd_l1_ratio = parseFloat(classifier.sgd_l1_ratio) || 0.15;
    this.sgd_learning_rate = classifier.sgd_learning_rate || this.sgd_learning_rate_list[0];
    this.sgd_loss = classifier.sgd_loss || this.sgd_loss_list[0];
    this.sgd_n_iter = classifier.sgd_n_iter || 5;
    this.sgd_penalty = classifier.sgd_penalty || this.sgd_penalty_list[0];
    this.tag = classifier.tag || null;
    this.id = classifier.id || null;
  }

  public getUrls() {
    return this.classifierResource.urls(this.group ? {group: this.group} : {tag: this.tag}).$promise;
  }

  public getLastHistoryModel() {
    return this.$q((resolve: any, reject: any) => {
      this.classifierResource.historymodel({classifier: this.id}).$promise.then((response: any) => {
        let lastModel = response.data.reduce((previousValue: any, currentItem: any) => {
          if(!previousValue) {
            return currentItem;
          }
          return moment(previousValue.created_at) < moment(currentItem.created_at) ?
            currentItem :
            previousValue;
        }, null);
        return lastModel ? resolve(new ClassifierHistoryModel(lastModel)) : reject();
      }).catch(reject);
    });
  }

  public copy(classifier: any) {
    if(!(classifier instanceof Classifier)) {
      return;
    }
    this.is_active = classifier.is_active;
    this.kind = classifier.kind;
    this.percent = classifier.percent;
    this.sgd_alpha = classifier.sgd_alpha;
    this.sgd_epsilon = classifier.sgd_epsilon;
    this.sgd_l1_ratio = classifier.sgd_l1_ratio;
    this.sgd_learning_rate = classifier.sgd_learning_rate;
    this.sgd_loss = classifier.sgd_loss;
    this.sgd_n_iter = classifier.sgd_n_iter;
    this.sgd_penalty = classifier.sgd_penalty;
    this.tag = classifier.tag;
  }

  public save() {
    return this.$q((resolve: any, reject: any) => {
      let action = this.id ? 'save' : 'create';
      this.classifierResource[action](this.getSaveData()).$promise.then((response: any) => {
        this.id = response.data.id;
        return resolve();
      }).catch(reject);
    });
  }

  private getSaveData() {
    let data: any = {
      object: 'classifier',
      is_active: this.is_active,
      kind: this.kind,
      percent: this.percent,
      sgd_alpha: this.sgd_alpha,
      sgd_epsilon: this.sgd_epsilon,
      sgd_l1_ratio: this.sgd_l1_ratio,
      sgd_learning_rate: this.sgd_learning_rate,
      sgd_loss: this.sgd_loss,
      sgd_n_iter: this.sgd_n_iter,
      sgd_penalty: this.sgd_penalty,
      tag: this.tag
    };
    this.id && (data.id = this.id);
    return data;
  }
}



