import { Component } from 'angular-ts-decorators';

@Component({
  selector: 'pagePreloader',
  templateUrl: './page-preloader.pug'
})
export class PagePreloader {
  private langId: any = localStorage.getItem('langId') || 'ru';
  public text = this.langId ? 'Загрузка, пожалуйста подождите ...' : 'Loading, please wait ...';
}
