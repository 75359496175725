import { StateProvider } from '@uirouter/angularjs';
import { ConstService } from '../../app/shared/const.service';

export const pixelRouting = ($stateProvider: StateProvider) => {
  $stateProvider
    .state('layout.pixels', {
      url: '/pixel/list',
      component: 'pixelListComponent',
      data: {
        permissions: {
          except: [ConstService.USER_ROLES.unauthorized],
          redirectTo: ConstService.REDIRECTS.base
        }
      }
    })
    .state('layout.pixel_analytics', {
      url: '/pixel/{pixelId:int}/analytics',
      component: 'pixelAnalyticsComponent',
      data: {
        permissions: {
          except: [ConstService.USER_ROLES.unauthorized],
          redirectTo: ConstService.REDIRECTS.base
        }
      }
    })
    .state('layout.pixel_create', {
      url: '/pixel/create',
      component: 'pixelEditComponent',
      data: {
        permissions: {
          except: [ConstService.USER_ROLES.unauthorized],
          redirectTo: ConstService.REDIRECTS.base
        }
      }
    })
    .state('layout.pixel_edit', {
      component: 'pixelEditComponent',
      url: '/pixel/{pixelId:int}/edit',
      data: {
        permissions: {
          except: [ConstService.USER_ROLES.unauthorized],
          redirectTo: ConstService.REDIRECTS.base
        },
        mode: 'edit'
      }
    });
};
