import { Router } from '@angular/router';
import { Component, OnInit } from 'angular-ts-decorators';
import * as _ from 'lodash';
import { AuthService } from '../../../app/core/services/auth.service';
import { ConstService } from '../../../app/shared/const.service';
import { PixelService } from '../../../app/core/services/pixel.service';

@Component({
  selector: 'pixelListComponent',
  templateUrl: './pixel-list.pug'
})
export class PixelListComponent implements OnInit {

  static $inject: string[] = ['$animate', '$uibModal', '$scope', '$filter', 'pixelService', 'authService', 'Notification', 'router', 'notifyService', 'uiGridConstants', 'i18nService'];
  public gridApi: any;
  private langId: any = localStorage.getItem('langId') || 'ru';
  // todo: (prokopenko) move all configs creation to factory
  public gridConfig: any;
  public preloader = true;
  public textLoc: any = {};
  public tariff: any = {};

  constructor(private $animate: any,
              private $uibModal: any,
              private $scope: any,
              private $filter: any,
              private pixelService: PixelService,
              private authService: AuthService,
              private Notification: any,
              private router: Router,
              private notifyService: any,
              private uiGridConstants: any,
              private i18nService: any
  ) {
  }

  ngOnInit(): void {
    const popupText = this.langId === 'ru' ? 'Уникальный охват за 30 дней' : 'Unique reach for 30 days';
    this.i18nService.setCurrentLang(this.langId);
    this.gridConfig = {
      data: [],
      enableFiltering: false,
      enableRowSelection: true,
      enableSelectAll: false,
      enableColumnMenus: false,
      exporterMenuVisibleData : false,
      multiSelect: false,
      enableGridMenu: true,
      gridMenuShowHideColumns: false,

      exporterCsvFilename: 'pixels.csv',
      exporterFieldCallback: (grid: any, row: any, col: any, value: any) => {
        if(col.name === 'is_active') {
          value = this.$filter('IsActive')(value);
        }
        if(col.name === 'updated_at') {
          value = this.$filter('IsAvailable')(value);
        }
        return value;
      },
      exporterMenuPdf: false,
      exporterMenuExcel: false,

      exporterPdfDefaultStyle: {fontSize: 9},
      exporterPdfTableStyle: {margin: [0, 0, 0, 0]},
      exporterPdfTableHeaderStyle: {
        fontSize: 10,
        bold: true,
        italics: true,
        color: 'red'
      },
      exporterPdfHeader: {text: 'Segments', style: 'headerStyle'},
      exporterPdfFooter: (currentPage: any, pageCount: any) => {
        return {text: currentPage.toString() + ' of ' + pageCount.toString(), style: 'footerStyle'};
      },
      exporterPdfCustomFormatter: (docDefinition: any) => {
        docDefinition.styles.headerStyle = {fontSize: 22, bold: true, margin: [40, 10, 0, 0]};
        docDefinition.styles.footerStyle = {fontSize: 10, bold: true, margin: [40, 10, 0, 0]};
        docDefinition.content[0].table.widths = ['*', '*', 'auto', 'auto', 'auto', 'auto', 'auto'];
        return docDefinition;
      },
      exporterPdfOrientation: 'landscape',
      exporterPdfPageSize: 'LETTER',
      exporterPdfMaxGridWidth: 650,

      onRegisterApi: (gridApi: any) => {
        this.gridApi = gridApi;
        this.$animate.enabled(gridApi.grid.element, false);
      },
      columnDefs: [
        {
          field: 'pid',
          displayName: 'Pixel ID',
          width: 130
        },
        {
          field: 'description',
          displayName: 'Description',
          width: '*',
          headerCellFilter: 'translate'
        },
        {
          field: 'is_active',
          displayName: 'Status',
          width: 100,
          cellFilter: 'IsActive|translate',
          headerCellFilter: 'translate'
        },
        {
          field: 'size',
          type: 'number',
          displayName: 'Size',
          // todo: (prokopenko) use template url
          headerCellTemplate:
            `<div role="columnheader" ng-class="{ \'sortable\': sortable }" ui-grid-one-bind-aria-labelledby-grid="col.uid + \'-header-text \' + col.uid + \'-sortdir-text\'" aria-sort="{{col.sort.direction == asc ? \'ascending\' : ( col.sort.direction == desc ? \'descending\' : (!col.sort.direction ? \'none\' : \'other\'))}}"><div role="button" tabindex="0" class="ui-grid-cell-contents ui-grid-header-cell-primary-focus"col-index="renderIndex" title="TOOLTIP"><span class="ui-grid-header-cell-label" ui-grid-one-bind-id-grid="col.uid + \'-header-text\'">{{ col.displayName CUSTOM_FILTERS }}</span>
<span class="audiences__size_popover glyphicon glyphicon-info-sign" popover-append-to-body="true" uib-popover="${popupText}" popover-trigger="mouseenter"></span>
<span ui-grid-one-bind-id-grid="col.uid + \'-sortdir-text\'" ui-grid-visible="col.sort.direction" aria-label="{{getSortDirectionAriaLabel()}}"> <i ng-class="{ \'ui-grid-icon-up-dir\': col.sort.direction == asc, \'ui-grid-icon-down-dir\': col.sort.direction == desc, \'ui-grid-icon-blank\': !col.sort.direction }" title="{{isSortPriorityVisible() ? i18n.headerCell.priority + \' \' + ( col.sort.priority + 1 )  : null}}" aria-hidden="true"> </i> <sub ui-grid-visible="isSortPriorityVisible()" class="ui-grid-sort-priority-number"> {{col.sort.priority + 1}} </sub> </span> </div> <div role="button" tabindex="0" ui-grid-one-bind-id-grid="col.uid + \'-menu-button\'" class="ui-grid-column-menu-button" ng-if="grid.options.enableColumnMenus && !col.isRowHeader  && col.colDef.enableColumnMenu !== false" ng-click="toggleMenu($event)" ng-class="{\'ui-grid-column-menu-button-last-col\': isLastCol}" ui-grid-one-bind-aria-label="i18n.headerCell.aria.columnMenuButtonLabel" aria-haspopup="true"> <i class="ui-grid-icon-angle-down" aria-hidden="true"> &nbsp; </i> </div> <div ui-grid-filter></div> </div>`,
          // headerCellTemplate: "grid-header-cell.html",
          cellClass: 'to-right',
          cellFilter: 'number:0',
          headerCellFilter: 'translate',
          width: 130
        },
        {
          field: 'size_updated',
          displayName: 'Updated',
          cellFilter: 'IsAvailable',
          headerCellFilter: 'translate',
          cellClass: 'center',
          width: 150
        },
        {
          field: 'created_at',
          displayName: 'Created',
          headerCellFilter: 'translate',
          width: 150
        }
      ]
    };

    this.pixelService.getPixels().then((pixels: any) => {
      this.preloader = false;
      this.gridConfig.data = pixels;
    });

    this.notifyService.notification$.subscribe((message: string) => {
      if (message === ConstService.AUTH_EVENTS.changeAccount || message === ConstService.AUTH_EVENTS.impersonated) {
        this.pixelService.getPixels().then((pixels: any) => {
          this.preloader = false;
          this.gridConfig.data = pixels;
        });
      }
    });

    this.$scope.$on(ConstService.AUTH_EVENTS.impersonated, () => {
      this.pixelService.getPixels();
    });

    this.authService.text$.subscribe((text) => {
      this.textLoc = text;
      this.gridConfig.columnDefs[0].displayName = text.pixelID;
      this.gridConfig.columnDefs[1].displayName = text.description;
      this.gridConfig.columnDefs[2].displayName = text.status;
      this.gridConfig.columnDefs[3].displayName = text.sizeL;
      this.gridConfig.columnDefs[4].displayName = text.updated;
      this.gridConfig.columnDefs[5].displayName = text.created;
      if (this.gridApi) {
        this.gridApi.core.notifyDataChange(this.uiGridConstants.dataChange.COLUMN);
      }
    });

    this.authService.userTariff$.subscribe((tariff: any) => {
      if (!tariff) {
        return;
      }
      this.tariff = tariff;
    });
  }

  public getTooltipText(button: any) {
    if(!this.gridApi) {
      return '';
    }
    if (button === 'analytic' && !this.tariff.type) {
      return this.textLoc.tariffPermissionError;
    }
    if(['analytic', 'code'].indexOf(button) === -1 && _.get(this.authService.getSelectedAccount(), 'permission') !== 'edit') {
      return this.textLoc.accountPermission;
    }
    if(!this.gridApi.selection.getSelectedRows().length) {
      return this.textLoc.selectAtLeastOnePixelFromTheTable;
    }
    if(!this.gridApi.selection.getSelectedRows()[0].is_active) {
      return this.textLoc.availableOnlyForActivePixels;
    }
    // if (button === "code") {
    // 	return "View pixel code actions is available only for pixels with 'external' and '1stparty' types";
    // }
    if(button === 'analytic') {
      return this.textLoc.analyticsIsAvailableOnlyForType;
    }
    return '';
  }

  public getLink(source: string) {
    switch(source) {
      case 'audience_create':
        return this.getSegmentCreateLink();
      case 'analytic':
        return this.getAnalyticLink();
      case 'edit':
        return this.getEditLink();
      case 'create':
        return this.getCreateLink();
      default:
        return '';
    }
  }

  public isDisabled(button: any) {
    if(!this.gridApi) {
      return true;
    }
    let active: any = true;
    if(['code', 'edit', 'delete', 'audience', 'analytic'].indexOf(button) !== -1) {
      active *= Number(this.gridApi.selection.getSelectedRows().length);
    }
    if(['audience', 'code', 'analytic'].indexOf(button) !== -1 && active) {
      active *= this.gridApi.selection.getSelectedRows()[0].is_active;
    }
    if(['analytic'].indexOf(button) !== -1 && active) {
      active *= (this.gridApi.selection.getSelectedRows()[0].collect_analytics);
    }
    if(['edit', 'audience', 'create', 'delete'].indexOf(button) !== -1 && active) {
      active *= Number(_.get(this.authService.getSelectedAccount(), 'permission') === 'edit');
    }
    if(['analytic'].indexOf(button) !== -1 && active && !this.tariff.type) {
      active *= 0;
    }
    return !active;
  }

  public openModal(mode: any) {
    if(this.isDisabled(mode)) {
      return;
    }
    let modalInstance;
    const config: any = {
      animation: true,
      component: '',
      size: 'lg',
      resolve: {
        pixel: () => {
          return this.gridApi.selection.getSelectedRows()[0];
        }
      }
    };
    let onConfirm: any = () => { return; };
    switch(mode) {
      case 'code':
        config.component = 'modalPixelCodeComponent';
        config.size = 'ls';
        config.resolve.pixelId = () => {
          return this.gridApi.selection.getSelectedRows()[0].pid;
        };
        break;
      case 'delete':
        config.component = 'modalPixelDeleteComponent';
        config.size = 'ls';
        config.resolve.type = function() {
          return 'pixel';
        };
        config.resolve.itemData = config.resolve.pixel;
        onConfirm = (resp: any) => {
          if(resp) {
            this.Notification.success('Pixel deleted');
          }
        };
        break;
      default:
        break;
    }

    modalInstance = this.$uibModal.open({
      component: config.component,
      animation: config.animation,
      size: config.size,
      resolve: config.resolve
    });
    modalInstance.mode = mode;
    modalInstance.result.then(onConfirm).catch(() => { return; });
  }

  public navigate(button: string): void {
    if(button === 'audience_create') {
      this.router.navigate(['/audiences/create/' + this.gridApi.selection.getSelectedRows()[0].id ]);
    }
  }

  private getSegmentCreateLink() {
    if(!this.gridApi || this.isDisabled('audience')) {
      return '';
    } else {
      return '/audiences/create/' + this.gridApi.selection.getSelectedRows()[0].id;
    }
  }

  private getAnalyticLink() {
    if(!this.gridApi || this.isDisabled('analytic')) {
      return '';
    } else {
      return '/pixel/' + this.gridApi.selection.getSelectedRows()[0].id + '/analytics';
    }
  }

  private getEditLink() {
    if(!this.gridApi || this.isDisabled('edit')) {
      return '';
    } else {
      return '/pixel/' + this.gridApi.selection.getSelectedRows()[0].id + '/edit';
    }
  }

  private getCreateLink() {
    if(!this.gridApi || this.isDisabled('create')) {
      return '';
    } else {
      return '/pixel/create';
    }
  }

}
