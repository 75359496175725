import { IFilterService } from 'angular';
import { Pipe, PipeTransform } from 'angular-ts-decorators';

@Pipe({name: 'IsActive'})
export class IsActive implements PipeTransform {
  public transform(input: string) {
    if(!input) {
      return 'Inactive';
    } else {
      return 'Active';
    }
  }
}

@Pipe({name: 'IsAvailable'})
export class IsAvailable implements PipeTransform {
  public transform(input: string) {
    if(!input) {
      return 'n/a';
    } else {
      return input;
    }
  }
}

@Pipe({name: 'NumberIsAvailable'})
export class NumberIsAvailable implements PipeTransform {
  static $inject: string[] = [
    '$filter'
  ];
  constructor(private $filter: IFilterService) {}

  public transform(input: string, param: string) {
    if(input === null || input === undefined) {
      return 'n/a';
    } else {
      return this.$filter('number')(input, param);
    }
  }
}

@Pipe({name: 'IsNaN'})
export class IsNaN implements PipeTransform {
  public transform(input: string) {
    if(isNaN(parseInt(input, 10)) || input === '') {
      return 'n/a';
    } else {
      return input;
    }
  }
}


@Pipe({name: 'uiselectAddSearch'})
export class UiSelectAddSearch implements PipeTransform {
  static $inject: string[] = [
    '$filter'
  ];
  constructor(private $filter: IFilterService) {}

  public transform(input: any) {
    if(!input) {
      return;
    }
    const search = input[0].segment_name;
    let filtred: string[] = this.$filter('filter')(input, search);
    if(!search) {
      filtred = filtred.slice(1);
    }
    return filtred;
  }
}
