import { Component, OnInit } from 'angular-ts-decorators';

@Component({
  selector: 'marketplacePublicComponent',
  templateUrl: './marketplace-public.pug'
})
export class MarketplacePublicComponent implements OnInit {

  static $inject: string[] = [
    '$filter',
    'marketplaceService'
  ];
  public gridConfig: any;
  public gridApi: any;
  public preloader = true;
  public searchData: any = [];
  public searchFilter = '';
  public getAnalyticLink: any;

  constructor(private $filter: any,
              private marketplaceService: any) {
  }

  ngOnInit(): void {
    // todo: (prokopenko) move all configs creation to factory
    // todo: (prokopenko) duplicate of marketplace
    this.gridConfig = {
      data: [],
      enableFiltering: false,
      enableRowSelection: true,
      enableSelectAll: false,
      enableColumnMenus: false,
      multiSelect: false,
      enableGridMenu: true,
      showTreeExpandNoChildren: false,
      gridMenuShowHideColumns: false,

      exporterCsvFilename: 'market_segments.csv',
      exporterFieldCallback: (grid: any, row: any, col: any, value: any) => {
        if(col.name === 'is_active') {
          value = this.$filter('IsActive')(value);
        }
        if(col.name === 'updated_at') {
          value = this.$filter('IsAvailable')(value);
        }
        return value;
      },
      exporterMenuPdf: false,
      exporterMenuExcel: false,
      treeIndent: 1,
      treeRowHeaderBaseWidth: 30,

      rowTemplate: '<div grid="grid" ng-class="{ \'audiences__grid_folder\': row.entity.is_empty }"><div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader, \'custom\': true }" ui-grid-cell></div></div>',

      onRegisterApi: (gridApi: any) => {
        this.gridApi = gridApi;

        this.getAnalyticLink = () => {
          if(this.gridApi.selection.getSelectedCount() !== 1 || this.gridApi.selection.getSelectedRows()[0].is_empty) {
            return '';
          } else {
            return '/marketplace/' + this.gridApi.selection.getSelectedRows()[0].id + '/analytics';
          }
        };
      },
      columnDefs: [
        {
          field: 'segment_name',
          displayName: 'Name',
          headerCellFilter: 'translate',
          sort: {direction: 'asc', priority: 1},
          width: '*',
          cellTemplate: `<div class='ui-grid-cell-contents'>{{row.entity.segment_name}} <span ng-show='!row.entity.is_empty && !!row.entity.segment_id'>({{row.entity.segment_id}})</span></div>`,
          cellClass: (grid: any, row: any) => {
            let addClass = 'tree_level--' + row.entity.$$treeLevel;
            if(row.entity.is_empty) {
              addClass += ' segments__group_row';
            }
            addClass += ' audiences__cell_name';
            return addClass;
          }
        },
        {
          field: 'size',
          type: 'number',
          displayName: 'Size',
          cellClass: 'to-right',
          cellFilter: 'number:0',
          headerCellFilter: 'translate',
          width: 100
        },
        {
          field: 'is_empty',
          type: 'boolean',
          displayName: 'group',
          visible: false,
          sort: {direction: 'desc', priority: 0}
        }
      ]
    };
    this.marketplaceService.getPublicMarketplace().then((segments: any) => {
      this.gridConfig.data = segments;
      this.preloader = false;
    });
  }

  public getSearchData(search: any) {
    const SEARCH_RESULTS_LIMIT = 50;
    let matcher = new RegExp(search, 'i');
    this.searchData = [];
    let segment;
    let colMatch;
    for(let i = 0; i < this.gridConfig.data.length && this.searchData.length < SEARCH_RESULTS_LIMIT; i++) {
      colMatch = 1;
      segment = this.gridConfig.data[i];
      if(segment.segment_name.match(matcher) || (String(segment.segment_id).match(matcher) && !(colMatch = 0))) {
        this.searchData.push({
          segment_name: segment.segment_name,
          segment_id: segment.segment_id,
          index: i,
          colMatch: colMatch
        });
      }
    }
  }

  public uiSelect(item: any, select: any) {
    if(!item) { return; }
    let treeLevel = this.gridApi.grid.rows[item.index].treeLevel - 1;
    let parentRow = this.gridApi.grid.rows[item.index];
    while(treeLevel-- >= 0) {
      parentRow = parentRow.treeNode.parentRow;
      this.gridApi.treeBase.expandRow(parentRow);
    }
    this.gridApi.selection.clearSelectedRows();
    this.gridApi.selection.selectRow(this.gridConfig.data[item.index]);

    const scrollToFocus = () => {
      this.gridApi.core.scrollTo(this.gridConfig.data[item.index], this.gridConfig.columnDefs[item.colMatch]);
    };

    setTimeout(scrollToFocus, 0);

    const event = document.createEvent('Event');
    select.clear(event);
  }
}


