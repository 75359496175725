import { Component } from 'angular-ts-decorators';

@Component({
  selector: 'layoutComponent',
  templateUrl: './layout.pug'
})
export class LayoutComponent {

  static $inject: string[] = [];

  constructor() {
  }
}
