import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
// RxJS
import { Observable } from 'rxjs';
import { Observer } from 'rxjs/internal/types';
import { tap } from 'rxjs/operators';
// Services
import { ConstService } from '../../shared/const.service';
import { NotifyService } from '../../shared/notify.service';
// Utils
import * as Highcharts from 'highcharts';

@Injectable({
  providedIn: 'root'
})
export class BillingService {
  private billingUrl: string = '/api/v1/partner/billing/';
  private billing: any = {
    updated: false,
    currencyRates: {},
    data: [],
    platforms: [],
    from: new Date(),
    to: new Date()
  };
  private chart: any = {
    updated: false,
    segmentIds: [],
    monthCount: 0,
    config: {
      options: {
        title: {
          text: null
        },
        chart: {
          zoomType: 'xy'
        },
        tooltip: {
          style: {
            padding: 10,
            fontWeight: 'bold'
          }
        }
      },
      xAxis: [{
        categories: [],
        crosshair: true
      }],
      yAxis: [{
        labels: {
          style: {
            color: Highcharts.getOptions().colors[1]
          }
        },
        title: {
          style: {
            color: Highcharts.getOptions().colors[1]
          }
        },
        opposite: false,
        visible: false
      },
        {
          labels: {
            style: {
              color: Highcharts.getOptions().colors[0]
            }
          },
          title: {
            style: {
              color: Highcharts.getOptions().colors[0]
            }
          },
          opposite: true,
          visible: false
        }],
      series: [],
      tooltip: {
        shared: true
      },
      legend: {
        layout: 'vertical',
        align: 'left',
        x: 120,
        verticalAlign: 'top',
        y: 100,
        floating: true,
        backgroundColor: '#FFFFFF'
      }
    },
    data: {
      totalImpressions: 0,
      totalEarned: 0,
      totalClicks: 'n/a',
      totalCTR: 'n/a',
      xAxis: [],
      xCategories: [],
      Earned: {
        data: [],
        // format: '${value:,.0f}',
        format: '{value:,.0f} руб',
        text: 'Earned',
        name: 'Earned',
        // prefix: '$',
        suffix: ' руб',
        color: '#8CC663'
      },
      Impressions: {
        data: [],
        format: '{value:,.0f}',
        text: 'Impressions',
        name: 'Impressions',
        suffix: '',
        color: '#78C8E5'
      },
      Clicks: {
        data: [],
        format: '{value:,.0f}',
        text: 'Clicks',
        name: 'Clicks',
        suffix: '',
        color: '#F7CA5D'
      },
      CTR: {
        data: [],
        format: '{value::.2f}%',
        text: 'CTR',
        name: 'CTR',
        suffix: '%',
        color: '#F08F66'
      }
    }
  };

  private grid: any = {
    updated: false,
    data: []
  };

  constructor(private notifyService: NotifyService,
              private http: HttpClient
  ) {
    this.notifyService.notification$.subscribe((message: string) => {
      switch(message) {
        case ConstService.AUTH_EVENTS.impersonated:
          this.notUpdated();
          break;
        case ConstService.AUTH_EVENTS.login:
          break;
        case ConstService.AUTH_EVENTS.logout:
          this.notUpdated();
          break;
      }
    });
  }

  public getBillingData(dateFrom: any, dateTo: any, platforms: any): Promise<any> {
    if(this.billing.from === dateFrom && this.billing.to === dateTo
      && this.billing.platforms.toString() === platforms.toString() && this.billing.updated) {
      return new Observable((observer: Observer<any>) => {
        observer.next([]);
        observer.complete();
      }).toPromise();
    }
    return this.http.get( this.billingUrl, {params: {
        platform_id: platforms.toString(),
        period_0: moment(dateFrom).format('YYYY-MM-DD'),
        period_1: moment(dateTo).format('YYYY-MM-DD')
      }}).pipe(tap((response: any) => {
        this.billing.platforms = platforms.slice();
        this.billing.from = dateFrom;
        this.billing.to = dateTo;
        this.billing.data.length = 0;
        this.grid.updated = false;
        this.chart.updated = false;
        this.billing.updated = true;
        response.data.forEach((item: any) => {
          this.billing.data.push(item);
        });
        this.calcCost('RUB');
    })).toPromise();
  }

  public getGridData(): any {
    if(this.grid.updated) {
      return this.grid.data;
    }
    const gridData: any = {};
    this.billing.data.forEach((segment: any) => {
      if(gridData.hasOwnProperty(segment.segment_id)) {
        gridData[segment.segment_id].earned += segment.calcCost;
        gridData[segment.segment_id].impressions += segment.impressions;
        if(gridData[segment.segment_id].clicks === 'n/a' || segment.clicks === null) {
          gridData[segment.segment_id].clicks = 'n/a';
        } else {
          gridData[segment.segment_id].clicks += segment.clicks;
        }
      } else {
        gridData[segment.segment_id] = {
          segment_id: segment.segment_id,
          segment_name: segment.segment_name,
          description: segment.description,
          platform_id: segment.platform_id,
          earned: segment.calcCost,
          impressions: segment.impressions,
          clicks: segment.clicks === null ? 'n/a' : segment.clicks,
          ctr: 0,
          parent: ''
        };
      }
      if(gridData[segment.segment_id].impressions !== 0 && gridData[segment.segment_id].clicks !== 'n/a') {
        gridData[segment.segment_id].ctr =
          Math.round(gridData[segment.segment_id].clicks / gridData[segment.segment_id].impressions * 100 * 100) / 100;
      } else {
        gridData[segment.segment_id].ctr = 'n/a';
      }
    });

    this.grid.data.length = 0;
    for(const row in gridData) {
      if(gridData.hasOwnProperty(row)) {
        gridData[row].earned = Math.round(gridData[row].earned * 100) / 100;
        this.grid.data.push(gridData[row]);
      }
    }
    this.grid.updated = true;
    return this.grid.data;
  }

  public notUpdated(): void {
    this.chart.updated = false;
    this.grid.updated = false;
    this.billing.updated = false;
  }

  public getChartData(segmentIds: any): any {
    if(!segmentIds) {
      segmentIds = [];
    }
    const segmentIdsEquals: boolean = (segmentIds.length === this.chart.segmentIds.length &&
      segmentIds.every( (value: any, i: number) => value === this.chart.segmentIds[i] ));
    if(this.chart.updated && segmentIdsEquals) {
      return this.chart.data;
    }
    this.chart.segmentIds = segmentIds.slice();
    if(!this.chart.updated) {
      this.chart.config.xAxis[0].categories.length = 0;
      this.chart.monthCount = this.billing.to.getMonth() - this.billing.from.getMonth() +
        (12 * (this.billing.to.getFullYear() - this.billing.from.getFullYear())) + 1;
      for(let i: number = 0; i < this.chart.monthCount; i++) {
        this.chart.config.xAxis[0].categories.push(
          moment(new Date(this.billing.from.getFullYear(), this.billing.from.getMonth() + i, 1)).format('YYYY-MM-DD'));
      }
      this.chart.data.Earned.data.length = this.chart.monthCount;
      this.chart.data.Impressions.data.length = this.chart.monthCount;
      this.chart.data.Clicks.data.length = this.chart.monthCount;
      this.chart.data.CTR.data.length = this.chart.monthCount;
    }

    this.chart.data.Earned.data.fill(0);
    this.chart.data.Impressions.data.fill(0);
    this.chart.data.Clicks.data.fill(0);
    this.chart.data.CTR.data.fill(0);

    this.chart.data.totalImpressions = 0;
    this.chart.data.totalEarned = 0;
    this.chart.data.totalClicks = 0;

    this.billing.data.forEach((segment: any) => {
      if(this.chart.segmentIds.length !== 0 && this.chart.segmentIds.indexOf(segment.segment_id) === -1) {
        return this.chart.data;
      }
      const index: number = this.chart.config.xAxis[0].categories.indexOf(segment.period);
      this.chart.data.Earned.data[index] += segment.calcCost;
      this.chart.data.Impressions.data[index] += segment.impressions;
      this.chart.data.Clicks.data[index] += segment.clicks;
      if(this.chart.data.Impressions.data[index] !== 0) {
        this.chart.data.CTR.data[index] =
          Math.round(this.chart.data.Clicks.data[index] / this.chart.data.Impressions.data[index] * 100 * 100) / 100;
      }
      this.chart.data.totalImpressions += segment.impressions;
      this.chart.data.totalEarned += segment.calcCost;
      if(this.chart.data.totalClicks !== 'n/a') {
        segment.clicks === null ? this.chart.data.totalClicks = 'n/a' : this.chart.data.totalClicks += segment.clicks;
      }
    });

    if(!this.chart.updated) {
      this.chart.config.xAxis[0].categories.forEach((date: any, index: any, arr: any) => {
        arr[index] = moment(Date.parse(date)).format( 'DD.MM.YY');
      });
      this.billing.data.forEach((segment: any, index: any, arr: any) => {
        arr[index].period = moment(Date.parse(segment.period)).format('DD.MM.YY');
      });
    }

    for(let i: number = 0; i < this.chart.config.xAxis[0].categories.length; i++) {
      if(this.chart.data.Impressions.data[i] !== 0) {
        this.chart.data.CTR.data[i] = Math.round(this.chart.data.Clicks.data[i] / this.chart.data.Impressions.data[i] * 100 * 100) / 100;
      } else {
        this.chart.data.CTR.data[i] = 0;
      }
      this.chart.data.Earned.data[i] = Math.round(this.chart.data.Earned.data[i] * 100) / 100;
    }
    if(this.chart.data.totalClicks !== 'n/a' && this.chart.data.totalImpressions !== 0) {
      this.chart.data.totalCTR = Math.round(this.chart.data.totalClicks / this.chart.data.totalImpressions * 100 * 100) / 100;
      // this.chart.data.totalClicks = this.$filter('number')(this.chart.data.totalClicks, '0');
    } else {
      this.chart.data.totalCTR = 'n/a';
    }

    this.chart.updated = true;
    return this.chart.data;
  }

  public getChartConfig(): any {
    return this.chart.config;
  }

  public setCurrencyRate(currencyRates: any): void {
    this.billing.currencyRates = currencyRates;
  }

  private calcCost(currency: any): void {
    if(currency === 'RUB') {
      this.billing.data.forEach((segment: any) => {
        switch(segment.currency) {
          case 'USD':
            segment.calcCost = segment.money * this.billing.currencyRates[segment.period].USD;
            break;
          case 'RUB':
            segment.calcCost = segment.money;
            break;
        }
      });
    } else {
      this.billing.data.forEach((segment: any) => {
        switch(segment.currency) {
          case 'USD':
            segment.calcCost = segment.money;
            break;
          case 'RUB':
            segment.calcCost = segment.money / this.billing.currencyRates[segment.period].USD;
            break;
        }
      });
    }
  }
}

