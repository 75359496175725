import { StateProvider } from '@uirouter/angularjs';
import { ConstService } from '../../app/shared/const.service';

export const campaignRouting = ($stateProvider: StateProvider) => {
  $stateProvider
    .state('layout.campaigns', {
      url: '/campaigns',
      component: 'campaignListComponent',
      data: {
        permissions: {
          except: ConstService.USER_ROLES.unauthorized,
          only: ConstService.USER_ROLES.demo,
          redirectTo: ConstService.REDIRECTS.campaigns
        }
      }
    })
    .state('layout.campaigns_analytics', {
      url: '/campaigns/{campaignId:int}/analytics',
      component: 'campaignAnalyticsComponent',
      data: {
        permissions: {
          except: ConstService.USER_ROLES.unauthorized,
          only: ConstService.USER_ROLES.demo,
          redirectTo: ConstService.REDIRECTS.campaigns
        }
      }
    });
};
