import * as angular from 'angular';
import { Component, Input, OnInit } from 'angular-ts-decorators';

@Component({
  selector: 'modalEditSharingComponent',
  templateUrl: './sharing-edit.modal.pug'
})
export class ModalEditSharingComponent implements OnInit {

  static $inject: string[] = ['externalsResource'];
  @Input() modalInstance: any;
  @Input() resolve: any;
  public sharing: any;
  public message: any;
  public modalTitle = 'Edit sharing';

  constructor(private externalsResource: any) {
  }

  ngOnInit(): void {
    this.sharing = angular.copy(this.resolve.sharing);
    this.sharing.cost_amount = parseFloat(this.sharing.cost_amount);
  }

  public editSharing() {
    this.externalsResource.update({
      id: this.sharing.id,
      cost_amount: this.sharing.cost_amount,
      is_active: true
    }, (response: any) => {
      this.modalInstance.close(this.sharing);
      this.message = '';
    }, (error: any) => {
      this.message = error.data.error_message;
    });
  }

  public cancel() {
    return this.modalInstance.dismiss('cancel');
  }
}
