import { Component, OnInit } from 'angular-ts-decorators';
import { AuthService } from '../../../app/core/services/auth.service';

@Component({
  selector: 'audienceLookalikeComponent',
  templateUrl: './audience-lookalike.pug'
})
export class AudienceLookalikeComponent implements OnInit {

  static $inject: string[] = [
    '$scope',
    '$state',
    '$stateParams',
    'audiencesResource',
    'audiencesService',
    'Notification',
    'authService',
    'marketplaceService'
  ];
  public preloader = true;
  public notFound = false;
  public notForLookalike = false;
  public segmentId: any;
  public publicAudiencesPreloader = true;
  public select: any = {
    excludeSegments: []
  };
  public excludableSegments: any[] = [];
  public searchData: any = [];
  public searchFilter = '';
  public lookalikePercent = 50;
  public lookalikeExpires = 30;
  public lookalikeMessage = '';
  public submitDisable = false;
  public modalTitle: any;
  public textLoc: any = {};

  constructor(private $scope: any,
              private $state: any,
              private $stateParams: any,
              private audiencesResource: any,
              private audiencesService: any,
              private Notification: any,
              private authService: AuthService,
              private marketplaceService: any) {
    this.authService.text$.subscribe((text) => {
      this.textLoc = text;
    });
  }

  ngOnInit(): void {
    this.segmentId = this.$stateParams.segmentId;

    this.audiencesService.getAudienceById(this.segmentId).subscribe((response: any) => {
      const segment = response;
      this.modalTitle = this.textLoc.setYourLookAlikeAudience + ' \'' + segment.segment_name + '\' '+ this.textLoc.segment +'!';
      if(['normal', 'constructor', 'manual', 'analytics', 'split'].indexOf(segment.type) === -1 ||
        !segment.is_active ||
        segment.is_empty ||
        this.authService.selectedAccount.permission !== 'edit') {
        this.notForLookalike = true;
      }
      this.preloader = false;
    }, () => {
      this.preloader = false;
      this.notFound = true;
    });

    // todo: (prokopenko) use promise all()
    this.marketplaceService.getAudienceList().then((publicSegments: any) => {
      this.excludableSegments = publicSegments;
      this.audiencesService.getAudienceList().then((audiences: any) => {
        this.addExcludableAudiences(audiences);
        this.getSearchData('');
        this.publicAudiencesPreloader = false;
      });
    });

  }

  // todo: (prokopenko) code duplication
  public getSearchData(search: any) {
    const SEARCH_RESULTS_LIMIT = 50;
    if(!search) {
      this.searchData = this.excludableSegments.slice(0, SEARCH_RESULTS_LIMIT);
      return;
    }
    const matcher = new RegExp(search, 'i');
    this.searchData.length = 0;
    let segment;
    for(let i = 0; i < this.excludableSegments.length && this.searchData.length < SEARCH_RESULTS_LIMIT; i++) {
      segment = this.excludableSegments[i];
      if(segment.segment_name.match(matcher) || String(segment.segment_id).match(matcher)) {
        let isSelected = false;
        for(let j = 0; j < this.select.excludeSegments.length; j++) {
          if(this.select.excludeSegments[j].segment_id === segment.segment_id) {
            isSelected = true;
            break;
          }
        }
        if(isSelected) {
          continue;
        }
        this.searchData.push(segment);
      }
    }
  }

  public createLookalike() {
    this.submitDisable = true;
    const excludedIds = this.select.excludeSegments.map((segment: any) => {
      return segment.segment_id;
    });
    this.audiencesService.addAudienceLookAlike({
      id: this.segmentId,
      percent: this.lookalikePercent,
      expire_days: this.lookalikeExpires,
      account: this.authService.getSelectedAccount().account_id,
      exclude_segments: excludedIds
    }).subscribe((response: any) => {
      response.lookalike_percent = this.lookalikePercent;
      response.lookalike_parent_id = this.segmentId;
      response.pixel_pid = this.lookalikePercent + '% lookalike';
      this.audiencesService.addAudience(response);
      this.Notification.success(this.textLoc.lookAlikeCreated);
      this.$state.go('layout.audiences', {}, {reload: true});
    },(error: any) => {
      this.submitDisable = false;
      this.lookalikeMessage = this.getErrorMessage(error);
    });
  }

  private addExcludableAudiences(audiences: any) {
    audiences.forEach((audience: any) => {
      if(audience.is_active && !audience.is_empty && (['normal', 'manual'].indexOf(audience.type) !== -1)) {
        for(let i = 0; i < this.excludableSegments.length; i++) {
          if(this.excludableSegments[i].segment_id === audiences.segment_id) {
            return;
          }
        }
        this.excludableSegments.push(audience);
      }
    });
  }

  private getErrorMessage(error: any): string {
    let message: string = '';
    const errorResponse: any = error.data.data.error_message;
    for(const item in errorResponse) {
      if(errorResponse.hasOwnProperty(item)) {
        message = errorResponse[item];
      }
    }
    return message;
  }
}
