import * as angular from 'angular';
import { Component, Input, OnInit } from 'angular-ts-decorators';

@Component({
  selector: 'modalFilterSizeComponent',
  templateUrl: './filter-size.modal.pug'
})
export class ModalFilterSizeComponent implements OnInit {

  static $inject: string[] = ['utilsService', '$scope'];
  @Input() modalInstance: any;
  @Input() resolve: any;
  public treeData: any;
  public countList: number[] = [50, 100, 200];
  public count: any;
  public affinityLengthAll: any;
  public query: any;
  public filterError: any;
  public filterErrorAffinity: any;
  public minSize: any;
  public maxSize: any;
  public minAffinity: any;
  public maxAffinity: any;
  public affinity: any;
  public uniqsRange: any;
  private sizeList: any[] = [];
  private affinityList: any[] = [];

  constructor(private utilsService: any, private $scope: any) {

  }

  ngOnInit(): void {
    this.treeData = this.resolve.intersections.tree;

    this.countList = [50, 100, 200];
    this.count = this.resolve.intersections.count;
    this.affinityLengthAll = this.resolve.intersections.affinity.length;
    this.query = '';
    this.filterError = false;

    this.minSize = this.resolve.intersections.filter.minSize;
    this.maxSize = this.resolve.intersections.filter.maxSize;
    this.minAffinity = Math.floor(this.resolve.intersections.filter.minAffinity);
    this.maxAffinity = Math.ceil(this.resolve.intersections.filter.maxAffinity);

    this.resolve.intersections.affinity.forEach((item: any) => {
      this.sizeList.push(item.intersectionSize);
      this.affinityList.push(Math.round(parseFloat(item.affinity)));
    });

    this.sizeList.push(0);
    this.sizeList.sort( this.sortList );
    this.affinityList.push(0);
    this.affinityList.sort( this.sortList );

    this.affinity = [this.getIndexByAffinity('min'), this.getIndexByAffinity('max')];
    this.uniqsRange = [this.getIndexBySize('min'), this.getIndexBySize('max')];
    this.checkVisibleTreeData(this.treeData);
  }


  public cancel() {
    this.modalInstance.close();
  }

  public addFilter() {
    this.modalInstance.close({
      filter: {
        minSize: this.minSize,
        maxSize: this.maxSize,
        minAffinity: this.minAffinity,
        maxAffinity: this.maxAffinity
      },
      count: this.count
    });
  }

  public changeSize(type: any) {
    if(this.minSize > this.maxSize) {
      this.filterError = true;
      return;
    }
    this.filterError = false;

    if(type === 'min') {
      this.uniqsRange[0] = this.getIndexBySize(type);
    } else {
      this.uniqsRange[1] = this.getIndexBySize(type);
    }

    this.checkVisibleTreeData(this.treeData);
  }

  public changeAffinity(type: any) {
    if(this.minAffinity > this.maxAffinity) {
      this.filterErrorAffinity = true;
      return;
    }
    this.filterErrorAffinity = false;

    if(type === 'min') {
      this.affinity[0] = this.getIndexByAffinity(type);
    } else {
      this.affinity[1] = this.getIndexByAffinity(type);
    }

    this.checkVisibleTreeData(this.treeData);
  }

  public checkTotal() {
    return this.resolve.intersections.affinity.filter((item: any) => {
      return this.checkFilterAllow(item) && item.isShow;
    }).length;
  }

  public changeCount(item: any) {
    this.count = item;
  }

  public collapseAll() {
    this.$scope.$broadcast('angular-ui-tree:collapse-all');
  }

  public expandAll() {
    this.$scope.$broadcast('angular-ui-tree:expand-all');
  }

  public toggle(scope: any) {
    scope.toggle();
  }

  public slideChange(event: any, element: any) {
    let scope: any = angular.element(element.handle).scope();
    let ctrl: any = scope.$ctrl;
    ctrl.utilsService.safeApply(scope, () => {
      ctrl.minSize = Math.floor(ctrl.sizeList[ctrl.uniqsRange[0]]);
      ctrl.maxSize = Math.ceil(ctrl.sizeList[ctrl.uniqsRange[1]]);
      ctrl.minAffinity = Math.floor(ctrl.affinityList[ctrl.affinity[0]]);
      ctrl.maxAffinity = Math.ceil(ctrl.affinityList[ctrl.affinity[1]]);
      ctrl.checkVisibleTreeData(ctrl.treeData);
    });
  }

  public checkVisible(event: any, element: any) {
    let scope: any = angular.element(element.handle).scope();
    let ctrl: any = scope.$ctrl;
    ctrl.utilsService.safeApply(scope, () => {
      ctrl.checkVisibleTreeData(ctrl.treeData);
    });
  }

  public checkVisibleSearch() {
    this.checkVisibleTreeData(this.treeData);
  }

  public toggleAll(scope: any, item: any, value: any) {
    if(!item.is_empty) { item.isShow = value; }

    scope.expand();
    this.toggleNodeList(item.nodes, value);
  }

  public visible(item: any) {
    return item.isVisibleTree;
  }

  private _visible(item: any) {
    return (
      !(this.query && this.query.length > 0 && !this.searchSegment(item))
      && (this.checkFilterAllow(item) || ((!item.affinity || item.nodes.length) && item.nodes.some((node: any) => {
        return this._visible(node);
      }))));
  }

  private checkFilterAllow(item: any) {
    return (item.affinity >= this.minAffinity && item.affinity <= this.maxAffinity
      && item.intersectionSize >= this.minSize && item.intersectionSize <= this.maxSize);
  }

  private searchSegment(segment: any) {
    let query = this.query.toLowerCase();
    let result = segment.segment_name.toLowerCase().indexOf(query) !== -1;
    if(result || !segment.nodes) {
      return result;
    }
    result = segment.nodes.some((node: any) => {
      return this.searchSegment(node);
    });
    return result;
  }

  private checkVisibleTreeData(data: any) {
    data.forEach((item: any) => {
      item.isFilterAllow = this.checkFilterAllow(item);
      item.isVisibleTree = this._visible(item);
      if(item.nodes.length) { this.checkVisibleTreeData(item.nodes); }
    });
  }

  private toggleNodeList(nodes: any, value: any) {
    nodes.forEach((node: any) => {
      if(node.affinity) { node.isShow = value; }
      if(node.nodes.length) { this.toggleNodeList(node.nodes, value); }
    });
  }

  private getIndexBySize(type: any) {
    let index = 0;

    if(type === 'min') {
      index = this.sizeList.findIndex((item: any) => {
        return item >= this.minSize;
      });
    } else {
      index = (this.maxSize >= this.sizeList[this.sizeList.length - 1]) ? this.sizeList.length - 1 : this.sizeList.findIndex((item: any) => {
        return item > this.maxSize;
      }) - 1;
      index = (index < 0) ? 0 : index;
    }

    return index;
  }

  private getIndexByAffinity(type: any) {
    let index = 0;

    if(type === 'min') {
      index = this.affinityList.findIndex((item: any) => {
        return item >= this.minAffinity;
      });
    } else {
      index = (this.maxAffinity >= this.affinityList[this.affinityList.length - 1]) ? this.affinityList.length - 1 : this.affinityList.findIndex((item: any) => {
        return item > this.maxAffinity;
      }) - 1;
      index = (index < 0) ? 0 : index;
    }

    return index;
  }

  private sortList(a: number, b: number): number {
    if(a > b) return 1;
    if(b > a) return -1;
    return 0;
  }
}
