import { StateProvider } from '@uirouter/angularjs';
import { NgModule } from 'angular-ts-decorators';
import { LayoutComponent } from './layout.component';
import { layoutRouting } from './layout.routes';

@NgModule({
  id: 'LayoutModule',
  imports: [],
  declarations: [
    LayoutComponent,
  ],
  providers: []
})
export class LayoutModule {
  static config = config;
}

function config($stateProvider: StateProvider) {
  layoutRouting($stateProvider);
}

config.$inject = ['$stateProvider'];
