import { StateProvider } from '@uirouter/angularjs';
import { ConstService } from '../../app/shared/const.service';

export const marketplaceRouting = ($stateProvider: StateProvider) => {
  $stateProvider
    .state('marketplace', {
      url: '/market',
      component: 'marketplacePublicComponent'
    })
    .state('layout.marketplace', {
      url: '/marketplace',
      component: 'marketplaceComponent',
      data: {
        permissions: {
          except: [ConstService.USER_ROLES.unauthorized],
          only: [ConstService.PERMISSIONS.marketplace],
          redirectTo: ConstService.REDIRECTS.segments
        }
      }
    })
    .state('layout.analytics_market', {
      url: '/marketplace/{audienceId:int}/analytics',
      // component: 'audienceAnalyticsComponent',
      // data: {
        // permissions: {
          // except: ConstService.USER_ROLES.unauthorized,
          // only: ConstService.PERMISSIONS.marketplace,
          // redirectTo: ConstService.REDIRECTS.segments
        // },
        // mode: 'market'
      // }
    });

};
