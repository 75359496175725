import { Component, Input, OnInit } from 'angular-ts-decorators';
import {AuthService} from '../../../app/core/services/auth.service';

@Component({
  selector: 'modalSharingFilter',
  templateUrl: './sharing-filter.modal.pug'
})
export class ModalSharingFilterComponent implements OnInit {

  @Input() modalInstance: any;

  static $inject: string[] = [
    'authService'
  ];

  public operator: string = 'and';
  public filterList: any = [];
  public selectorsMap: any = {
    segment_name: 'Audience Name',
    is_active: 'Status',
    product_name: 'Platform ID',
    customer_id: 'Customer ID',
    cost_amount: 'Cost',
    created_at: 'Created'
  };

  public datesMap: any = {
    '7': 'Last week',
    '14': 'Last 2 week',
    '30': 'Last month',
    '90': 'Last 3 month'
  };
  public textLoc = {};

  constructor(private authService: AuthService) {
    this.authService.text$.subscribe((text) => {
      this.textLoc = text;
      this.selectorsMap.segment_name = text.audienceName;
      this.selectorsMap.is_active = text.status;
      this.selectorsMap.product_name = text.platformID;
      this.selectorsMap.customer_id = text.customerID;
      this.selectorsMap.cost_amount = text.cost;
      this.selectorsMap.created_at = text.created;
      this.datesMap['7'] = text.lastWeek;
      this.datesMap['14'] = text.last2Week;
      this.datesMap['30'] = text.lastMonth;
      this.datesMap['90'] = text.Last3Month;
    });
  }

  ngOnInit(): void {
    let filters: any = null;
    try {
      filters = JSON.parse(localStorage.getItem('sharingFilter'));
    } catch (e) {}

    if (filters) {
      this.operator = filters.operator;
      this.filterList = filters.list;
    } else {
      this.addFilter();
    }
  }

  public cancel(): void {
    this.modalInstance.dismiss('cancel');
  }

  public save(): void {
    const filter: any = {
      operator: this.operator,
      list: this.filterList
    };
    localStorage.setItem('sharingFilter', JSON.stringify(filter));
    this.modalInstance.close(true);
  }

  public addFilter(): void {
    this.filterList.push({
      field: 'segment_name',
      value: '',
      operator: 'more'
    });
  }

  public changeColumn(rule: any, value: string): void {
    rule.field = value;
    switch (value) {
      case 'is_active':
        rule.value = true;
        break;
      case 'cost_amount':
        rule.value = 0;
        break;
      case 'created_at':
        rule.value = '7';
        break;
      default:
        rule.value = '';
        break;
    }
  }

  public deleteFilter(index: number): void {
    if (this.filterList.length === 1) {
      return;
    }
    this.filterList.splice(index, 1);
  }
}
