import { Component, OnInit } from 'angular-ts-decorators';
// import * as d3 from '(window as any).d3';
import * as _ from 'lodash';
import { AuthService } from '../../../app/core/services/auth.service';

declare const dagreD3: any;
declare const graphlibDot: any;


@Component({
  selector: 'audienceEditComponent',
  templateUrl: './audience-edit.pug'
})
export class AudienceEditComponent implements OnInit {

  static $inject: string[] = [
    '$scope',
    '$state',
    '$stateParams',
    'pixelService',
    'audiencesResource',
    'audiencesService',
    'authService',
    'Notification',
    'marketplaceService'
  ];
  public mode: any;
  public modeEdit: any;
  public preloader = true;
  public submitDisable = false;
  public message = '';
  public modalTitle = 'Create Audience';
  public pixel: any = {};
  public isGroup = false;
  public jsonTree: any = null;
  public elementSelected = false;
  public lookalikeModelVisualisationType = 'sunburst';
  public excludableSegments: any[] = [];
  public lookalikeEstimation: any = {};
  public lookalike = {
    newPercent: 0
  };
  public select: any = {
    excludeSegments: []
  };
  public sunburstInfo: any = {
    segment: null,
    value: null,
    samples: 0,
    show: false
  };
  public treeSelectedNode: any = {
    segment: null,
    value: null,
    samples: 0,
    show: false
  };
  public searchData: any[] = [];
  public searchFilter = '';
  public segmentId: any;
  public type: any;
  public pixelId: any;
  public pixelPID: any;
  public lookalikePercent: any;
  public publicAudiencesPreloader: any;
  public lookalikeParent: any;
  public audience: any;

  // todo: (prokopenko) move sunburst to another place
  // sunburst
  private sunburstWidth = 1062;
  private sunburstHeight = 500;
  private radius = Math.min(this.sunburstWidth, this.sunburstHeight) / 2;
  // breadcrumb dimensions: width, height, spacing, width of tip/tail.
  private b = {
    w: 96, h: 30, s: 3, t: 10
  };
  // total size of all segments; we set this later, after loading the data.
  private totalSize = 0;
  private vis = (window as any).d3.select('.sunburst__chart').append('svg:svg')
    .attr('width', this.sunburstWidth)
    .attr('height', this.sunburstHeight)
    .append('svg:g')
    .attr('id', 'container')
    .attr('transform', 'translate(' + this.sunburstWidth / 2 + ',' + this.sunburstHeight / 2 + ')');
  private partition = (window as any).d3.layout.partition()
    .size([2 * Math.PI, this.radius * this.radius])
    .value(function(d: any): number {
      return d.size;
    });
  private arc: any = (window as any).d3.svg.arc()
    .startAngle(function(d: any) {
      return d.x;
    })
    .endAngle(function(d: any) {
      return d.x + d.dx;
    })
    .innerRadius(function(d: any) {
      return Math.sqrt(d.y);
    })
    .outerRadius(function(d: any) {
      return Math.sqrt(d.y + d.dy);
    });
  private renderer: any = new dagreD3.render();
  private g: any;

  constructor(private $scope: any,
              private $state: any,
              private $stateParams: any,
              private pixelService: any,
              private audiencesResource: any,
              private audiencesService: any,
              private authService: AuthService,
              private Notification: any,
              private marketplaceService: any) {
  }

  ngOnInit(): void {
    this.mode = this.$state.current.data.mode;
    this.modeEdit = (this.mode === 'edit');
    this.clearAudience();

    this.$scope.$broadcast('refreshSlider');

    if(this.modeEdit) {
      this.segmentId = this.$stateParams.segmentId;
      this.audiencesResource.get({id: this.segmentId}).$promise.then((response: any) => {
        function secondsToDays(seconds: any) {
          return seconds === null ? null : seconds / 60 / 60 / 24;
        }

        let segment = response.data;
        if(segment.account !== this.authService.getSelectedAccount().account_id) {
          this.$state.go('layout.audiences', {}, {reload: true});
        }
        this.type = segment.type;
        this.modalTitle = 'Edit \'' + segment.segment_name + '\'';
        if(segment.segment_id) {
          this.modalTitle = this.modalTitle + ' (' + segment.segment_id + ')';
        }
        this.pixelId = segment.pixel_id;
        this.pixelPID = segment.pixel_pid;
        this.isGroup = !!segment.is_empty;
        if(this.type === 'look-alike') {
          segment.lookalike_stat && (this.lookalikeEstimation = segment.lookalike_stat.reduce((result: any, item: any) => {
            result[item.percent] = item.size;
            return result;
          }, {}));
          this.lookalikePercent = segment.lookalike_percent;
          this.lookalike.newPercent = segment.lookalike_percent;
          if(segment.lookalike_excluded) {
            this.publicAudiencesPreloader = true;
            this.marketplaceService.getAudienceList().then((publicSegments: any) => {
              this.excludableSegments = publicSegments;
              if(segment.lookalike_model_dot) {
                try {
                  this.constructSunburst(segment.lookalike_model_dot);
                } catch(error) {
                  console.log(error);
                }
              }
              this.audiencesService.getAudienceList().then((audiences: any) => {
                this.addExcludableAudiences(audiences);
                segment.lookalike_excluded.forEach((segment_id: any) => {
                  for(let i = 0; i < this.excludableSegments.length; i++) {
                    if(this.excludableSegments[i].segment_id === segment_id) {
                      this.select.excludeSegments.push(this.excludableSegments[i]);
                      return;
                    }
                  }
                  this.select.excludeSegments.push({
                    segment_id: segment_id,
                    segment_name: '<name hidden>'
                  });
                });
                this.getSearchData('');
                this.publicAudiencesPreloader = false;
              });
            });
          }
          this.audiencesService.getAudienceList().then(() => {
            this.lookalikeParent = segment.lookalike_parent_id ?
              this.audiencesService.getAudience(segment.lookalike_parent_id) : {};
            this.preloader = false;
          });
        } else {
          this.pixelService.getPixel(segment.pixel_pid).then((pixel: any) => {
            this.pixel = pixel || {};
            pixel && (this.audience.pixelPid = pixel.pid);
            this.preloader = false;
          });
        }

        this.audience.intensity = segment.intensity_min === null ? 1 : segment.intensity_min;
        this.audience.time[0] = (segment.expired_min === null || segment.expired_min === undefined) ?
          0 :
          secondsToDays(segment.expired_min);
        this.audience.time[1] = (segment.expired_max === null || segment.expired_max === undefined) ?
          90 :
          secondsToDays(segment.expired_max);
        this.audience.name = segment.segment_name;
        this.audience.account = segment.account;
        this.audience.is_total_audience = segment.is_total_audience;
        ((segment.urls && segment.urls.length) || (segment.keywords_shuffle && segment.keywords_shuffle.length)) && (this.audience.urls.length = 0);
        if(segment.stop_domains && segment.stop_domains.length) {
          this.audience.domains.value = segment.stop_domains;
          this.audience.domains.type = 'exclude';
        }
        if(segment.domains && segment.domains.length) {
          this.audience.domains.value = segment.domains;
          this.audience.domains.type = 'include';
        }
        !!segment.urls && segment.urls.forEach((url: any) => {
          this.audience.urls.push({type: 'equals', value: url});
        });
        !!segment.keywords_shuffle && segment.keywords_shuffle.forEach((rule: any) => {
          this.audience.urls.push({type: 'contains', value: rule});
        });
        this.audience.intensity = segment.intensity_min;
      }).catch(() => {
        this.preloader = false;
      }).finally(() => {
      });
    } else {
      this.pixelId = this.$stateParams.pixelId;
      this.type = 'normal';
      this.pixelService.getPixel(this.pixelId, 'id').then((pixel: any) => {
        if(pixel.account !== this.authService.getSelectedAccount().account_id) {
          this.$state.go('layout.pixels', {}, {reload: true});
        }
        this.pixel = pixel || {};
        pixel && (this.audience.pixelPid = pixel.pid);
        this.preloader = false;
      });
    }

  }


  public getBackLink() {
    if(this.modeEdit) {
      return '/audiences';
    } else {
      return '/pixel/list';
    }
  }

  public getEstimation(percent: any) {
    if(this.lookalikeEstimation.hasOwnProperty(percent)) {
      return this.lookalikeEstimation[percent];
    } else {
      return null;
    }
  }

  public createAudience() {
    this.submitDisable = true;
    let data = this.getValidData();
    this.audiencesResource.save(data).$promise.then((response: any) => {
      this.audiencesService.addAudience(response.data);
      this.Notification.success('Audience created');
      this.message = '';
      this.clearAudience();
    }).catch((error: any) => {
      this.message = error.data.error_message;
    }).finally(() => {
      this.submitDisable = false;
    });
  }

  public editAudience() {
    this.submitDisable = true;
    let data: any = this.getValidData();
    data = {
      id: this.segmentId,
      account: data.account,
      pixel_pid: data.pixel_pid,
      segment_name: data.segment_name,
      partners_only: data.partners_only,
      expired_max: data.expired_max,
      expired_min: data.expired_min,
      intensity_min: data.intensity_min
    };
    if(this.lookalike.newPercent !== undefined) {
      data.lookalike_percent = this.lookalike.newPercent;
      data.lookalike_excluded = this.select.excludeSegments.map((segment: any) => {
        return segment.segment_id;
      });
    }
    this.audiencesResource.patch(data).$promise.then((response: any) => {
      this.Notification.success('Audience changed');
      this.message = '';
      this.audiencesService.getAudienceList().then(() => {
        this.audiencesService.editAudience(this.segmentId, response.data.segment_name, response.data.lookalike_percent);
        this.lookalikePercent = response.data.lookalike_percent;
      });
    }).catch((error: any) => {
      this.message = error.data.error_message;
    }).finally(() => {
      this.submitDisable = false;
    });
  }

  public addDomainsRule() {
    this.audience.domains.value.push('');
  }

  public addUrlsRule() {
    this.audience.urls.push({type: 'equals', value: ''});
  }

  public removeDomainsRule(index: any) {
    this.audience.domains.value.splice(index, 1);
    this.audience.domains.value.length === 0 && this.addDomainsRule();
  }

  public removeUrlsRule(ruleIndex: any, wordIndex: any) {
    if(wordIndex === undefined) {
      this.audience.urls.splice(ruleIndex, 1);
    } else {
      this.audience.urls[ruleIndex].value.splice(wordIndex, 1);
      this.audience.urls[ruleIndex].value.length === 0 && this.audience.urls.splice(ruleIndex, 1);
    }
    this.audience.urls.length === 0 && this.addUrlsRule();
  }

  public addUrlsWords(index: any) {
    this.audience.urls[index].value.push('');
  }

  public selectUrlRuleType(index: any, type: any) {
    let rule = this.audience.urls[index];
    if(rule.type !== type) {
      rule.type = type;
      switch(type) {
        case 'equals':
          rule.value = '';
          break;
        case 'contains':
          rule.value = [''];
          break;
      }
    }
  }

  public haveEditPermission() {
    return (this.modeEdit ?
      this.authService.getAccountById(this.audience.account).permission :
      _.get(this.authService.getSelectedAccount(), 'permission')) === 'edit';
  }

  public edit() {
    if(this.$scope.editAudienceForm.$valid) {
      if(this.modeEdit) {
        this.editAudience();
      } else {
        this.createAudience();
      }
    }
  }

  public getSearchData(search: any) {
    let SEARCH_RESULTS_LIMIT = 50;
    if(!search) {
      // this.searchData = this.publicSegments.slice(0,SEARCH_RESULTS_LIMIT);
      this.searchData = this.excludableSegments.slice(0, SEARCH_RESULTS_LIMIT);
      return;
    }
    let matcher = new RegExp(search, 'i');
    this.searchData.length = 0;
    let segment;
    for(let i = 0; i < this.excludableSegments.length && this.searchData.length < SEARCH_RESULTS_LIMIT; i++) {
      segment = this.excludableSegments[i];
      if(segment.segment_name.match(matcher) || String(segment.segment_id).match(matcher)) {
        let isSelected = false;
        for(let j = 0; j < this.select.excludeSegments.length; j++) {
          if(this.select.excludeSegments[j].segment_id === segment.segment_id) {
            isSelected = true;
            break;
          }
        }
        if(isSelected) {
          continue;
        }
        this.searchData.push({
          segment_name: segment.segment_name,
          segment_id: segment.segment_id,
          index: i
        });
      }
    }
  }

  private clearAudience() {
    // todo: (prokopenko) move audience to Class
    this.audience = {
      pixelPid: this.pixel.pid || '',
      intensity: 1,
      account: this.authService.getSelectedAccount().account_id,
      name: '',
      time: [0, 90],
      is_total_audience: false,
      domains: {type: 'include', value: ['']},
      urls: [{type: 'equals', value: ''}]
    };
  }

  private cleanArray(actual: any) {
    let newArray = [];
    for(let i = 0; i < actual.length; i++) {
      actual[i] && newArray.push(actual[i]);
    }
    return newArray;
  }

  private getValidData() {
    function daysToSeconds(days: any) {
      return days === null ? null : days * 60 * 60 * 24;
    }

    let data: any = {
      pixel_pid: this.pixel.pid || null,
      segment_name: this.audience.name,
      account: this.audience.account,
      urls: [],
      keywords_shuffle: [],
      stop_domains: [],
      domains: [],
      partners_only: [this.pixel.pid],
      expired_max: daysToSeconds(this.audience.time[1]),
      expired_min: daysToSeconds(this.audience.time[0]),
      intensity_min: this.audience.intensity,
      is_total_audience: this.audience.is_total_audience
    };
    (this.type === 'look-alike') && (data.partners_only = []);
    if(data.is_total_audience) {
      return data;
    }
    switch(this.audience.domains.type) {
      case 'include':
        data.domains = this.cleanArray(this.audience.domains.value);
        break;
      case 'exclude':
        data.stop_domains = this.cleanArray(this.audience.domains.value);
        break;
    }
    this.audience.urls.forEach((rule: any) => {
      if(this.cleanArray(rule.value).length === 0) {
        return;
      }
      switch(rule.type) {
        case 'equals':
          data.urls.push(rule.value);
          break;
        case 'contains':
          data.keywords_shuffle.push(this.cleanArray(rule.value));
          break;
      }
    });
    return data;
  }

  private addExcludableAudiences(audiences: any) {
    audiences.forEach((audience: any) => {
      if(audience.is_active && !audience.is_empty && (['normal', 'manual'].indexOf(audience.type) !== -1)) {
        for(let i = 0; i < this.excludableSegments.length; i++) {
          if(this.excludableSegments[i].segment_id === audiences.segment_id) {
            return;
          }
        }
        this.excludableSegments.push(audience);
      }
    });
  }


  private asyncRender() {
    this.renderer((window as any).d3.select('#graph'), this.g);
  }

  private colors(node: any) {
    let index = (node.childrenIndex || 0) * 2 + node.depth % 2;
    // if(node.parent && node.parent.children[1] == node) {
    // 	index = 2;
    // }
    // index += node.depth%2;
    // colors.index = (colors.index + 1) % 4 || 0;
    let col = ['#F7CA5D', '#F08F66', '#8CC663', '#78C8E5'];
    // return col[colors.index];
    // return col[node.id % 4];
    return col[index];
  }

  /**
   * Main function to draw and set up the visualization, once we have the data.
   * @param json
   */
  private createVisualization(json: any) {
    const self: any = this;

    /**
     * Restore everything to full opacity when moving off the visualization.
     * @param d
     */
    function mouseleave(d: any) {
      if(self.elementSelected) {
        return;
      }
      // hide the breadcrumb trail
      (window as any).d3.select('#trail')
        .style('visibility', 'hidden');

      // deactivate all segments during transition.
      (window as any).d3.selectAll('.sunburst__chart path').on('mouseover', null);

      // transition each segment to full opacity and then reactivate it.
      (window as any).d3.selectAll('.sunburst__chart path')
        .transition()
        .duration(1000)
        .style('opacity', 1)
        .each('end', function() {
          (window as any).d3.select(this).on('mouseover', mouseover);
        });

      self.sunburstInfo.show = false;
      self.$scope.$apply();
    }

    /**
     * Fade all but the current sequence, and show it in the breadcrumb trail.
     * @param d
     */
    function mouseover(d: any) {
      if(self.elementSelected) {
        return;
      }
      let percentage: any = (100 * d.value / self.totalSize).toPrecision(3);
      let percentageString = percentage + '%';
      if(percentage < 0.1) {
        percentageString = '< 0.1%';
      }
      self.sunburstInfo.segment_name = d.segment_name;
      self.sunburstInfo.name = d.name;
      self.sunburstInfo.gini = Math.floor(parseFloat(d.gini) * 1000000) / 1000000;
      self.sunburstInfo.samples = d.size;
      self.sunburstInfo.similarity = d.similarity;
      self.sunburstInfo.show = true;

      let sequenceArray = self.getAncestors(d);
      self.updateBreadcrumbs(sequenceArray, percentageString);

      // fade all the segments.
      (window as any).d3.selectAll('.sunburst__chart path')
        .style('opacity', 0.3);

      // then highlight only those that are an ancestor of the current segment.
      self.vis.selectAll('path')
        .filter(function(node: any) {
          return (sequenceArray.indexOf(node) >= 0);
        })
        .style('opacity', 1);
      self.$scope.$apply();
    }

    // basic setup of page elements.
    this.initializeBreadcrumbTrail();

    // bounding circle underneath the sunburst, to make it easier to detect
    // when the mouse leaves the parent g.
    this.vis.append('svg:circle')
      .attr('r', this.radius)
      .style('opacity', 0);

    // for efficiency, filter nodes to keep only those large enough to see.
    let nodes = this.partition.nodes(json)
      .filter(function(d: any) {
        return (d.dx > 0.005); // 0.005 radians = 0.29 degrees
      });

    let path = this.vis.data([json]).selectAll('path')
      .data(nodes)
      .enter().append('svg:path')
      .attr('display', function(d: any) {
        return d.depth ? null : 'none';
      })
      .attr('d', this.arc)
      .attr('fill-rule', 'evenodd')
      .style('fill', (d: any) => {
        return this.colors(d);
      })
      .style('opacity', 1)
      .on('mouseover', mouseover)
      .on('click', () => {
        this.elementSelected = !this.elementSelected;
      });
    // add the mouseleave handler to the bounding circle.
    (window as any).d3.select('#container').on('mouseleave', mouseleave);

    // get total size of the tree = value of root node from partition.
    this.totalSize = (path.node() as any).__data__.value;
  }


  /**
   * Given a node in a partition layout, return an array of all of its ancestor nodes, highest first, but excluding the root.
   * @param node
   * @returns {any[]}
   */
  private getAncestors(node: any) {
    let path = [];
    let current = node;
    while(current.parent) {
      path.unshift(current);
      current = current.parent;
    }
    return path;
  }

  private initializeBreadcrumbTrail() {
    // add the svg area.
    let trail = (window as any).d3.select('.sunburst__sequence').append('svg:svg')
      .attr('width', this.sunburstWidth)
      .attr('height', 50)
      .attr('id', 'trail');
    // add the label at the end, for the percentage.
    trail.append('svg:text')
      .attr('id', 'endlabel')
      .style('fill', '#000');
  }


  /**
   * Update the breadcrumb trail to show the current sequence and percentage.
   * @param nodeArray
   * @param percentageString
   */
  private updateBreadcrumbs(nodeArray: any, percentageString: any) {

    const self: any = this;

    /**
     * Generate a string that describes the points of a breadcrumb polygon.
     * @param d
     * @param i
     * @returns {string}
     */
    const breadcrumbPoints: any = function(d: any, i: any) {
      let points = [];
      points.push('0,0');
      points.push(self.b.w + ',0');
      points.push(self.b.w + self.b.t + ',' + (self.b.h / 2));
      points.push(self.b.w + ',' + self.b.h);
      points.push('0,' + self.b.h);
      if(i > 0) { // leftmost breadcrumb; don't include 6th vertex.
        points.push(self.b.t + ',' + (self.b.h / 2));
      }
      return points.join(' ');
    };

    const wrap = (width: any, padding: any) => {
      return function() {
        let self = (window as any).d3.select(this),
          textLength = (self.node() as any).getComputedTextLength(),
          text = self.text();
        while(textLength > (width - 2 * padding) && text.length > 0) {
          text = text.slice(0, -1);
          self.text(text + '...');
          textLength = (self.node() as any).getComputedTextLength();
        }
      };
    };

    let tip: any = ((window as any).d3 as any).tip()
      .attr('class', '(window as any).d3-tip')
      .offset([-13, 0])
      .html(function(d: any) {
        return '<span>' + d.segment_name + '</span>';
      });
    (window as any).d3.select('#trail').call(tip);

    // data join; key function combines name and depth (= position in sequence).
    let g = (window as any).d3.select('#trail')
      .selectAll('g')
      .data(nodeArray, function(d: any) {
        return d.name + d.depth;
      });

    // add breadcrumb and label for entering nodes.
    let entering = g.enter().append('svg:g');

    entering.append('svg:polygon')
      .attr('points', breadcrumbPoints)
      .style('fill', (d: any) => {
        return this.colors(d);
      });

    entering.append('svg:text')
      .attr('x', (this.b.w + this.b.t) / 2)
      .attr('y', this.b.h / 2)
      .attr('dy', '0.35em')
      .attr('text-anchor', 'middle')
      .append('tspan')
      .text(function(d: any) {
        return d.segment_name || d.name;
      })
      .each(wrap(this.b.w, 5))
      .on('mouseover', tip.show)
      .on('mouseout', tip.hide);

    // set position for entering and updating nodes.
    g.attr('transform', (d: any, i: any) => {
      return 'translate(' + i * (this.b.w + this.b.s) + ', 0)';
    });

    // remove exiting nodes.
    g.exit().remove();

    // now move and update the percentage at the end.
    (window as any).d3.select('#trail').select('#endlabel')
      .attr('x', (nodeArray.length + 0.5) * (this.b.w + this.b.s))
      .attr('y', this.b.h / 2)
      .attr('dy', '0.35em')
      .attr('text-anchor', 'middle')
      .text(percentageString);

    // make the breadcrumb trail visible, if it's hidden.
    (window as any).d3.select('#trail')
      .style('visibility', '');

  }

  private constructSunburst(model_dot: any) {
    model_dot = model_dot.split('box').join('rect');
    this.g = graphlibDot.read(model_dot);

    this.jsonTree = {};
    this.g.nodes().forEach((el: any) => {
      if(this.g.inEdges(el).length === 0) {
        this.jsonTree.id = el;
      }
    });
    let maxDepth = 9;

    let getSegmentName = (segment_id: any) => {
      for(let i = 0; i < this.excludableSegments.length; i++) {
        if(this.excludableSegments[i].segment_id === segment_id) {
          return this.excludableSegments[i].segment_name;
        }
      }
      return null;
    };

    let getChilds = (el: any, depth: any) => {
      let out = this.g.outEdges(el.id);
      let label = this.g.node(el.id).label;
      label = label.split('\\n');
      label.forEach(function(lab: any) {
        if(lab.indexOf('samples') !== -1) {
          el.size = parseInt(lab.split(' = ')[1], 10);
        } else if(lab.indexOf('<=') !== -1) {
          el.name = lab.split(' <= ')[0];
        } else if(lab.indexOf('gini') !== -1) {
          el.gini = Math.floor(parseFloat(lab.split(' = ')[1]) * 10000) / 10000;
        } else if(lab.indexOf('value') !== -1) {
          el.modelValue = lab.split(' = ')[1];
        } else if(lab.indexOf('classes') !== -1) {
          el.classesSimilarity = lab.split(' = ')[1];
        }
      });
      if(el.modelValue) {
        let valueIndex = 1;
        if(el.classesSimilarity && el.classesSimilarity.indexOf('00000000') !== 1) {
          valueIndex = 0;
        }
        el.similarity = JSON.parse(el.modelValue)[valueIndex];
      }
      if(!(depth > maxDepth || out.length === 0)) {
        el.children = [];
        out.forEach(function(edge: any) {
          let neEl = {'id': edge.w, 'childrenIndex': el.children.length};
          el.children.push(neEl);
          getChilds(neEl, depth + 1);
        });
      }
      if(!el.name) {
        el.name = '';
      } else {
        el.segment_name = getSegmentName(el.name);
      }
    };

    getChilds(this.jsonTree, 0);
    this.createVisualization(this.jsonTree);
    this.createTree(this.jsonTree);

    let svg = (window as any).d3.select('#graphContainer'),
      inner = svg.select('g');
    // set up zoom support
    let zoom = (window as any).d3.behavior.zoom().on('zoom', () => {
      inner.attr('transform', 'translate(' + ((window as any).d3.event as any).translate + ')' +
        'scale(' + ((window as any).d3.event as any).scale + ')');
    });
    svg.call(zoom);

    // render the graphlib object using (window as any).d3.

    this.asyncRender();
    // setTimeout($scope.asyncRender, 0);

    let initialScale = 0.75;
    zoom
      .translate([(Number(svg.attr('width')) - this.g.graph().width * initialScale) / 2, 20])
      .scale(initialScale)
      .event(svg);

    svg.attr('height', this.g.graph().height * initialScale + 40);
    svg.attr('width', '1062px');
  }


  // let a      = document.createElement('a');
  // a.href     = 'data:image/svg+xml;utf8,' + unescape($('#graphContainer')[0].outerHTML);
  // a.download = 'plot.svg';
  // a.target   = '_blank';
  // document.body.appendChild(a); a.click(); document.body.removeChild(a);


  private createTree(sourceTree: any) {

    let svgGroup: any;

    // calculate total nodes, max label length
    let totalNodes = 0;
    let maxLabelLength = 0;
    // misc. letiables
    let i = 0;
    let duration = 750;
    let root: any;

    // size of the diagram
    let viewerWidth = 1062;
    let viewerHeight = 500;

    let click: any;

    let tree = (window as any).d3.layout.tree()
      .size([viewerHeight, viewerWidth]);

    // define a (window as any).d3 diagonal projection for use by the node paths later on.
    let diagonal = (window as any).d3.svg.diagonal()
      .projection(function(d: any) {
        return [d.y, d.x];
      });

    // a recursive helper function for performing some setup by walking through all nodes

    function visit(parent: any, visitFn: any, childrenFn: any) {
      if(!parent) { return; }

      visitFn(parent);

      let children = childrenFn(parent);
      if(children) {
        let count = children.length;
        for(let i = 0; i < count; i++) {
          visit(children[i], visitFn, childrenFn);
        }
      }
    }

    // call visit function to establish maxLabelLength
    visit(sourceTree, function(d: any) {
      totalNodes++;
      maxLabelLength = Math.max(d.name.length, maxLabelLength);

    }, function(d: any) {
      return d.children && d.children.length > 0 ? d.children : null;
    });


    // sort the tree according to the node names

    function sortTree() {
      tree.sort((a: any, b: any) => {
        return b.name.toLowerCase() < a.name.toLowerCase() ? 1 : -1;
      });
    }


    // define the zoomListener which calls the zoom function on the "zoom" event constrained within the scaleExtents
    let zoomListener = (window as any).d3.behavior.zoom().scaleExtent([0.1, 3]).on('zoom', zoom);

    // define the baseSvg, attaching a class for styling and the zoomListener
    let baseSvg = (window as any).d3.select('#tree-container').append('svg')
      .attr('width', viewerWidth)
      .attr('height', viewerHeight)
      .attr('class', 'overlay')
      .call(zoomListener);

    // append a group which holds all nodes and which the zoom Listener can act upon.
    svgGroup = baseSvg.append('g');

    // sort the tree initially incase the JSON isn't in a sorted order.
    // sortTree();

    // define the zoom function for the zoomable tree

    function zoom() {
      svgGroup.attr('transform', 'translate(' + ((window as any).d3.event as any).translate + ')scale(' + ((window as any).d3.event as any).scale + ')');
    }


    // function to center node when clicked/dropped so node doesn't get lost when collapsing/moving with large amount of children.

    function centerNode(source: any, width?: any, height?: any) {
      let scale = zoomListener.scale();
      let x = -source.y0;
      let y = -source.x0;
      width !== undefined || (width = viewerWidth / 2);
      height !== undefined || (height = viewerHeight / 2);
      x = x * scale + width;
      y = y * scale + height;
      baseSvg.select('g').transition()
        .duration(duration)
        .attr('transform', 'translate(' + x + ',' + y + ')scale(' + scale + ')');
      zoomListener.scale(scale);
      zoomListener.translate([x, y]);
    }

    // toggle children function

    function toggleChildren(d: any) {
      if(d.children) {
        d._children = d.children;
        d.children = null;
      } else if(d._children) {
        d.children = d._children;
        d._children = null;
      }
      return d;
    }

    const update = (source: any) => {
      // compute the new height, function counts total children of root node and sets tree height accordingly.
      // this prevents the layout looking squashed when new nodes are made visible or looking sparse when nodes are removed
      // this makes the layout more consistent.
      let levelWidth = [1];
      let childCount = function(level: any, n: any) {

        if(n.children && n.children.length > 0) {
          if(levelWidth.length <= level + 1) { levelWidth.push(0); }

          levelWidth[level + 1] += n.children.length;
          n.children.forEach(function(d: any) {
            childCount(level + 1, d);
          });
        }
      };
      childCount(0, root);
      let newHeight = (window as any).d3.max(levelWidth) * 25; // 25 pixels per line
      tree = tree.size([newHeight, viewerWidth]);

      // compute the new tree layout.
      let nodes = tree.nodes(root).reverse(),
        links = tree.links(nodes);

      // set widths between levels based on maxLabelLength.
      nodes.forEach(function(d: any) {
        d.y = (d.depth * (maxLabelLength * 10)); // maxLabelLength * 10px
        // alternatively to keep a fixed scale one can set a fixed depth per level
        // normalize for fixed-depth by commenting out below line
        // d.y = (d.depth * 500); //500px per level.
      });

      // update the nodes…
      const node = svgGroup.selectAll('g.node')
        .data(nodes, function(d: any) {
          return d.id || (d.id = ++i);
        });

      const mouseover = (d: any) => {
        this.treeSelectedNode.name = d.name;
        this.treeSelectedNode.gini = Math.floor(parseFloat(d.gini) * 1000000) / 1000000;
        this.treeSelectedNode.samples = d.size;
        this.treeSelectedNode.value = d.modelValue;
        this.treeSelectedNode.similarity = d.similarity;
        this.treeSelectedNode.show = true;
        this.treeSelectedNode.segment_name = d.segment_name;
        this.$scope.$apply();
        // console.log(d);
      };

      // enter any new nodes at the parent's previous position.
      let nodeEnter = node.enter().append('g')
        .attr('class', 'node')
        .attr('transform', function(d: any) {
          return 'translate(' + source.y0 + ',' + source.x0 + ')';
        })
        .on('click', click)
        .on('mouseover', mouseover);

      nodeEnter.append('circle')
        .attr('class', 'nodeCircle')
        .attr('r', 0)
        .style('fill', function(d: any) {
          return d._children ? 'lightsteelblue' : '#fff';
        });

      nodeEnter.append('text')
        .attr('x', function(d: any) {
          return d.children || d._children ? -10 : 10;
        })
        .attr('dy', '.35em')
        .attr('class', 'nodeText')
        .attr('text-anchor', function(d: any) {
          return d.children || d._children ? 'end' : 'start';
        })
        .text(function(d: any) {
          return d.name;
        })
        .style('fill-opacity', 0);

      // update the text to reflect whether node has children or not.
      node.select('text')
        .attr('x', function(d: any) {
          return d.children || d._children ? -10 : 10;
        })
        .attr('text-anchor', function(d: any) {
          return d.children || d._children ? 'end' : 'start';
        })
        .text(function(d: any) {
          return d.name;
        });

      // change the circle fill depending on whether it has children and is collapsed
      node.select('circle.nodeCircle')
        .attr('r', 4.5)
        .style('fill', function(d: any) {
          return d._children ? 'lightsteelblue' : '#fff';
        });

      // transition nodes to their new position.
      let nodeUpdate = node.transition()
        .duration(duration)
        .attr('transform', function(d: any) {
          return 'translate(' + d.y + ',' + d.x + ')';
        });

      // fade the text in
      nodeUpdate.select('text')
        .style('fill-opacity', 1);

      // transition exiting nodes to the parent's new position.
      let nodeExit = node.exit().transition()
        .duration(duration)
        .attr('transform', function(d: any) {
          return 'translate(' + source.y + ',' + source.x + ')';
        })
        .remove();

      nodeExit.select('circle')
        .attr('r', 0);

      nodeExit.select('text')
        .style('fill-opacity', 0);

      // update the links…
      let link = svgGroup.selectAll('path.link')
        .data(links, function(d: any) {
          return d.target.id;
        });

      // enter any new links at the parent's previous position.
      link.enter().insert('path', 'g')
        .attr('class', 'link')
        .attr('d', function(d: any) {
          let o = {
            x: source.x0,
            y: source.y0
          };
          return diagonal({
            source: o,
            target: o
          });
        });

      // transition links to their new position.
      link.transition()
        .duration(duration)
        .attr('d', diagonal);

      // transition exiting nodes to the parent's new position.
      link.exit().transition()
        .duration(duration)
        .attr('d', function(d: any) {
          let o = {
            x: source.x,
            y: source.y
          };
          return diagonal({
            source: o,
            target: o
          });
        })
        .remove();

      // stash the old positions for transition.
      nodes.forEach(function(d: any) {
        d.x0 = d.x;
        d.y0 = d.y;
      });
    };

    // toggle children on click.

    click = (d: any) => {
      if(((window as any).d3.event as any).defaultPrevented) { return; } // click suppressed
      d = toggleChildren(d);
      update(d);
      centerNode(d);
    };


    // define the root
    root = sourceTree;
    root.x0 = viewerHeight / 2;
    root.y0 = 0;

    // layout the tree initially and center on the root node.
    update(root);
    centerNode(root, 100);
  }
}
