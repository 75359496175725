import { StateProvider } from '@uirouter/angularjs';
import { ConstService } from '../../app/shared/const.service';

export const authRouting: any = ($stateProvider: StateProvider) => {
  $stateProvider
    .state('login', {
      url: '/login',
      template: '',
      data: {
        authorizedRoles: [ConstService.USER_ROLES.unauthorized],
        permissions: {
          only: ConstService.USER_ROLES.unauthorized,
          redirectTo: 'layout.pixels'
        }
      }
    })
    .state('registration', {
      url: '/registration'
    })
    .state('email-confirm', {
      url: '/email_confirm'
    })
    .state('error-confirm', {
      url: '/error_confirm'
    })
    .state('reset-pass', {
      url: '/reset_pass'
    })
    .state('pass-confirm', {
      url: '/pass_confirm'
    })
    .state('account', {
      url: '/account'
    });
};
