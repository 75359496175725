import { Component, OnInit } from 'angular-ts-decorators';
import { Classifier } from '../../../core/classes/classes';

@Component({
  selector: 'classifierEditComponent',
  templateUrl: './classifier-edit.pug'
})
export class ClassifierEditComponent implements OnInit {

  static $inject: string[] = [
    '$scope',
    '$stateParams',
    '$state',
    'classifierService',
    'Notification',
    '$http'
  ];
  public isEdit = (this.$state.current.data.mode === 'edit');
  public classifier: any = null;
  public tag: any = null;
  public notFound: any = null;
  public trainPreloader: any = null;
  public preloader: any = null;
  public classifierId = this.$stateParams.classifierId;

  constructor(private $scope: any,
              private $stateParams: any,
              private $state: any,
              private classifierService: any,
              private Notification: any,
              private $http: any) {
  }

  ngOnInit(): void {
    if(this.isEdit) {
      this.preloader = true;
      this.classifierService.getClassifierDetails(this.$stateParams.classifierId)
        .then((classifier: any) => {
          this.classifier = classifier;
          return this.classifierService.getTagDetails(this.classifier.tag);
        }).then((tag: any) => {
        this.tag = tag;
        this.preloader = false;
      }).catch(() => {
        this.notFound = true;
        this.preloader = false;
      });
    } else {
      this.preloader = true;
      this.classifierService.getTagDetails(this.$stateParams.tagId)
        .then((tag: any) => {
          this.tag = tag;
          this.classifier = new Classifier({tag: tag.id});
          this.preloader = false;
        }).catch(() => {
        this.notFound = true;
        this.preloader = false;
      });
    }
  }

  public edit() {
    this.classifier.save().then(() => {
      let message = this.isEdit ? 'Classifier edited' : 'Classifier created';
      if(!this.isEdit) {
        this.classifierService.addClassifier(this.classifier);
        this.$state.go('layout.classifier_edit', {classifierId: this.classifier.id}, {reload: false});
      } else {
        this.classifierService.updateClassifier(this.classifier);
      }
      this.Notification.success(message);
    }).catch((error: any) => {
      this.Notification.error(error.data.error_message);
    });
  }

  public train(classifier_id: any) {
    if(this.trainPreloader) {
      return;
    }
    // todo: (prokopenko) use resources
    this.$http.post('/api/v1/classifier/service/apply-async/classifier_train/', {
      args: [classifier_id]
    }).then((response: any) => {
      let state = response.data.state;
      let task_id = response.data['task-id'];
      this.checkTrainStatus(task_id);
    }).catch((error: any) => {
    }).finally(() => {
    });
  }

  public checkTrainStatus(task_id: any) {
    let CHECK_TIMEOUT = 3000;
    this.trainPreloader = true;
    // todo: (prokopenko) use resources
    this.$http.get('/api/v1/classifier/service/tasks/result/' + task_id + '/').then((response: any) => {
      switch(response.data.state) {
        case 'SUCCESS':
          this.trainPreloader = false;
          this.Notification.success('Train finished successful');
          break;
        case 'PENDING':
          setTimeout(() => {
            this.checkTrainStatus(task_id);
          }, CHECK_TIMEOUT);
          break;
        case 'FAILURE':
          this.trainPreloader = false;
          this.Notification.error('Train finished with error');
          break;
        default:
          this.trainPreloader = false;
          this.Notification.error('Train finished with status: ' + response.data.state);
      }
    }).catch((error: any) => {
      this.trainPreloader = false;
      this.Notification.error('Train finished with error');
    });
  }


}
