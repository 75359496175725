import { IHttpService } from 'angular';
import { Component, OnInit } from 'angular-ts-decorators';
import * as Highcharts from 'highcharts';
import * as _ from 'lodash';
import { AuthService } from '../../../app/core/services/auth.service';
import { ConstService } from '../../../app/shared/const.service';

@Component({
  selector: 'audienceAnalyticsComponent',
  templateUrl: './audience-analytics.pug'
})
export class AudienceAnalyticsComponent implements OnInit {

  static $inject: string[] = [
    '$scope',
    '$state',
    '$q',
    '$http',
    '$stateParams',
    'marketplaceResource',
    'audiencesService',
    'marketplaceService',
    'audiencesResource',
    'utilsService',
    '$uibModal',
    'userMarketplaceService',
    'affinityResource',
    'authService'
  ];
  public mode: any = this.$state.current.data.mode;
  public path: any;
  public preloaderAudience: any = true;
  public preloaderIntersection = true;
  public preloaderAnalytic = true;
  public audienceId = this.$stateParams.audienceId;
  public audience: any = {};
  public notFound = false;
  public analitics: any = {};
  public affinitySectionLoading = true;
  public selectedAffinityCount = 0;
  public allAffinity: any = [];
  public mapData: any[] = [];
  public mapConfig: any;
  public genderChartConfig: any;
  public ageFemaleChartConfig: any;
  public ageMaleChartConfig: any;
  public educationChartConfig: any;
  public incomeChartConfig: any;
  public affinityChartConfig: any;
  public uniqsChartConfig: any;
  public citiesChartConfig: any;
  public populationChartConfig: any;
  public sortList: any[] = [{ label: 'Uniqs', value: 'intersectionSize' }, { label: 'Affinity', value: 'affinity' }];

  private resource = this.audiencesResource;
  private service = this.audiencesService;
  private allCSVdata: any = [];
  private citiesCSVData: any = [];
  private genderCSVData: any = [];
  private affinityChartData: any = [];
  private affinityTreeData: any = [];
  private affintyFilter: any = {};
  private selectedAffinity: any = [];
  private affinityExtremeValue: any = {};
  private affinityCount = 50;
  private affinityCountShow = 50;
  private allAffinityMap: any = {};
  private ACCURACY: any = {
    99: 2.58,
    95: 1.96,
    90: 1.65
  };
  private ageCategories = ['18-24', '25-29', '30-34', '35-39', '40-44', '45-49', '50-54', '55-59', '60+'];
  private educationCategories = ['school', 'college', 'high', 'phd', 'doctoral'];
  private incomeCategories = ['micro', 'small', 'average', 'bigger', 'large'];
  private populationCategories = ['less 100k', '100-400k', '400-700k', 'more 700r'];

  private affinitySortBy: any;

  constructor(private $scope: any,
              private $state: any,
              private $q: any,
              private $http: IHttpService,
              private $stateParams: any,
              private marketplaceResource: any,
              private audiencesService: any,
              private marketplaceService: any,
              private audiencesResource: any,
              private utilsService: any,
              private $uibModal: any,
              private userMarketplaceService: any,
              private affinityResource: any,
              private authService: AuthService) {
  }

  ngOnInit(): void {
    this.affinitySortBy = this.sortList[0];

    if(this.mode === 'market') {
      this.resource = this.marketplaceResource;
      this.service = this.marketplaceService;
    }
    $.each((window as any).Highcharts.maps['custom/world'].features, (index: any, feature: any) => {
      this.mapData.push({
        key: feature.properties['hc-key'],
        value: 0
      });
    });
    this.mapConfig = {
      options: {
        legend: {
          layout: 'vertical',
          align: 'left',
          verticalAlign: 'bottom'
        },
        colorAxis: {
          min: 0,
          stops: [
            [0, '#FFFFFF'],
            [0.001, '#EFEFFF'],
            [0.5, (window as any).Highcharts.getOptions().colors[0]],
            [1, ((window as any).Highcharts.Color((window as any).Highcharts.getOptions().colors[0]) as any).brighten(-0.5).get()]
          ]
        }
      },
      chartType: 'map',
      title: {
        text: ''
      },
      series: [{
        data: this.mapData,
        mapData: (window as any).Highcharts.maps['custom/world'],
        joinBy: ['hc-key', 'key'],
        name: 'Uniqs',
        states: {
          hover: {
            color: (window as any).Highcharts.getOptions().colors[2]
          }
        },
        dataLabels: {
          enabled: false,
          formatter: function() {
            return (this.point.properties && this.point.properties['hc-a2']);
          }
        }
      }
      ]
    };
    this.resource.get({id: this.audienceId}).$promise.then((response: any) => {
      this.audience = response.data;
      this.getPath();
      const responseList: any[] = [this.resource.top_cities({id: this.audienceId}).$promise,
        this.resource.analytics({id: this.audienceId}).$promise];
      this.$q.all(responseList).then((responses: any) => {
        this.getTopCities(responses[0].data);
        this.getAnalytics(responses[1].data);
        this.getPercentage();
        this.getCSVdata();
      }).finally(() => {
        this.preloaderAnalytic = false;
      });
      const intersectionsRequest: any = this.resource.intersections({
        id: this.audienceId,
        limit: 10000
      }).$promise;
      this.$q.all([
        intersectionsRequest,
        this.userMarketplaceService.getAudienceList(),
        this.affinityResource.get_profile({id: this.audienceId}).$promise
      ]).then((responses: any) => {
        this.joinResponseData(responses[0].data, responses[1]);
        this.setFilter(responses[2].data);
        this.setIntersections();
        this.selectedAffinityCount = this.selectedAffinity.length;
      }).finally(() => {
        this.preloaderIntersection = false;
      });
    }).catch(() => {
      this.notFound = true;
    }).finally(() => {
      this.preloaderAudience = false;
    });
    this.mapConfig.series[0].allAreas = true;


    this.genderChartConfig = this.getChartConfig({title: 'Gender', type: 'pie'});
    this.ageFemaleChartConfig = this.getChartConfig({
      title: 'Females',
      type: 'bar',
      opposite: true,
      legend: false,
      categories: this.ageCategories,
      color: ConstService.COLORS.RED
    });
    this.ageMaleChartConfig = this.getChartConfig({
      title: 'Males',
      type: 'bar',
      legend: false,
      categories: this.ageCategories,
      color: ConstService.COLORS.BLUE
    });
    this.educationChartConfig = this.getChartConfig({
      title: 'Education',
      type: 'column',
      legend: false,
      categories: this.educationCategories,
      color: ConstService.COLORS.GREEN
    });
    this.incomeChartConfig = this.getChartConfig({
      title: 'Income',
      type: 'column',
      legend: false,
      categories: this.incomeCategories,
      color: ConstService.COLORS.ORANGE
    });
    this.affinityChartConfig = this.getChartConfig({
      title: 'Affinity',
      type: 'bar',
      legend: false,
      xAxisVisible: false,
      color: ConstService.COLORS.RED,
      events: {
        render: () => {
          this.affinitySectionLoading = false;
        }
      }
    });
    this.uniqsChartConfig = this.getChartConfig({
      title: 'Uniqs',
      type: 'bar',
      legend: false,
      xAxisVisible: true,
      color: ConstService.COLORS.RED,
      events: {
        render: () => {
          this.affinitySectionLoading = false;
        }
      }
    });
    this.citiesChartConfig = this.getChartConfig({
      title: 'Cities',
      type: 'column',
      legend: false,
      xAxisVisible: true,
      color: ConstService.COLORS.ORANGE,
      width: 531
    });
    this.populationChartConfig = this.getChartConfig({
      title: 'Population',
      type: 'pie',
      categories: this.populationCategories
    });
  }

  public cleanIntersections() {
    this.affinityCount = 50;
    this.affinityCountShow = 50;

    this.affinityResource.delete_profile({id: this.audienceId}).$promise.then();

    this.setFilter('');
    this.setIntersections();

    this.selectedAffinityCount = this.selectedAffinity.length;
  }

  public exportAllCSV() {
    if(this.preloaderAnalytic || this.preloaderIntersection) { return; }

    let self = this;
    let header_string = '"' + this.audience.segment_name + '", "' + this.audience.segment_id + '"\n\n';
    let modalInstance;
    let config = {
      animation: true,
      component: 'modalCSVOrderComponent',
      size: 'sm',
      backdrop: 'static'
    };
    let onConfirm: any = (data: any) => {
      if(!data) { return; }

      let order = data.value.split('-');
      let CSVData = (self[order[0]].concat(self[order[1]])).concat(self[order[2]]);
      this.utilsService.exportCSVTable(CSVData, this.audience.segment_id + '_' + this.audience.size + '_analytics', header_string);
    };

    modalInstance = this.$uibModal.open(config);
    modalInstance.result.then(onConfirm);
  }

  public exportFullIntersections() {
    let segment_id = this.audience.segment_id;
    let header_string = '"' + this.audience.segment_name + '", "' + segment_id + '"\n\n';
    let affinity = this.affinityToFormatCSV(this.allAffinity);

    this.utilsService.exportCSVTable(affinity, segment_id + '_full_affinity', header_string);
  }

  public openModalRange() {

    let modalInstance;

    let config = {
      animation: true,
      component: 'modalFilterSizeComponent',
      size: 'ls',
      backdrop: 'static',
      resolve: {
        intersections: {
          affinity: this.affinityChartData,
          tree: this.affinityTreeData,
          filter: this.affintyFilter,
          count: this.affinityCount,
          extremeValue: this.affinityExtremeValue
        }
      }
    };
    let onConfirm: any = (data: any) => {
      if(!data) { return; }

      this.selectedAffinity = this.affinityChartData.filter((item: any) => { return item.isShow; }).map((item: any) => {
        return item.id;
      });

      this.selectedAffinityCount = this.selectedAffinity.length;
      this.affintyFilter = data.filter;
      this.affinityCount = data.count;
      this.affinityCountShow = data.count;

      this.affinityResource.save_profile(this.getSendData()).$promise.then();

      this.setIntersections();
    };

    modalInstance = this.$uibModal.open(config);
    modalInstance.result.then(onConfirm);
  }

  public showMoreAffinity() {
    this.affinityCountShow += 20;
    this.setIntersections();
  }

  public checkShowMore() {
    return this.affinityCountShow < this.allAffinity.length;
  }


  public getToleranceInfo(chartConfig: any) {
    let averageErrorPercent = chartConfig.series[0].averageErrorPercent;
    if(!isFinite(averageErrorPercent) || !chartConfig.series[0].accuracy) {
      return '';
    }
    averageErrorPercent = averageErrorPercent === 0 ? '<0.1%' : averageErrorPercent + '%';
    return `<div class='analytics__popover'><b>Tolerance</b><br>sample: ${chartConfig.series[0].sampleSize}
    <br>probability: ${chartConfig.series[0].accuracy}%<br>deviation: ${averageErrorPercent}</div>`;
  }

  private getNormalizeSize(analytic: any) {
    if(analytic.default_percent && analytic.provider_percent) {
      return Math.floor(analytic.size * analytic.default_percent / analytic.provider_percent);
    } else {
      return analytic.size;
    }
  }

  private getFakeSeriesData(config: any) {
    if(config.options.title.text === 'Gender') {
      config.series[0].data[0] = {
        name: 'Male',
        size: 50,
        default_percent: 0.5,
        y: 50,
        color: ConstService.COLORS.BLUE
      };
      config.series[0].data[1] = {
        name: 'Female',
        size: 50,
        default_percent: 0.5,
        y: 50,
        color: ConstService.COLORS.RED
      };
      return;
    }
    if(config.options.title.text === 'Population') {
      config.series[0].data[0] = {
        name: 'less 100k',
        size: 40,
        default_percent: 0.25,
        y: 50,
        color: ConstService.COLORS.ORANGE
      };
      config.series[0].data[1] = {
        name: '100-400k',
        size: 60,
        default_percent: 0.25,
        y: 60,
        color: ConstService.COLORS.BLUE
      };
      config.series[0].data[2] = {
        name: '400-700k',
        size: 70,
        default_percent: 0.25,
        y: 70,
        color: ConstService.COLORS.GREEN
      };
      config.series[0].data[3] = {
        name: 'more 700k',
        size: 30,
        default_percent: 0.25,
        y: 30,
        color: ConstService.COLORS.RED
      };
      return;
    }
    if(config.options.title.text === 'Cities') {
      config.series[0].data = [{
        name: 'Moscow (RU)',
        y: 1000,
        size: 1000
      }, {
        name: 'St. Petersburg (RU)',
        y: 500
      }, {
        name: 'New York (US)',
        y: 300
      }, {
        name: 'Paris (FR)',
        y: 200
      }, {
        name: 'London (UK)',
        y: 100
      }];
      return;
    }
    config.series[0].data.forEach(function(data: any) {
      data.y = data.size = Math.random() * 100 / config.xAxis.categories.length;
    });
  }

  private setIntersections() {
    let allAffinity = this.affinityChartData.filter((item: any) => {
      return item.affinity >= this.affintyFilter.minAffinity && item.affinity <= this.affintyFilter.maxAffinity
        && item.intersectionSize >= this.affintyFilter.minSize && item.intersectionSize <= this.affintyFilter.maxSize && item.isShow;
    });

    allAffinity.sort((a, b) => b[this.affinitySortBy.value] - a[this.affinitySortBy.value]);

    let affinityTotal = allAffinity.length;
    let showCount = (affinityTotal > this.affinityCountShow) ? this.affinityCountShow : affinityTotal;
    let showItems: any[] = [];

    _.each(allAffinity, (item: any, index: any) => {
      let name = allAffinity[index].segment_name;

      this.allAffinityMap[name] = index;

      if(index < showCount) {
        showItems.push(item);
      }
    });

    this.allAffinity = allAffinity;
    this.AddIntersectionsToFullCSV(allAffinity);
    this.showIntersections(showItems);
  }

  private showIntersections(intersectionList: any) {
    let max_affinity = intersectionList.reduce((max: any, a: any) => {
      return a.affinity > max ? a.affinity : max;
    }, 100) - 100;
    let min_affinity = Math.abs(intersectionList.reduce((min: any, a: any) => {
      return a.affinity < min ? a.affinity : min;
    }, 100) - 100);

    this._cleanIntersections();

    // todo: (prokopenko) magic numbers??
    this.affinityChartConfig.options.chart.height = 20 * intersectionList.length + 62;
    this.affinityChartConfig.options.chart.width = 400;
    this.uniqsChartConfig.options.chart.height = 20 * intersectionList.length + 62;
    this.uniqsChartConfig.options.chart.width = 600;

    _.each(intersectionList, (item: any) => {
      let affinity = Math.floor(item.affinity);

      this.affinityChartConfig.series[0].data.push({
        y: affinity > 100 ?
          (affinity - 100) / max_affinity * 100 :
          (affinity - 100) / min_affinity * 100,
        real: affinity,
        color: affinity > 100 ? ConstService.COLORS.GREEN : ConstService.COLORS.ORANGE
      });
      this.affinityChartConfig.xAxis.categories.push(item.segment_name);
      this.uniqsChartConfig.series[0].data.push(item.intersectionSize);
      this.uniqsChartConfig.xAxis.categories.push(item.segment_name);
    });
  }

  private _cleanIntersections() {
    this.affinityChartConfig.series[0].data = [];
    this.affinityChartConfig.xAxis.categories = [];
    this.affinityChartConfig.xAxis.categories = [];
    this.uniqsChartConfig.series[0].data = [];
    this.uniqsChartConfig.xAxis.categories = [];
    this.uniqsChartConfig.xAxis.categories = [];
  }

  private AddIntersectionsToFullCSV(intersections: any) {
    let formatted = this.affinityToFormatCSV(intersections);
    this.allCSVdata = formatted;
  }

  private affinityToFormatCSV(intersections: any) {
    let formatted: any[] = [];

    formatted.push(['Affinity']);
    formatted.push(['segment name', 'uniqs', 'affinity']);

    _.each(intersections, (item: any) => {
      formatted.push([item.segment_name, item.intersectionSize, item.affinity]);
    });

    formatted.push([]);
    return formatted;
  }

  private getTopCities(cities: any) {
    cities.sort((a: any, b: any) => {
      return b.size - a.size;
    });
    this.citiesCSVData.push(['Top Cities']);
    this.citiesCSVData.push(['city', 'country', 'value']);
    cities.forEach((top: any) => {
      this.citiesCSVData.push([top.city_name, top.country_iso, this.getNormalizeSize(top)]);
      let city_name = top.city_name + ' (' + top.country_iso + ')';
      this.citiesChartConfig.series[0].data.push({
        y: this.getNormalizeSize(top),
        name: city_name
      });
      this.citiesChartConfig.xAxis.categories.push(city_name);
    });
    this.citiesCSVData.push([]);
    this.citiesCSVData.push(['Countries']);
    this.citiesCSVData.push(['iso', 'country', 'uniqs']);
    this.mapData.forEach((country: any) => {
      this.citiesCSVData.push([country.key, ConstService.COUNTRIES[country.key.toUpperCase()], country.value]);
    });
    this.citiesCSVData.push([]);
  }

  private getAnalytics(analytics: any) {
    analytics.forEach((item: any) => {
      let category;
      switch(item.group_name) {
        case 'gender':
          this.genderChartConfig.series[0].data.push({
            name: item.category_name,
            size: this.getNormalizeSize(item),
            default_percent: item.default_percent,
            y: this.getNormalizeSize(item),
            color: item.category_name.toLowerCase() === 'male' ? ConstService.COLORS.BLUE : ConstService.COLORS.RED
          });
          break;
        case 'education':
          switch(item.category_name) {
            case 'SECONDARY':
              category = this.educationChartConfig.series[0].data[0];
              category.name = 'school';
              break;
            case 'SECONDARY_SPECIAL':
              category = this.educationChartConfig.series[0].data[1];
              category.name = 'college';
              break;
            case 'HIGHER_1':
            case 'HIGHER_2':
              category = this.educationChartConfig.series[0].data[2];
              category.name = 'high';
              break;
            case 'PHD':
              category = this.educationChartConfig.series[0].data[3];
              category.name = 'phd';
              break;
            case 'DOCTORAL_DEGREE':
              category = this.educationChartConfig.series[0].data[4];
              category.name = 'doctoral';
              break;
          }
          if(!category) {
            break;
          }
          category.size += this.getNormalizeSize(item);
          !!item.default_percent && (category.default_percent += item.default_percent);
          category.y += this.getNormalizeSize(item);
          break;
        case 'income':
          category = this.incomeChartConfig.series[0];
          switch(item.category_name) {
            case 'micro':
              category = category.data[0];
              category.name = 'micro';
              break;
            case 'small':
              category = category.data[1];
              category.name = 'small';
              break;
            case 'average':
              category = category.data[2];
              category.name = 'average';
              break;
            case 'bigger':
              category = category.data[3];
              category.name = 'bigger';
              break;
            case 'large':
              category = category.data[4];
              category.name = 'large';
              break;
          }
          if(!category) {
            break;
          }
          category.size += this.getNormalizeSize(item);
          !!item.default_percent && (category.default_percent += item.default_percent);
          category.y += this.getNormalizeSize(item);
          break;
        case 'male_age':
        case 'female_age':
          category = item.group_name === 'male_age' ?
            this.ageMaleChartConfig.series[0] :
            this.ageFemaleChartConfig.series[0];
          switch(item.category_name.substring(2)) {
            case 'A17_21':
            case 'A22_24':
              category = category.data[0];
              category.name = '18-24';
              break;
            case 'A25_29':
              category = category.data[1];
              category.name = '25-29';
              break;
            case 'A30_34':
              category = category.data[2];
              category.name = '30-34';
              break;
            case 'A35_39':
              category = category.data[3];
              category.name = '35-39';
              break;
            case 'A40_44':
              category = category.data[4];
              category.name = '40-44';
              break;
            case 'A45_49':
              category = category.data[5];
              category.name = '45-49';
              break;
            case 'A50_54':
              category = category.data[6];
              category.name = '50-54';
              break;
            case 'A55_59':
              category = category.data[7];
              category.name = '55-59';
              break;
            case 'A60_64':
            case 'A65_00':
              category = category.data[8];
              category.name = '60+';
              break;
          }
          if(!category) {
            break;
          }
          category.size += this.getNormalizeSize(item);
          !!item.default_percent && (category.default_percent += item.default_percent);
          category.y += this.getNormalizeSize(item);
          break;
        case 'city_sizes':
          category = this.populationChartConfig.series[0];
          switch(item.category_name) {
            case 'small':
              category = category.data[0];
              category.name = 'less 100k';
              category.color = ConstService.COLORS.ORANGE;
              break;
            case 'normal':
              category = category.data[1];
              category.name = '100-400k';
              category.color = ConstService.COLORS.BLUE;
              break;
            case 'big':
              category = category.data[2];
              category.name = '400-700k';
              category.color = ConstService.COLORS.GREEN;
              break;
            case 'large':
              category = category.data[3];
              category.name = 'more 700k';
              category.color = ConstService.COLORS.RED;
              break;
          }
          if(!category) {
            break;
          }
          category.size = this.getNormalizeSize(item);
          category.default_percent = item.default_percent;
          category.y = this.getNormalizeSize(item);
          break;
        case 'country':
          this.setGeoData(item.category_name, this.getNormalizeSize(item));
          break;
      }
    });
  }

  private getPercentage() {
    let segmentSize = this.audience.size;
    let accuracy = '95';
    let z = this.ACCURACY[accuracy];
    [
      this.educationChartConfig,
      this.ageFemaleChartConfig,
      this.ageMaleChartConfig,
      this.incomeChartConfig,
      this.genderChartConfig,
      this.citiesChartConfig,
      this.populationChartConfig
    ].forEach((config: any) => {
      let series = config.series[0];
      let sampleSize = series.data.reduce((x: any, item: any) => {
        return x + item.y;
      }, 0);
      series.sampleSize = series.data.reduce((x: any, item: any) => {
        return x + this.getNormalizeSize(item);
      }, 0);
      if(series.sampleSize < 10) {
        this.getFakeSeriesData(config);
      }
      series.data.forEach((item: any, index: any, arr: any) => {
        item.percent = item.y / sampleSize * 100;
      });
      series.accuracy = accuracy;
      series.averageErrorPercent = 0;
      series.data.forEach((item: any, index: any, arr: any) => {
        let defaultPercent = item.default_percent ? item.default_percent : 0.5;
        if(!!segmentSize) {
          item.error = z * Math.sqrt(defaultPercent * (1 - defaultPercent) * (segmentSize - series.sampleSize) / (series.sampleSize * segmentSize));
        } else {
          item.error = z * Math.sqrt(defaultPercent * (1 - defaultPercent) / series.sampleSize);
        }
        item.error_percent = Math.floor(item.error * 1000) / 10;
        series.averageErrorPercent += item.error_percent / arr.length;
      });
      series.averageErrorPercent = Math.floor(series.averageErrorPercent * 10) / 10;
    });
  }

  private getCSVdata() {
    [
      this.educationChartConfig,
      this.ageFemaleChartConfig,
      this.ageMaleChartConfig,
      this.incomeChartConfig,
      this.populationChartConfig
    ].forEach((config: any) => {
      this.genderCSVData.push([config.options.title.text]);
      for(let i = 0; i < config.xAxis.categories.length; i++) {
        this.genderCSVData.push([config.xAxis.categories[i], this.getNormalizeSize(config.series[0].data[i])]);
      }
      this.genderCSVData.push([]);
    });
    this.genderCSVData.push([this.genderChartConfig.options.title.text]);
    for(let i = 0; i < this.genderChartConfig.series[0].data.length; i++) {
      this.genderCSVData.push([this.genderChartConfig.series[0].data[i].name,
        this.getNormalizeSize(this.genderChartConfig.series[0].data[i])]);
    }
    this.genderCSVData.push([]);
  }

  private setGeoData(country: any, size: any) {
    this.mapData.forEach((item: any) => {
      if(item.key === country.toLowerCase()) {
        item.value = size;
      }
    });
  }

  private getPath() {
    this.citiesChartConfig.options.plotOptions.column.dataLabels.enabled = false;
    this.service.getAudienceList().then((audiences: any) => {
      this.path = this.audience.segment_name;
      let parent_segment_id = this.audience.parents[0];
      let segment = this.service.getAudience(this.audience.id);
      segment && (this.audience.size = segment.size);
      this.citiesChartConfig.options.plotOptions.column.dataLabels.enabled = true;
      while(parent_segment_id) {
        let parent = null;
        for(let i = 0; i < audiences.length; i++) {
          if(parent_segment_id === audiences[i].segment_id) {
            parent = audiences[i];
            this.path = parent.segment_name + ' » ' + this.path;
            parent_segment_id = parent.parents[0];
            break;
          }
        }
        parent || (parent_segment_id = null);
      }
    });
  }

  private joinResponseData(affinityList: any, marketplaceList: any) {
    this.affinityChartData = [];
    this.affinityTreeData = [];

    affinityList = affinityList.filter((item: any) => {
      return item.name !== 'Regular users';
    });

    marketplaceList.forEach((segment: any) => {
      affinityList.forEach((item: any) => {
        if(item.id === segment.id) {
          segment.affinity = item.affinity;
          segment.intersectionSize = this.getNormalizeSize(item);
        }
      });

      if(!segment.affinity) { segment.intersectionSize = 0; }

      segment.nodes = [];
      segment.isFilterAllow = true;
      segment.isVisibleTree = true;
      segment.isShow = 0;
    });

    marketplaceList.sort((a: any, b: any) => {
      return b.intersectionSize - a.intersectionSize;
    });

    marketplaceList.forEach((segment: any) => {
      if(!segment.parent) {
        this.affinityTreeData.push(segment);
      } else {
        marketplaceList.forEach((item: any) => {
          if(segment.parent === item.segment_id) { item.nodes.push(segment); }
        });
      }

      if(segment.affinity) { this.affinityChartData.push(segment); }
    });

    this.affinityTreeData = this.affinityTreeData.filter((item: any) => {
      return filterEmptyNodes(item);
    });

    function filterEmptyNodes(node: any) {
      node.nodes = node.nodes.filter((item: any) => {
        return filterEmptyNodes(item);
      });
      return node.nodes.length || node.affinity;
    }
  }

  private setFilter(data: any) {
    this.affinityExtremeValue.minSize = 0;
    this.affinityExtremeValue.minAffinity = 0;
    this.affinityExtremeValue.maxSize = (this.affinityChartData.length && this.affinityChartData[0].intersectionSize) ? this.affinityChartData[0].intersectionSize : 0;
    this.affinityExtremeValue.maxAffinity = 0;

    this.affinityChartData.forEach((item: any) => {
      this.affinityExtremeValue.maxAffinity = (item.affinity > this.affinityExtremeValue.maxAffinity) ? item.affinity : this.affinityExtremeValue.maxAffinity;
    });

    if(!data) {
      this.selectedAffinity = this.affinityChartData.map((item: any) => { return item.id; });

      this.affintyFilter.minSize = 0;
      this.affintyFilter.minAffinity = 0;
      this.affintyFilter.maxSize = Math.ceil(this.affinityExtremeValue.maxSize);
      this.affintyFilter.maxAffinity = Math.ceil(this.affinityExtremeValue.maxAffinity);
    } else {
      this.selectedAffinity = data.selected_segments;

      this.affintyFilter.minSize = data.uniqs_min;
      this.affintyFilter.minAffinity = data.affinity_min;
      this.affintyFilter.maxSize = (data.uniqs_max === 0) ? Math.ceil(this.affinityExtremeValue.maxSize) : data.uniqs_max;
      this.affintyFilter.maxAffinity = (data.affinity_max === 0) ? Math.ceil(this.affinityExtremeValue.maxAffinity) : data.affinity_max;
    }

    this.affinityChartData.forEach((item: any) => {
      item.isShow = (this.selectedAffinity.indexOf(item.id) !== -1) ? 1 : 0;
    });
  }

  private getSendData() {
    let data: any = {};

    data.selected_segments = this.selectedAffinity;
    data.uniqs_min = this.affintyFilter.minSize;
    data.affinity_min = this.affintyFilter.minAffinity;
    data.uniqs_max = (this.affintyFilter.maxSize === Math.ceil(this.affinityExtremeValue.maxSize)) ? 0 : this.affintyFilter.maxSize;
    data.affinity_max = (this.affintyFilter.maxAffinity === Math.ceil(this.affinityExtremeValue.maxAffinity)) ? 0 : this.affintyFilter.maxAffinity;

    data.name = this.audience.segment_name + '(' + this.audience.segment_id + ')';
    data.id = this.audienceId;
    data.account = this.authService.getSelectedAccount().account_id;

    return data;
  }

  private getChartConfig(opts: any) {
    let config: any = {};
    let title = opts.title || '';
    let type = opts.type || 'bar';
    let opposite = !!opts.opposite;
    let legend = (typeof opts.legend === 'boolean') ? opts.legend : true;
    let xAxisVisible = (typeof opts.xAxisVisible === 'boolean') ? opts.xAxisVisible : true;
    let categories = opts.categories || [];
    let color = opts.color || '';
    let width = opts.width || null;
    let height = opts.height || null;
    let events = opts.events || {};

    config.options = {
      title: {
        text: title
      },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: type,
        width: width,
        height: height,
        events: events
      },
      tooltip: {
        enabled: false,
        pointFormat: '<b>{point.name}: {point.percentage:.1f}%</b>',
        style: {
          padding: 10
        }
      },
      credits: {
        enabled: false
      },
      exporting: {
        enabled: true,
        buttons: {
          contextButton: {
            menuItems: [{
              text: 'Export to PNG',
              onclick: function() {
                this.exportChart();
              }
            }]
          }
        }
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
            inside: false,
            color: '#000000',
            formatter: function() {
              let dataSum = this.series.data.reduce((x: any, item: any) => {
                return x + item.total;
              }, 0);
              let pcnt = (this.y / dataSum) * 100;
              return (window as any).Highcharts.numberFormat(pcnt, 0, ',') + '%';
            }
          },
          events: events
        },
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },
          showInLegend: true
        },
        column: {
          dataLabels: {
            enabled: true,
            inside: false,
            color: '#000000',
            formatter: function() {
              let dataSum = this.series.data.reduce((x: any, item: any) => {
                return x + item.total;
              }, 0);
              let pcnt = (this.y / dataSum) * 100;
              return (window as any).Highcharts.numberFormat(pcnt, 0, ',') + '%';
            }
          }
        },
        series: {
          stacking: 'normal'
        }
      },
      legend: {
        enabled: legend
      }
    };
    config.xAxis = {
      categories: categories,
      opposite: opposite,
      title: {
        text: null
      },
      visible: xAxisVisible
    };
    config.yAxis = {
      visible: false,
      reversed: opposite
    };
    config.series = [{
      name: title,
      color: color,
      // colorByPoint: true,
      data: []
    }];

    for(let i = 0; i < categories.length; i++) {
      config.series[0].data.push({
        name: '',
        size: 0,
        default_percent: 0,
        y: 0
      });
    }
    const self: any = this;


    function getSVG(charts: any, options: any, callback: any) {
      let svgArr: any[] = [],
        top = 0,
        width = 0,
        i: any,
        svgResult = (svgres: any) => {
          // let svg = svgres.replace('<svg', '<g transform="translate(0,' + top + ')" ');
          let svg = svgres.replace('<svg', '<g transform="translate(' + width + ', 0)"');
          svg = svg.replace('</svg>', '</g>');
          top = Math.max(top, charts[i].chartHeight);
          width += charts[i].chartWidth;
          svgArr.push(svg);
          if(svgArr.length === charts.length) {
            callback('<svg height="' + top + '" width="' + width + '" version="1.1" xmlns="http://www.w3.org/2000/svg">' + svgArr.join('') + '</svg>');
          }
        };
      for(i = 0; i < charts.length; ++i) {
        charts[i].getSVGForLocalExport(options, {}, () => {
          console.log('Failed to get SVG');
        }, svgResult);
      }
    }

    function exportCharts(charts: any, ext_options: any) {
      let default_options = (window as any).Highcharts.getOptions().exporting;
      (default_options as any).libURL = default_options.url;
      let options = _.merge(default_options, ext_options);

      charts.forEach((chart: any) => {
        let sourceWidth = chart.options.exporting.sourceWidth;
        chart.options.exporting.sourceWidth = sourceWidth ? sourceWidth : chart.chartWidth;
      });

      getSVG(charts, options, function(svg: any) {
        (window as any).Highcharts.downloadSVGLocal(svg, options);
      });
    }

    function exportAgeGender() {
      let charts = Array.prototype.map.call(document.getElementsByClassName('analytics__age_gander_chart'),
        function(el: any) {
          return (window as any).Highcharts.charts[el.getAttribute('data-highcharts-chart')];
        });
      exportCharts(charts, null);
    }

    switch(title) {
      case 'Gender':
        config.options.exporting.enabled = false;
        config.options.tooltip.pointFormat = '<b>{point.percentage:.1f}%</b>';
        config.options.tooltip.enabled = true;
        break;
      case 'Population':
        config.options.tooltip.pointFormat = '<b>{point.percentage:.1f}%</b>';
        config.options.tooltip.enabled = true;
        break;
      case 'Females':
        config.options.exporting.enabled = false;
        config.options.exporting.buttons.contextButton.menuItems[0].onclick = exportAgeGender;
        config.options.tooltip.pointFormat = '<b>{point.name}: {point.percent:.1f}%</b>';
        break;
      case 'Males':
        config.options.exporting.buttons.contextButton.menuItems[0].onclick = exportAgeGender;
        config.options.tooltip.pointFormat = '<b>{point.name}: {point.percent:.1f}%</b>';
        break;
      case 'Education':
        config.options.tooltip.pointFormat = '<b>{point.name}: {point.percent:.1f}%</b>';
        break;
      case 'Income':
        config.options.tooltip.pointFormat = '<b>{point.name}: {point.percent:.1f}%</b>';
        break;
      case 'Uniqs':
        config.options.tooltip.pointFormat = '<b>uniqs: {point.y}</b>';
        config.options.plotOptions.bar.dataLabels.enabled = false;
        config.options.tooltip.enabled = true;
        config.series[0].pointWidth = 20;
        // this.xAxis.width = 200;
        config.xAxis.labels = {
          style: {
            // "width": "300px",
            // "min-width": "300px",
            'font-size': '14px'
          }
          // useHTML : true
        };
        config.xAxis.plotLines = [];
        config.options.plotOptions.series = {
          cursor: 'pointer',
          point: {
            events: {
              mouseOver: () => {
                let chart = this.affinityChartConfig.getHighcharts();
                let category = config.category;
                chart.series[0].data.forEach(function(item: any, i: any) {
                  if(item.category === category) {
                    item.setState('hover');
                    chart.tooltip.refresh(chart.series[0].data[i]);
                  }
                });
              },
              mouseOut: () => {
                let chart = this.affinityChartConfig.getHighcharts();
                let category = config.category;
                chart.series[0].data.forEach(function(item: any, i: any) {
                  if(item.category === category) {
                    item.setState();
                    chart.tooltip.hide();
                  }
                });
              }
            }
          }
        };
        break;
      case 'Affinity':
        config.options.tooltip.pointFormat = 'Affinity index: <b>{point.real}</b>';
        config.options.tooltip.enabled = true;
        config.options.plotOptions.bar.dataLabels.enabled = false;
        config.series[0].pointWidth = 20;
        config.yAxis.min = -100;
        config.yAxis.max = 100;
        config.xAxis.plotLines = [];
        config.options.plotOptions.series = {
          cursor: 'pointer',
          point: {
            events: {
              mouseOver: function() {
                let chart = self.uniqsChartConfig.getHighcharts();
                let category = this.category;
                chart.series[0].data.forEach(function(item: any, i: any) {
                  if(item.category === category) {
                    item.setState('hover');
                    chart.tooltip.refresh(chart.series[0].data[i]);
                  }
                });
              },
              mouseOut: function() {
                let chart = self.uniqsChartConfig.getHighcharts();
                let category = this.category;
                chart.series[0].data.forEach(function(item: any, i: any) {
                  if(item.category === category) {
                    item.setState();
                    chart.tooltip.hide();
                  }
                });
              }
            }
          }
        };
        break;
      case 'Cities':
        config.options.plotOptions.column.dataLabels.formatter = function() {
          let dataSum = self.audience.size;
          if(!dataSum) {
            return '';
          }
          let pcnt = (this.y / dataSum) * 100;
          return (window as any).Highcharts.numberFormat(pcnt, 0, ',') + '%';
        };
        break;
      default:
        config.options.tooltip.pointFormat = '<b>{point.name}: {point.percentage:.1f}%</b>';
        break;
    }
    return config;
  }
}
