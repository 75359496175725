import { Injectable } from '@angular/core';
import { DjangoResponse } from './types/django-response.type';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  static getDjangoData(response: DjangoResponse<any>): any {
    return response.data;
  }
}
